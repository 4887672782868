import { map } from "lodash";
import clipboard from "clipboardy";
import copy from "copy-to-clipboard";
import { useMemo, useState } from "react";

import { Entity, HasCode, toTitleOrName } from "@api";

import { toLink } from "@utils/navigation";
import { useAsyncEffect } from "@utils/effects";
import { Maybe, when } from "@utils/maybe";

import { showSuccess } from "@ui/notifications";

const toCode = (i: Maybe<HasCode>) => (i?.code ? i.code : "");

export const copyLinks = (items: Entity[]) =>
  copy(map(items, toLink).join("\r\n"));

export const copyCodes = (items: HasCode[]) =>
  copy(map(items, toCode).join("\r\n"));

export const copyMarkdown = (items: Entity[]) =>
  copy(
    map(
      items,
      (i) =>
        // prettier-ignore
        `${items?.length > 1 ? "- " : ""}[${when(toCode(i as Maybe<HasCode>), (c) => c + ": ")}${toTitleOrName(i)}](${toLink(i)})`
    ).join("\r\n")
  );

export const useClipboardValue = (defaultValue?: string) => {
  const [value, setValue] = useState<Maybe<string>>(defaultValue);

  useAsyncEffect(async () => {
    const inClipboard = await clipboard.read();

    if (inClipboard) {
      setValue(inClipboard);
    }
  }, []);

  return value;
};

export const useClipboardLink = (defaultValue?: string) => {
  const value = useClipboardValue(defaultValue);

  return useMemo(
    () => (!!value?.startsWith("http") ? value : undefined),
    [value]
  );
};

export const copyToClipboard = (value: string) => {
  copy(value);
  showSuccess("Copied to clipboard");
};
