import { EntityType, SettingsData } from "@api";
import { Maybe, when } from "@utils/maybe";
import { plural, sentenceCase } from "@utils/string";

export const toSettingKey = (pkg: string, key: string) => `${pkg}.${key}`;

export interface ToLabelOptions {
  plural?: boolean;
  case?: "lower" | "title";
}

export const toEntityLabel = (
  type: Maybe<EntityType>,
  options: ToLabelOptions,
  settings: SettingsData
) => {
  const alias =
    when(type, (t) => settings[`${t}_alias`] as Maybe<string>) ||
    (type === "outcome" && "objective") ||
    type ||
    "item";

  // Convert case first since it respects acronyms
  const cased =
    options?.case === "lower"
      ? sentenceCase(alias).toLowerCase()
      : sentenceCase(alias);

  return plural(cased, !!options?.plural ? 2 : 1);
};
