import { useEffect, useMemo, useState } from "react";
import { map, find, isArray, isFunction } from "lodash";

import { DatabaseID, Entity, PropertyValueRef, Update } from "@api";
import {
  WorkflowData,
  WorkflowAction,
  WorkflowContext,
  WorkflowSuggestion,
} from "@state/workflows";

import { ensureArray } from "@utils/array";

import { Button } from "@ui/button";
import { Dialog } from "@ui/dialog";

import { PropertyValuesList } from "./property-values-list";

type Props<
  T extends Entity,
  A extends WorkflowAction<T> | WorkflowSuggestion<T>
> = {
  action: A;
  context: WorkflowContext<T>;
  source: DatabaseID;
  onCollected: (action: A, collected: PropertyValueRef<T>[]) => void;
  onCancel: () => void;
} & (A extends WorkflowSuggestion<T>
  ? { data: WorkflowData<T> & { update: Update<T> } }
  : { data: WorkflowData<T> });

export const WorkflowCollectDialog = <
  T extends Entity,
  A extends WorkflowAction<T> | WorkflowSuggestion<T>
>({
  action,
  data,
  source,
  context,
  onCollected,
  onCancel,
}: Props<T, A>) => {
  const [values, setValues] = useState<PropertyValueRef<T>[]>([]);
  const toCollect = useMemo(
    () => (isArray(action.collect) ? action.collect : []),
    [action]
  );
  const CustomComponent = useMemo(
    () => (isFunction(action.collect) ? action.collect : undefined),
    [action]
  );

  useEffect(() => {
    if (isFunction(action.collect)) {
      return;
    }

    if (!action.collect?.length) {
      onCollected?.(action, []);
    } else {
      setValues(
        map(toCollect, (r) => ({ ...r, value: { [r.type]: undefined } }))
      );
    }
  }, [action.collect]);

  if (CustomComponent) {
    return (
      <CustomComponent
        data={data as WorkflowData<T> & { update: Update<T> }}
        context={context}
        onCollected={(vs) => onCollected(action, vs)}
        onCancelled={onCancel}
      />
    );
  }

  return (
    <Dialog
      onDismiss={onCancel}
      title={action.title || "Select each required field"}
      description={action.description}
      actions={
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button variant="primary" onClick={() => onCollected(action, values)}>
            Done
          </Button>
        </>
      }
    >
      <PropertyValuesList
        values={values as PropertyValueRef<Entity>[]}
        source={source}
        propsEditable={false}
        onMutate={(change) => {
          const changes = ensureArray(change);
          setValues(
            map(
              values,
              (v) => find(changes, (c) => c.field === v.field) || v
            ) as PropertyValueRef<T>[]
          );
        }}
      />
    </Dialog>
  );
};
