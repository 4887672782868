import { useState } from "react";
import { flatMap } from "lodash";

import {
  DatabaseID,
  Entity,
  PropertyDef,
  SingleFilterQuery,
  toTitleOrName,
} from "@api";

import { useSmartSearch } from "@state/generic";
import { useActiveWorkspaceId } from "@state/workspace";

import { Fn } from "@utils/fn";
import { Maybe, when } from "@utils/maybe";
import { isEmptyFilter } from "@utils/filtering";
import { ensureArray, justOne } from "@utils/array";
import { toRef } from "@utils/property-refs";
import { toBaseScope } from "@utils/scope";
import { withoutStar } from "@utils/wildcards";
import { isPersonId } from "@utils/id";

import { MultiRelationSelect } from "@ui/select";

import { OpHeader } from "./op-header";
import { TriggerButton } from "./trigger-button";

export interface Props {
  filter: SingleFilterQuery;
  definition: PropertyDef<Entity>;
  source: Maybe<DatabaseID>;
  onChanged?: Fn<Maybe<SingleFilterQuery>, void>;
  editing?: boolean;
}

export default function PropertyFilterRelation({
  filter,
  definition,
  onChanged,
  source,
  editing,
}: Props) {
  const [open, setOpen] = useState(false);
  const filterType = filter.type;
  const wID = useActiveWorkspaceId();
  const scope = source?.scope
    ? // Allow searching for anything if private scope
      isPersonId(toBaseScope(source.scope))
      ? wID
      : source.scope
    : wID;
  const {
    onSearch: setQuery,
    options: results,
    loading,
  } = useSmartSearch(
    justOne(withoutStar(definition.options?.references)) || "task",
    scope,
    {
      toOption: (e) => ({
        id: e.id,
        name: toTitleOrName(e),
      }),
    }
  );

  if (filterType !== "relations" && filterType !== "relation") {
    throw new Error("Can only use PropertyFilterRelation with relation types.");
  }

  const selected = when(
    filter.value?.[filterType] ?? filter.values?.[filterType],
    ensureArray
  );
  const isEmpty = isEmptyFilter(filter);

  return (
    <MultiRelationSelect
      open={open}
      setOpen={setOpen}
      value={selected}
      options={!isEmpty ? results : []}
      searchable={!isEmpty}
      onSearch={setQuery}
      defaultOpen={editing}
      closeOnBlur={false}
      portal={true}
      onChange={(values) =>
        onChanged?.({
          ...filter,
          value: undefined,
          values: { [filterType]: flatMap(values, (v) => toRef(v)) },
        })
      }
      placeholder={`Filter values...`}
      loading={loading}
      header={
        <OpHeader
          filter={filter}
          prop={definition}
          onChanged={onChanged}
          setOpen={setOpen}
        />
      }
    >
      <TriggerButton filter={filter} prop={definition} onChanged={onChanged} />
    </MultiRelationSelect>
  );
}
