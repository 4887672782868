import { useCallback } from "react";

import { Action, PropertyMutation } from "@api";

import { useInflateStatus } from "@state/databases";

import { OneOrMany } from "@utils/array";
import { Fn } from "@utils/fn";
import { asMutation } from "@utils/property-mutations";

export const useCheckAction = (
  action: Action,
  mutate?: Fn<OneOrMany<PropertyMutation<Action>>, void>
) => {
  const inflate = useInflateStatus();
  const onChange = useCallback(() => {
    if (!mutate) {
      return;
    }
    const status = inflate(action.status?.id, action.source);
    mutate(
      asMutation(
        { field: "status", type: "status" },
        { id: status?.group !== "done" ? "DNE" : "OPN" }
      )
    );
  }, [mutate, action.status?.id]);

  return !!mutate ? onChange : undefined;
};
