import { find, map } from "lodash";
import { useCallback, useEffect, useRef } from "react";

import { ID } from "@api";

import {
  useLazyEntities,
  useLazyEntity,
  useUpdateEntity,
} from "@state/generic";
import { needsScheduling, useCreateNextForSchedule } from "@state/schedule";
import { useLazyPropertyDef } from "@state/databases";

import { Fn } from "@utils/fn";
import { asMutation } from "@utils/property-mutations";
import { usePushTo } from "@utils/navigation";

import { Button } from "@ui/button";
import { HStack } from "@ui/flex";
import { Text } from "@ui/text";
import { Dialog } from "@ui/dialog";
import { MenuGroup } from "@ui/menu-group";
import { Menu } from "@ui/menu";
import { MenuItem } from "@ui/menu-item";
import { ArrowUpRight } from "@ui/icon";

import { render, toEngine } from "..";

interface DialogProps {
  scheduleId: ID;
  onComplete?: Fn<void, void>;
  onCancel?: Fn<void, void>;
}

export const SchedulePublishDialog = ({
  scheduleId,
  onCancel,
  onComplete: _onComplete,
}: DialogProps) => {
  const pushTo = usePushTo();
  const schedule = useLazyEntity<"schedule">(scheduleId);
  const mutate = useUpdateEntity(scheduleId);
  const times = useRef(0);
  const statusProp = useLazyPropertyDef(schedule?.source, {
    field: "status",
    type: "status",
  });

  const onComplete = useCallback(() => {
    const status = find(
      statusProp?.values.status,
      (s) => s.group === "in-progress"
    );

    if (status) {
      mutate(asMutation({ field: "status", type: "status" }, status));
    }

    _onComplete?.();
  }, [_onComplete]);

  const handleDismiss = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  const { create, next } = useCreateNextForSchedule(scheduleId, onComplete);

  const instances = useLazyEntities(schedule?.instances || []);

  useEffect(() => {
    if (!schedule) {
      return;
    }

    // Already created one (just for good measure), but don't need to create more
    if (times.current > 1 && !needsScheduling(schedule)) {
      return;
    }

    times.current += 1;
    create?.();
  }, [next]);

  return (
    <Dialog
      title="Publishing recurring work"
      onDismiss={handleDismiss}
      actions={
        <HStack>
          <Button onClick={handleDismiss}>Cancel</Button>
        </HStack>
      }
    >
      <Menu>
        <MenuGroup>
          <MenuItem disabled={true}>
            <Text subtle>Creating...</Text>
          </MenuItem>

          {!!instances?.length &&
            map(instances, (item) =>
              render(toEngine(item)?.asMenuItem, {
                key: item.id,
                item,
                onOpen: pushTo,
                iconRight: ArrowUpRight,
              })
            )}
        </MenuGroup>
      </Menu>
    </Dialog>
  );
};
