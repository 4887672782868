import { createContext, ReactNode, useContext, useMemo } from "react";

import { Entity, Update } from "@api";

import { useQueueUpdates } from "@state/generic";

import { Maybe } from "@utils/maybe";
import { OneOrMany } from "@utils/array";
import { Fn } from "@utils/fn";

import { usePageId } from "./app-page";

export interface MutateState {
  mutate: Fn<OneOrMany<Update<Entity>>, void>;
}

export const MutateContext = createContext<Maybe<MutateState>>(undefined);

export const useMutateContext = () => {
  return useContext(MutateContext);
};

export const useMutate = () => {
  const pageId = usePageId();
  const fallback = useQueueUpdates(pageId);
  return useContext(MutateContext)?.mutate || fallback;
};

export const WithMutateContext = ({
  mutate,
  children,
}: { mutate: MutateState["mutate"] } & { children: ReactNode }) => {
  const context = useMemo(() => ({ mutate }), [mutate]);
  return (
    <MutateContext.Provider value={context}>{children}</MutateContext.Provider>
  );
};
