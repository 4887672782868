import { Project, PropertyRef } from "@api";

import { withHandle } from "@utils/event";
import { when } from "@utils/maybe";
import { toMutation } from "@utils/property-mutations";
import { whenEmpty } from "@utils/array";

import { EntityContextMenu } from "@ui/entity-context-menu";
import { HStack, SpaceBetween } from "@ui/flex";
import { Icon, ProjectIcon, iconFromString } from "@ui/icon";
import { Label } from "@ui/label";
import { MenuItem } from "@ui/menu-item";
import { EditableText } from "@ui/editable-text";
import { SelectableListCard, SelectableListItem } from "@ui/selectable-items";
import { PropertyValueStack } from "@ui/property-value-stack";
import { TextHighlight } from "@ui/text-highlight";
import { Container } from "@ui/container";

import { UIEngine } from "../types";

import styles from "./styles.module.css";

const DEFAULT_PROPS: PropertyRef<Project>[] = [
  { field: "status", type: "status" },
];

export const ProjectEngine: UIEngine<Project> = {
  asMenuItem: function ({ item, onOpen, ...rest }) {
    return (
      <MenuItem
        {...rest}
        onClick={withHandle(() => onOpen?.(item))}
        wrapLabel={false}
      >
        <SpaceBetween>
          <TextHighlight color={item.color}>
            <Label icon={when(item.icon, iconFromString)} text={item.name} />
          </TextHighlight>
        </SpaceBetween>
      </MenuItem>
    );
  },
  asListCard: function ProjectListCard(props) {
    const { item, selection, setSelection, onChange, showProps } = props;
    return (
      <EntityContextMenu
        entity={item}
        selection={selection}
        setSelection={setSelection}
      >
        <SelectableListCard {...props}>
          <Container className={styles.bar} stack="vertical" inset="bottom">
            <HStack>
              <Icon icon={when(item.icon, iconFromString) || ProjectIcon} />

              <TextHighlight color={item.color}>
                <Label className={styles.title}>{item.name || "Project"}</Label>
              </TextHighlight>
            </HStack>

            <PropertyValueStack
              item={item}
              props={whenEmpty(showProps, DEFAULT_PROPS)}
              onChange={onChange}
              hideEmpty={props.hideEmpty}
            />
          </Container>
        </SelectableListCard>
      </EntityContextMenu>
    );
  },
  asListItem: function (props) {
    const { item, showProps, onChange } = props;
    return (
      <EntityContextMenu
        entity={item}
        selection={props.selection}
        setSelection={props.setSelection}
      >
        <SelectableListItem {...props}>
          <SpaceBetween className={styles.upper}>
            <HStack className={styles.middle} gap={8}>
              <Icon icon={iconFromString(item.icon) || ProjectIcon} />
              <TextHighlight color={item.color}>
                <EditableText
                  key={item.id}
                  text={item.name || ""}
                  disabled={true}
                  placeholder="Untitled campaign"
                  blurOnEnter={true}
                  onChange={(v) =>
                    onChange?.(
                      toMutation(item, { field: "name", type: "text" }, v)
                    )
                  }
                />
              </TextHighlight>
            </HStack>

            <PropertyValueStack
              className={styles.rowDetails}
              justify="flex-end"
              gap={2}
              item={item}
              props={whenEmpty(showProps, DEFAULT_PROPS)}
              hideEmpty={props.hideEmpty}
              onChange={onChange}
            />
          </SpaceBetween>
        </SelectableListItem>
      </EntityContextMenu>
    );
  },
};
