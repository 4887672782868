import { atomFamily, selectorFamily } from "recoil";

import { ID, KnowledgeBase } from "@api";

import {
  getItem,
  indexedDBStorageForStore,
  mergeItem,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { isDefault } from "@utils/recoil";

export type KnowledgeBaseStoreState = StoreState<KnowledgeBase>;

export const WorkspaceKnowledgeBaseStoreAtom = atomFamily<
  KnowledgeBaseStoreState,
  ID
>({
  key: "WorkspaceKnowledgeBaseStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "knowledgebase")],
});

export const KnowledgeBaseStoreAtom = WorkspaceWrappedAtom(
  "KnowledgeBaseStoreAtom",
  WorkspaceKnowledgeBaseStoreAtom
);

export const KnowledgeBaseAtom = selectorFamily({
  key: "KnowledgeBaseAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(KnowledgeBaseStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(KnowledgeBaseStoreAtom, mergeItem<KnowledgeBase>(newValue));
      }
    },
});
