import { atomFamily, selectorFamily } from "recoil";

import { ID, Action } from "@api";

import {
  getItem,
  indexedDBStorageForStore,
  mergeItem,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { isDefault } from "@utils/recoil";

export type ActionStoreState = StoreState<Action>;

export const WorkspaceActionStoreAtom = atomFamily<ActionStoreState, ID>({
  key: "WorkspaceActionStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "action")],
});

export const ActionStoreAtom = WorkspaceWrappedAtom(
  "ActionStoreAtom",
  WorkspaceActionStoreAtom
);

export const ActionAtom = selectorFamily({
  key: "ActionAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(ActionStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(ActionStoreAtom, mergeItem<Action>(newValue));
      }
    },
});
