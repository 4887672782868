import { ReactNode, RefObject, useRef } from "react";
import {
  ConnectDragSource,
  ConnectDropTarget,
  useDrag,
  useDrop,
} from "react-dnd";

import { Entity, PropertyValueRef, Status, StatusGroup } from "@api";

import { DropPosition } from "@utils/drag-drop";
import { Maybe } from "@utils/maybe";

import { DropHighlight } from "@ui/drop-highlight";

export type DragRef = {
  ref: PropertyValueRef<Entity>;
  order: number;
};

export type DragToRef = {
  ref?: PropertyValueRef<Entity>;
  group?: StatusGroup;
  order?: number;
  position: "default" | "before" | "after";
};

interface DragProps {
  property: PropertyValueRef<Entity>;
  order: number;
  onReorder: (t: DragRef, to: DragToRef) => void;
  ref: RefObject<HTMLDivElement>;
  connect?: ConnectDropTarget | ConnectDragSource;
}

type DropProps = {
  property?: PropertyValueRef<Entity>;
  order?: number;
  ref: RefObject<HTMLDivElement | HTMLDivElement>;
  connect?: ConnectDropTarget | ConnectDragSource;
  group?: Status["group"];
};

type DropTargetProps = Omit<DropProps, "ref"> & {
  children?: ReactNode;
};

export const usePropertyValueDrag = ({
  property,
  order,
  onReorder,
  ref,
  connect,
}: DragProps) => {
  const [{ opacity }, dragRef] = useDrag<
    DragRef,
    DragToRef,
    { opacity?: number }
  >(
    () => ({
      type: "property-value",
      item: () => ({
        ref: property,
        order: order,
      }),

      collect: (monitor) => (monitor.isDragging() ? { opacity: 0.5 } : {}),
      end: (_dragged, monitor) => {
        const target = monitor.getDropResult();
        const item = monitor.getItem();
        if (target) {
          onReorder?.(item, target);
        }
      },
    }),
    [property, onReorder]
  );

  if (connect) {
    connect(dragRef(ref));
  } else {
    dragRef(ref);
  }

  return [{ opacity }, dragRef] as [{ opacity: number }, ConnectDragSource];
};

export const usePropertyValueDrop = ({
  property,
  order,
  connect,
  ref,
  group,
}: DropProps) => {
  const [{ dropping }, dropRef] = useDrop<
    DragRef[],
    DragToRef,
    { dropping: Maybe<DropPosition> }
  >(
    () => ({
      accept: "property-value",
      canDrop: (_t, m) => m.isOver({ shallow: true }),
      collect: (monitor) => ({
        dropping: monitor.canDrop() && monitor.isOver() ? "before" : undefined,
      }),
      drop: () => ({
        ref: property,
        group: group,
        order: order,
        position: "before",
      }),
    }),
    [property]
  );

  if (connect) {
    connect(dropRef(ref));
  } else {
    dropRef(ref);
  }

  return [{ dropping }, dropRef] as [
    { dropping: Maybe<DropPosition> },
    ConnectDropTarget
  ];
};

export const usePropertyValueDragDrop = (props: DragProps & DropProps) => {
  const [dragProps, dragRef] = usePropertyValueDrag(props);
  const [dropProps] = usePropertyValueDrop({ ...props, connect: dragRef });

  return { ...dragProps, ...dropProps };
};

export const DropTarget = ({ children, ...rest }: DropTargetProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ dropping }] = usePropertyValueDrop({
    ref,
    ...rest,
  });

  return (
    <div ref={ref}>
      {dropping && <DropHighlight />}
      {children}
    </div>
  );
};
