import { useRecoilState, useRecoilValue } from "recoil";
import { ID } from "@api";

import { countForQuickFilter } from "@state/views";
import {
  QuickFilter,
  toggleFilter,
  ViewQuickFilterAtom,
} from "@state/quick-filters";

import { hashable } from "@utils/serializable";

import { CounterButton } from "@ui/counter-button";

export const QuickFilterButton = ({
  viewId,
  filter,
}: {
  viewId: ID;
  filter: QuickFilter;
}) => {
  const [viewFilter, setViewState] = useRecoilState(
    ViewQuickFilterAtom(viewId)
  );
  const count = useRecoilValue(
    countForQuickFilter(hashable({ viewId, filterId: filter.id }))
  );
  return (
    <div style={{ paddingRight: "6px" }}>
      <CounterButton
        key={filter.id}
        count={count || 0}
        title={filter.name}
        color="gray_3"
        state={
          viewFilter.selected === filter.id
            ? "selected"
            : !!viewFilter.selected
            ? "faint"
            : "default"
        }
        onClick={() => setViewState(toggleFilter(filter))}
      />
    </div>
  );
};
