import { useMemo } from "react";
import { map } from "lodash";
import { useTimezoneSelect, ITimezoneOption } from "react-timezone-select";

import { useSetting } from "@state/settings";
import { useActiveWorkspaceId } from "@state/workspace";

import { composel, Fn } from "@utils/fn";
import { Maybe, maybeMap, when } from "@utils/maybe";
import { formatOffsetInMilitary } from "@utils/time";
import { equalsAny } from "@utils/logic";

import { MenuItem } from "@ui/menu-item";
import { Globe } from "@ui/icon";
import { Ellipsis } from "@ui/ellipsis";
import { Select, SelectProps, TextSubtextOption } from "./single-select";

import styles from "./select.module.css";

type Timezone = string;

type EntitySelectProps = Omit<SelectProps, "value" | "options" | "onChange"> & {
  value: Maybe<Timezone>;
  onChange: Fn<Maybe<Timezone>, void>;
};

const toOption = (o: ITimezoneOption) => ({
  id: o.value,
  name: o.label,
  subtext: "GMT " + formatOffsetInMilitary(Number(o.offset) * 60),
});

export const TimezoneSelect = ({
  value,
  onChange,
  placeholder,
  children,
  ...props
}: EntitySelectProps) => {
  const wID = useActiveWorkspaceId();
  const defaultTZ = useSetting<string>(wID, "timezone");
  const { options: allZones, parseTimezone } = useTimezoneSelect({
    labelStyle: "offsetHidden",
  });

  const allOptions = useMemo(() => map(allZones, toOption), [allZones]);

  const options = useMemo(
    () => [
      {
        // label: "Defaults",
        options: maybeMap(allOptions, (o) =>
          equalsAny(o.id, [
            defaultTZ,
            Intl.DateTimeFormat().resolvedOptions().timeZone,
          ])
            ? o
            : undefined
        ),
      },
      { label: "All", options: allOptions },
    ],
    [allOptions]
  );

  const selected = useMemo(
    () => when(value, composel(parseTimezone, toOption)),
    [value, parseTimezone]
  );

  return (
    <Select
      value={selected}
      options={options}
      {...props}
      onChange={(t) => onChange?.(t?.id)}
      overrides={{ Option: TextSubtextOption }}
    >
      {children || (
        <MenuItem icon={Globe} className={styles.control}>
          <Ellipsis>
            {selected?.name || placeholder || "Choose timezone..."}
          </Ellipsis>
        </MenuItem>
      )}
    </Select>
  );
};
