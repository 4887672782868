import { Extension } from "@tiptap/react";

import { Entity, Page, Update } from "@api";

import { OneOrMany } from "@utils/array";
import { Fn } from "@utils/fn";
import { asCreateUpdate, asMutation } from "@utils/property-mutations";

type PageExtensionOptions = {
  scope?: string;
  mutate?: Fn<OneOrMany<Update<Entity>>, void>;
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    page: {
      createPage: (options: { title?: string }) => ReturnType;
    };
  }
}

export const PageExtension = Extension.create<PageExtensionOptions>({
  addCommands() {
    return {
      createPage:
        ({ title }) =>
        (options) => {
          const { tr, dispatch } = options;
          const { selection } = tr;

          const mutate = this.options.mutate;
          const scope = this.options.scope;

          if (!mutate || !scope) {
            throw new Error("PageExtension requires scope and mutate options");
          }

          const update = asCreateUpdate<Page>({ type: "page", scope: scope }, [
            asMutation({ field: "title", type: "text" }, title),
            asMutation({ field: "location", type: "text" }, scope),
          ]) as Update<Entity>;

          mutate(update);

          options.commands.setMention({
            id: update.id, // Will be a temp id
            label: title,
          });

          return true;
        },
    };
  },
});
