import { omit } from "lodash";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useCallback, useMemo, useState } from "react";
import { CreateOrUpdate, ID } from "@api";

import { ProjectStoreAtom } from "@state/projects";
import { ViewAtom, ViewStoreAtom, View, isTemplateViewId } from "@state/views";
import {
  clearTempUpdates,
  mergeUpdates,
  queueUpdate,
  removeItem,
  removeItemPure,
} from "@state/store";
import { useCreateFromObject, useLocalChanges } from "@state/generic";

import { isLocalID, maybeTypeFromId, newHumanId } from "@utils/id";
import { usePushTo } from "@utils/navigation";
import { when } from "@utils/maybe";
import { Fn, composel } from "@utils/fn";
import { extractLast, toLocation } from "@utils/scope";
import { flattenChanges } from "@utils/property-mutations";

import { ViewCreateDialog } from "@ui/engine/view";
import { HStack } from "@ui/flex";
import { Button } from "@ui/button";
import { usePageId } from "@ui/app-page";
import { showError } from "@ui/notifications";

interface Props {
  viewId: ID;
  onSaved?: Fn<void, void>;
  onCancelled?: Fn<void, void>;
  className?: string;
}

export const ViewChangesActions = ({
  viewId,
  className,
  onSaved,
  onCancelled,
}: Props) => {
  const pageId = usePageId();
  const pushTo = usePushTo();
  const setStore = useSetRecoilState(ViewStoreAtom);
  const setProjectStore = useSetRecoilState(ProjectStoreAtom);
  const view = useRecoilValue(ViewAtom(viewId));
  const create = useCreateFromObject("view", view?.source.scope, pageId);
  const { changes } = useLocalChanges(viewId, ViewStoreAtom);
  const [show, showDialog] = useState(false);
  const isPersonal = useMemo(
    () => when(extractLast(view?.location), maybeTypeFromId) === "person",
    [view]
  );
  const isSaved = useMemo(() => !isLocalID(view?.id || ""), [view]);
  const canUpdate = useMemo(
    () => !view?.id || !isTemplateViewId(view?.id),
    [view?.id]
  );

  const onNewView = useCallback((view: View) => {
    pushTo(view);
  }, []);

  const handleRevert = useCallback(() => {
    setStore(
      composel(removeItem(view?.id || viewId), clearTempUpdates(viewId))
    );
    onCancelled?.();
  }, [setStore]);

  const handleSave = useCallback(() => {
    if (!view) {
      return;
    }

    const merged = mergeUpdates(changes);
    merged && setStore(queueUpdate({ ...merged, mode: undefined }));
    setStore(clearTempUpdates(viewId));

    onSaved?.();
  }, [view, changes, queueUpdate, setProjectStore]);

  const handleSaveTemplate = useCallback(() => {
    if (!view || !create) {
      return showError("Not ready.");
    }

    // Create from template but override all the newly applied changes
    create([
      {
        ...view,
        id: newHumanId("view"),
        alias: view.id,
        source: view.source,
        location: toLocation(view.source?.scope),
        // Flatten the changes
        ...flattenChanges(
          (mergeUpdates(changes) as CreateOrUpdate<View>).changes
        ),
      },
    ]);

    // Clear out changes to the previous template view
    setStore(composel(removeItemPure(viewId), clearTempUpdates(viewId)));

    onSaved?.();
  }, [create, view, changes, setProjectStore]);

  if (!changes.length) {
    return <></>;
  }

  return (
    <>
      {show && (
        <ViewCreateDialog
          defaults={omit(view, "id")}
          onCancel={() => showDialog(false)}
          onSaved={(view) => {
            handleRevert();
            onNewView?.(view);
          }}
        />
      )}
      <HStack gap={0} className={className}>
        <Button size="small" subtle onClick={handleRevert}>
          {isSaved ? "Revert" : "Discard"}
        </Button>

        {/* <Button
          size="small"
          subtle
          onClick={() => showDialog(true)}
          variant={"secondary"}
        >
          Save as new
        </Button> */}

        {canUpdate && (
          <Button size="small" variant="primary-alt" onClick={handleSave}>
            {isPersonal ? "Save changes" : "Save for everyone"}
          </Button>
        )}

        {!canUpdate && (
          <Button
            size="small"
            variant="primary-alt"
            onClick={handleSaveTemplate}
          >
            {isPersonal ? "Save changes" : "Save for everyone"}
          </Button>
        )}
      </HStack>
    </>
  );
};
