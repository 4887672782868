import OgLink from "@tiptap/extension-link";
import { Plugin } from "@tiptap/pm/state";
import { MarkType, NodeType } from "@remirror/pm/model";
import { PluginKey } from "prosemirror-state";

import { onClient } from "@utils/ssr";
import { NavigateOptions, useNavigate } from "@utils/navigation";
import { Fn } from "@utils/fn";
import { isExternal } from "@utils/url";
import { isHumanId } from "@utils/id";
import { Editor } from "@tiptap/core";

let navigate = (url: string, opts?: NavigateOptions) =>
  onClient(() => {
    if (opts?.newTab) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  });

export const useInternalNavigator = () => {
  navigate = useNavigate() as Fn<string, void>;
};

type ClickHandlerOptions = {
  editor: Editor;
  type: MarkType | NodeType;
};

export function clickHandler(options: ClickHandlerOptions): Plugin {
  return new Plugin({
    key: new PluginKey(`${options.type.name}_click_handler`),
    props: {
      handleClick: function (view, pos, event) {
        if (event.button !== 0) {
          return false;
        }

        let a = event.target as HTMLElement;
        const els = [];

        while (a.nodeName !== "DIV") {
          els.push(a);
          a = a.parentNode as HTMLElement;
        }

        if (!els.find((value) => value.nodeName === "A")) {
          return false;
        }

        const attrs = options.editor.getAttributes(options.type);
        const link = event.target as HTMLLinkElement;

        const href =
          link?.href ??
          attrs.href ??
          (isHumanId(attrs.id) ? `/${attrs.id}` : undefined);

        if (href) {
          event.preventDefault();
          navigate(href, {
            newTab: event.ctrlKey || event.metaKey || isExternal(href),
          });

          return true;
        }

        return false;
      },
    },
  });
}

export const Link = OgLink.extend({
  priority: 100,
  excludes: "_",
  HTMLAttributes: {
    target: "_blank",
  },
  addProseMirrorPlugins() {
    return [
      ...(this?.parent?.() || []),
      clickHandler({ editor: this.editor, type: this.type }),
    ];
  },
});
