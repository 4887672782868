import { initial } from "lodash";
import { useState } from "react";

import { Integration, Link } from "@api";
import { getNotionLocation } from "@api/integrations/notion";

import { useAsyncEffect } from "@utils/effects";
import { toNotionUrl } from "@utils/notion";
import { cx } from "@utils/class-names";

import Breadcrumb from "@ui/breadcrumb";

import styles from "./document-location.module.css";

interface Props {
  docId: string;
  source: Integration;
  clickable?: boolean;
  subtle?: boolean;
  showCurrent?: boolean;
  onLoaded?: (parents: Link[]) => void;
  onClick?: () => void;
  className?: string;
}

export const DocumentLocation = ({
  docId,
  source,
  onLoaded,
  subtle = false,
  clickable,
  className,
  showCurrent = true,
}: Props) => {
  if (source !== "notion") {
    throw new Error("Notion is the only document integration.");
  }
  const [parents, setParents] = useState<Link[]>();

  useAsyncEffect(async () => {
    if (docId) {
      const parents = await getNotionLocation(docId);
      setParents(parents);
      onLoaded?.(parents);
    }
  }, [docId]);

  return parents ? (
    <Breadcrumb
      className={cx(className)}
      linkClassName={cx(subtle && styles.subtle)}
      clickable={clickable}
      links={(showCurrent ? parents : initial(parents)) || []}
      scrollable={true}
    />
  ) : (
    <Breadcrumb
      scrollable={true}
      className={cx(className)}
      linkClassName={cx(subtle && styles.subtle)}
      clickable={clickable}
      links={[
        { text: "Notion", url: toNotionUrl(docId) },
        { text: "Loading..." },
      ]}
    />
  );
};
