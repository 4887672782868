import { find } from "lodash";
import { useMemo } from "react";

import { ID } from "@api";

import { useLazyGetNote } from "@state/notes";
import { useMarkAsSeen } from "@state/generic";
import { useSetting } from "@state/settings";

import { isSlack } from "@utils/link";
import { toMarkdown } from "@utils/rich-text";

import { Fn } from "@utils/fn";
import { useAppPageContext } from "@ui/app-page";

import { SlackThread } from "./slack-thread";

interface Props {
  noteId: ID;
  onClose?: Fn<void, void>;
}

export const DiscussionThread = ({ noteId, onClose }: Props) => {
  const pageId = useAppPageContext();
  const note = useLazyGetNote(noteId);
  const channel = useSetting<string>(noteId, "channel");

  // Mark the note as seen by current user
  useMarkAsSeen(noteId, pageId);

  const slackThread = useMemo(
    () => find(note?.links, (l) => isSlack(l.url)),
    [note]
  );

  return (
    <SlackThread
      thread={slackThread?.url}
      defaultChannel={channel}
      defaultTitle={toMarkdown(note?.body)}
      header="white"
      showInitialMessage={true}
      onClose={onClose}
    />
  );
};
