import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";

import { TextInput, TextInputProps } from "@ui/input";

import { MenuItemProps } from "./default";

import styles from "./styles.module.css";

type TextMenuItemProps = MenuItemProps & {
  onTextChanged?: Fn<string, void>;
  updateOn?: TextInputProps["updateOn"];
};

export function TextMenuItem({
  icon,
  text,
  indent,
  style,
  updateOn,
  dark = false,
  onTextChanged,
  className,
  onClick,
}: TextMenuItemProps) {
  return (
    <div
      className={cx(
        styles.menuItem,
        !icon && styles.noIcon,
        dark && styles.dark,
        className
      )}
      style={{ marginLeft: `${(indent || 0) * 25}px`, ...style }}
      onClick={onClick}
    >
      <TextInput
        value={text || ""}
        onChange={onTextChanged}
        updateOn={updateOn || "blur"}
      />
    </div>
  );
}
