import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Document from "@tiptap/extension-document";
import Heading from "@tiptap/extension-heading";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";

import { useDebouncedCallback } from "@utils/hooks";
import { Fn } from "@utils/fn";
import { Button } from "@ui/button";
import { PlusIcon } from "@ui/icon";

import { Mentioner, GlobalSuggestion } from "./mention-extension";

import styles from "./document-editor.module.css";

const CustomDocument = Document.extend({
  content: "(taskList|(heading taskList))+",
});

export const ChecklistEditor = ({
  content,
  onChanged,
}: {
  content: string;
  onChanged: Fn<string, void>;
}) => {
  const onChangeDebounced = useDebouncedCallback(
    (v: string) => onChanged?.(v),
    1000,
    { trailing: true }
  );

  const editor = useEditor({
    extensions: [
      CustomDocument,
      StarterKit.configure({
        document: false,
        blockquote: false,
        codeBlock: false,
        heading: false,
        bulletList: false,
      }),
      Heading,
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      Mentioner.configure({
        suggestion: GlobalSuggestion({}),
      }),
    ],
    content: content || '<ul data-type="taskList"></ul>',

    editorProps: {
      attributes: {
        class: styles.tiptap,
      },
    },

    // triggered on every change
    onUpdate: ({ editor }) => {
      onChangeDebounced(editor.getHTML());
    },
  });

  if (!editor) {
    return <></>;
  }

  return (
    <div
      className={styles.wrapper}
      onClick={() => editor.chain().focus().run()}
    >
      <EditorContent editor={editor} />
      <Button
        className={styles.spaceUp}
        icon={PlusIcon}
        size="small"
        subtle
        onClick={() => {
          editor
            .chain()
            .setContent(
              editor.getHTML() + `<h1></h1><ul data-type="taskList"></ul>`
            )
            .focus(editor.state.doc.content.size + 1)
            .run();
        }}
      >
        New list
      </Button>
    </div>
  );
};
