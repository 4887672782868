import { ReactNode } from "react";
import { isString } from "lodash";
import * as RadixTooltip from "@radix-ui/react-tooltip";

import { TextSmall } from "@ui/text";

import styles from "./tooltip.module.css";

export interface Props {
  text: string | ReactNode;
  children: ReactNode;
  delay?: number;
  side?: "top" | "right" | "bottom" | "left";
}

export const Tooltip = ({ delay, side, text, children }: Props) => (
  <RadixTooltip.Root delayDuration={delay}>
    <RadixTooltip.Trigger asChild>
      <span className={styles.refOnly}>{children}</span>
    </RadixTooltip.Trigger>
    <RadixTooltip.Portal>
      <RadixTooltip.Content
        className={styles.tooltip}
        sideOffset={2}
        side={side}
      >
        {isString(text) ? <TextSmall bold>{text}</TextSmall> : text}
        {/* <RadixTooltip.Arrow className={styles.arrow} /> */}
      </RadixTooltip.Content>
    </RadixTooltip.Portal>
  </RadixTooltip.Root>
);
