import { omit } from "lodash";
import { atomFamily, selectorFamily } from "recoil";

import { Color, FilterQuery, ID, Task } from "@api";

import { ViewAtom } from "@state/views";

import { Maybe } from "@utils/maybe";
import { isDefault } from "@utils/recoil";

import { filtersForView } from "./definitions";

export interface QuickFilter {
  id: ID;
  name: string;
  color: Color;
  filter: FilterQuery<Task>;
}

export type InternalViewQuickFilterState = {
  selected: Maybe<ID>;
  peaking: Maybe<ID>;
  search: Maybe<string>;
};

export type ViewQuickFilterState = InternalViewQuickFilterState & {
  available: QuickFilter[];
};

const defaultQuickFilters = (viewId: ID): InternalViewQuickFilterState => ({
  selected: undefined,
  peaking: undefined,
  search: undefined,
});

export const InternalViewQuickFilterAtom = atomFamily({
  key: "InternalViewQuickFilter",
  default: defaultQuickFilters,
});

export const ViewQuickFilterAtom = selectorFamily<ViewQuickFilterState, ID>({
  key: "ViewQuickFilter",
  get:
    (viewId) =>
    ({ get }) => {
      const view = get(ViewAtom(viewId));
      const available = view ? filtersForView(view) : [];
      return { ...get(InternalViewQuickFilterAtom(viewId)), available };
    },
  set:
    (viewId) =>
    ({ set }, newValue) =>
      set(
        InternalViewQuickFilterAtom(viewId),
        isDefault(newValue) ? newValue : omit(newValue, "available")
      ),
});
