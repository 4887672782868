import { addMinutes, differenceInMinutes } from "date-fns";
import { Meeting, Person, Status } from "@api";
import { Maybe } from "@utils/maybe";
import { isBetween } from "@utils/date";
import { now } from "@utils/now";
import { fromPointDate, usePointDate } from "@utils/date-fp";

export const isRunning = (_entity: Meeting, status: Status) =>
  status?.group === "in-progress";

export const isHost = (entity: Meeting, me: Maybe<Person>) =>
  entity.owner?.id == me?.id;

export const toDurationMins = (entity: Meeting) =>
  entity.end && entity.start
    ? Math.abs(
        differenceInMinutes(
          fromPointDate(entity.end),
          fromPointDate(entity.start)
        )
      )
    : undefined;

export const isStartingSoon = (entity: Meeting) =>
  !!usePointDate(
    entity.start,
    (time) =>
      time && isBetween(now(), addMinutes(time, -10), addMinutes(time, 10))
  );

export const isInProgress = (entity: Meeting) =>
  !!(
    entity.start &&
    entity.end &&
    isBetween(now(), fromPointDate(entity.start), fromPointDate(entity.end))
  );
