import { Company } from "@api";

import { useLazyPropertyValue } from "@state/databases";

import { cx } from "@utils/class-names";
import { isEmptyRef } from "@utils/property-refs";
import { safeAs } from "@utils/maybe";
import { ellipsify } from "@utils/string";

import { Ellipsis } from "@ui/ellipsis";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { SelectableListCard, SelectableListItem } from "@ui/selectable-items";
import { Text, TextSmall } from "@ui/text";
import { PropertyLabel } from "@ui/property-label";
import { PropertyValueStack } from "@ui/property-value-stack";
import { Icon, PersonIcon } from "@ui/icon";
import { Divider } from "@ui/divider";

import { UIEngine } from "../types";
import { EntityEngine } from "../fallback";

import { CompanyPane } from "./pane";

import styles from "./styles.module.css";

export const CompanyEngine: UIEngine<Company> = {
  asMenuItem: EntityEngine.asMenuItem as UIEngine<Company>["asMenuItem"],

  asListItem: function (props) {
    const { item, showProps, onChange } = props;
    return (
      <EntityContextMenu
        entity={item}
        selection={props.selection}
        setSelection={props.setSelection}
      >
        <SelectableListItem {...props}>
          <SpaceBetween>
            <HStack gap={8}>
              <Icon
                size="medium"
                icon={<PersonIcon shape="square" size="medium" person={item} />}
              />
              <HStack gap={6} align="baseline">
                <Text>{item.name || ""}</Text>
              </HStack>
            </HStack>

            <PropertyValueStack
              className={styles.rowDetails}
              justify="flex-end"
              gap={2}
              item={item}
              props={showProps}
              hideEmpty={props.hideEmpty}
              onChange={onChange}
            />
          </SpaceBetween>
        </SelectableListItem>
      </EntityContextMenu>
    );
  },

  asListCard: (props) => {
    const { item, showProps, group } = props;

    const typeVal = useLazyPropertyValue(item, {
      field: "type",
      type: "select",
    });

    return (
      <EntityContextMenu entity={props.item}>
        <SelectableListCard
          {...props}
          className={cx(styles.card, props.className)}
        >
          <VStack fit="container">
            <SpaceBetween gap={6} fit="container">
              <Icon
                size="large"
                icon={<PersonIcon person={item} shape="square" />}
              />

              <FillSpace>
                <Text>{item.name}</Text>
                <TextSmall subtle>
                  <Ellipsis>
                    {safeAs<string>(item.custom?.bio) || "No bio"}
                  </Ellipsis>
                </TextSmall>
              </FillSpace>

              {!isEmptyRef(typeVal) && (
                <PropertyLabel valueRef={typeVal} source={item.source} />
              )}
            </SpaceBetween>

            <Divider hideLast />

            <PropertyValueStack
              wrap
              item={item}
              editable={false}
              props={showProps}
              blacklist={["name", "avatar"]}
              hideEmpty={props.hideEmpty}
            />
          </VStack>
        </SelectableListCard>
      </EntityContextMenu>
    );
  },

  asPrimaryPane: CompanyPane,
};
