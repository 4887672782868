import { Plugin } from "@tiptap/pm/state";
import { Extension } from "@tiptap/react";
import { DOMParser as ProseMirrorDOMParser } from "prosemirror-model";
import { isMarkdown, toHtml } from "@utils/markdown";

export const MarkdownPasteHandler = Extension.create({
  name: "markdownPasteHandler",

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handlePaste: (view, event, slice) => {
            const clipboardData = event.clipboardData;
            const text = clipboardData?.getData("text/plain");

            if (text && isMarkdown(text)) {
              // Convert the markdown to HTML
              const html = toHtml(text);

              // Insert the HTML into the editor
              const { state, dispatch } = view;
              const tr = state.tr;

              const doc = ProseMirrorDOMParser.fromSchema(
                view.state.schema
              ).parse(new DOMParser().parseFromString(html, "text/html"));

              tr.replaceSelectionWith(doc);
              dispatch(tr);
              return true; // Prevent the default paste behavior
            }
            return false;
          },
        },
      }),
    ];
  },
});
