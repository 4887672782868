import { useDebouncedCallback } from "use-debounce";
import { useEffect, useMemo } from "react";

import { HasBody, hasBody, ID, Project } from "@api";

import { summariseBody, useAiUseCase } from "@state/ai";
import { useLazyEntity } from "@state/generic";

import { asMutation, asUpdate } from "@utils/property-mutations";
import { safeAs } from "@utils/maybe";
import { isEmpty, toMarkdown } from "@utils/rich-text";

import { Button } from "@ui/button";
import { Magic } from "@ui/icon";
import { ColoredSection } from "@ui/section";
import { ReadonlyPlainText } from "@ui/rich-text";
import { useMutate } from "@ui/mutate";

interface Props {
  entityId: ID;
  sectionLabel?: string;
  hideOnEmpty?: boolean;
}

export const SummarySection = ({
  entityId,
  sectionLabel,
  hideOnEmpty = false,
}: Props) => {
  const process = useLazyEntity(entityId);
  const mutate = useMutate();
  const summary = safeAs<Project>(process)?.summary;
  const hasSummary = useMemo(() => !isEmpty(summary), [summary]);

  const ai = useAiUseCase(
    summariseBody,
    (s) =>
      process &&
      mutate(
        asUpdate(
          process,
          asMutation({ field: "summary", type: "rich_text" }, { markdown: s })
        )
      )
  );

  const autoGenSummary = useDebouncedCallback(() => {
    if (hasSummary || !process) {
      return;
    }

    if (!hasBody(process)) {
      return;
    }

    if (toMarkdown(process.body).length < 100) {
      return;
    }

    ai.run?.({ item: process });
  }, 2000);

  useEffect(() => autoGenSummary, [safeAs<HasBody>(process)?.body]);

  if (!hasSummary && hideOnEmpty) {
    return <></>;
  }

  return (
    <ColoredSection
      icon={Magic}
      title={sectionLabel || "Summary"}
      color="purple_5"
      actions={
        <Button
          subtle
          size="tiny"
          onClick={() => hasBody(process) && ai.run({ item: process })}
          loading={ai.loading}
        >
          Regenerate
        </Button>
      }
    >
      <ReadonlyPlainText text={summary} />
    </ColoredSection>
  );
};
