import { useCallback, useMemo } from "react";
import { map, uniqBy } from "lodash";

import { DatabaseID, Entity, PropertyType, SelectOption } from "@api";
import { useLazyPropertyValues } from "@state/databases";
import { useStore } from "@state/generic";
import { getItem } from "@state/store";

import { maybeMap } from "@utils/maybe";
import { getPropertyValue } from "@utils/property-refs";
import { withoutBy } from "@utils/array";
import { toUpdate } from "@utils/property-mutations";
import { asLocal, cid } from "@utils/id";

import { useMultiCheckedState } from "@ui/checked";
import { CommandItem } from "@ui/command-menu";
import { Tag } from "@ui/tag";
import { CheckIcon } from "@ui/icon";
import { Text } from "@ui/text";

import { SetPropertyCommands } from "./types";
import { useCommandSearch } from "../utils";

export const MultiSelectCommands = ({
  property,
  entities,
  mutate,
}: SetPropertyCommands) => {
  const propType = property.type;

  if (propType !== "multi_select") {
    throw new Error(
      "Can only use MultiSelctCommands on multi-select property types."
    );
  }

  const source = entities[0]?.source as DatabaseID;
  const values = useLazyPropertyValues(source, property);
  const store = useStore(source.type);
  const realEntities = maybeMap(entities, (e) => getItem(store, e.id));
  const toCheckedState = useMultiCheckedState(realEntities, property);
  const search = useCommandSearch();

  const options = useMemo(
    () =>
      map(values[propType], (s: SelectOption) => ({
        option: s,
        checked: toCheckedState(s.id || s.name || ""),
      })),
    [values, toCheckedState]
  );

  const handleChange = useCallback(
    (value: SelectOption, action: "add" | "remove") => {
      mutate(
        map(realEntities, (entity) => {
          const prev =
            getPropertyValue<Entity, PropertyType>(entity, property)[
              propType
            ] || [];

          const newValue =
            action === "add"
              ? uniqBy([...prev, value], (s) => s.id)
              : withoutBy(prev, value, (t) => t.id === value.id);

          return toUpdate(entity, property, newValue);
        })
      );
    },
    [realEntities, mutate]
  );

  return (
    <>
      <CommandItem
        value="clear unset value"
        onClick={() => mutate({ [propType]: [] })}
      >
        <Text subtle>Clear value</Text>
      </CommandItem>

      {map(options, ({ option, checked }) => (
        <CommandItem
          key={option.id || option.name}
          value={`${option.name} ${option.id}`}
          onSelectAction="clear"
          onClick={() =>
            handleChange(option, checked === true ? "remove" : "add")
          }
          icon={<CheckIcon checked={checked} />}
        >
          <Tag color={option.color}>{option.name}</Tag>
        </CommandItem>
      ))}

      {!property.locked && search && (
        <CommandItem
          value={`skip: create ${search}`}
          onClick={() =>
            handleChange({ id: asLocal(cid(4)), name: search }, "add")
          }
        >
          <Text subtle>Create</Text> <Tag>{search}</Tag>
        </CommandItem>
      )}
    </>
  );
};
