import { ReactNode, useMemo } from "react";
import { Color } from "@api";

import { ComponentOrNode } from "@utils/react";
import { cx } from "@utils/class-names";
import {
  fromColor,
  sansDefault,
  toColorVar,
  toInverseColorVar,
} from "@utils/color";
import { when } from "@utils/maybe";

import { Label } from "@ui/label";
import { Container, Props as ContainerProps } from "@ui/container";
import { Button } from "@ui/button";
import { TimesCircle } from "@ui/icon";

import styles from "./banner.module.css";

interface Props {
  text?: string;
  icon?: ComponentOrNode;
  color?: Color;
  fit?: ContainerProps["fit"];
  align?: "left" | "center";
  variant?: "rounded" | "square";
  onDismiss?: () => void;
  className?: string;
  children?: ReactNode;
}

export const Banner = ({
  className,
  color,
  text,
  icon,
  variant,
  fit,
  align = "center",
  onDismiss,
  children,
}: Props) => {
  const css = useMemo(
    () =>
      when(color && fromColor(sansDefault(color)), ([base, shade]) => ({
        backgroundColor: toColorVar(base, shade),
        color: toInverseColorVar(base, shade),
      })),
    [color]
  );

  return (
    <Container
      padding="none"
      fit={fit}
      className={cx(
        styles.banner,
        variant === "rounded" && styles.rounded,
        styles[align],
        className
      )}
      style={css}
    >
      {children || <Label className={styles.label} icon={icon} text={text} />}
      {!!onDismiss && (
        <Button
          icon={TimesCircle}
          className={styles.dismiss}
          onClick={onDismiss}
          size="tiny"
          subtle
        />
      )}
    </Container>
  );
};
