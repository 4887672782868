import { Campaign, PropertyRef } from "@api";
import { useMemo } from "react";

import { useLazyPropertyValue } from "@state/databases";

import { withHandle } from "@utils/event";
import { when } from "@utils/maybe";
import { toMutation } from "@utils/property-mutations";
import { formatShort } from "@utils/date";
import { useISODate } from "@utils/date-fp";
import { whenEmpty } from "@utils/array";

import { EntityContextMenu } from "@ui/entity-context-menu";
import { HStack, SpaceBetween } from "@ui/flex";
import { Icon, iconFromString } from "@ui/icon";
import { Label } from "@ui/label";
import { MenuItem } from "@ui/menu-item";
import { EditableText } from "@ui/editable-text";
import { Text } from "@ui/text";
import { StatusTag } from "@ui/tag";
import { SelectableListCard, SelectableListItem } from "@ui/selectable-items";
import { PropertyValueStack } from "@ui/property-value-stack";
import { TextHighlight } from "@ui/text-highlight";
import { Container } from "@ui/container";

import { UIEngine } from "../types";

import styles from "./styles.module.css";

const DEFAULT_PROPS: PropertyRef<Campaign>[] = [
  { field: "status", type: "status" },
  { field: "start", type: "date" },
  { field: "end", type: "date" },
];

export const CampaignEngine: UIEngine<Campaign> = {
  asMenuItem: function ({ item, onOpen, ...rest }) {
    const { value } = useLazyPropertyValue(item, {
      field: "status",
      type: "status",
    });
    const status = useMemo(() => value.status, [value]);

    return (
      <EntityContextMenu entity={item}>
        <MenuItem
          {...rest}
          onClick={withHandle(() => onOpen?.(item))}
          wrapLabel={false}
        >
          <SpaceBetween>
            <TextHighlight color={item.color}>
              <Label icon={when(item.icon, iconFromString)} text={item.name} />
            </TextHighlight>

            <HStack>
              {status && <StatusTag showIcon={false} status={status} />}
              {item.start && item.end && (
                <Text subtle>
                  {useISODate(item.start, (d) => formatShort(d))}
                  {"->"}
                  {useISODate(item.end, (d) => formatShort(d))}
                </Text>
              )}
            </HStack>
          </SpaceBetween>
        </MenuItem>
      </EntityContextMenu>
    );
  },
  asListCard: function CampaignListCard(props) {
    const { item, selection, setSelection, onChange, showProps } = props;
    return (
      <EntityContextMenu
        entity={item}
        selection={selection}
        setSelection={setSelection}
      >
        <SelectableListCard {...props}>
          <Container
            className={styles.bar}
            gap={0}
            stack="vertical"
            inset="bottom"
          >
            <Label
              icon={when(item.icon, iconFromString)}
              className={styles.title}
            >
              {item.name || "Campaign"}
            </Label>

            <PropertyValueStack
              item={item}
              props={whenEmpty(showProps, DEFAULT_PROPS)}
              onChange={onChange}
              hideEmpty={props.hideEmpty}
            />
          </Container>
        </SelectableListCard>
      </EntityContextMenu>
    );
  },
  asListItem: function (props) {
    const { item, showProps, onChange } = props;
    return (
      <EntityContextMenu
        entity={item}
        selection={props.selection}
        setSelection={props.setSelection}
      >
        <SelectableListItem {...props}>
          <SpaceBetween className={styles.upper}>
            <HStack className={styles.middle} gap={8}>
              {when(iconFromString(item.icon), (i) => (
                <Icon icon={i} />
              ))}
              <TextHighlight color={item.color}>
                <EditableText
                  key={item.id}
                  text={item.name || ""}
                  disabled={true}
                  placeholder="Untitled campaign"
                  blurOnEnter={true}
                  onChange={(v) =>
                    onChange?.(
                      toMutation(item, { field: "name", type: "text" }, v)
                    )
                  }
                />
              </TextHighlight>
            </HStack>

            <PropertyValueStack
              className={styles.rowDetails}
              justify="flex-end"
              gap={2}
              item={item}
              hideEmpty={props.hideEmpty}
              props={whenEmpty(showProps, DEFAULT_PROPS)}
              onChange={onChange}
            />
          </SpaceBetween>
        </SelectableListItem>
      </EntityContextMenu>
    );
  },
};
