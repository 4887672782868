import { ReactNode, useCallback, useRef, useState } from "react";

import { Link, getUrlMeta } from "@api";

import { linkName, useAiUseCase } from "@state/ai";

import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";
import { useAsyncEffect } from "@utils/effects";
import { isUrl, toDomain } from "@utils/url";
import { all } from "@utils/promise";
import { toMarkdown } from "@utils/rich-text";
import { toPlainText } from "@utils/markdown";

import { Button } from "@ui/button";
import { Dialog } from "@ui/dialog";
import { Field, TextInput } from "@ui/input";
import { HStack, VStack } from "@ui/flex";
import { Icon, ImageIcon, LinkAlt, SpinnerIcon } from "@ui/icon";
import { MenuItem } from "@ui/menu-item";
import { TextBox } from "@ui/rich-text";

export interface Props {
  link: Maybe<Link>;
  title?: string;
  onValidLink?: Fn<Link, boolean>;
  onLinked?: Fn<Link, void>;
  onCancel?: Fn<void, void>;
}

export const LinkDialog = ({
  link: _link,
  title,
  onCancel,
  onLinked: onChanged,
  onValidLink,
}: Props) => {
  const enriching = useRef<string>();
  const [suggested, setSuggested] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState(
    _link || { url: "", text: "", icon: undefined }
  );
  const { run, loading: aiLoading } = useAiUseCase(linkName);

  const onLink = useCallback(
    (useLink?: Link) => {
      const finalLink = useLink || link;
      onChanged?.({
        ...finalLink,
        text:
          finalLink.text || suggested || toDomain(finalLink.url) || "New Link",
      });
    },
    [link]
  );

  useAsyncEffect(async () => {
    const url = link.url;
    if (url.startsWith("http")) {
      setLoading(true);
      enriching.current = url;
      const [metadata, aiTitle] = await all([
        getUrlMeta(url),
        run({ link: url }),
      ]);

      if (enriching?.current !== url) {
        return;
      }

      const title =
        aiTitle && (metadata?.confidence || 0) < 0.8 ? aiTitle : metadata?.text;

      const validated = onValidLink?.({
        ...link,
        icon: metadata?.icon,
        text: title,
      });

      if (!validated === false) {
        setLink({ url: "" });
        setSuggested("");
      } else {
        setLink({ ...link, icon: metadata?.icon });
        setSuggested(title || "");
      }

      setLoading(false);
      enriching.current = undefined;
    }
  }, [link.url, enriching]);

  return (
    <Dialog
      title={title || "Link a resource"}
      mode="blocking"
      onDismiss={onCancel}
      actions={
        <HStack>
          <Button onClick={() => onCancel?.()}>Cancel</Button>
          <Button variant="primary" onClick={() => onLink()} loading={loading}>
            Add link
          </Button>
        </HStack>
      }
    >
      <VStack gap={16} fit="container">
        {!!link.url && isUrl(link.url) && (
          <Field label="Description">
            <TextBox
              key={"link-description"}
              text={{ markdown: link.text || "" }}
              focus={!!link.url}
              onChanged={(text) =>
                setLink({ ...link, text: toPlainText(toMarkdown(text) || "") })
              }
              updateOn="blur"
              placeholder={suggested || "What is this link..."}
            />
          </Field>
        )}
        {loading && (
          <MenuItem icon={<Icon icon={SpinnerIcon} />}>
            Loading link information...
          </MenuItem>
        )}
        {!loading && (
          <Field>
            <TextInput
              icon={
                <Icon
                  icon={
                    !!enriching?.current ? (
                      SpinnerIcon
                    ) : link.icon ? (
                      <ImageIcon url={link.icon} />
                    ) : (
                      LinkAlt
                    )
                  }
                />
              }
              value={link.url}
              autoFocus={!link.url}
              onChange={(url) =>
                setLink(
                  url
                    ? { ...link, url }
                    : { url: "", text: "", icon: undefined }
                )
              }
              updateOn="change"
              placeholder="Type or paste a link..."
            />
          </Field>
        )}
      </VStack>
    </Dialog>
  );
};

export const LinkDialogButton = ({
  children,
  onLink,
  title,
}: {
  title?: string;
  children: ReactNode;
  onLink: Fn<Link, void>;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {open && (
        <LinkDialog
          title={title}
          link={undefined}
          onCancel={() => setOpen(false)}
          onLinked={(l) => {
            setOpen(false);
            onLink?.(l);
          }}
        />
      )}
      <div onClick={() => setOpen(true)} style={{ display: "contents" }}>
        {children || <Button subtle>{children}</Button>}
      </div>
    </>
  );
};
