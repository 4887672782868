import { atomFamily, selectorFamily } from "recoil";

import { ID, Deal } from "@api";

import {
  getItem,
  indexedDBStorageForStore,
  mergeItem,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { isDefault } from "@utils/recoil";

export type DealStoreState = StoreState<Deal>;

export const WorkspaceDealStoreAtom = atomFamily<DealStoreState, ID>({
  key: "WorkspaceDealStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "deal")],
});

export const DealStoreAtom = WorkspaceWrappedAtom(
  "DealStoreAtom",
  WorkspaceDealStoreAtom
);

export const DealAtom = selectorFamily({
  key: "DealAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(DealStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(DealStoreAtom, mergeItem<Deal>(newValue));
      }
    },
});
