import { useCallback } from "react";
import { stringify } from "query-string";

import { envOption } from "@utils/env";
import { redirect } from "@utils/url";

import { Button } from "@ui/button";
import { SlackColor } from "@ui/icon";
import { required } from "@utils/maybe";

const SLACK_AUTH_URL = "https://slack.com/oauth/v2/authorize";
const SCOPES: string[] = [
  "app_mentions:read",
  "bookmarks:read",
  "bookmarks:write",
  "calls:read",
  "calls:write",
  "channels:history",
  "channels:join",
  "channels:manage",
  "channels:read",
  "channels:write.invites",
  "channels:write.topic",
  "chat:write",
  "chat:write.customize",
  "chat:write.public",
  "commands",
  "dnd:read",
  "emoji:read",
  "files:read",
  "files:write",
  "groups:history",
  "groups:read",
  "groups:write",
  "groups:write.invites",
  "groups:write.topic",
  "im:history",
  "im:read",
  "im:write",
  "links:read",
  "links:write",
  "metadata.message:read",
  "mpim:history",
  "mpim:read",
  "mpim:write",
  "mpim:write.invites",
  "mpim:write.topic",
  "pins:write",
  "reactions:read",
  "reactions:write",
  "reminders:read",
  "reminders:write",
  "remote_files:read",
  "remote_files:write",
  "team:read",
  "usergroups:read",
  "users.profile:read",
  "users:read",
  "users:read.email",
  "users:write",
  "usergroups:write",
];

export const SlackAuthorize = () => {
  const onAuthorize = useCallback(() => {
    const redirectUri = `${required(
      process.env.NEXT_PUBLIC_HOST,
      () => "Missing HOST env var."
    ).replace("http://local", "https://local")}/auth/slack-response`;

    const url = `${SLACK_AUTH_URL}?${stringify({
      client_id: envOption({
        prod: "4327581758883.4327683562130",
        dev: "4327581758883.6256894062338",
      }),
      scope: SCOPES.join(","),
      redirect_uri: redirectUri,
    })}`;

    redirect(url);
  }, []);

  return (
    <Button icon={SlackColor} onClick={onAuthorize}>
      Authorize Slack
    </Button>
  );
};
