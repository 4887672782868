import { useLocation, useParams } from "react-router-dom";
import { ComponentType, useMemo } from "react";
import { filter } from "lodash";

import { Fn } from "@utils/fn";
import { useQueryParams } from "@utils/hooks";
import { Maybe } from "@utils/maybe";

import { Redirect } from "./redirect";

interface Props<T> {
  params: Fn<Record<string, Maybe<string>>, T>;
  component: ComponentType<T>;
}
export default function WithParams<T>({ params, component: Comp }: Props<T>) {
  return (
    // Stops TS from complaining about the type of the component
    <Comp style={{}} {...params({ ...useQueryParams(), ...useParams() })} />
  );
}

interface LocationPartsProps<T> {
  params: Fn<string[], T>;
  component: ComponentType<T>;
}

export function WithLocationParts<T>({
  params,
  component: Comp,
}: LocationPartsProps<T>) {
  const location = useLocation();
  const parts = useMemo(
    () => filter(location.pathname.split("/"), Boolean),
    [location.pathname]
  );

  if (!parts?.length) {
    return <Redirect to="/home" />;
  }

  return (
    // Stops TS from complaining about the type of the component
    <Comp style={{}} {...params(parts)} />
  );
}
