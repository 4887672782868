import { isArray } from "lodash";
import { ensureMany, OneOrMany } from "./array";
import { Maybe } from "./maybe";

export type Star = "*";
export type OrStar<T> = T | Star;

export const isStar = <T>(thing: Maybe<OrStar<T>>): thing is Star =>
  thing === "*";

export const withoutStar = <T>(things: Maybe<OrStar<T>>): Maybe<T> =>
  isStar(things) ? undefined : things;

export const throwStar = <T>(things: Maybe<OrStar<T>>): Maybe<T> => {
  if (isStar(things)) {
    throw new Error("Star not allowed.");
  }
  return things;
};

export const equals = <T>(a: Maybe<OrStar<T>>, b: Maybe<OrStar<T>>): boolean =>
  isStar(a) || isStar(b) ? true : a === b;

export const isMatch = <T>(
  thing: T,
  things: Maybe<OrStar<OneOrMany<T>>>
): boolean =>
  isStar(things) ||
  (isArray(things) && isStar(things?.[0])) ||
  !!ensureMany(things)?.includes(thing);

export const replaceStar = <T>(thing: Maybe<OrStar<T>>, replacement: T): T =>
  isStar(thing) ? replacement : (thing as T);

export const whenStar = <T, R>(
  thing: Maybe<OrStar<T>>,
  fn: (t: T) => R
): R | undefined => (isStar(thing) ? undefined : fn(thing as T));
