import DataLoader from "dataloader";
import { ID, getNotes } from "@api";
import { cachedFuncByWorkspace } from "@state/workspace";

import { lookup } from "@utils/array";
import { toMilliSeconds } from "@utils/time";

const noteLoader = new DataLoader(
  async (ids: readonly string[]) => {
    const notes = await getNotes(ids as ID[]);
    const hashed = lookup(notes || [], (i) => i.id);
    return ids.map(hashed);
  },
  { cache: false }
);

export const getNoteLoader = cachedFuncByWorkspace(
  () => (id) => noteLoader.load(id),
  toMilliSeconds("1 minute"),
  ([v]) => v
);

export const getNotesLoader = cachedFuncByWorkspace(
  () => getNotes,
  toMilliSeconds("1 minute")
);
