import { filter, values } from "lodash";
import { selector, selectorFamily } from "recoil";

import { Calendar, ID } from "@api";

import { omitEmpty } from "@utils/array";
import { isMatch } from "@utils/scope";

import { CalendarStoreAtom } from "./atoms";

export const allCalendars = selector({
  key: "allCalendars",
  get: ({ get }) => {
    return omitEmpty(values(get(CalendarStoreAtom).lookup));
  },
});

export const calendarsForTeam = selectorFamily({
  key: "calendarsForTeam",
  get:
    (teamId: ID) =>
    ({ get }) =>
      filter(values(get(CalendarStoreAtom).lookup), (p) =>
        isMatch(p?.source?.scope, teamId)
      ) as Calendar[],
});
