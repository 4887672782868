import { atomFamily, selectorFamily } from "recoil";

import { ID, Calendar } from "@api";

import {
  getItem,
  indexedDBStorageForStore,
  mergeItem,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { isDefault } from "@utils/recoil";

export type CalendarStoreState = StoreState<Calendar>;

export const WorkspaceCalendarStoreAtom = atomFamily<CalendarStoreState, ID>({
  key: "WorkspaceCalendarStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "calendar")],
});

export const CalendarStoreAtom = WorkspaceWrappedAtom(
  "CalendarStoreAtom",
  WorkspaceCalendarStoreAtom
);

export const CalendarAtom = selectorFamily({
  key: "CalendarAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(CalendarStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(CalendarStoreAtom, mergeItem<Calendar>(newValue));
      }
    },
});
