import { isString } from "lodash";

export type JsonValue =
  | string
  | Date
  | number
  | boolean
  | JsonObject
  | JsonArray;
export interface JsonObject {
  [x: string]: JsonValue;
}
export type JsonArray = JsonValue[];
export type Json = JsonArray | JsonObject;

export const tryParse = (json: string) => {
  try {
    return JSON.parse(json) as JsonArray | JsonObject;
  } catch (err) {
    return undefined;
  }
};

export const asJSON = (value: string | Json | any) =>
  isString(value) ? tryParse(value) : value;
