import { map } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { useDebouncedCallback } from "@utils/hooks";
import { withHandle } from "@utils/event";
import { asNumber, fixed } from "@utils/number";
import { Maybe, when } from "@utils/maybe";
import { Fn } from "@utils/fn";
import { format, fuzzyParseTime, Time } from "@utils/time";

import { Text } from "@ui/text";
import { Dropdown } from "@ui/dropdown";
import { TextInput } from "@ui/input";
import { HStack, VStack } from "@ui/flex";
import { Button } from "@ui/button";
import { KeyEnter } from "@ui/icon";

import styles from "./time-picker.module.css";

interface DurationPickerProps {
  mins: Maybe<number>;
  maxTime?: number;
  onChange: Fn<Maybe<number>, void>;
  children: React.ReactNode;
}

const durationOptions = [
  [1, 2, 3, 4],
  [5, 10, 15, 20],
  [30, 45, 60, "max"],
];

export const DurationPicker = ({
  mins,
  maxTime = 60,
  onChange,
  children,
}: DurationPickerProps) => {
  const [open, setOpen] = useState(false);
  const slowOnChange = useDebouncedCallback(onChange, 500);
  const changeAndClose = useCallback(
    (v: Maybe<number>) => {
      setOpen(false);
      onChange(v);
    },
    [onChange]
  );

  return (
    <Dropdown trigger={children} open={open} setOpen={setOpen}>
      <div onClick={withHandle(() => {})}>
        <TextInput
          value={String(mins)}
          inputType="number"
          autoFocus={true}
          selectOnFocus={true}
          updateOn="change"
          onChange={(v) => slowOnChange(asNumber(v))}
          onEnter={() => changeAndClose(mins)}
        />
        {/* <Divider /> */}
        <VStack className={styles.durationOptions} gap={0}>
          {map(durationOptions, (row, i) => (
            <HStack key={i} gap={0}>
              {map(row, (v) => (
                <Button
                  key={v}
                  size="small"
                  subtle
                  className={styles.timeOption}
                  onClick={() =>
                    changeAndClose(v === "max" ? maxTime : asNumber(v))
                  }
                >
                  {v === "max" ? (
                    <Text>Max</Text>
                  ) : (
                    <Text>
                      {v} <Text subtle>mins</Text>
                    </Text>
                  )}
                </Button>
              ))}
            </HStack>
          ))}
        </VStack>
      </div>
    </Dropdown>
  );
};

interface TimePickerProps {
  time: Maybe<Time>;
  onChange: Fn<Maybe<Time>, void>;
  children: React.ReactNode;
}

const DEFAULT_TIMES: Time[][] = [
  [
    [9, 0],
    [10, 0],
    [11, 0],
    [12, 0],
  ],
  [
    [13, 0],
    [14, 0],
    [15, 0],
    [16, 0],
  ],
];

export const TimePicker = ({ time, onChange, children }: TimePickerProps) => {
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState("");

  const changeAndClose = useCallback(
    (v: Maybe<Time>) => {
      setOpen(false);
      onChange(v);
    },
    [onChange, time]
  );

  const parsed = useMemo(() => when(filter, fuzzyParseTime), [filter]);
  const options = useMemo((): Time[][] => {
    if (!parsed) {
      return DEFAULT_TIMES;
    }
    const [hours, mins] = parsed;
    return [
      [
        [hours, 0],
        [hours, 15],
        [hours, 30],
        [hours, 45],
      ],
      [
        [hours + 1, 0],
        [hours + 1, 15],
        [hours + 1, 30],
        [hours + 1, 45],
      ],
    ];
  }, [parsed?.[0], parsed?.[1]]);

  return (
    <Dropdown trigger={children} open={open} setOpen={setOpen}>
      <div onClick={withHandle(() => {})}>
        <div className={styles.timeInput}>
          <TextInput
            value={filter}
            autoFocus={true}
            selectOnFocus={true}
            updateOn="change"
            placeholder="Start typing a time..."
            onChange={(v) => setFilter(v)}
            onEnter={() => changeAndClose(parsed)}
          />
          {parsed && (
            <Button
              size="small"
              subtle
              iconRight={KeyEnter}
              className={styles.button}
              onClick={() => changeAndClose(parsed)}
            >
              <Text subtle>{format(parsed)}</Text>
            </Button>
          )}
        </div>
        {/* <Divider /> */}
        <VStack className={styles.durationOptions} gap={0}>
          {map(options, (row, i) => (
            <HStack key={i} gap={0}>
              {map(row, (time, i) => (
                <Button
                  key={i}
                  size="small"
                  subtle
                  className={styles.timeOption}
                  wrapLabel={false}
                  onClick={() => changeAndClose(time)}
                >
                  <Text>{format(time)}</Text>
                </Button>
              ))}
            </HStack>
          ))}
        </VStack>
      </div>
    </Dropdown>
  );
};
