import { map, filter as loFilter, groupBy, isString, find } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { ID, PersonRole } from "@api";

import { useSetSpace } from "@state/spaces";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useMe } from "@state/persons";
import { useLazyAllTeams } from "@state/teams";
import { useCurrentWorkspace } from "@state/workspace";
import { useAddToFetchResults } from "@state/fetch-results";

import { when } from "@utils/maybe";
import { toRef } from "@utils/property-refs";
import { equalsAny } from "@utils/logic";
import { useStickyState } from "@utils/hooks";
import { containsRef } from "@utils/relation-ref";
import { useGoTo } from "@utils/navigation";
import { maybeLookup } from "@utils/array";

import { PlusAlt } from "@ui/icon";
import { Button } from "@ui/button";
import { TeamCreateDialog } from "@ui/engine/team";
import { SpaceBetween, VStack } from "@ui/flex";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import { Heading, Text } from "@ui/text";
import { getEngine, render } from "@ui/engine";
import { Container } from "@ui/container";
import AppPage from "./app-page";

import styles from "./teams-page.module.css";

interface Props {}

export function TeamsPage({}: Props) {
  const me = useMe();
  const workspace = useCurrentWorkspace();
  const goTo = useGoTo();
  const [page] = useRegisterPage();
  const setSpace = useSetSpace();
  const allTeams = useLazyAllTeams();
  const onTeamAdded = useAddToFetchResults("all-teams"); // Matches internal cache key inside useAllLazyTeams
  const [addingTeam, setAddingTeam] = useState<boolean | string>(false);
  const addingDefaults = useMemo(
    () =>
      !addingTeam
        ? undefined
        : isString(addingTeam)
        ? {
            parent: toRef(addingTeam),
            owner: toRef(me),
            color: find(allTeams, (t) => t.id === addingTeam)?.color,
          }
        : { owner: toRef(me) },
    [addingTeam, allTeams]
  );
  const editable = useMemo(
    () => equalsAny(me.role, [PersonRole.Admin, PersonRole.Owner]),
    [me.role]
  );
  const [filter, setFilter] = useStickyState<"all" | "me">(
    "all",
    "spaces-filter"
  );
  const filteredTeams = useMemo(
    () =>
      filter === "me"
        ? loFilter(allTeams, (t) => containsRef(t.people, me))
        : allTeams,
    [allTeams, filter]
  );
  const teams = useMemo(() => {
    const exists = maybeLookup(filteredTeams, (t) => t.id);
    return groupBy(
      filteredTeams,
      (team) =>
        when(team.parent?.id, (id) => (exists(id) ? id : undefined)) || "all"
    );
  }, [filteredTeams, filter]);

  const onSelectTeam = useCallback(
    (id: ID) => {
      console.log("team selected", id);
      setSpace(id);
      goTo(`/${id}`);
    },
    [goTo]
  );

  usePageUndoRedo(page.id);

  return (
    <AppPage page={page}>
      <StackContainer>
        {!!addingDefaults && (
          <TeamCreateDialog
            defaults={addingDefaults}
            onCancel={() => setAddingTeam(false)}
            onSaved={(t) => {
              onTeamAdded([t]);
              setAddingTeam(false);
            }}
          />
        )}

        <Sheet size="full" transparency="low" interactable={false}>
          <VStack>
            <div className={styles.header}>
              <SpaceBetween>
                <VStack gap={0}>
                  <Heading bold>All Teams</Heading>
                  <Text subtle>At {workspace?.name || "your company."}</Text>
                </VStack>
                {editable && (
                  <Button onClick={() => setAddingTeam(true)} icon={PlusAlt}>
                    New team
                  </Button>
                )}
              </SpaceBetween>
            </div>

            <Container>
              <VStack
                width="container"
                fit="container"
                className={styles.teamsWrap}
                gap={30}
              >
                {map(teams["all"], (team) => (
                  <VStack gap={10}>
                    {render(getEngine("team").asListCard, {
                      item: team,
                      onOpen: () => onSelectTeam(team.id),
                    })}
                    {map(teams[team.id], (subteam) => (
                      <Container gap={10} stack="vertical" padding="left">
                        {render(getEngine("team").asListItem, {
                          item: subteam,
                          onOpen: () => onSelectTeam(subteam.id),
                        })}
                      </Container>
                    ))}
                  </VStack>
                ))}
              </VStack>
            </Container>
          </VStack>
        </Sheet>
      </StackContainer>
    </AppPage>
  );
}
