import { useCallback, useMemo } from "react";

import { Entity, PropertyMutation, hasFollowers } from "@api";

import { useMe } from "@state/persons";

import { asAppendMutation } from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";
import { containsRef } from "@utils/relation-ref";
import { Fn } from "@utils/fn";
import { OneOrMany } from "@utils/array";

import { Button } from "@ui/button";
import { Bell } from "@ui/icon";
import { Tooltip } from "@ui/tooltip";

type Props<T extends Entity = Entity> = {
  entity: T;
  mutate: Fn<OneOrMany<PropertyMutation<T>>, void>;
  variant?: "icon-only" | "default";
};

export const FollowButton = <T extends Entity>({
  entity,
  mutate,
  variant = "default",
}: Props<T>) => {
  const me = useMe();
  const followers = useMemo(
    () => (hasFollowers(entity) ? entity.refs?.followers || [] : []),
    [entity]
  );
  const isFollowing = useMemo(() => containsRef(followers, me), [followers]);

  const handleChange = useCallback(() => {
    mutate(
      asAppendMutation(
        { field: "refs.followers", type: "relations" },
        [toRef(me)],
        isFollowing ? "remove" : "add"
      )
    );
  }, [entity.id, isFollowing]);

  return (
    <Tooltip text={isFollowing ? "Unfollow" : "Follow"}>
      <Button
        size="small"
        variant={isFollowing ? "primary" : "secondary"}
        icon={Bell}
        subtle
        onClick={handleChange}
      >
        {variant !== "icon-only" && isFollowing && "Following"}
      </Button>
    </Tooltip>
  );
};
