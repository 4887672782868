import { ReactNode } from "react";

import * as RDMenu from "@radix-ui/react-dropdown-menu";

import { handle } from "@utils/event";
import { Fn } from "@utils/fn";
import { cx } from "@utils/class-names";

import { Button } from "./button";
import { EllipsisH } from "./icon";
import { MenuItem, MenuItemProps } from "./menu-item";

import styles from "./action-menu.module.css";

interface Props {
  actions?: ReactNode;
  trigger?: ReactNode;
  className?: string;
  children?: ReactNode;
  onOpen?: Fn<boolean, void>;
}

type ActionItemProps = Omit<MenuItemProps, "onClick"> & {
  onClick?: Fn<void, void>;
};

export const ActionItem = ({
  children,
  onClick,
  ...props
}: ActionItemProps) => (
  <RDMenu.Item onSelect={() => onClick?.(undefined)}>
    <MenuItem {...props}>{children}</MenuItem>
  </RDMenu.Item>
);

export const ActionLabel = ({ children }: { children: ReactNode }) => (
  <RDMenu.Label>
    <div className={styles.label}>{children}</div>
  </RDMenu.Label>
);

export const ActionGroup = ({
  label,
  children,
}: {
  label?: string;
  children: ReactNode;
}) => (
  <RDMenu.Group>
    {label && <ActionLabel>{label}</ActionLabel>}
    {children}
  </RDMenu.Group>
);

export const ActionDivider = () => (
  <RDMenu.Separator className={styles.divider} />
);

export const ActionMenu = (props: Props) => {
  const trigger = props.trigger || props.children;
  const actions = !!props.trigger ? props.children : props.actions;

  return (
    <RDMenu.Root onOpenChange={props.onOpen}>
      <RDMenu.Trigger asChild>
        <div className={cx(styles.inherit, props.className)}>
          {trigger ?? (
            <Button as="button" subtle size="small" icon={EllipsisH} />
          )}
        </div>
      </RDMenu.Trigger>

      <RDMenu.Portal>
        <RDMenu.Content
          align="start"
          alignOffset={6}
          className={styles.popover}
          onClick={handle}
          avoidCollisions={true}
        >
          {actions}
        </RDMenu.Content>
      </RDMenu.Portal>
    </RDMenu.Root>
  );
};
