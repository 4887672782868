import { Agenda } from "@api";
import { WorkflowDefinitionConfig } from "@state/workflows";

export const definitions: WorkflowDefinitionConfig<Agenda> = {
  triggers: [],
  suggestions: [],
  actions: [],
};

export default definitions;
