import { map } from "lodash";
import { useCallback } from "react";

import { Process, RichText, SelectOption } from "@api";

import { useEditInAppCommands } from "@state/app";
import {
  useLazyEntities,
  useQueueUpdates,
  useUpdateEntity,
} from "@state/generic";
import { useLazyPropertyDef } from "@state/databases";
// import { useAutoLinkRelated } from "@state/pages/effects";

import { useShowMore } from "@utils/hooks";
import { Maybe, safeAs, when } from "@utils/maybe";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { ensureArray } from "@utils/array";
import { useGoTo } from "@utils/navigation";

import { usePageId } from "@ui/app-page";
import { Centered, Container } from "@ui/container";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { ArrowUpRight, RelationIcon } from "@ui/icon";
import { Sheet } from "@ui/sheet-layout";
import { WorkflowActions } from "@ui/workflow-action-button";
import { EditableHeading } from "@ui/editable-heading";
import { TemplateBanner } from "@ui/template-banner";
import { LabelledValue } from "@ui/property-value";
import { PeopleStack } from "@ui/people-stack";
import { AddMenuItem, ShowMoreMenuItem } from "@ui/menu-item";
import { GlobalMultiEntitySelect } from "@ui/select";
import { PaneContainer, PaneHeader } from "@ui/pane-header";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { ResourceExtractor } from "@ui/resource-extractor";
import { EntityHeaderBar } from "@ui/entity-header-bar";
import { OnHover } from "@ui/on-hover";
import { PropertyValueButton } from "@ui/property-value-button";
import { SummarySection } from "@ui/summary-section";
import { ApplyTemplateButton } from "@ui/apply-template-button";
import { PageBody } from "@ui/page-body";

import { PaneOpts } from "../types";
import { render, toEngine } from "..";

import styles from "./styles.module.css";

export const ProcessPane = ({ id, item: process }: PaneOpts<Process>) => {
  const mutate = useUpdateEntity(process.id, process.source.scope);
  const editInAppCommands = useEditInAppCommands();
  const ownerProp = useLazyPropertyDef(process.source, {
    field: "owner",
    type: "relation",
  });

  const handleBodyChanged = useCallback(
    (n: Maybe<RichText>) =>
      mutate(asMutation({ field: "body", type: "rich_text" }, n)),
    [mutate]
  );

  return (
    <Sheet size="primary">
      {!!process.template && <TemplateBanner />}

      <VStack gap={0}>
        <Container>
          <EntityHeaderBar entity={process} />
        </Container>

        <OnHover.Trigger>
          <Centered padding="horizontal">
            <VStack gap={20} fit="container">
              <SpaceBetween direction="horizontal" align="stretch" gap={12}>
                <VStack gap={0}>
                  <Container padding="none" inset="left">
                    <HStack gap={0}>
                      <OnHover.Target
                        show={
                          !!safeAs<SelectOption[]>(process.custom?.category)
                            ?.length
                        }
                      >
                        <PropertyValueButton
                          entity={process}
                          field="custom.category"
                          type="multi_select"
                        />
                      </OnHover.Target>

                      <OnHover.Target show={!!process.status}>
                        <PropertyValueButton
                          entity={process}
                          field="status"
                          type="status"
                        />
                      </OnHover.Target>
                      {!process.owner && (
                        <OnHover.Target>
                          <PropertyValueButton
                            entity={process}
                            field="owner"
                            type="relation"
                          />
                        </OnHover.Target>
                      )}
                    </HStack>
                  </Container>

                  <SpaceBetween align="baseline" gap={0}>
                    <EditableHeading
                      key={process.id}
                      text={process.name || ""}
                      placeholder="What is this for?"
                      onChange={(text) => {
                        when(text, (i) =>
                          mutate(asMutation({ field: "name", type: "text" }, i))
                        );
                      }}
                    />

                    <ApplyTemplateButton entity={process} />
                  </SpaceBetween>
                </VStack>

                {process.owner && (
                  <HStack>
                    <LabelledValue label="Owner">
                      <Container
                        size="half"
                        padding="vertical"
                        onClick={() => editInAppCommands(ownerProp, process)}
                      >
                        <PeopleStack
                          people={when(process.owner, ensureArray) || []}
                          size="xlarge"
                        />
                      </Container>
                    </LabelledValue>
                  </HStack>
                )}
              </SpaceBetween>

              {!process.template && (
                <HStack fit="container" gap={4} className={styles.hideEmpty}>
                  <WorkflowActions entity={process} />
                </HStack>
              )}
            </VStack>
          </Centered>
        </OnHover.Trigger>
      </VStack>

      <Centered stack="vertical" gap={10}>
        {!process.template && (
          <SummarySection entityId={process.id} hideOnEmpty={true} />
        )}

        <PageBody
          entity={process}
          className={styles.editor}
          body={process.body}
          onBodyChanged={handleBodyChanged}
        />
      </Centered>
    </Sheet>
  );
};

export const ProcessLinksPane = ({ page }: { page: Process }) => {
  const pageId = usePageId();
  const goTo = useGoTo();
  const related = useLazyEntities(page.refs.related || []);
  const mutate = useQueueUpdates(pageId);
  const relatedDef = useLazyPropertyDef(page.source, {
    field: "refs.related",
    type: "relations",
  });
  const { visible, showMore, hasMore, moreCount } = useShowMore(
    related || [],
    5
  );

  // TODO: Extract out related like from meetings to other entities
  // Move into generic effects
  // Automatically link work that is mentioned in the agendas....
  // useAutoLinkRelated(page, pageId);

  return (
    <Sheet size="secondary">
      <PaneHeader title="Related work" />

      <ResourceExtractor entityId={page.id} />

      <PaneContainer>
        <Menu>
          <MenuGroup>
            {map(visible, (item) =>
              render(toEngine(item)?.asMenuItem, {
                key: item.id,
                item: item,
                onChange: (cs) => mutate(asUpdate(item, cs)),
                onOpen: goTo,
                iconRight: ArrowUpRight,
              })
            )}

            {hasMore && (
              <ShowMoreMenuItem count={moreCount} onClick={showMore} />
            )}

            <GlobalMultiEntitySelect
              value={page.refs.related}
              scope={page.source.scope}
              allowed={relatedDef?.options?.references}
              onChange={(r) =>
                mutate(
                  asUpdate(
                    page,
                    asMutation({ field: "refs.related", type: "relations" }, r)
                  )
                )
              }
              closeOnSelect={true}
              placeholder="Link work"
            >
              <AddMenuItem
                icon={RelationIcon}
                title="Link related work"
                subtle
              />
            </GlobalMultiEntitySelect>
          </MenuGroup>
        </Menu>
      </PaneContainer>
    </Sheet>
  );
};
