import { find } from "lodash";
import { useCallback, useMemo } from "react";

import { Ref, Team, TeamVisibility } from "@api";

import { useCreateTeam } from "@state/teams";

import { Fn } from "@utils/fn";
import { toPropertyValueRef, toRef } from "@utils/property-refs";
import { Maybe, when } from "@utils/maybe";

import { Button } from "@ui/button";
import {
  PersonMultiSelect,
  PersonSelect,
  Select,
  TextSubtextOption,
} from "@ui/select";
import { Field, TextInput } from "@ui/input";
import { FillSpace } from "@ui/flex";
import { DialogSplit } from "@ui/dialog-split";
import { Text } from "@ui/text";
import { Container } from "@ui/container";
import { SlackSelect } from "@ui/select/slack";
import { showError } from "@ui/notifications";
import { usePageId } from "@ui/app-page";
import { TEAM_VISIBILITIES } from "@ui/settings-team";
import { TeamButton, TeamSelect } from "@ui/team-select";
import { ColorSelect } from "@ui/select/color";
import { Icon, Swatch } from "@ui/icon";

import styles from "./styles.module.css";

interface Props {
  defaults: Partial<Omit<Team, "id">>;
  onSaved?: Fn<Ref, void>;
  onCancel?: Fn<void, void>;
}

export const TeamCreateDialog = ({ onCancel, onSaved, defaults }: Props) => {
  const pageId = usePageId();
  const { team, setTeam, create } = useCreateTeam(pageId, defaults);
  const channel = useMemo(
    () =>
      when(team, (t) =>
        toPropertyValueRef(t, {
          field: "settings.channel",
          type: "text",
        })
      ),
    [team]
  );

  const onCreate = useCallback(async () => {
    if (!team?.name) {
      showError("Enter a team name.");
      return;
    }

    const saved = create();

    if (saved) {
      onSaved?.(saved);
    } else {
      onCancel?.();
    }
  }, [team, create, onSaved]);

  return (
    <DialogSplit
      title={"New team"}
      onDismiss={onCancel}
      side={
        <>
          <Text subtle>Create a team and add people from your workspace.</Text>
          <Text subtle>
            Choose where your team's notion pages and slack threads should be
            created by default.
          </Text>
        </>
      }
      actions={
        <>
          <Button onClick={() => onCancel?.()}>Cancel</Button>
          <Button variant="primary" onClick={onCreate}>
            Create team
          </Button>
        </>
      }
    >
      <FillSpace>
        <Container gap={20} stack="vertical" fit="container">
          <Field label="Name">
            <TextInput
              icon={
                <ColorSelect
                  color={team?.color}
                  onChange={(color) => setTeam((t) => ({ ...t, color: color }))}
                >
                  <Icon icon={<Swatch color={team?.color || "gray_5"} />} />
                </ColorSelect>
              }
              value={team.name || ""}
              onChange={(t) => setTeam({ ...team, name: t })}
              updateOn="change"
              placeholder="Enter team name..."
            />
          </Field>

          <Field label="Parent Team">
            <TeamSelect
              team={team.parent}
              onChanged={(p) => setTeam({ ...team, parent: toRef(p) })}
              className={{ trigger: styles.control }}
            >
              <TeamButton
                team={team.parent}
                caret={true}
                className={styles.control}
                emptyIsPrivate={false}
              />
            </TeamSelect>
          </Field>

          <Field label="Owner">
            <PersonSelect
              className={styles.control}
              value={team.owner}
              portal={true}
              onChange={(p) => p && setTeam({ ...team, owner: p })}
              placeholder="Select owner..."
            />
          </Field>

          <Field label="Team Members">
            <PersonMultiSelect
              className={{ trigger: styles.control }}
              placeholder="Select people..."
              value={team.people}
              portal={true}
              onChange={(p) => p && setTeam({ ...team, people: p })}
            />
          </Field>

          <Field label="Visibility">
            <Select
              options={TEAM_VISIBILITIES}
              value={find(TEAM_VISIBILITIES, { id: team.visibility })}
              overrides={useMemo(() => ({ Option: TextSubtextOption }), [])}
              className={{ trigger: styles.control }}
              onChange={(v) =>
                setTeam((t) => ({
                  ...t,
                  visibility:
                    (v?.id as Maybe<TeamVisibility>) || TeamVisibility.Open,
                }))
              }
            />
          </Field>

          <Field label="Team Channel">
            <SlackSelect
              mode="channel"
              portal={true}
              className={{ trigger: styles.control }}
              channel={channel?.value?.text}
              placeholder="Select a channel..."
              onChange={(id) =>
                setTeam({
                  ...team,
                  settings: { ...team.settings, channel: id },
                })
              }
            />
          </Field>
        </Container>
      </FillSpace>
    </DialogSplit>
  );
};
