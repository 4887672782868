import { find, some } from "lodash";

import { CreateOrUpdate, Team } from "@api";

import { isCreateOrUpdate } from "@state/store";
import { WorkflowDefinition, WorkflowDefinitionConfig } from "@state/workflows";

import { asMutation, asUpdate } from "@utils/property-mutations";
import { containsRef, uniqRefs } from "@utils/relation-ref";

// Always make sure that the owner is in the team.peopl
export const ensureOwnerInTeam: WorkflowDefinition<Team> = {
  id: "ensureOwnerInTeam",
  trigger: "WILL_UPDATE",
  type: "team",
  allowed: ({ entity: team, update }, { stores }) => {
    // Don't run on add operations
    if (
      !isCreateOrUpdate(update) ||
      some(update.changes, (f) => f.op === "add" || f.op === "remove")
    ) {
      return false;
    }

    const owner =
      find(update.changes, (c) => c.field === "owner")?.value.relation ||
      team?.owner;

    const people =
      find(update.changes, (c) => c.field === "people")?.value.relations ||
      team?.people ||
      [];

    // Is updating owner or people
    return !!owner && !!people && !containsRef(people, owner);
  },
  execute: ({ entity: team, update }, { session }) => {
    const owner =
      find((update as CreateOrUpdate<Team>).changes, { field: "owner" })?.value
        .relation || team.owner;

    const people =
      find((update as CreateOrUpdate<Team>).changes, { field: "people" })?.value
        .relations ||
      team.people ||
      [];

    if (!owner) {
      return [];
    }

    return asUpdate(
      team,
      asMutation(
        { field: "people", type: "relations" },
        uniqRefs([...people, owner])
      )
    );
  },
};

export const definitions: WorkflowDefinitionConfig<Team> = {
  triggers: [ensureOwnerInTeam],
  actions: [],
};

export default definitions;
