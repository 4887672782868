import { pick } from "lodash";
import { AtomEffect } from "recoil";

import { Maybe, when } from "@utils/maybe";
import { isDefault } from "@utils/recoil";
import { composel } from "@utils/fn";
import { isClient } from "@utils/ssr";
import { ID } from "./types";
import { getItem, removeItem, setItem } from "./storage";

type PersistenceOptions<T> = {
  key: string;
  props?: Array<keyof T>;
  clean?: (val: Partial<T>) => Partial<T>;
  skipDefault?: boolean;
  default?: T;
};

export function getStored<T>(
  key: string,
  defaultVal: T,
  props?: (keyof T)[]
): T;
export function getStored<T>(key: string): Maybe<T>;
export function getStored<T>(
  key: string,
  defaultVal?: T,
  props?: (keyof T)[]
): Maybe<T> {
  if (!isClient()) {
    return undefined;
  }
  const savedValue = getItem(key);

  if (savedValue !== null && savedValue !== undefined) {
    return {
      ...defaultVal,
      ...(props ? pick(JSON.parse(savedValue), props) : JSON.parse(savedValue)),
    };
  }

  return defaultVal;
}

export const appendKey = (key: string, part: ID) => `${key}.${part}`;

export const localStorageEffect =
  <T>(options: PersistenceOptions<T>): AtomEffect<T> =>
  ({ setSelf, onSet }) => {
    if (!isClient()) {
      return;
    }

    if (options.skipDefault !== true) {
      // Load from LS and merge with default
      when(
        getStored(options.key, options.default),
        composel(options.clean || ((a) => a), setSelf)
      );
    }

    onSet((newValue, _, isReset) => {
      isReset || isDefault(newValue)
        ? removeItem(options.key)
        : setItem(
            options.key,
            JSON.stringify(
              options.props ? pick(newValue, options.props) : newValue
            )
          );
    });
  };
