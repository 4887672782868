import { last as arLast, reduce } from "lodash";

export const copy = <T>(ts: Set<T>): Set<T> => new Set(ts);

export const without = <T>(ts: Set<T>, t: T): Set<T> => {
  const r = copy(ts);
  r.delete(t);
  return r;
};

export const add = <T>(ts: Set<T>, t: T): Set<T> => copy(ts).add(t);

export const addAll = <T>(ts: Set<T>, vals: T[]): Set<T> =>
  reduce(vals, (s, n) => s.add(n), copy(ts));

export const last = <T>(ts: Set<T>): T | undefined => arLast(Array.from(ts));

export const toArray = <T>(ts: Set<T>): T[] => Array.from(ts);
