import { useEffect, useMemo } from "react";
import { useRecoilSnapshot } from "recoil";

import { debug } from "@utils/debug";
import { getEnvVar } from "@utils/env";
import { onClient } from "@utils/ssr";

export function DebugObserver() {
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    debug("The following atoms were modified:");
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      debug(node.key, snapshot.getLoadable(node));
    }
  }, [snapshot]);

  return null;
}

export function RecoilDebugger() {
  const enabled = useMemo(
    () => onClient(() => getEnvVar("DEBUGGING") === "true"),
    []
  );
  return enabled ? <DebugObserver /> : <></>;
}
