import { atom, selector, selectorFamily } from "recoil";
import { find } from "lodash";

import { ID } from "@api";
import { isDefault } from "@utils/recoil";

import { setAppCommands, updatePage } from "./actions";
import { AppLocationState } from "./types";

export const AppLocationAtom = atom<AppLocationState>({
  key: "AppLocationAtom",
  default: { history: [] },
});

export const AppPageAtom = selectorFamily({
  key: "AppPageAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      find(get(AppLocationAtom).history, (p) => p.id === id),
  set:
    (id: ID) =>
    ({ set }, newValue) => {
      // throw new Error("asdsa");
      !!newValue &&
        !isDefault(newValue) &&
        set(AppLocationAtom, updatePage(id, newValue));
    },
});

export const AppCommandsAtom = selector({
  key: "AppCommands",
  get: ({ get }) => get(AppLocationAtom).commands,
  set: ({ set }, newValue) => {
    !!newValue &&
      !isDefault(newValue) &&
      set(AppLocationAtom, setAppCommands(newValue));
  },
});
