import { map, set } from "lodash";

import { Entity, EntityType } from "@api";
import { EntityForType } from "@api/mappings";

import generic from "@state/generic/workflows";
import view from "@state/views/workflows";
import backlog from "@state/backlog/workflows";
import task from "@state/tasks/workflows";
import schedule from "@state/schedule/workflows";
import meeting from "@state/meetings/workflows";
import action from "@state/actions/workflows";
import agenda from "@state/agendas/workflows";
import project from "@state/projects/workflows";
import sprint from "@state/sprint/workflows";
import outcome from "@state/outcomes/workflows";
import team from "@state/teams/workflows";
import deal from "@state/deal/workflows";
import contact from "@state/contact/workflows";
import company from "@state/company/workflows";

import { when } from "@utils/maybe";
import { ensureMany, pushDirty } from "@utils/array";
import { switchEnum } from "@utils/logic";

import { WorkflowDefinition, WorkflowDefinitionConfig } from "./types";

type EntityConfigs = {
  [T in EntityType | "*"]?: WorkflowDefinitionConfig<
    T extends EntityType ? EntityForType<T> : Entity
  >;
};

// Add new workflow definitions here
let _all: EntityConfigs;

const pushDef = (
  configs: EntityConfigs,
  type: EntityType | "*",
  def: WorkflowDefinition
): void => {
  const field: keyof WorkflowDefinitionConfig = switchEnum(def.trigger, {
    ACTION: (): keyof WorkflowDefinitionConfig => "actions",
    SUGGEST: "suggestions",
    WILL_UPDATE: "triggers",
    DID_UPDATE: "triggers",
  });

  if (!configs[type]?.[field]) {
    if (!!configs[type]) {
      set(configs, [type, field], []);
    } else {
      set(configs, [type], { [field]: [] });
    }
  }

  pushDirty(configs[type]?.[field] as WorkflowDefinition<Entity>[], def);
};

const all = () => {
  if (!_all) {
    const indexed: EntityConfigs = {
      view,
      task,
      team,
      project,
      meeting,
      action,
      agenda,
      deal,
      backlog,
      schedule,
      sprint,
      outcome,
      contact,
      company,
    };
    map(generic.triggers, (t) => {
      map(ensureMany(t.type), (type) => {
        pushDef(indexed, type, t);
      });
    });
    map(generic.actions, (t) => {
      map(ensureMany(t.type), (type) => {
        pushDef(indexed, type, t);
      });
    });
    map(generic.suggestions, (t) => {
      map(ensureMany(t.type), (type) => {
        pushDef(indexed, type, t);
      });
    });
    _all = indexed;
  }
  return _all;
};

export const getTriggers = <T extends EntityType>(type?: T) => [
  ...(all()["*"]?.triggers || []),
  ...(when(type, (t) => all()[t]?.triggers) || []),
];

export const getSuggestions = <T extends EntityType>(type?: T) => [
  ...(all()["*"]?.suggestions || []),
  ...(when(type, (t) => all()[t]?.suggestions) || []),
];

export const getActions = <T extends EntityType>(type?: T) => [
  ...(all()["*"]?.actions || []),
  ...(when(type, (t) => all()[t]?.actions) || []),
];
