import { atomFamily, selectorFamily } from "recoil";

import { ID, Project } from "@api";
import {
  getItem,
  indexedDBStorageForStore,
  mergeItem,
  StoreState,
} from "@state/store";
import { isDefault } from "@utils/recoil";

import { WorkspaceWrappedAtom } from "@state/workspace";

export type ProjectState = Project & {
  viewIds?: ID[];
};

export type ProjectStoreState = StoreState<ProjectState>;

export const WorkspaceProjectStoreAtom = atomFamily<ProjectStoreState, ID>({
  key: "WorkspaceProjectStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "project")],
});

export const ProjectStoreAtom = WorkspaceWrappedAtom(
  "ProjectStoreAtom",
  WorkspaceProjectStoreAtom
);

export const ProjectAtom = selectorFamily({
  key: "ProjectAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(ProjectStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(ProjectStoreAtom, mergeItem<ProjectState>(newValue));
      }
    },
});
