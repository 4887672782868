import { Note, PropertyRef } from "@api";

import { withHandle } from "@utils/event";
import { whenEmpty } from "@utils/array";
import { formatHuman } from "@utils/date";

import { EntityContextMenu } from "@ui/entity-context-menu";
import { FillSpace, SpaceBetween } from "@ui/flex";
import { MenuItem } from "@ui/menu-item";
import { SelectableListCard, SelectableListItem } from "@ui/selectable-items";
import { RelationIcon } from "@ui/relation-label";
import { PropertyValueStack } from "@ui/property-value-stack";
import { ReadonlyPlainText } from "@ui/rich-text";
import { Icon } from "@ui/icon";
import { Text } from "@ui/text";
import { Ellipsis } from "@ui/ellipsis";
import { UpdateThread } from "@ui/update-thread";
import { DiscussionThread } from "@ui/discussion-thread";

import { UIEngine } from "../types";
import { NoteCardItem } from "./card";

import styles from "./styles.module.css";
import { usePointDate } from "@utils/date-fp";

const DEFAULT_PROPS: PropertyRef<Note>[] = [{ field: "next", type: "date" }];

export const NoteEngine: UIEngine<Note> = {
  asMenuItem: function ({ item, onOpen, ...rest }) {
    return (
      <EntityContextMenu entity={item}>
        <MenuItem
          {...rest}
          onClick={withHandle(() => onOpen?.(item))}
          wrapLabel={false}
        >
          <SpaceBetween gap={6}>
            <Icon icon={<RelationIcon relation={item.createdBy} />} />
            <FillSpace>
              <Ellipsis>
                <ReadonlyPlainText
                  text={item.body}
                  className={styles.noHighlight}
                />
              </Ellipsis>
            </FillSpace>
            {item.createdAt && (
              <Text subtle>{usePointDate(item?.createdAt, formatHuman)}</Text>
            )}
          </SpaceBetween>
        </MenuItem>
      </EntityContextMenu>
    );
  },
  asListCard: (props) => {
    const { item, onOpen } = props;
    return (
      <EntityContextMenu
        entity={item}
        selection={props.selection}
        setSelection={props.setSelection}
      >
        <SelectableListCard {...props}>
          <NoteCardItem
            note={item}
            onClick={() => onOpen?.(item)}
            showSource={false}
          />
        </SelectableListCard>
      </EntityContextMenu>
    );
  },
  asListItem: function NoteListItem(props) {
    const { item, className, showProps, onChange } = props;

    return (
      <EntityContextMenu
        entity={item}
        selection={props.selection}
        setSelection={props.setSelection}
      >
        <SelectableListItem {...props} className={className}>
          <SpaceBetween gap={6}>
            <Icon icon={<RelationIcon relation={item.createdBy} />} />
            <FillSpace>
              <Ellipsis>
                <ReadonlyPlainText
                  text={item.body}
                  className={styles.noHighlight}
                />
              </Ellipsis>
            </FillSpace>

            <PropertyValueStack
              justify="flex-end"
              gap={2}
              item={item}
              props={whenEmpty(showProps, DEFAULT_PROPS)}
              hideEmpty={props.hideEmpty}
              onChange={onChange}
            />
          </SpaceBetween>
        </SelectableListItem>
      </EntityContextMenu>
    );
  },
  asPrimaryPane: function NotePrimaryPane({ item: note }) {
    {
      if (note?.type === "update") {
        return <UpdateThread noteId={note?.id} />;
      }

      return <DiscussionThread noteId={note?.id} />;
    }
  },
};

export { NoteCardItem as UpdateItem };
