import { find } from "lodash";

import { Entity, Project, Update } from "@api";
import {
  WorkflowAction,
  WorkflowContext,
  WorkflowDefinitionConfig,
} from "@state/workflows";

import { inflateProperty } from "@utils/property-refs";

import { ParentStartDialog } from "@ui/parent-start-dialog";

// Archive a project when completed
export const startProject: WorkflowAction<Project, Update<Entity>[]> = {
  id: "startProject",
  trigger: "ACTION",
  type: "project",
  icon: undefined,
  title: "Start project",

  // TODO: Lol simplify inflating properties in workflow defs
  allowed: ({ entity }, { props }) =>
    inflateProperty(entity, find(props, { field: "status" }))?.status?.group ===
    "planning",
  collect: ({ data: { entity }, onCollected, onCancelled, context }) => (
    <ParentStartDialog
      entity={entity}
      toParentProp={{ field: "projects", type: "relations" }}
      context={context as WorkflowContext<Entity>}
      onCollected={onCollected}
      onCancelled={onCancelled}
    />
  ),
  execute: ({ entity, collected }, { session }) => {
    return collected as Update<Project>[];
  },
};

export const definitions: WorkflowDefinitionConfig<Project> = {
  triggers: [],
  actions: [startProject],
};

export default definitions;
