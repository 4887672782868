import { filter, values } from "lodash";
import { selector, selectorFamily } from "recoil";

import { Schedule, ID } from "@api";

import { omitEmpty } from "@utils/array";

import { ScheduleStoreAtom } from "./atoms";

export const allSchedules = selector({
  key: "allSchedules",
  get: ({ get }) => {
    return omitEmpty(values(get(ScheduleStoreAtom).lookup));
  },
});

export const calendarsForTeam = selectorFamily({
  key: "calendarsForTeam",
  get:
    (teamId: ID) =>
    ({ get }) => {
      return filter(values(get(ScheduleStoreAtom).lookup), (p) =>
        p?.source.scope?.includes(teamId)
      ) as Schedule[];
    },
});
