import { filter, map } from "lodash";
import { ID, IntegrationAuth, WorkspaceConfig } from "@api";

import { OneOrMany, ensureMany, uniqConcat } from "@utils/array";

import { WorkspaceSessionState } from "./types";

export const setActiveWorkspace =
  (workspaceId: ID) => (state: WorkspaceSessionState) => ({
    ...state,
    active: workspaceId,
  });

export const addWorkspaceConfig =
  (config: OneOrMany<WorkspaceConfig>) =>
  (state: WorkspaceSessionState): WorkspaceSessionState => ({
    ...state,
    workspaces: uniqConcat(
      state.workspaces,
      ensureMany(config),
      (c) => c.workspace?.id
    ),
    active: state.active || ensureMany(config)[0]?.workspace?.id || "none",
  });

export const addAuthToWorkspace =
  (workspaceId: ID, auth: IntegrationAuth) =>
  (state: WorkspaceSessionState) => {
    return {
      ...state,
      workspaces: map(state.workspaces, (w) =>
        w.workspace?.id === workspaceId
          ? {
              ...w,
              auths: {
                ...w.auths,
                [auth.source]: auth,
              },
            }
          : w
      ),
    };
  };

export const removeWorkspaceConfig =
  (workspaceID: ID) => (state: WorkspaceSessionState) => ({
    ...state,
    workspaces: filter(
      state.workspaces,
      (w) => w.workspace?.id !== workspaceID
    ),
  });
