import {
  useActiveWorkspaceId,
  useLazyWorkspace,
  useUpdateWorkspace,
} from "@state/workspace";

import { asMutation, toMutation } from "@utils/property-mutations";

import { Centered } from "@ui/container";
import { Heading, Text } from "@ui/text";
import { Divider } from "@ui/divider";
import { VStack } from "@ui/flex";
import { Field, TextInput } from "@ui/input";

import SettingsSchema from "./settings-schemas";
import { TimezoneSelect } from "@ui/select/timezone";
import { useSetting } from "@state/settings";
import { Maybe } from "@utils/maybe";

interface Props {}

export function SettingsWorkspaceData({}: Props) {
  const wID = useActiveWorkspaceId();
  const entity = useLazyWorkspace();
  const mutate = useUpdateWorkspace();
  const timezone = useSetting<Maybe<string>>(wID, "timezone");

  return (
    <Centered gap={20} stack="vertical">
      <VStack gap={0}>
        <Heading bold>Workspace Info</Heading>
        <Text subtle>Configure where how this workspace saves data.</Text>
      </VStack>

      <Divider direction="horizontal" />

      <VStack gap={20} fit="container">
        <Field label="Organisation Name">
          <TextInput
            value={entity?.name || ""}
            onChange={(name) =>
              entity &&
              name &&
              mutate([
                toMutation(entity, { field: "name", type: "text" }, name),
              ])
            }
            updateOn="blur"
            placeholder="Update team name..."
          />
        </Field>

        <Field label="Default Timezone">
          <TimezoneSelect
            value={timezone}
            onChange={(tz) =>
              mutate([
                asMutation({ field: "settings.timezone", type: "text" }, tz),
              ])
            }
            placeholder="Choose default timezone..."
          />
        </Field>
      </VStack>
    </Centered>
  );
}

export function SettingsWorkspaceProperties({}: Props) {
  const entity = useLazyWorkspace();

  return (
    <Centered gap={20} stack="vertical">
      <VStack gap={0}>
        <Heading bold>Workspace Properties</Heading>
        <Text subtle>
          These properties are shared across your whole workspace. Changes here
          will effect all teams.
        </Text>
      </VStack>

      <Divider direction="horizontal" />

      {entity && <SettingsSchema parent={entity} />}
    </Centered>
  );
}
