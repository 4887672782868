import { filter, values } from "lodash";
import { selector, selectorFamily } from "recoil";

import { Sprint, ID } from "@api";

import { omitEmpty } from "@utils/array";
import { maybeMap } from "@utils/maybe";

import { SprintStoreAtom, SprintAtom } from "./atoms";
import {
  ViewAtom,
  ViewStoreAtom,
  isTemplateViewId,
  toTemplateViewId,
} from "../views";

export const allSprints = selector({
  key: "allSprints",
  get: ({ get }) => {
    return omitEmpty(values(get(SprintStoreAtom).lookup));
  },
});

export const sprintsForTeam = selectorFamily({
  key: "sprintsForTeam",
  get:
    (teamId: ID) =>
    ({ get }) => {
      return filter(values(get(SprintStoreAtom).lookup), (p) =>
        p?.source.scope?.includes(teamId)
      ) as Sprint[];
    },
});

export const viewsForSprint = selectorFamily({
  key: "viewsForSprint",
  get:
    (id: ID) =>
    ({ get }) => {
      const sprint = get(SprintAtom(id));
      const savedIds = maybeMap(values(get(ViewStoreAtom).lookup), (v) =>
        v && !isTemplateViewId(v.id) && v.for?.id === id ? v?.id : undefined
      );
      const tempIds = [toTemplateViewId("sprint-task", { parent: id })];

      return maybeMap([...tempIds, ...savedIds], (id) =>
        id ? get(ViewAtom(id)) : undefined
      );
    },
});
