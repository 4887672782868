import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { filter, find, groupBy, map, sortBy } from "lodash";

import {
  Entity,
  EntityType,
  Integration,
  PropertyDef,
  PropertyType,
} from "@api";

import {
  newPropertyDef,
  useLazyProperties,
  useUpdatePropertyDef,
} from "@state/databases";
import { useLazyWorkspace } from "@state/workspace";
import { useInstalledEntities } from "@state/packages";
import { useEntityLabels } from "@state/settings";

import { DecimalOrdering } from "@utils/ordering";
import { Maybe, when } from "@utils/maybe";
import { isMatch, toScope } from "@utils/scope";
import { plural } from "@utils/string";
import { composel } from "@utils/fn";
import { useEffectOnce } from "@utils/hooks";
import { isAnyRelation, isAnyText } from "@utils/property-refs";

import { Text } from "@ui/text";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { MenuItem } from "@ui/menu-item";
import { ArrowRight, Check, PlusIcon } from "@ui/icon";
import { MenuGroup } from "@ui/menu-group";
import {
  DragRef,
  DragToRef,
  usePropertyDefDragDrop,
} from "@ui/property-drag-drop";
import { DropHighlight } from "@ui/drop-highlight";
import { HStack, VStack } from "@ui/flex";
import { Menu } from "@ui/menu";
import { CounterButton } from "@ui/counter-button";

import { PropertyEditDialog } from "./property-edit-dialog";

interface Props {
  parent: Entity;
  type?: EntityType;
  editing?: string;
  editable?: boolean;
}

interface MenuItemProps {
  def: PropertyDef<Entity, PropertyType>;
  order: number;
  disabled?: boolean;
  onReorder: (t: DragRef, to: DragToRef) => void;
  onClick?: () => void;
}

export const SchemaMenuItem = ({
  def,
  onClick,
  disabled,
  onReorder,
}: MenuItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { dropping } = usePropertyDefDragDrop({
    property: def,
    ref,
    onReorder,
  });
  return (
    <div ref={ref}>
      {dropping && <DropHighlight />}

      <MenuItem
        icon={<PropertyTypeIcon {...def} />}
        iconRight={disabled ? Check : ArrowRight}
        disabled={disabled}
        onClick={() => onClick?.()}
      >
        {def.label}
      </MenuItem>
    </div>
  );
};

export default function SettingsSchema({
  parent,
  type,
  editable = true,
  editing: _editing,
}: Props) {
  const workspace = useLazyWorkspace();
  const scope = useMemo(() => toScope(parent?.id), [workspace, parent]);
  const toTypeLabel = useEntityLabels(scope);
  const entities = useInstalledEntities(parent?.id);
  const [filtering, setFiltering] = useState<Maybe<EntityType>>();
  const source = useMemo(
    () =>
      filtering
        ? {
            source: Integration.Traction,
            type: filtering,
            scope: scope || "never",
          }
        : undefined,
    [scope, filtering]
  );
  const props = useLazyProperties(source);
  const update = useUpdatePropertyDef(source);

  const [editing, setEditing] =
    useState<Maybe<PropertyDef<Entity, PropertyType>>>();

  const { system, custom } = useMemo(
    () =>
      composel(
        (ps) =>
          filter(
            ps,
            (p) =>
              p.visibility !== "hidden" &&
              (!p.system
                ? // Custom property that is an exact scope match
                  p.scope === scope
                : // Or non-relation system properties that overlaps the scope
                  isMatch(scope, p.scope) &&
                  !p.readonly &&
                  (!isAnyRelation(p) ||
                    ["owner", "assigned"]?.includes(p.field)))
          ),
        (ps) => sortBy(ps, (p) => p.order),
        (ps) => groupBy(ps, (p) => (!p.system ? "custom" : "system"))
      )(props),
    [props]
  );

  const onReorder = useCallback(
    (t: DragRef, to: DragToRef) => {
      const lower = DecimalOrdering.drop(to.order || 1);
      const upper = DecimalOrdering.bump(to.order || 1);

      update(t.def, { order: to.position === "after" ? upper : lower });
      update(to.def, { order: to.position === "after" ? lower : upper });
    },
    [update]
  );

  const onAddNew = useCallback(
    () =>
      setEditing(
        newPropertyDef({
          scope,
          order: props.length + 1,
          entity: filtering ? [filtering] : [],
        })
      ),
    [scope, props?.length, filtering]
  );

  useEffectOnce(() => {
    if (_editing && !!props.length) {
      when(
        find(props, (p) => p.field === _editing),
        setEditing
      );
      return true;
    }
    return false;
  }, [_editing, props]);

  useEffect(() => {
    if (type && !!entities.includes(type)) {
      setFiltering(type);
    } else if (!filtering) {
      setFiltering("task");
    }
  }, [type]);

  return (
    <>
      <VStack gap={16} fit="container">
        <HStack>
          {map(entities, (type) => (
            <CounterButton
              key={type}
              size="small"
              state={type === filtering ? "selected" : "default"}
              onClick={() => setFiltering(type)}
            >
              {plural(toTypeLabel(type))}
            </CounterButton>
          ))}
        </HStack>
        <Menu>
          <MenuGroup>
            {map(custom, (p, i) => (
              <SchemaMenuItem
                key={p.field}
                def={p}
                disabled={!editable}
                order={i}
                onReorder={onReorder}
                onClick={() => setEditing(p)}
              />
            ))}

            {editable && (
              <MenuItem icon={PlusIcon} onClick={onAddNew}>
                <Text subtle>Add field</Text>
              </MenuItem>
            )}
          </MenuGroup>
          <MenuGroup label="System fields">
            {map(system, (p, i) => (
              <SchemaMenuItem
                key={p.field}
                def={p}
                disabled={!editable}
                order={i}
                onReorder={onReorder}
                onClick={() => setEditing(p)}
              />
            ))}
          </MenuGroup>
        </Menu>
      </VStack>

      {editing && source && (
        <PropertyEditDialog
          prop={editing}
          source={source}
          onClose={() => setEditing(undefined)}
        />
      )}
    </>
  );
}
