import { createContext, ReactNode, useContext, useMemo } from "react";
import { merge } from "lodash";

import { PropertyRef } from "@api";

import { Maybe } from "@utils/maybe";

export interface SuggestedPropsState {
  props: PropertyRef[];
}

export const SuggestedPropsContext =
  createContext<Maybe<SuggestedPropsState>>(undefined);

export const useSuggestedPropsContext = () => {
  return useContext(SuggestedPropsContext);
};

export const useSuggestedProps = () => {
  return useContext(SuggestedPropsContext)?.props;
};

export const WithSuggestedProps = ({
  props,
  children,
}: SuggestedPropsState & { children: ReactNode }) => {
  const existing = useSuggestedPropsContext();
  const context = useMemo(() => merge(existing, { props }), [props, existing]);
  return (
    <SuggestedPropsContext.Provider value={context}>
      {children}
    </SuggestedPropsContext.Provider>
  );
};
