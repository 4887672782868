import { atomFamily, selectorFamily } from "recoil";

import { ID, Company } from "@api";

import {
  getItem,
  indexedDBStorageForStore,
  mergeItem,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { isDefault } from "@utils/recoil";

export type CompanyStoreState = StoreState<Company>;

export const WorkspaceCompanyStoreAtom = atomFamily<CompanyStoreState, ID>({
  key: "WorkspaceCompanyStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "company")],
});

export const CompanyStoreAtom = WorkspaceWrappedAtom(
  "CompanyStoreAtom",
  WorkspaceCompanyStoreAtom
);

export const CompanyAtom = selectorFamily({
  key: "CompanyAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(CompanyStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(CompanyStoreAtom, mergeItem<Company>(newValue));
      }
    },
});
