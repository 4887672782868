import { useMemo } from "react";

import { Form, Ref } from "@api";

import { toTemplateViewId } from "@state/views";

import { usePushTo } from "@utils/navigation";

import { PaneHeader } from "@ui/pane-header";
import { Sheet } from "@ui/sheet-layout";
import { ViewMenuList } from "@ui/view-card-compact";
import { VStack } from "@ui/flex";

type Props = {
  form: Form;
  submitter?: Ref;
};

export const FormSubmissionsPane = ({ form, submitter }: Props) => {
  const type = form?.entity || "task";
  const pushTo = usePushTo();
  const viewId = useMemo(
    () =>
      toTemplateViewId("form-submissions", {
        parent: form.id,
        for: form.id,
        entity: type,
      }),
    [form?.id]
  );

  return (
    <Sheet size="secondary">
      <VStack>
        <PaneHeader title={"Recent Submissions"} />
        <ViewMenuList viewId={viewId} onOpen={pushTo} />
      </VStack>
    </Sheet>
  );
};
