import { useMemo } from "react";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { SystemPackages, useHasPackages } from "@state/packages";
import { useLazyEntity } from "@state/generic";

import { useSyncPathnameID } from "@utils/url";
import { when } from "@utils/maybe";
import { toChildLocation } from "@utils/scope";
import { usePushTo } from "@utils/navigation";
import { getRelationValue } from "@utils/property-refs";

import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { StackContainer } from "@ui/sheet-layout";
import { ResourcesPane } from "@ui/resources-pane";
import TaskPane from "@ui/engine/task/pane";
import { EntityMessagesPane } from "@ui/messages-pane";
import { PaneItem, PaneManager } from "@ui/pane-manager";
import { ClockHistory, Comments, EmojiIcon, Process, Search } from "@ui/icon";
import { ScheduleInstancesPane } from "@ui/engine/schedule";
import { SearchPane } from "@ui/search-pane";
import { RelatedMeetingsPane } from "@ui/engine/meeting";
import { RelatedProcessesPane } from "@ui/engine/process";

import AppPage from "./app-page";
import { TemplatePaneManager } from "@ui/template-pane-manager";

interface Props {
  taskId: string;
  showBack?: boolean;
}

export default function TaskPage({ taskId, showBack = true }: Props) {
  const task = useLazyEntity<"task">(taskId);
  const pushTo = usePushTo();
  const [page] = useRegisterPage(taskId, task);
  const fullLocation = useMemo(
    () => when(task, (t) => toChildLocation(t.location, t.id)),
    [task?.id, task?.location]
  );
  const installed = useHasPackages(taskId || "", [
    SystemPackages.Processes,
    SystemPackages.Meetings,
  ]);

  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(taskId, task?.id);

  if (!task) {
    return <></>;
  }

  return (
    <AppPage page={page} title={task?.title} loading={!task}>
      <StackContainer>
        {showBack && <SmartBreadcrumbSheet />}

        <TaskPane id={taskId} item={task} />

        {!task.template && (
          <PaneManager size="secondary">
            <PaneItem id="search" title="Search" icon={Search}>
              <SearchPane parentId={task.id} onOpen={pushTo} />
            </PaneItem>

            <PaneItem
              id="resources"
              title="Attachments"
              icon={<EmojiIcon emoji="🔗" />}
            >
              {fullLocation && <ResourcesPane location={fullLocation} />}
            </PaneItem>

            <PaneItem
              id="messages"
              title="Messages"
              icon={<EmojiIcon emoji="💬" />}
            >
              <EntityMessagesPane entityId={task.id} />
            </PaneItem>

            {installed[SystemPackages.Meetings] && (
              <PaneItem id="meetings" title="Meetings" icon={Comments}>
                <RelatedMeetingsPane entityId={task.id} />
              </PaneItem>
            )}

            {installed[SystemPackages.Processes] && (
              <PaneItem id="processes" title="Processes" icon={Process}>
                <RelatedProcessesPane entityId={task.id} />
              </PaneItem>
            )}

            {when(getRelationValue(task, "refs.repeat"), (ref) => (
              <PaneItem id="schedule" title="Schedule" icon={ClockHistory}>
                <ScheduleInstancesPane schedule={ref} instanceId={task.id} />
              </PaneItem>
            ))}
          </PaneManager>
        )}

        {task.template && <TemplatePaneManager id={taskId} entity={task} />}
      </StackContainer>
    </AppPage>
  );
}
