import { forwardRef } from "react";
import { cx } from "@utils/class-names";
import { Container, Props as ContainerProps } from "./container";
import styles from "./card.module.css";

type Props = {
  className?: string;
  interactable?: boolean;
  children: React.ReactNode;
} & ContainerProps;

export const Card = forwardRef<HTMLDivElement, Props>(
  ({ className, interactable = true, children, padding, ...rest }, ref) => (
    <Container
      ref={ref}
      className={cx(
        styles.card,
        padding !== "none" && styles.padded,
        interactable && styles.interactable,
        className
      )}
      padding="none"
      {...rest}
    >
      {children}
    </Container>
  )
);
