import { map } from "lodash";
import { ReactNode, useMemo } from "react";

import { Color, SelectOption, Status } from "@api";

import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { Maybe, when } from "@utils/maybe";
import { ComponentOrNode } from "@utils/react";
import {
  fromColor,
  sansDefault,
  toColor,
  toColorVar,
  toInverseColorVar,
} from "@utils/color";

import { HStack } from "@ui/flex";
import { StatusIcon } from "@ui/status-button";
import { Icon } from "./icon";

import styles from "./tag.module.css";
import { Size } from "./types";

type Props = {
  id?: string;
  name?: string;
  icon?: ComponentOrNode;
  color?: Color | "default";
  size?: Size;
  className?: string;
  style?: React.CSSProperties;
  onClick?: Fn<React.MouseEvent, void>;
  children?: ReactNode;
};

type StatusTagProps = Omit<Props, "id" | "name" | "icon" | "color"> & {
  status: Maybe<Status>;
  blocked?: boolean;
  showIcon?: boolean;
};

export const Tag = ({
  id,
  name,
  icon,
  color,
  onClick,
  size = "default",
  style: _style,
  className,
  children,
}: Props) => {
  const style = useMemo(
    () =>
      when(color && fromColor(sansDefault(color)), ([base, shade]) => ({
        ..._style,
        backgroundColor: toColorVar(base, shade),
        color: toInverseColorVar(base, shade),
      })) || _style,
    [color]
  );
  return (
    <span
      id={id}
      onClick={onClick}
      className={cx(styles.tag, styles[size], className)}
      style={style || {}}
    >
      {when(icon, (i) => (
        <Icon size="xsmall" className={cx(styles.icon)} icon={i} />
      ))}
      {name || children || "Loading..."}
    </span>
  );
};

export const StatusTag = ({
  status,
  className,
  showIcon = true,
  blocked,
  ...props
}: StatusTagProps) => {
  // Status only supports base colors, always uses light shade
  const color = useMemo(() => {
    const [base] = fromColor(sansDefault(status?.color || "default"));
    return toColor(base, 5);
  }, [status?.color]);

  return (
    <Tag
      id={status?.id}
      name={status?.name}
      color={color}
      icon={
        showIcon ? (
          <StatusIcon
            size="xsmall"
            styles={{
              color:
                // Planning dotted line needs inverse colouring
                status?.group === "planning"
                  ? toInverseColorVar(sansDefault(status?.color || "default"))
                  : toColorVar(sansDefault(status?.color || "default")),
            }}
            status={status}
            blocked={blocked}
          />
        ) : undefined
      }
      className={cx(styles.status, !showIcon && styles.noIcon, className)}
      {...props}
    />
  );
};

export const Tags = ({
  tags,
  size,
}: {
  tags: Array<SelectOption>;
  size?: Props["size"];
}) => (
  <HStack gap={3}>
    {map(tags, (t) => (
      <Tag key={t.id ?? t.name} {...t} size={size} />
    ))}
  </HStack>
);
