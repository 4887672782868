import { useRecoilValue } from "recoil";
import { map } from "lodash";

import { toLabel, useLazyEntities } from "@state/generic";
import { recentsListAsRefs } from "@state/recents";

import { useGoTo } from "@utils/navigation";
import { useShowMore } from "@utils/hooks";

import { CommandGroup, CommandItem } from "@ui/command-menu";
import { RelationIcon } from "@ui/relation-label";
import { Text } from "@ui/text";
import { EllipsisH } from "@ui/icon";

import { AppCommandsProps } from "./types";
import { useIsSearching } from "./utils";
import { useMemo } from "react";

export const RecentsCommands = ({
  showSize = 3,
  groupLabel,
}: AppCommandsProps & { showSize?: number; groupLabel?: false }) => {
  const goTo = useGoTo();

  const isSearching = useIsSearching();
  const recentRefs = useRecoilValue(recentsListAsRefs);
  const allRecents = useLazyEntities(recentRefs);
  const {
    visible: recents,
    hasMore,
    showMore,
  } = useShowMore(allRecents || [], showSize, isSearching);

  const Recents = useMemo(
    () =>
      map(recents, (f) => (
        <CommandItem
          key={f.id}
          value={`recently viewed ${toLabel(f)} ${f.id}`}
          icon={<RelationIcon relation={f} />}
          onClick={() => goTo(f)}
        >
          {toLabel(f)}
        </CommandItem>
      )),
    [recents]
  );

  if (!recents?.length) {
    return <></>;
  }

  return (
    <CommandGroup label={groupLabel !== false ? "Recently viewed" : undefined}>
      {Recents}

      {hasMore && (
        <CommandItem
          value="show more recently viewed"
          icon={EllipsisH}
          onSelectAction="none"
          onClick={() => showMore()}
        >
          <Text subtle>Show more recently viewed</Text>
        </CommandItem>
      )}
    </CommandGroup>
  );
};
