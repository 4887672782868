import { flatMap, map, some } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Entity, Meeting, Update } from "@api";

import { summariseMeeting, useStreamAIUseCase } from "@state/ai";
import { useLazyEntities, useQueueUpdates } from "@state/generic";

import { asMutation, asUpdate } from "@utils/property-mutations";
import { isEmpty, toMarkdown } from "@utils/rich-text";
import { maybeMap } from "@utils/maybe";

import { Button } from "@ui/button";
import { Dialog } from "@ui/dialog";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Text, TextSmall } from "@ui/text";
import { Field } from "@ui/input";
import { ReadonlyDocument, TextBox } from "@ui/rich-text";
import { EmptyMenuItem } from "@ui/menu-item";
import { ColoredSection } from "@ui/section";
import { CheckIcon } from "@ui/icon";
import { Tooltip } from "@ui/tooltip";
import { usePageId } from "@ui/app-page";

import { render, useEngine } from "..";

interface EndMeetingDialogProps {
  meeting: Meeting;
  onCancel: () => void;
  onComplete: (updates?: Update<Meeting>[]) => void;
}

export const EndMeetingDialog = ({
  meeting,
  onCancel,
  onComplete,
}: EndMeetingDialogProps) => {
  const pageId = usePageId();
  const [summary, setSummary] = useState<string>("");
  const mutate = useQueueUpdates(pageId);
  const agendas = useLazyEntities<"agenda">(meeting.refs?.agendas || []);
  const actionRefs = useMemo(
    () => flatMap(agendas, (a) => a.refs?.actions || []),
    [agendas]
  );
  const actions = useLazyEntities<"action">(actionRefs);
  const engine = useEngine("action");
  const hasNotes = useMemo(
    () => some(agendas, (a) => !isEmpty(a.notes)),
    [agendas]
  );

  const { run, loading } = useStreamAIUseCase(summariseMeeting, (s) =>
    setSummary(s)
  );

  const handleSave = useCallback(() => {
    mutate(
      asUpdate(meeting, [
        asMutation(
          { field: "summary", type: "rich_text" },
          { markdown: summary }
        ),
        asMutation({ field: "status", type: "status" }, { id: "FNS" }),
      ])
    );
    onComplete([]);
  }, [summary, actions]);

  useEffect(() => {
    run({ meeting });
  }, []);

  return (
    <Dialog
      title={"Meeting Recap"}
      description="Summarise outcomes and actions for the meeting ."
      onDismiss={onCancel}
      // Prevent summary from focusing since we're using ai to generate it
      onAutoFocus={(e) => e.preventDefault()}
      actions={
        <SpaceBetween>
          <Tooltip text="Coming soon 😜..." delay={0}>
            <Button size="small" subtle icon={<CheckIcon checked={false} />}>
              Send summary to slack
            </Button>
          </Tooltip>

          <HStack>
            <Button variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSave}>
              End Meeting
            </Button>
          </HStack>
        </SpaceBetween>
      }
    >
      <VStack gap={10}>
        <Field label="Summary">
          <TextBox
            key={"sumary"}
            text={{ markdown: summary }}
            onChanged={(rt) => setSummary(toMarkdown(rt))}
            submitOnEnter={false}
            placeholder="Generating..."
          />
        </Field>

        <Field label="Notes">
          <ColoredSection inset={false}>
            <VStack>
              {maybeMap(
                agendas,
                (agenda) =>
                  !isEmpty(agenda.notes) && (
                    <VStack gap={0}>
                      <TextSmall subtle>{agenda.title}</TextSmall>
                      <ReadonlyDocument
                        textSize="small"
                        content={agenda.notes}
                      />
                    </VStack>
                  )
              )}
              {!hasNotes && (
                <Text subtle>No notes taken in this meeting...</Text>
              )}
            </VStack>
          </ColoredSection>
        </Field>

        <Field label="Actions">
          {map(actions, (action) =>
            render(engine.asListItem, {
              item: action,
              key: action.id,
              onChange: (cs) => mutate(asUpdate(action, cs) as Update<Entity>),
            })
          )}
          {!actions?.length && (
            <EmptyMenuItem text="No actions from this meeting..." />
          )}
        </Field>
      </VStack>
    </Dialog>
  );
};
