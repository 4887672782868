import {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { find, last, lowerCase, map, some } from "lodash";

import { ID, View } from "@api";

import {
  compareViewIds,
  isFiltering,
  isGrouping,
  toLayoutName,
  toRecommendedProps,
  toTemplateIdBase,
  useReorderView,
  useToViewTitle,
  useTempView,
  ViewStoreAtom,
  useDefaultsForView,
} from "@state/views";
import { setQuickSearch, ViewQuickFilterAtom } from "@state/quick-filters";
import {
  AppCommandsAtom,
  editPropertyInCmdK,
  setCommandsOpen,
} from "@state/app";
import { useEntitySource, useLocalChanges } from "@state/generic";
import { useLazyProperties, useLazyPropertyDef } from "@state/databases";
import { isCreateOrUpdate } from "@state/store";
import { useEntityLabels } from "@state/settings";

import { toCount } from "@utils/filtering";
import { Fn } from "@utils/fn";
import { length } from "@utils/array";
import { Maybe, when } from "@utils/maybe";
import { plural } from "@utils/string";
import { usePageSelection } from "@utils/selectable";
import { cx } from "@utils/class-names";
import { toFieldName } from "@utils/property-refs";
import { useResetableState, useShowMore } from "@utils/hooks";
import { useShortcut } from "@utils/event";
import { ifDo } from "@utils/logic";
import { isPersonId, isWorkspaceId } from "@utils/id";
import { usePushTo } from "@utils/navigation";

import { HStack, SpaceBetween } from "@ui/flex";
import { Button } from "@ui/button";
import { TextLarge, Text } from "@ui/text";
import {
  BoardGroup,
  Cog,
  EllipsisH,
  Eye,
  EyeSlash,
  FilterAlt,
  Home,
  Icon,
  PlusIcon,
  Search,
  Slash,
  SlidersH,
  SortAsc,
  SortDesc,
  ViewIcon,
} from "@ui/icon";
import { QuickFilterButton } from "@ui/quick-filter";
import { TabBar, TabBarItem } from "@ui/tab-bar";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { OnReorder, useItemDragDrop, useItemDrop } from "@ui/entity-drag-drop";
import { SearchInput } from "@ui/search-input";
import { useSuggestedProps } from "@ui/suggested-props";
import { AddWorkActionMenu } from "./add-work-dialog";

import { EntityContextMenu } from "./entity-context-menu";
import {
  FilterOptionsMenu,
  LayoutOptionsMenu,
  ShowPropsOptionsMenu,
  SortByOptionsMenu,
  ViewOptionsDropdown,
} from "./view-options-menu";
import { RelationIcon, RelationLabel } from "./relation-label";

import styles from "./view-header.module.css";

interface Props {
  view: View;
  views?: View[];
  onOpen: Fn<View, void>;
  showOptions?: boolean;
  showTitle?: boolean;
  showViewsBar?: boolean;
  showOtherViews?: boolean;
  onShowOptions?: Fn<boolean, void>;
}

export const ViewHeader = forwardRef(
  (
    {
      view,
      views,
      onOpen,
      showOptions,
      showTitle = true,
      showViewsBar: _showViewsBar = true,
      showOtherViews = true,
      onShowOptions,
    }: Props,
    ref: Ref<HTMLDivElement>
  ) => {
    const pushTo = usePushTo();
    const viewId = view.id;
    const { changes } = useLocalChanges(viewId, ViewStoreAtom);
    const quickFilter = useRecoilValue(ViewQuickFilterAtom(viewId));
    const [selection] = usePageSelection();
    const toEntityLabel = useEntityLabels(view?.source.scope);
    const viewDefaults = useDefaultsForView(view.id);
    const nestedSource = useEntitySource(view.entity || "task", view.source);
    const [showViewsBar, setShowViewsBar] = useResetableState(
      () => length(views) > 1 || _showViewsBar,
      [_showViewsBar, views?.length]
    );
    const defaults = useMemo(
      () => ({
        ...viewDefaults,
        location: view.source.scope,
        source: nestedSource,
      }),
      [viewDefaults, nestedSource]
    );

    const [openOptions, setOpenOptions] = useState<string>();
    const { setup } = useTempView(
      useMemo(
        () =>
          view && {
            for: view.for,
            location: view.location,
            source: view.source,
            entity: view.entity,
            template: view.template ? "nested" : undefined,
          },
        [view]
      )
    );

    const onReorder = useReorderView(views);
    const showGrouping = useMemo(
      () =>
        isGrouping(view) ||
        some(
          changes,
          (u) =>
            isCreateOrUpdate(u) &&
            some(u.changes, (c) => c.field === "grouping")
        ),
      [view, changes]
    );
    const isSorting = useMemo(
      () =>
        !!view?.sort?.length ||
        some(
          changes,
          (u) =>
            isCreateOrUpdate(u) && some(u.changes, (c) => c.field === "sort")
        ),
      [view, changes]
    );

    const addButtonRef = useRef<HTMLDivElement>(null);
    const [{ dropping }] = useItemDrop({
      type: "view",
      ref: addButtonRef,
      item: last(views),
      forcePosition: "after",
    });

    const handleCreate = useCallback(() => {
      const view = setup();
      view && onOpen(view);
    }, [setup]);

    if (!view) {
      return <></>;
    }

    const title = showTitle && (
      <TextLarge bold className={styles.name}>
        Board {isFiltering(view) ? "showing filtered" : "showing all"}{" "}
        {lowerCase(plural(toEntityLabel(view.entity)))} for{" "}
        {view.for?.id &&
          !isWorkspaceId(view.for?.id) &&
          !isPersonId(view.for?.id) && (
            <>
              <Icon
                size="medium"
                icon={
                  <RelationIcon
                    className={styles.inherit}
                    relation={view.for}
                  />
                }
              />
              <RelationLabel
                icon={false}
                className={styles.inherit}
                relation={view.for}
              />
            </>
          )}
        {view.for?.id && !!isPersonId(view.for?.id) && (
          <>
            <Icon size="medium" icon={EyeSlash} />
            Private
          </>
        )}
        {!!when(view?.for?.id, isWorkspaceId) && "all teams"}
      </TextLarge>
    );

    return (
      <div ref={ref}>
        {showTitle && showOtherViews && (
          <div className={styles.nameContainer}>{title}</div>
        )}

        {showViewsBar && (
          <SpaceBetween className={styles.header}>
            <div className={styles.tabsContainer}>
              {showTitle && !showOtherViews && title}
              {showOtherViews && (
                <TabBar
                  active={toTemplateIdBase(view.id)}
                  showMax={4}
                  onActiveChanged={(id) =>
                    when(
                      find(views, (v) => v.id === id),
                      onOpen
                    )
                  }
                >
                  {map(views, (o, i) => (
                    <ViewTabBarItem
                      key={o.id}
                      index={i}
                      view={o}
                      active={compareViewIds(view.id, o.id)}
                      onReorder={onReorder}
                    />
                  ))}
                  <Button
                    subtle
                    icon={PlusIcon}
                    iconSize="small"
                    ref={addButtonRef}
                    size="small"
                    onClick={handleCreate}
                    className={cx(dropping && styles.dropBefore)}
                  >
                    <Text subtle>New board</Text>
                  </Button>
                </TabBar>
              )}
            </div>

            <HStack gap={4}>
              {isSorting && (
                <ViewOptionsDropdown
                  open={openOptions === "sort"}
                  onOpen={(o) => setOpenOptions(o ? "sort" : undefined)}
                  viewId={view.id}
                  icon={
                    view?.sort?.[0]?.direction === "desc" ? SortDesc : SortAsc
                  }
                  text="Sort"
                  highlighted={true}
                  summary={`Sorting by ${view.sort?.length} fields`}
                >
                  <SortByOptionsMenu viewId={view.id} />
                </ViewOptionsDropdown>
              )}

              <ViewOptionsDropdown
                open={openOptions === "filter"}
                onOpen={(o) => setOpenOptions(o ? "filter" : undefined)}
                viewId={view.id}
                icon={FilterAlt}
                text="Filter"
                highlighted={isFiltering(view)}
                summary={
                  when(
                    (view.filter && toCount(view.filter)) || undefined,
                    (c) => `Filtering by ${c} fields`
                  ) ??
                  ifDo(view.settings?.hideNested, () => "Filtering") ??
                  "Add Filter"
                }
              >
                <FilterOptionsMenu viewId={view.id} />
              </ViewOptionsDropdown>

              <ViewOptionsDropdown
                open={openOptions === "show"}
                onOpen={(o) => setOpenOptions(o ? "show" : undefined)}
                viewId={view.id}
                icon={Eye}
                highlighted={!!view?.showProps?.length}
                text={
                  when(
                    view?.showProps?.length || undefined,
                    (l) => `${l} ${plural("field", l)}`
                  ) ?? "Show"
                }
                summary={
                  when(
                    view?.showProps?.length || undefined,
                    (l) => `Showing ${l} ${plural("field", l)}`
                  ) ?? "Fields to show"
                }
              >
                <ShowPropsOptionsMenu viewId={view.id} />
              </ViewOptionsDropdown>

              {showGrouping && (
                <ViewOptionsDropdown
                  open={openOptions === "sections"}
                  onOpen={(o) => setOpenOptions(o ? "sections" : undefined)}
                  viewId={view.id}
                  icon={BoardGroup}
                  text="Sections"
                  highlighted={true}
                  // summary={`${toLayoutName(view.layout)} layout`}
                >
                  <LayoutOptionsMenu viewId={view.id} />
                </ViewOptionsDropdown>
              )}

              <ViewOptionsDropdown
                open={openOptions === "layout"}
                onOpen={(o) => setOpenOptions(o ? "layout" : undefined)}
                viewId={view.id}
                icon={<ViewIcon layout={view.layout} />}
                text="Layout"
                highlighted={isGrouping(view)}
                summary={`${toLayoutName(view.layout)} layout`}
              >
                <LayoutOptionsMenu viewId={view.id} />
              </ViewOptionsDropdown>

              {!changes?.length ? (
                <Button
                  subtle
                  size="small"
                  icon={SlidersH}
                  onClick={() => onShowOptions?.(!showOptions)}
                >
                  <Text subtle>All options</Text>
                </Button>
              ) : (
                <Button
                  size="small"
                  variant={"primary-alt"}
                  icon={SlidersH}
                  onClick={() => onShowOptions?.(!showOptions)}
                >
                  All options
                </Button>
              )}
            </HStack>
          </SpaceBetween>
        )}

        <SpaceBetween
          className={cx(
            styles.panel,
            !!showViewsBar && styles.bordered,
            !showViewsBar && styles.whitePanel
          )}
        >
          <HStack gap={0}>
            <ViewQuickSearch viewId={view.id} />
            {!!selection.selected.size && <SelectionActions view={view} />}
            {!selection?.selected.size &&
              map(quickFilter.available, (f) => (
                <QuickFilterButton key={f.id} viewId={view.id} filter={f} />
              ))}
          </HStack>

          <HStack gap={4}>
            <AddWorkActionMenu
              defaults={defaults}
              variant="secondary"
              subtle={false}
              size="small"
              onSaved={pushTo}
            />
            {!showViewsBar && (
              <Button
                icon={Cog}
                subtle
                size="small"
                onClick={() => setShowViewsBar(true)}
              />
            )}
          </HStack>
        </SpaceBetween>
      </div>
    );
  }
);

const SelectionActions = ({ view }: { view: View }) => {
  const [selection] = usePageSelection();
  const setAppCommands = useSetRecoilState(AppCommandsAtom);
  const itemSource = useEntitySource(view.entity, view.source);
  const props = useLazyProperties(itemSource);
  const suggested = useSuggestedProps();
  const locationProp = useLazyPropertyDef(itemSource, {
    field: "location",
    type: "text",
  });
  const reccProps = useMemo(
    () => toRecommendedProps(view, suggested, props),
    [view, suggested]
  );
  const { visible } = useShowMore(reccProps, 5);

  return (
    <HStack data-selectable-ignore-clicks="true" gap={4}>
      <Text className={styles.selectionText} bold>
        Selected ({selection.selected.size})
      </Text>

      <Button
        size="small"
        subtle
        icon={Slash}
        onClick={() => setAppCommands(setCommandsOpen(true))}
      >
        Modify
      </Button>

      <Button
        size="small"
        subtle
        icon={Home}
        onClick={() => setAppCommands(editPropertyInCmdK(locationProp))}
      >
        Move to...
      </Button>

      {map(visible, (p) => (
        <Button
          key={p.field}
          size="small"
          subtle
          icon={<PropertyTypeIcon {...p} />}
          onClick={() => setAppCommands(editPropertyInCmdK(p))}
        >
          {toFieldName(p)}
        </Button>
      ))}

      <Button
        size="small"
        subtle
        icon={EllipsisH}
        onClick={() => setAppCommands(setCommandsOpen(true))}
      />
    </HStack>
  );
};

interface ItemProps {
  view: View;
  active: boolean;
  index: number;
  onReorder: OnReorder<View>;
}

function ViewTabBarItem({ onReorder, view, active, index }: ItemProps) {
  const ref = useRef<HTMLDivElement>(null);
  const toTitle = useToViewTitle(view);
  const item = useMemo(
    () => ({
      id: view.id,
      title: toTitle(view) || view.name,
      icon: <ViewIcon layout={view.layout} />,
    }),
    [view]
  );
  const { dropping } = useItemDragDrop({
    item: view,
    onReorder,
    ref,
    forcePosition: "before",
  });

  return (
    <EntityContextMenu entity={view}>
      <TabBarItem
        ref={ref}
        item={item}
        inset={index === 0}
        active={active}
        className={cx(
          dropping === "before" && styles.dropBefore,
          dropping === "after" && styles.dropAfter
        )}
      />
    </EntityContextMenu>
  );
}

export const ViewQuickSearch = ({
  viewId,
  autoFocus,
}: {
  viewId: ID;
  autoFocus?: boolean;
}) => {
  const [quickFilter, setQuickFilter] = useRecoilState(
    ViewQuickFilterAtom(viewId)
  );
  const setSearch = useCallback(
    (s: Maybe<string>) => setQuickFilter(setQuickSearch(s)),
    [setQuickFilter]
  );
  const [searching, setSearching] = useState(autoFocus ?? false);

  useShortcut(
    { command: true, key: "KeyF" },
    [() => !searching, () => setSearching(true)],
    [searching]
  );

  useEffect(() => {
    setSearch(undefined);
  }, []);

  if (!searching) {
    return (
      <Button
        subtle
        size="small"
        icon={Search}
        onClick={() => setSearching(true)}
      />
    );
  }

  return (
    <SearchInput
      autoFocus={true}
      search={quickFilter.search}
      setSearch={setSearch}
      onBlur={() => !quickFilter.search?.length && setSearching(false)}
    />
  );
};
