import { useCallback, useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { minutesAgo, useTick } from "@utils/time";
import { usePointDate } from "@utils/date-fp";

import { allJobs, JobStoreAtom } from "./atoms";
import { getJobQueueLoader } from "./queries";
import { setJobs } from "./actions";

export function useCheckForNewJobs() {
  const [store, setStore] = useRecoilState(JobStoreAtom);
  const check = useCallback(async () => {
    await getJobQueueLoader((jobs) => setStore(setJobs(jobs)));
  }, []);

  return useMemo(() => ({ check, lastChecked: store.lastChecked }), []);
}

export function useJobQueue() {
  const jobs = useRecoilValue(allJobs);
  const { check, lastChecked } = useCheckForNewJobs();

  // Check every hour minutes if we have new jobs
  useEffect(() => {
    if (!lastChecked || usePointDate(lastChecked, (d) => minutesAgo(d) > 10)) {
      check();
    }
  }, [useTick("1 minute")]);

  return jobs;
}
