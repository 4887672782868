import { useRecoilState } from "recoil";

import { GlobalFetchOptionsAtom, showArchived } from "@state/fetch-results";

import { CommandGroup, CommandItem } from "@ui/command-menu";
import { Archive, ArchiveOpen } from "@ui/icon";

import { AppCommandsProps } from "./types";

export const ArchiveCommands = ({}: AppCommandsProps) => {
  const [globalFetch, setGlobalFetch] = useRecoilState(GlobalFetchOptionsAtom);

  return (
    <CommandGroup label="Archive">
      {!globalFetch.archived && (
        <CommandItem
          value="open the archives"
          icon={Archive}
          onClick={() => setGlobalFetch(showArchived(true))}
        >
          Open archives
        </CommandItem>
      )}
      {!!globalFetch.archived && (
        <CommandItem
          value="Open archives"
          icon={ArchiveOpen}
          onClick={() => setGlobalFetch(showArchived(false))}
        >
          Close archives
        </CommandItem>
      )}
    </CommandGroup>
  );
};
