import { ReactNode, useMemo } from "react";
import { isString } from "lodash";

import { Fn } from "@utils/fn";

import { Button } from "@ui/button";
import { SectionLabel, Props as LabelProps } from "@ui/label";
import { Divider } from "@ui/divider";
import { FillSpace, SpaceBetween, VStack } from "./flex";
import { ArrowRight, Icon } from "@ui/icon";

import styles from "./clickable-section.module.css";

export interface Props {
  title: string | ReactNode;
  labelSize?: LabelProps["size"];
  count?: number;
  actions?: ReactNode;
  children: ReactNode;
  className?: string;
  onClick?: Fn<void, void>;
}

export const ClickableSection = ({
  title,
  count,
  children,
  onClick,
  labelSize,
  actions,
  className,
}: Props) => {
  const label = useMemo(() => {
    if (isString(title) && !!onClick) {
      return (
        <Button
          className={styles.button}
          variant="secondary"
          size="tiny"
          inset={true}
          iconRight={<Icon className={styles.iconRight} icon={ArrowRight} />}
          subtle
        >
          <SectionLabel
            size={labelSize}
            text={!count ? title : `${title} (${count})`}
          />
        </Button>
      );
    }

    if (isString(title)) {
      return (
        <SectionLabel
          size={labelSize}
          text={!count ? title : `${title} (${count})`}
        />
      );
    }

    return title;
  }, [title, count]);

  return (
    <VStack fit="container" gap={0} className={className}>
      {(title || label) && (
        <SpaceBetween gap={8} className={styles.header}>
          <span onClick={() => onClick?.()}>{label}</span>
          <FillSpace direction="horizontal">
            <Divider direction="horizontal" />
          </FillSpace>
          {actions}
        </SpaceBetween>
      )}
      {children}
    </VStack>
  );
};
