import { atomFamily, selectorFamily } from "recoil";
import { ID, Task } from "@api";

import {
  getItem,
  indexedDBStorageForStore,
  setItem,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace/atoms";

import { Maybe } from "@utils/maybe";
import { isDefault } from "@utils/recoil";

export type { Task };

export type TaskStoreState = StoreState<Task>;

export const WorkspaceTaskStoreAtom = atomFamily<TaskStoreState, ID>({
  key: "WorkspaceTaskStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "task")],
});

export const TaskStoreAtom = WorkspaceWrappedAtom(
  "TaskStoreAtom",
  WorkspaceTaskStoreAtom
);

export const TaskAtom = selectorFamily<Maybe<Task>, ID>({
  key: "Task",
  get:
    (id) =>
    ({ get }) =>
      getItem(get(TaskStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(TaskStoreAtom, setItem(newValue));
      }
    },
});
