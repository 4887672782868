import { atomFamily, selectorFamily } from "recoil";
import { ID, Person } from "@api";

import { isDefault } from "@utils/recoil";

import {
  getItem,
  indexedDBStorageForStore,
  setItemPure,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type PersonStoreState = StoreState<Person>;

export const WorkspacePersonStoreAtom = atomFamily<PersonStoreState, ID>({
  key: "WorkspacePersonStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "person")],
});

export const PersonStoreAtom = WorkspaceWrappedAtom(
  "PersonStoreAtom",
  WorkspacePersonStoreAtom
);

export const PersonAtom = selectorFamily({
  key: "PersonAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(PersonStoreAtom), id) || undefined,
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(PersonStoreAtom, setItemPure(newValue));
      }
    },
});
