import { Contact } from "@api";

import { useUpdateEntity } from "@state/generic";

import { safeAs, when } from "@utils/maybe";
import { asMutation } from "@utils/property-mutations";

import { usePageId } from "@ui/app-page";
import { Centered, Container } from "@ui/container";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Sheet } from "@ui/sheet-layout";
import { WorkflowActions } from "@ui/workflow-action-button";
import { EditableHeading } from "@ui/editable-heading";
import { TemplateBanner } from "@ui/template-banner";
import { EntityHeaderBar } from "@ui/entity-header-bar";
import { EntityProperties } from "@ui/entity-properties";
import { Icon, PersonIcon } from "@ui/icon";
import { EditableText } from "@ui/editable-text";
import { LinkStack } from "@ui/link-stack";
import { LinkDialogButton } from "@ui/link-dialog";
import { ContextItem, ContextMenu } from "@ui/context-menu";

import { PaneOpts } from "../types";

import styles from "./styles.module.css";

export const ContactPane = ({ id, item }: PaneOpts<Contact>) => {
  if (!item) {
    return <></>;
  }

  return (
    <Sheet size="primary">
      {!!item.template && <TemplateBanner />}

      <VStack fit="container" gap={20}>
        <ContactHeader contact={item} />

        <Centered stack="vertical" gap={30} padding="horizontal">
          {/* <LabelledPropertyValueList
            entity={item}
            onChange={mutate}
            stack="horizontal"
          /> */}

          <EntityProperties entityId={id} />
        </Centered>
      </VStack>
    </Sheet>
  );
};

interface HeaderProps {
  contact: Contact;
}

const ContactHeader = ({ contact }: HeaderProps) => {
  const pageId = usePageId();
  const mutate = useUpdateEntity(contact.id, pageId);

  if (!contact) {
    return <h1>Not found.</h1>;
  }

  return (
    <VStack gap={40} fit="container">
      <EntityHeaderBar entity={contact} padding="both" />

      <Centered padding="horizontal" fit="container">
        <Container
          padding="none"
          gap={20}
          fit="container"
          inset="left"
          stack="vertical"
        >
          <SpaceBetween gap={10} fit="container">
            <LinkDialogButton
              title="Add a profile photo"
              onLink={(l) =>
                l?.url &&
                mutate(asMutation({ field: "avatar", type: "text" }, l?.url))
              }
            >
              <ContextMenu
                actions={
                  <>
                    <ContextItem
                      text="Clear"
                      onClick={() =>
                        mutate(
                          asMutation({ field: "avatar", type: "text" }, "")
                        )
                      }
                    />
                  </>
                }
              >
                <Icon
                  size="xlarge"
                  className={styles.avatar}
                  icon={
                    <PersonIcon size="xlarge" person={contact} shape="circle" />
                  }
                />
              </ContextMenu>
            </LinkDialogButton>

            <VStack gap={0} fit="container">
              <HStack>
                <EditableHeading
                  key={contact.id}
                  text={contact.name || ""}
                  size="h3"
                  placeholder="Contact name"
                  onChange={(text) => {
                    when(text, (i) =>
                      mutate(asMutation({ field: "name", type: "text" }, i))
                    );
                  }}
                />
                <LinkStack
                  links={contact.websites}
                  onChange={(ls) =>
                    mutate(asMutation({ field: "websites", type: "links" }, ls))
                  }
                />
              </HStack>

              <EditableText
                key={contact.id}
                text={safeAs<string>(contact.custom?.bio) || ""}
                placeholder="Add bio..."
                allowNewLines={true}
                className={styles.subline}
                subtle
                onChange={(text) => {
                  when(text, (i) =>
                    mutate(asMutation({ field: "custom.bio", type: "text" }, i))
                  );
                }}
              />
            </VStack>
          </SpaceBetween>

          {!contact.template && (
            <HStack fit="container" gap={4}>
              <WorkflowActions size="small" entity={contact} />
            </HStack>
          )}
        </Container>
      </Centered>
    </VStack>
  );
};
