import { keys, omit, reduce } from "lodash";

import { Entity, PropertyDef, PropertyType } from "@api";

import { composel } from "@utils/fn";
import { asTimestamp } from "@utils/date";
import { Maybe } from "@utils/maybe";

import { PropertyDefStoreState } from "./atoms";
import { toPropertyDefAliases } from "./utils";

const getPropertyDef = <T extends Entity>(
  state: PropertyDefStoreState,
  def: PropertyDef<T>
): Maybe<PropertyDef<T>> => {
  const { id } = toPropertyDefAliases(def);
  return state?.lookup[id];
};

export const setPropertyDefinition =
  <T extends Entity>(propDef: PropertyDef<T>) =>
  (state: PropertyDefStoreState): PropertyDefStoreState => {
    const current = getPropertyDef(state, propDef);

    // Do nothing if the current value is newer
    if (
      current &&
      asTimestamp(current?.updatedAt) >= asTimestamp(propDef.updatedAt)
    ) {
      // debug("Skipping storing property def as current is newer.", {
      //   current,
      //   propDef,
      // });
      return state;
    }

    const { aliases, id } = toPropertyDefAliases(propDef);

    return {
      ...state,
      aliases: {
        ...state.aliases,
        ...aliases,
      },
      lookup: {
        ...state?.lookup,
        [id]: propDef as PropertyDef<Entity>,
      },
    };
  };

export const replacePropertyDefinition =
  <T extends Entity>(old: PropertyDef<T>, neww: PropertyDef<T>) =>
  (state: PropertyDefStoreState): PropertyDefStoreState =>
    composel(removePropertyDefinition(old), setPropertyDefinition(neww))(state);

export const setPropertyDefinitions =
  <T extends Entity>(propDefs: PropertyDef<T>[]) =>
  (state: PropertyDefStoreState): PropertyDefStoreState =>
    reduce(propDefs, (s, def) => setPropertyDefinition(def)(s), state);

export const addPropertyDefinition = setPropertyDefinition;

export const removePropertyDefinition =
  <T extends Entity>(def: PropertyDef<T>) =>
  (state: PropertyDefStoreState): PropertyDefStoreState => {
    const { aliases, id } = toPropertyDefAliases(def);
    return {
      ...state,
      aliases: omit(state.aliases, ...keys(aliases)),
      lookup: omit(state.lookup, id),
    };
  };

export const addPropertyValues =
  <T extends Entity, P extends PropertyType>(
    def: PropertyDef<T>,
    values: PropertyDef<T, P>["values"]
  ) =>
  (state: PropertyDefStoreState): PropertyDefStoreState => {
    const existing = getPropertyDef(state, def);

    if (!existing) {
      return setPropertyDefinition(def)(state);
    }

    return setPropertyDefinition({ ...existing, values })(state);
  };
