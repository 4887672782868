import { find, map, reduce } from "lodash";
import { PropertyValueRef, Team } from "@api";
import { useMemo } from "react";

import { useLazyProperties } from "@state/databases";
import { toLabel } from "@state/teams";

import { withHandle } from "@utils/event";
import { cx } from "@utils/class-names";
import { toMutation } from "@utils/property-mutations";
import { toPropertyValueRef } from "@utils/property-refs";

import { EntityContextMenu } from "@ui/entity-context-menu";
import { HStack, SpaceBetween } from "@ui/flex";
import { Icon, TeamIcon } from "@ui/icon";
import { Label } from "@ui/label";
import { ListCard } from "@ui/list-card";
import { MenuItem } from "@ui/menu-item";
import { EditableText } from "@ui/editable-text";
import { ListItem } from "@ui/list-item";
import { PropertyValue } from "@ui/property-value";
import { PeopleStack } from "@ui/people-stack";
import { TextXLarge, Text } from "@ui/text";
import { Container } from "@ui/container";
import { RelationIcon } from "@ui/relation-label";

import { ListItemOpts, UIEngine } from "../types";
import { TeamCreateDialog } from "./create-dialog";

import styles from "./styles.module.css";

function TeamListItem({
  item,
  className,
  onOpen,
  showProps,
  variant,
  onChange,
  ...props
}: ListItemOpts<Team> & { item: Team }) {
  const allProps = useLazyProperties(item.source, false);
  const visible = useMemo(
    () =>
      reduce(
        showProps || [
          { field: "status", type: "status" },
          { field: "start", type: "date" },
          { field: "end", type: "date" },
        ],
        (curr, p) => {
          if (["title", "code"].includes(p.field)) {
            return curr;
          }
          const def = find(allProps, (d) => p.field === d.field);
          if (!def) {
            return curr;
          }
          const val = toPropertyValueRef(item, def);

          return [...curr, val];
        },
        [] as PropertyValueRef<Team>[]
      ),
    [item, showProps, allProps]
  );

  return (
    <ListItem
      key={item.id}
      className={cx(styles.listItem, className)}
      onClick={(e) => onOpen?.(item)}
      selectable={false}
    >
      <SpaceBetween className={styles.upper}>
        <HStack className={styles.middle} gap={4}>
          <Icon icon={<TeamIcon team={item} />} />
          <EditableText
            key={item.id}
            text={item.name || ""}
            disabled={true}
            placeholder="Untitled team"
            blurOnEnter={true}
            onChange={(v) =>
              onChange?.(toMutation(item, { field: "title", type: "text" }, v))
            }
          />
        </HStack>

        <HStack className={styles.rowDetails} justify="flex-end" gap={2}>
          {map(visible, (val) => (
            <PropertyValue
              key={val.field}
              valueRef={val}
              source={item.source}
              variant={variant || "unlabelled"}
              onChange={(c) =>
                onChange?.({
                  field: val.field,
                  type: val.type,
                  value: c,
                  prev: val.value,
                })
              }
            />
          ))}
        </HStack>
      </SpaceBetween>
    </ListItem>
  );
}

export const TeamEngine: UIEngine<Team> = {
  asMenuItem: function ({ item, onOpen, ...rest }) {
    return (
      <EntityContextMenu entity={item}>
        <MenuItem
          {...rest}
          onClick={withHandle(() => onOpen?.(item))}
          wrapLabel={false}
        >
          <SpaceBetween gap={6}>
            <Label icon={<TeamIcon team={item} />} text={toLabel(item)} />
            {item.owner && (
              <Icon icon={<RelationIcon relation={item.owner} />} />
            )}
          </SpaceBetween>
        </MenuItem>
      </EntityContextMenu>
    );
  },
  asListCard: ({ item, onOpen, ...rest }) => (
    <EntityContextMenu entity={item}>
      <ListCard
        padding="none"
        selectable={false}
        onClick={() => onOpen?.(item)}
      >
        <Container fit="container" stack="vertical" gap={10}>
          <SpaceBetween>
            <HStack>
              <Icon size="medium" icon={<TeamIcon team={item} />} />

              <TextXLarge bold>{item.name || "Team"}</TextXLarge>
            </HStack>

            <PeopleStack people={item.people} />
          </SpaceBetween>
        </Container>
      </ListCard>
    </EntityContextMenu>
  ),
  asListItem: TeamListItem,
  asCreateDialog: TeamCreateDialog,
};

export * from "./create-dialog";
