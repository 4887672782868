import { useRecoilValue } from "recoil";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ID, PropertyType } from "@api";

import { PresenceStoreAtom, useRealtimePresence } from "@state/realtime";
import { useActiveWorkspaceId, useAuthedUserId } from "@state/workspace";

import { maybeValues } from "@utils/object";
import { toColorVar, toInverseColorVar } from "@utils/color";
import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";

import { Tag } from "@ui/tag";
import { Text } from "@ui/text";
import { RelationIcon } from "@ui/relation-label";
import { Icon } from "@ui/icon";
import { DocumentEditor, DocumentEditorProps } from "@ui/rich-text";

import styles from "./real-time-field.module.css";
import { useLazyEntity, useNestedSource } from "@state/generic";

type RealTimeFieldProps = {
  entity: ID;
  field: string;
  type: PropertyType;
  onLocked?: Fn<boolean, void>;
  children: React.ReactNode;
};

export const RealTimeField = ({
  entity,
  field,
  type,
  onLocked,
  children,
}: RealTimeFieldProps) => {
  const wID = useActiveWorkspaceId();
  const me = useAuthedUserId();
  const { update } = useRealtimePresence(wID);
  const store = useRecoilValue(PresenceStoreAtom);
  const isLockingRef = useRef<boolean>(false);

  const handleFocus = useCallback(async () => {
    isLockingRef.current = true;
    await update({ editing: entity, editingProp: { field, type } });
  }, [update, entity, field, type]);

  const handleBlur = useCallback(async () => {
    isLockingRef.current = false;
    await update({ editing: undefined, editingProp: undefined });
  }, [update, entity, field, type]);

  const editing = useMemo(
    () =>
      maybeValues(
        store.lookup,
        (p) => p.editing === entity && p.editingProp?.field === field
      )?.[0],
    [store]
  );

  const isLocked = useMemo(
    () => !!editing && editing?.person !== me,
    [editing]
  );

  const css = useMemo(
    (): React.CSSProperties =>
      isLocked ? { outlineColor: toColorVar("pink_5") } : {},
    [isLocked]
  );
  const labelCss = useMemo(
    (): React.CSSProperties =>
      isLocked ? { color: toInverseColorVar("pink_5") } : {},
    [isLocked]
  );

  // When locked changes, notify callbacks
  useEffect(() => {
    onLocked?.(isLocked);
  }, [isLocked]);

  // Unlock on unmount
  useEffect(() => {
    return () => {
      if (isLockingRef.current) {
        handleBlur();
      }
    };
  }, []);

  return (
    <div
      style={css}
      className={cx(isLocked ? styles.locked : styles.unlocked)}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      {children}
      {isLocked && (
        <Tag color="pink_5" className={styles.label}>
          <Icon icon={<RelationIcon relation={{ id: editing.person }} />} />{" "}
          <Text style={labelCss}>is typing...</Text>
        </Tag>
      )}
    </div>
  );
};

export const RealTimeDocumentEditor = ({
  entity: entityId,
  field,
  ...props
}: Omit<RealTimeFieldProps, "type" | "children"> & DocumentEditorProps) => {
  const [locked, setLocked] = useState(false);
  const entity = useLazyEntity(entityId);
  const nestedSource = useNestedSource(entity);
  return (
    <RealTimeField
      entity={entityId}
      field={field}
      type="rich_text"
      onLocked={setLocked}
    >
      <DocumentEditor
        {...props}
        editable={!locked}
        scope={nestedSource?.scope}
      />
    </RealTimeField>
  );
};
