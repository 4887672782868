import { useSetRecoilState } from "recoil";
import { useState } from "react";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { stringify } from "querystring";

import { authenticate, Integration, WorkspaceConfig } from "@api";

import {
  addWorkspaceConfig,
  WorkspaceSessionStateAtom,
} from "@state/workspace";

import { redirect } from "@utils/url";
import { required } from "@utils/maybe";
import { envOption } from "@utils/env";

import { Button, Props as ButtonProps } from "@ui/button";
import { Google, SlackColor } from "@ui/icon";
import { showError } from "@ui/notifications";

export const GoogleAuthenticate = ({ children, ...props }: ButtonProps) => {
  const [loading, setLoading] = useState(false);
  const setWorkspace = useSetRecoilState(WorkspaceSessionStateAtom);
  const setWorkspaceAndRedirect = (workspace: WorkspaceConfig) => {
    setWorkspace(addWorkspaceConfig(workspace));
    // Allow time for recoil to sync to local storage
    setTimeout(() => redirect("/home"), 1000);
  };

  const login = useGoogleLogin({
    flow: "implicit",
    onSuccess: async (token) => {
      const credential = token.access_token;

      if (!credential) {
        showError("Authentication cancelled.");
        return;
      }

      try {
        setLoading(true);

        const config = await authenticate(credential, Integration.Google);

        if (!config) {
          showError("No workspace found.");
          return;
        }

        setWorkspaceAndRedirect(config);
      } catch (err) {
        showError((err as Error).message);
        setLoading(false);
      }
    },
    onError: () => {
      showError("Authentication failed.");
    },
  });

  return (
    <Button
      style={{ textAlign: "center" }}
      icon={Google}
      fit="container"
      onClick={() => {
        setLoading(true);
        login();
      }}
      loading={loading}
    >
      Continue with Google
    </Button>
  );
};

export const SlackAuthenticate = ({ children, ...props }: ButtonProps) => {
  const base = "https://slack.com/oauth/v2/authorize";
  const scopes: string[] = [];
  const userScopes = [
    "channels:read",
    "groups:read",
    "im:read",
    "mpim:read",
    "search:read",
    "files:read",
  ];
  const redirectUri = `${required(
    process.env.NEXT_PUBLIC_HOST,
    () => "Missing HOST env var."
  )?.replace("http://local", "https://local")}/auth/slack-response`;

  const url = `${base}?${stringify({
    client_id: envOption({
      prod: "4327581758883.4327683562130",
      dev: "4327581758883.6256894062338",
    }),
    user_scope: userScopes.join(" "),
    scope: scopes.join(" "),
    redirect_uri: redirectUri,
  })}`;
  return (
    <Button icon={SlackColor} fit="container" onClick={() => redirect(url)}>
      {children || "Continue with Slack"}
    </Button>
  );
};
