import { createContext, ReactNode, useContext, useMemo } from "react";
import { flatMap } from "lodash";

import { ID } from "@api";

import { Maybe } from "@utils/maybe";
import { fromScope } from "@utils/scope";
import { ensureMany, OneOrMany } from "@utils/array";

export interface ViewingWithinState {
  ids: ID[];
}

export const ViewingWithinContext =
  createContext<Maybe<ViewingWithinState>>(undefined);

export const useViewingWithinContext = () => {
  return useContext(ViewingWithinContext);
};

export const useViewingWithin = () => {
  return useContext(ViewingWithinContext)?.ids;
};

export const WithViewingWithin = ({
  scope,
  children,
}: { scope: Maybe<OneOrMany<string>> } & { children: ReactNode }) => {
  const existing = useViewingWithinContext();
  const context = useMemo(
    () =>
      scope
        ? {
            ids: [
              ...(existing?.ids || []),
              ...flatMap(ensureMany(scope), fromScope),
            ],
          }
        : existing,
    [scope, existing]
  );

  return (
    <ViewingWithinContext.Provider value={context}>
      {children}
    </ViewingWithinContext.Provider>
  );
};
