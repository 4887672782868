import { atomFamily, selectorFamily } from "recoil";

import { ID, Contact } from "@api";

import {
  getItem,
  indexedDBStorageForStore,
  mergeItem,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { isDefault } from "@utils/recoil";

export type ContactStoreState = StoreState<Contact>;

export const WorkspaceContactStoreAtom = atomFamily<ContactStoreState, ID>({
  key: "WorkspaceContactStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "contact")],
});

export const ContactStoreAtom = WorkspaceWrappedAtom(
  "ContactStoreAtom",
  WorkspaceContactStoreAtom
);

export const ContactAtom = selectorFamily({
  key: "ContactAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(ContactStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(ContactStoreAtom, mergeItem<Contact>(newValue));
      }
    },
});
