import { Fragment } from "react";
import { isArray, map } from "lodash";

import {
  Entity,
  PersonRef,
  PropertyDef,
  RelationRef,
  SelectOption,
  SingleFilterQuery,
  Status,
} from "@api";

import { Maybe, when } from "@utils/maybe";
import { isEmptyFilter, toLabel as toOpLabel } from "@utils/filtering";
import { formatHuman } from "@utils/date";
import { toLabel as toStatusLabel } from "@utils/status";
import { switchEnum } from "@utils/logic";
import { ensureArray } from "@utils/array";
import { toLabel as toFormulaLabel } from "@utils/formula";
import { withHardHandle } from "@utils/event";
import { sentenceCase } from "@utils/string";
import { Fn } from "@utils/fn";

import { RelationLabel } from "@ui/relation-label";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { MenuItem } from "@ui/menu-item";
import { TrashAlt } from "@ui/icon";
import { Button } from "@ui/button";
import { FillSpace, HStack, SpaceBetween } from "@ui/flex";

import styles from "./trigger-button.module.css";
import { useCalDate } from "@utils/date-fp";
import { Ellipsis } from "@ui/ellipsis";

export interface Props {
  filter: SingleFilterQuery;
  prop: PropertyDef<Entity>;
  onChanged?: Fn<Maybe<SingleFilterQuery>, void>;
}

const toLabel = (filter: SingleFilterQuery): Maybe<string> => {
  const value =
    filter.value?.[filter.type] ||
    filter.values?.[filter.type] ||
    filter?.value?.formula;

  if (!value) {
    return "";
  }

  if (isArray(value)) {
    return `one of ${value.length} values`;
  }

  return switchEnum(filter.type, {
    select: () => (value as SelectOption).name,
    status: () => toStatusLabel(value as Maybe<Status>),
    person: () => (value as PersonRef).name,
    multi_select: () => (value as SelectOption).name,
    relation: () => (value as RelationRef).name,
    number: () => String(value),
    date: () => {
      if (filter.value?.formula) {
        return toFormulaLabel(filter.value?.formula);
      }
      return useCalDate(filter.value?.date, (d) => d && formatHuman(d));
    },
    else: () => String(value),
  });
};

export const TriggerButton = ({ filter, prop, onChanged }: Props) => {
  return (
    <MenuItem
      icon={<PropertyTypeIcon field={filter.field} type={filter.type} />}
      className={styles.button}
    >
      <SpaceBetween>
        <FillSpace>
          <Ellipsis>
            <HStack gap={4} fit="container">
              <strong>
                {prop?.label || when(filter.field, sentenceCase) || "Property"}
              </strong>{" "}
              {toOpLabel(filter.op)}{" "}
              {filter.type === "relations" || filter.type === "relation"
                ? map(ensureArray(filter.values?.[filter.type]), (r, i, is) => (
                    <Fragment key={r.id}>
                      <RelationLabel
                        className={styles.inline}
                        icon={false}
                        fit="content"
                        relation={r}
                      />
                      {i < is.length - 1 ? ", " : ""}
                    </Fragment>
                  ))
                : !isEmptyFilter(filter)
                ? toLabel(filter)
                : undefined}
            </HStack>
          </Ellipsis>
        </FillSpace>

        <Button
          size="tiny"
          subtle
          icon={TrashAlt}
          className={styles.delete}
          onClick={withHardHandle(() => onChanged?.(undefined))}
        />
      </SpaceBetween>
    </MenuItem>
  );
};
