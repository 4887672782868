import { filter, values } from "lodash";
import { selector, selectorFamily } from "recoil";

import { Content, ID } from "@api";

import { omitEmpty } from "@utils/array";

import { ContentStoreAtom } from "./atoms";

export const allContents = selector({
  key: "allContents",
  get: ({ get }) => {
    return omitEmpty(values(get(ContentStoreAtom).lookup));
  },
});

export const contentsForTeam = selectorFamily({
  key: "contentsForTeam",
  get:
    (teamId: ID) =>
    ({ get }) => {
      return filter(values(get(ContentStoreAtom).lookup), (p) =>
        p?.source.scope?.includes(teamId)
      ) as Content[];
    },
});
