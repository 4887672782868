import { useSetRecoilState } from "recoil";
import { ID } from "@api";

import { AppCommandsAtom, setCommandsAction, useActivePage } from "@state/app";

import { validShortcut, useShortcut } from "@utils/event";
import { SelectionState, SetSelectionState } from "@utils/selectable";
import { Fn } from "@utils/fn";

type Props = {
  onOpen: Fn<string, void>;
  selection: SelectionState;
  setSelection: SetSelectionState;
};

export const useSelectionShortcuts = (
  pageId: ID,
  { selection, setSelection, onOpen }: Props
) => {
  const setAppCommands = useSetRecoilState(AppCommandsAtom);
  const isActive = useActivePage(pageId);

  useShortcut(
    ["Delete", "Backspace"],
    [
      (e) => isActive && validShortcut(e),
      () => {
        if (selection.selected.size) {
          setAppCommands(setCommandsAction("delete", true));
        }
      },
    ],
    [selection, isActive]
  );

  useShortcut(
    "Enter",
    [
      (e) => isActive && validShortcut(e),
      () => {
        if (selection.focused) {
          onOpen?.(selection.focused);
        }
      },
    ],
    [selection, isActive]
  );
};
