import { ReactNode, useEffect, useRef, useState } from "react";
import * as RCMenu from "@radix-ui/react-context-menu";

import { Fn } from "@utils/fn";
import { handle } from "@utils/event";

import { MenuItem, MenuItemProps } from "@ui/menu-item";
import { AngleRightIcon } from "./icon";

import styles from "./action-menu.module.css";

type SubMenuProps = MenuItemProps & {
  children: ReactNode;
};

export const ContextSubMenu = ({ children, text, ...props }: SubMenuProps) => (
  <RCMenu.ContextMenuSub>
    <RCMenu.ContextMenuSubTrigger asChild>
      <div>
        <MenuItem {...props} iconRight={AngleRightIcon}>
          {text}
        </MenuItem>
      </div>
    </RCMenu.ContextMenuSubTrigger>
    <RCMenu.ContextMenuPortal>
      <RCMenu.ContextMenuSubContent
        className={styles.popover}
        sideOffset={4}
        alignOffset={-2}
      >
        {children}
      </RCMenu.ContextMenuSubContent>
    </RCMenu.ContextMenuPortal>
  </RCMenu.ContextMenuSub>
);

export const ContextItem = ({
  children,
  onClick,
  ...props
}: Omit<MenuItemProps, "onClick"> & { onClick?: Fn<Event, void> }) => (
  // Timeout fixes a bug where `mouse-events: none` is left on body when another
  // modal is shown from a context menu action
  <RCMenu.Item onSelect={(e) => setTimeout(() => onClick?.(e), 1)}>
    <MenuItem {...props}>{children}</MenuItem>
  </RCMenu.Item>
);

export const ContextLabel = ({ children }: { children: ReactNode }) => (
  <RCMenu.Label>
    <div className={styles.label}>{children}</div>
  </RCMenu.Label>
);

export const ContextDivider = () => (
  <RCMenu.Separator className={styles.divider} />
);

interface Props {
  children: ReactNode;
  actions: ReactNode;
  onOpen?: Fn<boolean, void>;
}

export const ContextMenu = ({ children, onOpen, actions }: Props) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const div = ref.current;
    if (div) {
      if (open) {
        div.children?.[0]?.classList?.add("hover");
      } else {
        div.children?.[0]?.classList?.remove("hover");
      }
    }
  }, [ref.current, open]);

  return (
    <RCMenu.Root
      onOpenChange={(o) => {
        setOpen(o);
        onOpen?.(o);
      }}
    >
      <RCMenu.Trigger asChild>
        <div ref={ref} style={{ display: "contents" }}>
          {children}
        </div>
      </RCMenu.Trigger>

      <RCMenu.Portal>
        {/* Mark mouse events as handled from here upwards. */}
        <div onMouseUp={handle} onMouseDown={handle} onClick={handle}>
          <RCMenu.Content className={styles.popover}>{actions}</RCMenu.Content>
        </div>
      </RCMenu.Portal>
    </RCMenu.Root>
  );
};
