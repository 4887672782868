import { useCallback, useMemo } from "react";

import { Entity, PropertyType, PropertyValue as PropertyValueType } from "@api";

import { useLazyPropertyValue } from "@state/databases";
import { useUpdateEntity } from "@state/generic";

import { asMutation } from "@utils/property-mutations";
import { isEmptyRef, toFieldName } from "@utils/property-refs";

import { Props as ButtonProps, Button } from "@ui/button";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { Text } from "@ui/text";
import { Props as PropertyValueProps, PropertyValue } from "./property-value";

type Props<T extends Entity = Entity> = {
  entity: T;
  field: string;
  type: PropertyType;
  label?: string;
} & Omit<
  PropertyValueProps<T>,
  "valueRef" | "source" | "onChange" | "children"
> &
  Pick<ButtonProps, "subtle" | "size" | "icon">;

export const PropertyValueButton = ({
  field,
  type,
  entity,
  label,
  subtle = true,
  icon,
  size = "small",
  ...props
}: Props) => {
  const prop = useLazyPropertyValue(entity, { field, type });
  const mutate = useUpdateEntity(entity.id);
  const children = useMemo(
    () =>
      isEmptyRef(prop) ? (
        <Button
          subtle={subtle}
          size={size}
          icon={
            icon || <PropertyTypeIcon field={prop.field} type={prop.type} />
          }
        >
          <Text subtle={subtle}>Add {toFieldName(prop)}</Text>
        </Button>
      ) : undefined,
    [prop, subtle, size, icon]
  );

  if (!prop) {
    return <></>;
  }

  return (
    <PropertyValue
      {...props}
      onChange={(v) => mutate(asMutation(prop, v[prop.type]))}
      valueRef={prop}
      source={entity.source}
      children={children}
    />
  );
};
