import { selectorFamily } from "recoil";

import {
  Entity,
  EntityType,
  FetchOptions,
  FilterQuery,
  HasArchivedAt,
  HasCode,
  HasDeletedAt,
  HasTemplate,
  ID,
  RelationRef,
  SearchOptions,
  toTitleOrName,
} from "@api";

import { StoreState, getItem, setItem } from "@state/store";
import { allPropertiesForType } from "@state/databases";
import { ViewAtom } from "@state/views";

import { Maybe, maybeMap, safeAs } from "@utils/maybe";
import { isLocalID, isTemplateId, isWorkspaceId, typeFromId } from "@utils/id";
import { isDefault } from "@utils/recoil";
import { maybeValues } from "@utils/object";
import { indexBy, maybeMapMax } from "@utils/array";
import { fuzzyMatch } from "@utils/search";
import { passes } from "@utils/filtering";

import { getStore } from "./atoms";
import { some } from "lodash";

export const GenericStore = selectorFamily<
  Maybe<StoreState<Entity>>,
  EntityType
>({
  key: "GenericStore",
  get:
    (type: EntityType) =>
    ({ get }) => {
      if (!type) {
        return undefined;
      }

      return get(getStore(type as EntityType));
    },
  set:
    (type: EntityType) =>
    ({ set }, newValue) => {
      if (!type || !newValue || isDefault(newValue)) {
        return getStore(type as EntityType);
      }

      return set(getStore(type as EntityType), newValue);
    },
});

export const GenericItem = selectorFamily<Maybe<Entity>, string>({
  key: "GenericItem",
  get:
    (id: string) =>
    ({ get }) => {
      const type = id && typeFromId(id);

      if (!type) {
        return undefined;
      }

      if (type === "view" && isTemplateId(id)) {
        return get(ViewAtom(id));
      }

      return getItem(get(getStore(type as EntityType)), id);
    },
  set:
    (id: string) =>
    ({ set }, newValue) => {
      const type = typeFromId(id);

      if (!type || !newValue || isDefault(newValue)) {
        return undefined;
      }

      return set(getStore(type as EntityType), setItem(newValue));
    },
});

export const GenericItems = selectorFamily<Maybe<Entity[]>, ID[]>({
  key: "GenericItem",
  get:
    (ids) =>
    ({ get }) =>
      maybeMap(ids, (id) => get(GenericItem(id))),
});

export const getRelationRef = selectorFamily({
  key: "getRelationRef",
  get:
    (ref: Maybe<RelationRef>) =>
    ({ get }): Maybe<RelationRef | Entity> => {
      // Return existing ref if it already has a name
      if (!ref || !ref.id) {
        return undefined;
      }

      return get(GenericItem(ref.id)) || ref;
    },
});

const toSearchable = (t: Entity) =>
  ((t as HasCode)?.code ? `${(t as HasCode)?.code}: ` : "") + toTitleOrName(t);

export const searchStore = selectorFamily({
  key: "searchStore",
  get:
    (
      opts: Maybe<
        {
          type: EntityType;
          query: string;
          scope?: string;
        } & SearchOptions
      >
    ) =>
    ({ get }) => {
      if (!opts || !opts.limit) {
        return [];
      }

      const { type, query, limit, scope } = opts;

      const store = get(getStore(type));
      const allItems = maybeValues(store?.lookup);
      const tokens =
        opts?.method === "fuzzy"
          ? query.split(" ").filter((s) => s.length > 3)
          : !!query?.trim()
          ? [query]
          : [];

      return maybeMapMax(allItems, limit ?? 20, (t) =>
        !!t &&
        !(t as HasDeletedAt)?.deletedAt &&
        (!(t as HasArchivedAt)?.archivedAt || opts.archived) &&
        !isLocalID(t.id) &&
        (opts.templates
          ? !!safeAs<HasTemplate>(t)?.template
          : !safeAs<HasTemplate>(t)?.template) &&
        (!scope || isWorkspaceId(scope) || t?.source?.scope?.includes(scope)) &&
        (!tokens?.length || some(tokens, (q) => fuzzyMatch(q, toSearchable(t))))
          ? t
          : undefined
      );
    },
});

export const itemsForQuery = selectorFamily({
  key: "itemsForQuery",
  get:
    (
      params: Maybe<{
        type: EntityType;
        filter: Record<any, any>;
        opts?: FetchOptions;
      }>
    ) =>
    ({ get }) => {
      if (!params) {
        return [];
      }

      const { type, filter, opts } = params;
      const store = get(getStore(type));
      const allItems = maybeValues(store?.lookup);
      const props = indexBy(get(allPropertiesForType(type)), (p) => p.field);

      return maybeMapMax(allItems, opts?.limit ?? 20, (t) =>
        !!t &&
        !(t as HasDeletedAt)?.deletedAt &&
        (!(t as HasArchivedAt)?.archivedAt || opts?.archived === true) &&
        (!(t as HasTemplate)?.template || opts?.templates === true) &&
        (!filter || passes(t, filter as FilterQuery, props))
          ? t
          : undefined
      );
    },
});
