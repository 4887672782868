import DataLoader from "dataloader";
import { ID, getResources } from "@api";
import { cachedFuncByWorkspace } from "@state/workspace";

import { lookup } from "@utils/array";
import { toMilliSeconds } from "@utils/time";

const resourceLoader = new DataLoader(
  async (ids: readonly string[]) => {
    const resources = await getResources(ids as ID[]);
    const hashed = lookup(resources || [], (i) => i.id);
    return ids.map(hashed);
  },
  { cache: false }
);

export const getResourceLoader = cachedFuncByWorkspace(
  () => (id) => resourceLoader.load(id),
  toMilliSeconds("1 minute"),
  ([v]) => v
);

export const getResourcesLoader = cachedFuncByWorkspace(
  () => getResources,
  toMilliSeconds("1 minute")
);
