import { DOMAttributes, ReactNode } from "react";
import { isString } from "lodash";

import { ComponentOrNode } from "@utils/react";
import { cx } from "@utils/class-names";

import { Icon } from "./icon";
import { Container } from "./container";
import { TextLarge, TextSmall } from "./text";

import styles from "./square-button.module.css";

type Props = {
  icon?: ComponentOrNode;
  text?: string | ReactNode;
  stat?: ReactNode;
  selected?: boolean;
  disabled?: boolean;
  className?: string;
} & DOMAttributes<HTMLDivElement>;

export const StatButton = ({ className, stat, ...props }: Props) => (
  <SquareButton
    className={cx(styles.stat, className)}
    {...props}
    stat={
      <TextLarge bold className={styles.text}>
        {stat}
      </TextLarge>
    }
  />
);

export const SquareButton = ({
  icon,
  stat,
  text,
  selected,
  disabled,
  className,
  ...props
}: Props) => (
  <div
    className={cx(
      styles.button,
      styles.stack,
      selected && styles.selected,
      disabled && styles.disabled,
      className
    )}
    {...props}
  >
    {icon && <Icon size={"large"} icon={icon} className={styles.icon} />}
    {!!stat && stat}
    {isString(text) ? (
      <TextSmall className={styles.text}>{text}</TextSmall>
    ) : (
      text
    )}
  </div>
);
