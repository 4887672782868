import { ID, toTitleOrName } from "@api";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useLazyEntity, useMarkAsSeen } from "@state/generic";

import { useSyncPathnameID } from "@utils/url";

import { usePageId } from "@ui/app-page";
import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { StackContainer } from "@ui/sheet-layout";
import AppPage from "@ui/page/app-page";

import { PagePane } from "./pane";

interface Props {
  id: ID;
}

const DocumentPage = ({ id }: Props) => {
  const pageId = usePageId();
  const entity = useLazyEntity<"page">(id);
  const [page] = useRegisterPage(id, entity);
  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(id, entity?.id);

  // Mark the note as seen by current user
  useMarkAsSeen(id, pageId);

  if (!entity) {
    return <>Not found.</>;
  }

  return (
    <AppPage page={page} loading={!entity} title={toTitleOrName(entity)}>
      <StackContainer>
        <SmartBreadcrumbSheet />
        <PagePane id={id} item={entity} />
      </StackContainer>
    </AppPage>
  );
};

export default DocumentPage;
