import { find } from "lodash";
import { Deal } from "@api";
import { findAvailableStatuses } from "@state/databases";
import { WorkflowDefinition, WorkflowDefinitionConfig } from "@state/workflows";

import { firstAfter, omitEmpty } from "@utils/array";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { equalsAny } from "@utils/logic";

import { StatusIcon, StatusNext, UserIcon } from "@ui/icon";
import { showError } from "@ui/notifications";

export const assignOwner: WorkflowDefinition<Deal> = {
  id: "assignOwner",
  trigger: "ACTION",
  type: ["deal"],
  icon: UserIcon,
  title: "Assign Owner",

  allowed: ({ entity }, context) =>
    // Not done
    entity?.status?.group !== "done" &&
    // Not yet assigned
    !entity?.owner?.id,

  collect: [{ field: "owner", type: "relation" }],

  execute: ({ entity, collected }, { props }) => {
    return [asUpdate(entity, collected)];
  },
};

export const moveDealForward: WorkflowDefinition<Deal> = {
  id: "moveDealForward",
  trigger: "ACTION",
  type: ["deal"],
  icon: StatusNext,
  title: "Progress Stage",

  allowed: ({ entity }, context) =>
    // In early
    entity?.status?.group !== "done" &&
    // Is assigned to me
    entity?.owner?.id === context.session.user.id,

  execute: ({ entity }, { props }) => {
    const statuses = findAvailableStatuses(props, entity.source);
    const next = firstAfter(statuses, (s) => s.id === entity.status?.id);

    if (!next) {
      return [];
    }

    return [
      asUpdate(
        entity,
        omitEmpty([asMutation({ field: "status", type: "status" }, next)])
      ),
    ];
  },
};

export const markWon: WorkflowDefinition<Deal> = {
  id: "markWon",
  trigger: "ACTION",
  type: ["deal"],
  icon: <StatusIcon status={{ group: "done", color: "green" }} />,
  title: "Mark as Won",

  allowed: ({ entity }, context) =>
    // In early
    equalsAny(entity?.status?.group, ["not-started", "in-progress"]) &&
    // Is assigned to me
    entity?.owner?.id === context.session.user.id,

  execute: ({ entity }, { props }) => {
    const statuses = findAvailableStatuses(props, entity.source);
    const next = find(
      statuses,
      (s) => s.group === "done" && s.color === "green"
    );

    if (!next) {
      showError("Couldn't find 'won' status.");
      return [];
    }

    return [
      asUpdate(
        entity,
        omitEmpty([asMutation({ field: "status", type: "status" }, next)])
      ),
    ];
  },
};

export const markLost: WorkflowDefinition<Deal> = {
  id: "markLost",
  trigger: "ACTION",
  type: ["deal"],
  icon: <StatusIcon status={{ group: "done", color: "gray" }} />,
  title: "Mark as Lost",

  allowed: ({ entity }, context) =>
    // In early
    equalsAny(entity?.status?.group, ["not-started", "in-progress"]) &&
    // Is assigned to me
    entity?.owner?.id === context.session.user.id,

  execute: ({ entity }, { props }) => {
    const statuses = findAvailableStatuses(props, entity.source);
    const next = find(
      statuses,
      (s) => s.group === "done" && s.color !== "green"
    );

    if (!next) {
      showError("Couldn't find 'lsot' status.");
      return [];
    }

    return [
      asUpdate(
        entity,
        omitEmpty([asMutation({ field: "status", type: "status" }, next)])
      ),
    ];
  },
};

export const definitions: WorkflowDefinitionConfig<Deal> = {
  triggers: [],
  suggestions: [],
  actions: [moveDealForward, markWon, markLost, assignOwner],
};

export default definitions;
