import { map, take } from "lodash";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { ID, PropertyRef, Ref, View } from "@api";

import {
  toShortTitle,
  useLazyGetView,
  useLazyItemsForView,
  useLazyViews,
  useToViewTitle,
} from "@state/views";

import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { useGoTo } from "@utils/navigation";
import { toKey } from "@utils/property-refs";
import { GroupedItems } from "@utils/grouping";
import { whenNotEmpty } from "@utils/maybe";
import { respectHandled } from "@utils/event";
import { equalsAny } from "@utils/logic";
import { useArrayKey } from "@utils/react";

import { Sheet, SheetProps } from "@ui/sheet-layout";
import { TextLarge } from "@ui/text";
import { HStack, SpaceBetween } from "@ui/flex";
import { ArrowUpRight } from "@ui/icon";
import { CardHeader } from "@ui/card-header";
import { Button } from "@ui/button";
import { MenuGroup } from "@ui/menu-group";
import { Menu } from "@ui/menu";
import { ShowMoreMenuItem } from "@ui/menu-item";
import { Container } from "@ui/container";
import { render, useEngine } from "@ui/engine";
import { ViewResultsList } from "./view-results-list";
import { PropertyValue } from "./property-value";

import styles from "./view-card.module.css";

type Props = {
  viewId: ID;
  limit?: number;
  onOpen?: Fn<Ref, void>;
  editable?: boolean;
  className?: string;
  header?: ReactNode;
  as?: "card" | "sheet";
} & SheetProps;

export const ViewCard = ({
  viewId,
  editable = true,
  className,
  limit,
  onOpen,
  height,
  header,
  as,
  size,
}: Props) => {
  const goTo = useGoTo();
  const view = useLazyGetView(viewId);
  const { items } = useLazyItemsForView(viewId, { quickFilter: false });
  const toTitle = useToViewTitle(view);

  const handleHeaderClicked = useCallback(() => {
    if (!view) {
      return;
    }

    if (onOpen) {
      onOpen(view);
    } else {
      goTo(view);
    }
  }, [onOpen, view]);

  if (!view) {
    return (
      <Sheet
        className={cx(as === "card" ? styles.card : styles.sheet, className)}
        height={height || "content"}
        size={size || "secondary"}
        transparency="none"
      >
        Loading...
      </Sheet>
    );
  }

  return (
    <Sheet
      className={cx(styles.sheet, className)}
      height={height || "content"}
      size={size || "secondary"}
      transparency="none"
    >
      <CardHeader className={styles.header} border={!items?.grouped && !header}>
        <Container
          gap={4}
          stack="vertical"
          inset="top"
          padding="none"
          fit="container"
        >
          <SpaceBetween align="center" fit="container">
            <HStack onClick={respectHandled(handleHeaderClicked)}>
              <TextLarge bold className={styles.title}>
                {view ? toTitle(view) : "Loading..."}
              </TextLarge>
              <TextLarge bold subtle>
                {items?.all?.length || "0"}
              </TextLarge>
            </HStack>

            <Button
              subtle
              size="small"
              variant="primary"
              icon={ArrowUpRight}
              onClick={() => view && onOpen?.(view)}
            >
              Open
            </Button>
          </SpaceBetween>
          {header}
        </Container>
      </CardHeader>

      <CardViewLayout
        view={view}
        viewId={viewId}
        editable={editable}
        limit={limit}
        onOpen={onOpen}
      />
    </Sheet>
  );
};

export const TabbedViewCard = ({
  viewIds,
  ...props
}: Omit<Props, "viewId"> & { viewIds: ID[] }) => {
  const [viewId, setViewId] = useState(viewIds[0]);
  const lastIndex = useMemo(() => viewIds?.indexOf(viewId), [viewId]);
  const views = useLazyViews(viewIds);
  const header = useMemo(() => {
    return (
      <Container
        inset="horizontal"
        padding="none"
        fit="container"
        className={styles.tabbedButtons}
      >
        <HStack gap={4}>
          {map(views, (v) => (
            <Button
              key={v.id}
              size="small"
              className={cx(
                styles.tabbedButton,
                equalsAny(viewId, [v.id, v.alias || ""]) && styles.active
              )}
              onClick={() => setViewId(v.id)}
            >
              {toShortTitle(v)}
            </Button>
          ))}
        </HStack>
      </Container>
    );
  }, [viewId, viewIds]);

  useEffect(() => {
    if (!viewIds.includes(viewId)) {
      setViewId(viewIds[lastIndex] || viewIds[0]);
    }
  }, [useArrayKey(viewIds)]);

  return <ViewCard {...props} viewId={viewId} header={header} />;
};

const CardViewLayout = ({
  view,
  viewId,
  onOpen,
  editable,
  limit,
}: Props & { view: View }) => {
  const showProps = useMemo(
    (): PropertyRef[] =>
      whenNotEmpty(view?.showProps) || [
        { field: "assigned", type: "relation" },
      ],
    [view]
  );

  return (
    <>
      {view?.layout !== "card" && (
        <ViewResultsList
          viewId={viewId}
          className={styles.list}
          showProps={showProps}
          editable={editable}
          limit={limit}
          onOpen={onOpen}
          variant="icon-only"
          addButton={{ showLocation: true }}
        />
      )}

      {view?.layout === "card" && (
        <ColumnLayoutPreview view={view} onOpen={onOpen} />
      )}
    </>
  );
};

export const ColumnLayoutPreview = ({
  view,
  onClick,
  onOpen,
}: {
  view: View;
  onClick?: Fn<void, void>;
  onOpen: Props["onOpen"];
}) => {
  const { items } = useLazyItemsForView(view.id, { quickFilter: false });
  const engine = useEngine(view?.entity);
  return (
    <Container
      className={styles.columnContainer}
      onClick={respectHandled(() => onClick?.())}
    >
      <HStack align="flex-start">
        {map(items.grouped?.groups, (group: GroupedItems) => {
          return (
            <Menu key={toKey(group.value)} className={styles.column}>
              <MenuGroup>
                <PropertyValue
                  valueRef={group.value}
                  source={group.items[0]?.source}
                  editable={false}
                />
                {map(take(group.items, 10), (item) =>
                  render(engine.asMenuItem, { key: item.id, item, onOpen })
                )}
                {group.items.length > 10 && (
                  <ShowMoreMenuItem count={group.items.length - 10} />
                )}
              </MenuGroup>
            </Menu>
          );
        })}
      </HStack>
    </Container>
  );
};
