import { useMemo } from "react";

import { Status } from "@api";

import { daysAgo, formatDay, formatRelative } from "@utils/date";
import { Fn } from "@utils/fn";
import { ISODate, useISODate } from "@utils/date-fp";

import { Button } from "@ui/button";
import { TextSmall } from "@ui/text";
import { Clock, Icon } from "@ui/icon";
import { HStack } from "./flex";

import styles from "./due-date.module.css";

export const DueDate = ({
  date,
  status,
  onClick,
}: {
  date: ISODate;
  status?: Status["group"];
  onClick?: Fn<React.MouseEvent, void>;
}) => {
  const dueInDays = useMemo(() => useISODate(date, daysAgo), [date]);
  const label = useMemo(
    () => useISODate(date, (d) => formatRelative(d, formatDay)),
    [date]
  );
  const danger = useMemo(
    () => dueInDays < 0 && status !== "done",
    [dueInDays, status]
  );

  return (
    <Button
      variant={danger ? "danger" : dueInDays === 0 ? "primary" : "secondary"}
      subtle
      size="tiny"
      onClick={onClick}
    >
      <HStack gap={2} align="flex-end">
        <Icon size="xsmall" className={styles.inherit} icon={Clock} />
        <TextSmall className={styles.inherit}>{label}</TextSmall>
      </HStack>
    </Button>
  );
};
