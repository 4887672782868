import { useMemo } from "react";

import { Maybe } from "@utils/maybe";
import { cx } from "@utils/class-names";
import { format } from "@utils/date";

import { Label, Props as LabelProps } from "@ui/label";

import styles from "./time-label.module.css";

type Props = LabelProps & { date: Maybe<Date> };

export const TimeLabel = ({
  date,
  size = "small",
  className,
  ...rest
}: Props) => {
  const time = useMemo(() => date && format(date, "hh:mm"), [date]);
  const amPm = useMemo(() => date && format(date, "a"), [date]);

  if (!date) {
    return <></>;
  }

  return (
    <Label {...rest} size={size} className={cx(styles.label, className)}>
      {time}
      <span className={styles.ampm}>{amPm}</span>
    </Label>
  );
};
