import { atomFamily, selectorFamily } from "recoil";

import { ID, Roadmap } from "@api";

import {
  getItem,
  indexedDBStorageForStore,
  mergeItem,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { isDefault } from "@utils/recoil";

export type RoadmapStoreState = StoreState<Roadmap>;

export const WorkspaceRoadmapStoreAtom = atomFamily<RoadmapStoreState, ID>({
  key: "WorkspaceRoadmapStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "roadmap")],
});

export const RoadmapStoreAtom = WorkspaceWrappedAtom(
  "RoadmapStoreAtom",
  WorkspaceRoadmapStoreAtom
);

export const RoadmapAtom = selectorFamily({
  key: "RoadmapAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(RoadmapStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(RoadmapStoreAtom, mergeItem<Roadmap>(newValue));
      }
    },
});
