import { atomFamily } from "recoil";

import { ID } from "@api";

import { OrStar } from "@utils/wildcards";
import { WorkspaceWrappedAtom } from "@state/workspace";
import { appendKey, localStorageEffect } from "@state/local-storage-effect";

export type AppSpace = {
  id: ID;
  scopes: OrStar<ID[]>;
};

const defaultSpace = (wid: ID): AppSpace => ({ id: wid, scopes: "*" });

export const AppSpaceAtom = WorkspaceWrappedAtom(
  "WorkspaceAppSpaceAtom",
  atomFamily<AppSpace, ID>({
    key: "AppSpaceAtom",
    default: defaultSpace,
    effects: (wid) => [
      localStorageEffect<AppSpace>({
        key: appendKey("traction.space", wid),
        default: defaultSpace(wid),
        clean: (v) => v,
      }),
    ],
  })
);

export const setActiveSpace =
  (id: ID, scopes: OrStar<ID[]>) => (_: AppSpace) => ({
    id,
    scopes,
  });
