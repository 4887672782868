import { addDays } from "date-fns";
import { last, map, omit, padStart } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { DatabaseID, Ref, Sprint } from "@api";

import { useCreateFromObject, useCreateFromTemplate } from "@state/generic";
import { useLazyPeopleForTeam } from "@state/teams";
import { toTemplateViewId, useLazyItemsForView } from "@state/views";
import { useActiveWorkspaceId } from "@state/workspace";
import { useLazyTemplates } from "@state/templates";

import { Fn } from "@utils/fn";
import { Maybe, when } from "@utils/maybe";
import { now } from "@utils/now";
import { toRef } from "@utils/property-refs";
import { asUTC, durationInDays } from "@utils/date";
import { IntegerOrdering, setOrders } from "@utils/ordering";
import { extractTeam } from "@utils/scope";
import { fromCalDate, toCalDate, useCalDate } from "@utils/date-fp";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { DateInputPicker } from "@ui/date-picker";
import { DialogSplit } from "@ui/dialog-split";
import { EducationTip } from "@ui/education-tip";
import { FillSpace, SpaceBetween, ThirdSpace } from "@ui/flex";
import { Field, TextInput } from "@ui/input";
import { EntityMultiSelect } from "@ui/select";
import { EmojiSelect } from "@ui/select/emoji";
import { Text } from "@ui/text";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { MenuItem } from "@ui/menu-item";
import { EditAlt, EmojiIcon } from "@ui/icon";
import { Tag } from "@ui/tag";
import { showError } from "@ui/notifications";

interface Props {
  defaults: Partial<Omit<Sprint, "id">>;
  onSaved?: Fn<Ref, void>;
  onCancel?: Fn<void, void>;
}

export const SprintCreateDialog = ({ onCancel, onSaved, defaults }: Props) => {
  const wID = useActiveWorkspaceId();
  const [mode, setMode] = useState<"edit" | "notion">("edit");
  const source = useMemo(
    (): DatabaseID => ({
      type: "sprint",
      scope: defaults.source?.scope || defaults.location || wID,
    }),
    [defaults?.source?.scope]
  );
  const create = useCreateFromObject("sprint", source.scope);
  const teamId = useMemo(
    () => extractTeam(defaults.source?.scope),
    [defaults?.source?.scope]
  );
  const people = useLazyPeopleForTeam(teamId || "");
  const [template, setTemplate] = useState<Sprint>();
  const templates = useLazyTemplates(source);

  const { items: sprints } = useLazyItemsForView(
    toTemplateViewId("team-sprint", { parent: teamId }),
    { archived: false },
    false
  );

  const [sprint, _setSprint] = useState<Partial<Sprint>>({
    ...defaults,
    duration: defaults?.duration || 14,
  });

  const { create: createFromTemplate } = useCreateFromTemplate(
    source,
    onSaved,
    useMemo(() => ({ appendName: false }), [sprint])
  );

  const setSprint = (s: Partial<Sprint>) =>
    _setSprint((curr) => {
      const additional = {
        ...when(s.orders?.default, (o) => ({
          name: `Sprint ${padStart(String(o), 3, "0")}`,
          code: `SP${padStart(String(o), 3, "0")}`,
        })),

        ...when(
          (s.start || curr.start) && (s.duration || curr.duration)
            ? ([s.duration || curr.duration, s.start || curr.start] as const)
            : undefined,
          ([d, st]) => ({
            end: useCalDate(st, (date) =>
              asUTC(addDays(date || now(), (d || 14) - 1))
            ),
          })
        ),

        ...when(
          s.end && curr.start ? [curr.start, s.end] : undefined,
          ([st, end]) => ({
            duration: useCalDate(st, (date) =>
              durationInDays(date, fromCalDate(end, "local"))
            ),
          })
        ),
        ...when(
          s.start && curr.end ? [s.start, curr.end] : undefined,
          ([st, end]) => ({
            duration: useCalDate(st, (date) =>
              durationInDays(date, fromCalDate(end))
            ),
          })
        ),
      };

      return { ...curr, ...s, ...additional };
    });

  const onCreate = useCallback(async () => {
    if (template) {
      // If template is being used, then create from template, with defaults passed in above
      createFromTemplate?.(template, {
        defaults: { [template.id]: omit(sprint, "id") },
      });
    } else {
      if (!create) {
        return showError("Not ready.");
      }
      // Else just create from object
      const [saved] = create([sprint]);
      onSaved?.(saved);
    }
  }, [create, sprint, template, onSaved, onCancel]);

  useEffect(() => {
    if (!sprint.people?.length) {
      setSprint({ people: map(people, (p) => toRef(p)) });
    }
  }, [people]);

  useEffect(() => {
    if (!!sprints.sorted?.length && !sprint.orders?.default) {
      const lastSprint = last(sprints.sorted) as Maybe<Sprint>;
      if (lastSprint) {
        setSprint({
          orders: setOrders(
            lastSprint.orders,
            "default",
            IntegerOrdering.bump(Number(lastSprint.orders?.default) || 0)
          ),
          start: useCalDate(lastSprint.end, (d) =>
            asUTC(addDays(d || now(), 1))
          ),
          duration: lastSprint.duration,
        });
      }
    }
  }, [sprints.sorted]);

  const menuControl = (
    <Menu>
      <MenuGroup>
        <MenuItem
          icon={EditAlt}
          text="Start from scratch"
          onClick={() => setMode("edit")}
        />
      </MenuGroup>
      <MenuGroup label="Use template">
        {map(templates, (t: Sprint) => (
          <MenuItem
            key={t.id}
            onClick={() => {
              setTemplate(t);
              setSprint(omit(t, "code", "order", "start", "end"));
              setMode("edit");
            }}
            icon={t.icon ? <EmojiIcon emoji={t.icon} /> : undefined}
            text={t.name}
          />
        ))}
      </MenuGroup>
    </Menu>
  );

  return (
    <DialogSplit
      title={"New sprint"}
      onDismiss={onCancel}
      side={
        <SpaceBetween direction="vertical">
          {!!mode ? (
            menuControl
          ) : (
            <Text subtle>
              Sprints are a way to organise work into time-boxed iterations.
            </Text>
          )}
          <EducationTip relevantTo={["sprint"]} />
        </SpaceBetween>
      }
      actions={
        <>
          <Button onClick={() => onCancel?.()}>Cancel</Button>
          <Button variant="primary" onClick={onCreate}>
            Create sprint
          </Button>
        </>
      }
    >
      <FillSpace>
        <Container gap={20} stack="vertical" fit="container">
          {!mode && menuControl}
          {mode === "edit" && (
            <>
              {template && (
                <Text subtle>
                  Creating from template <Tag>{template.name}</Tag>
                </Text>
              )}
              <Field label="Icon">
                <EmojiSelect
                  emoji={sprint.icon || "🏃‍♂️"}
                  size="large"
                  onChange={(i) => setSprint({ icon: i })}
                />
              </Field>

              <SpaceBetween gap={10}>
                <ThirdSpace>
                  <Field label="Number">
                    <TextInput
                      inputType={"number"}
                      value={String(sprint.orders?.default || 0)}
                      onChange={(t) =>
                        setSprint({
                          ...sprint,
                          orders: setOrders(sprint.orders, "default", t),
                        })
                      }
                      updateOn="blur"
                      autoFocus={true}
                      placeholder="Sprint number..."
                    />
                  </Field>
                </ThirdSpace>
                <FillSpace>
                  <Field label="Name">
                    <TextInput
                      value={sprint.name || ""}
                      onChange={(t) => setSprint({ ...sprint, name: t })}
                      updateOn="change"
                      placeholder="Enter sprint name..."
                    />
                  </Field>
                </FillSpace>
              </SpaceBetween>

              <SpaceBetween gap={10}>
                <Field label="Start date">
                  <DateInputPicker
                    date={fromCalDate(sprint.start)}
                    onChanged={(d) =>
                      setSprint({ start: d && toCalDate(d, "local") })
                    }
                    portal={true}
                  />
                </Field>
                <Field label="Duration (days)">
                  <TextInput
                    inputType={"number"}
                    value={String(sprint.duration || 0)}
                    updateOn="change"
                    onChange={(d) => setSprint({ duration: Number(d) })}
                  />
                </Field>
                <Field label="End date">
                  <DateInputPicker
                    date={fromCalDate(sprint.end)}
                    onChanged={(d) =>
                      setSprint({ end: d && toCalDate(d, "local") })
                    }
                    portal={true}
                  />
                </Field>
              </SpaceBetween>

              <Field label="People">
                <EntityMultiSelect
                  value={sprint.people}
                  type={"person"}
                  onChange={(ps) => setSprint({ people: ps })}
                  portal={true}
                />
              </Field>
            </>
          )}
        </Container>
      </FillSpace>
    </DialogSplit>
  );
};
