import { map } from "lodash";
import { useCallback } from "react";

import { Maybe, when } from "@utils/maybe";

import { SectionLabel } from "@ui/label";
import { Button } from "@ui/button";
import { copyToClipboard } from "@ui/clipboard";

import styles from "./code-label.module.css";

interface Props {
  code: Maybe<string>;
  onClick?: () => void;
}

export const CodeLabel = ({ code, onClick }: Props) => {
  const onCopy = useCallback(() => when(code, copyToClipboard), [code]);
  return (
    <Button
      size="tiny"
      subtle
      onClick={onClick ?? onCopy}
      className={styles.button}
      fit="content"
    >
      <SectionLabel className={styles.code}>
        {map(code?.split(""), (d, i) => (
          <span key={d + i} className={styles.digit}>
            {d}
          </span>
        ))}
      </SectionLabel>
    </Button>
  );
};
