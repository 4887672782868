import { isString } from "lodash";
import { CSSProperties, ReactNode, useMemo } from "react";

import { when } from "@utils/maybe";
import { Icon } from "./icon";
import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { ComponentOrNode } from "@utils/react";
import { capitalCase } from "@utils/string";
import { equalsAny } from "@utils/logic";

import { Size } from "./types";

import styles from "./label.module.css";

export interface Props {
  text?: string;
  icon?: ComponentOrNode;
  iconRight?: ComponentOrNode;
  fit?: "container" | "content";
  subtle?: boolean;
  padded?: boolean;
  bold?: boolean;
  primary?: boolean;
  children?: ReactNode;
  className?: string;
  iconClassName?: string;
  size?: Size;
  htmlFor?: string;
  onClick?: Fn<React.MouseEvent, void>;
  style?: CSSProperties;
}
export const Label = ({
  text,
  children,
  icon,
  iconRight,
  subtle = false,
  padded = false,
  bold = false,
  primary = false,
  fit = "container",
  htmlFor,
  className,
  onClick,
  iconClassName,
  style,
  size = "default",
}: Props) => (
  <label
    onClick={onClick}
    className={cx(
      styles.label,
      bold && styles.bold,
      primary && styles.primary,
      subtle && styles.subtle,
      padded && styles.padded,
      fit === "container" ? styles.fitContainer : styles.fitContent,
      className
    )}
    style={style}
    htmlFor={htmlFor}
  >
    {when(icon || undefined, (i) => (
      <Icon
        size={size}
        className={cx(
          styles.icon,
          !(text || children) && styles.only,
          iconClassName
        )}
        icon={i}
      />
    )) || false}
    {!!(text || children) && (
      <span
        className={cx(styles.text, size && styles[size], bold && styles.bold)}
      >
        {text || children}
      </span>
    )}
    {when(iconRight || undefined, (i) => (
      <Icon
        size={size}
        className={cx(
          styles.iconRight,
          !(text || children) && styles.only,
          iconClassName
        )}
        icon={i}
      />
    )) || false}
  </label>
);

export const SectionLabel = ({
  text,
  className,
  bold = false,
  size,
  ...props
}: Props) => {
  const formatted = useMemo(
    () =>
      isString(text) && equalsAny(size, ["default", "small"])
        ? capitalCase(text)
        : text,
    [text]
  );

  return (
    <Label
      {...props}
      className={cx(styles.sectionLabel, className)}
      fit="content"
      bold={bold}
      size={size}
      iconClassName={cx(styles.sectionLabelIcon, props.iconClassName)}
      text={formatted}
    />
  );
};
