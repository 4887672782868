import { useRecoilValue } from "recoil";
import { filter, flatMap, map } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import pluralize from "pluralize";

import { Entity, Update } from "@api";

import { respectHandled, useWindowEvent } from "@utils/event";
import { cx } from "@utils/class-names";
import { fallback, Fn } from "@utils/fn";
import { ifDo } from "@utils/logic";

import { isCreateOrUpdate, referencesID } from "@state/store";
import {
  GenericItem,
  useDiscardUpdate,
  useProcessUpdates,
  useRetryUpdate,
} from "@state/generic";

import { Maybe, when } from "@utils/maybe";
import { uniqBy } from "@utils/array";

import { Label, SectionLabel } from "@ui/label";
import { Text } from "@ui/text";
import { Modal } from "@ui/modal";
import { CopyIcon, ExclamationTriangle, SpinnerIcon } from "@ui/icon";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { Container } from "@ui/container";
import { Button } from "@ui/button";
import { PropertyValuesList } from "@ui/property-values-list";
import { copyToClipboard } from "@ui/clipboard";
import { Dialog } from "@ui/dialog";

import styles from "./saving-queue.module.css";
import { format, fromTimestamp } from "@utils/date";
import { RelationLabel } from "./relation-label";

const SaveErrorDetails = ({ update }: { update: Update<Entity> }) => {
  const entity = useRecoilValue(GenericItem(update.id));
  const values = useMemo(
    () =>
      isCreateOrUpdate(update)
        ? uniqBy(update.changes, (c) => c.field, "last")
        : undefined,
    [update]
  );

  if (!values || !entity) {
    return <></>;
  }

  return (
    <VStack fit="container">
      <Text bold>
        Failed to {update.method} {update.source?.type}:
      </Text>
      {isCreateOrUpdate(update) && (
        <PropertyValuesList values={values} source={entity?.source} />
      )}
    </VStack>
  );
};

export const SaveErrorDialog = ({
  updates,
  onDismiss,
}: {
  updates: Maybe<Update<Entity>[]>;
  onDismiss: Fn<void, void>;
}) => {
  const retry = useRetryUpdate();
  const discard = useDiscardUpdate();

  const onDiscard = useCallback(() => {
    map(updates, (update) => discard(update));
  }, [updates]);

  const onRetry = useCallback(() => {
    map(updates, (update) => retry(update));
  }, [updates]);

  return (
    <Dialog
      title="Sync Error"
      description="There was an error syncing these changes. We cannot continue until it has been resolved."
      onDismiss={onDismiss}
      actions={
        <SpaceBetween>
          <Button
            icon={CopyIcon}
            subtle
            onClick={() => copyToClipboard(JSON.stringify(updates))}
          >
            Copy information
          </Button>

          <HStack gap={2}>
            <Button subtle onClick={onDiscard}>
              {updates?.length === 1 ? "Discard" : "Discard all"}
            </Button>
            <Button variant="primary" size="small" onClick={onRetry}>
              {updates?.length === 1 ? "Retry" : "Retry all"}
            </Button>
          </HStack>
        </SpaceBetween>
      }
    >
      <VStack gap={12} fit="container" className={styles.allChanges}>
        {map(updates, (update, i) => (
          <SaveErrorDetails
            key={`${update.id}${update.nextAttempt}${i}`}
            update={update}
          />
        ))}
      </VStack>
    </Dialog>
  );
};

export const SavingQueue = () => {
  const [open, setOpen] = useState(false);
  const [blocked, setBlocked] = useState<Maybe<Update<Entity>[]>>();

  const { saving, updating, unsaved } = useProcessUpdates();

  const incompleteUpdates = useMemo(
    () => [...unsaved, ...updating],
    [unsaved, updating]
  );

  const status = useMemo(
    () =>
      fallback(
        () => ifDo(saving, () => "Saving..."),
        () =>
          ifDo(
            !saving &&
              !!filter(incompleteUpdates, (u) => u.mode !== "temp").length,
            () => "Blocked..."
          )
      ),
    [incompleteUpdates, saving]
  );

  useEffect(() => {
    const blocked = filter(incompleteUpdates, (u) => !!u.attempt);
    const downstream = flatMap(blocked, (b) =>
      filter(
        incompleteUpdates,
        (u2) => (u2.id === b.id && b !== u2) || referencesID(u2, b.id)
      )
    );
    setBlocked([...blocked, ...downstream]);
  }, [incompleteUpdates]);

  useWindowEvent(
    "beforeunload",
    (e) => {
      if (saving) {
        e.stopImmediatePropagation();
        e.returnValue =
          "Changes are still being saved. Are you sure you want to leave?";
      }
    },
    false,
    [saving]
  );

  return (
    <>
      {!!blocked?.length && (
        <SaveErrorDialog updates={blocked} onDismiss={() => setBlocked([])} />
      )}
      <Modal
        mode="passive"
        open={true}
        padding="none"
        className={cx(!blocked?.length && styles.offscreen)}
      >
        <Container
          className={cx(
            styles.container,
            saving && styles.updating,
            open && styles.open
          )}
          padding="none"
          stack="vertical"
          align="stretch"
          fit="container"
          gap={0}
        >
          {open && (
            <FillSpace>
              <Container fit="container">
                <VStack>
                  {map(incompleteUpdates, (u, i) => (
                    <UpdateListItem
                      key={`${u.id}/${u.queued}`}
                      update={u}
                      onClick={() => setBlocked([u])}
                    />
                  ))}
                </VStack>
              </Container>
            </FillSpace>
          )}

          <Container
            onClick={() => setOpen(!open)}
            className={cx(styles.footer, open && styles.open)}
          >
            <SpaceBetween>
              <Label
                subtle
                icon={
                  saving
                    ? SpinnerIcon
                    : status && status?.includes("Block")
                    ? ExclamationTriangle
                    : undefined
                }
              >
                {status}
              </Label>
              {open && (
                <Text subtle>
                  {incompleteUpdates?.length}{" "}
                  {pluralize("change", incompleteUpdates?.length)}
                </Text>
              )}
            </SpaceBetween>
          </Container>
        </Container>
      </Modal>
    </>
  );
};

export const UpdateListItem = ({
  update: u,
  onClick,
}: {
  update: Update<Entity>;
  onClick?: Fn<void, void>;
}) => {
  return (
    <SpaceBetween
      direction="horizontal"
      fit="container"
      onClick={respectHandled(() => onClick?.())}
    >
      <HStack>
        <SectionLabel bold={false}>
          {when(u.persisted || u.queued, (d) =>
            format(fromTimestamp(d), "h:mma")
          ) || "Unknown"}
        </SectionLabel>
        <RelationLabel relation={{ id: u.id }} fit="container" />
      </HStack>

      {isCreateOrUpdate(u) && (
        <Text subtle className={cx(!!u.attempt && styles.red)}>
          {u.changes?.length} change
        </Text>
      )}
      {u.method === "delete" && <Text subtle>Deleting {u.source?.type}</Text>}
      {u.method === "restore" && <Text subtle>Restoring {u.source?.type}</Text>}
    </SpaceBetween>
  );
};
