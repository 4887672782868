import { ReactNode } from "react";

import styles from "./ellipsis.module.css";
import { cx } from "@utils/class-names";

interface Props {
  children: ReactNode;
  className?: string;
}

export const Ellipsis = ({ className, children }: Props) => (
  <div className={cx(styles.ellipsis, className)}>{children}</div>
);

export const TextStack = ({ children }: { children: ReactNode }) => (
  <Ellipsis className={styles.textStack}>{children}</Ellipsis>
);
