import { atomFamily, selectorFamily } from "recoil";

import { ID, Event } from "@api";

import {
  getItem,
  indexedDBStorageForStore,
  mergeItem,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { isDefault } from "@utils/recoil";

export type EventStoreState = StoreState<Event>;

export const WorkspaceEventStoreAtom = atomFamily<EventStoreState, ID>({
  key: "WorkspaceEventStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "event")],
});

export const EventStoreAtom = WorkspaceWrappedAtom(
  "EventStoreAtom",
  WorkspaceEventStoreAtom
);

export const EventAtom = selectorFamily({
  key: "EventAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(EventStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(EventStoreAtom, mergeItem<Event>(newValue));
      }
    },
});
