import { useState } from "react";
import { Outcome, PropertyRef } from "@api";

import { useLazyPropertyValue } from "@state/databases";

import { cx } from "@utils/class-names";
import { useSlowSelected } from "@utils/selectable";
import { toMutation } from "@utils/property-mutations";
import { whenEmpty } from "@utils/array";

import { HStack, SpaceBetween } from "@ui/flex";
import { PropertyValue } from "@ui/property-value";
import { EditableText } from "@ui/editable-text";
import { ChildWorkProgression } from "@ui/child-work-progression";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { SelectableListItem } from "@ui/selectable-items";
import { PropertyValueStack } from "@ui/property-value-stack";
import { DueDate } from "@ui/due-date";
import { SmartLocationLabel } from "@ui/location-button";
import { SubListButton, SubListItems } from "@ui/sub-list-items";

import { ListItemOpts } from "../types";

import styles from "./list-item.module.css";

const DEFAULT_PROPS: PropertyRef<Outcome>[] = [
  { field: "assigned", type: "relation" },
];

export const OutcomeListItem = (props: ListItemOpts<Outcome>) => {
  const {
    item,
    showProps,
    selection,
    setSelection,
    className,
    onChange,
    showCode = true,
  } = props;
  const [showSubs, setShowSubs] = useState(false);
  const status = useLazyPropertyValue(item, {
    field: "status",
    type: "status",
  });
  const editable = useSlowSelected(item.id, selection);

  return (
    <>
      <EntityContextMenu
        entity={item}
        selection={selection}
        setSelection={setSelection}
      >
        <SelectableListItem
          className={cx(styles.row, styles.task, className)}
          {...props}
        >
          <SpaceBetween className={styles.upper}>
            <HStack className={styles.middle} gap={4}>
              <SubListButton
                {...props}
                open={showSubs}
                setOpen={setShowSubs}
                field="refs.tasks"
              />
              <PropertyValue
                inset={true}
                variant="no-icon"
                size="tiny"
                valueRef={status}
                onChange={(v) =>
                  onChange?.(
                    toMutation(
                      item,
                      { field: "status", type: "status" },
                      v.status
                    )
                  )
                }
                source={item?.source}
              />

              <EditableText
                key={item.id}
                disabled={!editable}
                className={cx(
                  styles.title,
                  status?.value?.status?.group === "done" && styles.strike
                )}
                text={item.title || ""}
                placeholder="Untitled"
                blurOnEnter={true}
                onChange={(v) =>
                  onChange?.(
                    toMutation(item, { field: "title", type: "text" }, v)
                  )
                }
              />

              <SmartLocationLabel
                size="small"
                subtle
                location={item.location}
              />

              {item.end && (
                <DueDate
                  date={item.end}
                  status={status.value.status?.group}
                  onClick={() => props.onOpen?.(item)}
                />
              )}
            </HStack>

            <HStack className={styles.rowDetails} justify="flex-end" gap={2}>
              {!!item?.refs?.tasks?.length && (
                <ChildWorkProgression
                  parentId={item?.id}
                  property={{ field: "refs.tasks", type: "relations" }}
                />
              )}

              <PropertyValueStack
                item={item}
                props={whenEmpty(showProps, DEFAULT_PROPS)}
                blacklist={["status", "title", "icon"]}
                onChange={onChange}
                hideEmpty={props.hideEmpty}
              />
            </HStack>
          </SpaceBetween>
        </SelectableListItem>
      </EntityContextMenu>
      {showSubs && <SubListItems {...props} field={"refs.tasks"} />}
    </>
  );
};
