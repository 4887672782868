import { FilterQuery, Entity } from "@api/types";

import { Maybe, maybeMap, when } from "@utils/maybe";
import { isAnd, isOr } from "@utils/filtering";
import { ensureMany } from "@utils/array";
import { equalsAny } from "@utils/logic";
import { isAnyRelation } from "@utils/property-refs";
import { reduce } from "lodash";

type FilterExtension = (
  filter: Maybe<FilterQuery<Entity>>
) => Maybe<FilterQuery<Entity>>;

const asExtension = (ext: FilterExtension): FilterExtension => ext;

// Hack to fix the fact that some fields like refs.backlog and refs.sprint are different when defined
// vs when connected in the package flow. They become refs.backlogs (relations)
const REQUIRE_ALIASING = ["refs.backlog", "refs.sprint"];
export const aliasRelations = asExtension((filter) => {
  if (!filter) {
    return undefined;
  }

  if (isAnd(filter)) {
    return { and: maybeMap(filter.and, aliasRelations) };
  }

  if (isOr(filter)) {
    return { or: maybeMap(filter.or, aliasRelations) };
  }

  if (equalsAny(filter.field, REQUIRE_ALIASING)) {
    return {
      or: [
        filter,

        {
          ...filter,
          field: filter.field + "s",
          type: "relations",
          op: "contains",
          value: when(filter.value, (v) => ({
            relations: ensureMany(v.relation),
          })),
          values: when(filter.values, (vs) => ({
            relations: ensureMany(vs.relation),
          })),
        },
      ],
    };
  }

  return filter;
});

// When you filter on a relation field, you also want to include things that live WITHIN that relation
export const relationFilters = asExtension((filter) => {
  if (!filter) {
    return undefined;
  }

  if (isAnd(filter)) {
    return { and: maybeMap(filter.and, relationFilters) };
  }

  if (isOr(filter)) {
    return { or: maybeMap(filter.or, relationFilters) };
  }

  if (!isAnyRelation(filter)) {
    return filter;
  }

  return {
    or: [
      // Filter things that live within the relation
      ...maybeMap(
        ensureMany(filter.value?.[filter.type] || filter.values?.[filter.type]),
        (ref) => ({
          field: "location",
          op: "contains",
          type: "text",
          value: { text: ref.id },
        })
      ),

      // Or have the relation property set directly
      filter,
    ],
  } as FilterQuery;
});

export const extensions: FilterExtension[] = [aliasRelations, relationFilters];

export const applyExtensions = (filter: Maybe<FilterQuery<Entity>>) =>
  reduce(extensions, (f, ext) => ext(f), filter);
