import { map, orderBy } from "lodash";
import { useMemo } from "react";

import { Meeting } from "@api";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useMe } from "@state/persons";
import { useLazyEntity, useMarkAsSeen } from "@state/generic";
import { isHost, usePastMeetings } from "@state/meetings";

import { Maybe } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { useSyncPathnameID } from "@utils/url";
import { useShowMore } from "@utils/hooks";

import { usePageId } from "@ui/app-page";
import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { CheckIcon, ClockHistory, RelationIcon, Search } from "@ui/icon";
import { PaneItem, PaneManager } from "@ui/pane-manager";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import { PaneContainer, PaneHeader } from "@ui/pane-header";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { render, useEngine } from "@ui/engine";
import {
  MeetingPane,
  MeetingHostPane,
  MeetingLinksPane,
} from "@ui/engine/meeting";
import { MeetingActionsPane } from "@ui/engine/action/pane";
import { MeetingParticipantPane } from "@ui/engine/meeting/host-pane";
import { TemplatePaneManager } from "@ui/template-pane-manager";
import { ShowMoreMenuItem } from "@ui/menu-item";

import AppPage from "./app-page";

interface Props {
  meetingId: string;
}

const MeetingPage = ({ meetingId }: Props) => {
  const pageId = usePageId();
  const me = useMe();
  const meeting = useLazyEntity<"meeting">(meetingId);
  const hosting = useMemo(
    () => !!meeting && isHost(meeting, me),
    [meeting?.owner?.id, me?.id]
  );

  const [page] = useRegisterPage(meetingId, meeting);
  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(meetingId, meeting?.id);

  // Mark the note as seen by current user
  useMarkAsSeen(meetingId, pageId);

  if (!meeting) {
    return <>Not found.</>;
  }

  return (
    <AppPage page={page} loading={!meeting} title={meeting?.name}>
      <StackContainer>
        <SmartBreadcrumbSheet />

        <MeetingPane id={meetingId} item={meeting} />

        {!meeting.template && (
          <PaneManager size="secondary">
            {hosting && (
              <PaneItem id="host" title="Host Tools" icon={Search}>
                <MeetingHostPane meeting={meeting} />
              </PaneItem>
            )}

            {!hosting && <MeetingParticipantPane meeting={meeting} />}

            <PaneItem id="linked" title="Linked work" icon={RelationIcon}>
              <MeetingLinksPane meeting={meeting} />
            </PaneItem>

            <PaneItem
              id="actions"
              title="Actions"
              icon={<CheckIcon checked={true} color="red" />}
            >
              <MeetingActionsPane meeting={meeting} />
            </PaneItem>

            <PaneItem id="related" title="Past Meetings" icon={ClockHistory}>
              <RelatedMeetings meeting={meeting} />
            </PaneItem>
          </PaneManager>
        )}

        {!!meeting.template && (
          <TemplatePaneManager id={meetingId} entity={meeting} />
        )}
      </StackContainer>
    </AppPage>
  );
};

function RelatedMeetings({ meeting }: { meeting: Maybe<Meeting> }) {
  const goTo = useGoTo();
  const engine = useEngine("meeting");
  const past = usePastMeetings(meeting);
  const sorted = useMemo(() => orderBy(past, (r) => r.start, "desc"), [past]);
  const meetings = useShowMore(sorted, 6);

  if (!sorted.length) {
    return <></>;
  }

  return (
    <Sheet size="secondary" height="content">
      <PaneHeader title="Past Meetings" />

      <PaneContainer>
        <Menu>
          <MenuGroup>
            {map(meetings.visible, (meeting) =>
              render(engine.asMenuItem, {
                key: meeting.id,
                item: meeting,
                onOpen: goTo,
              })
            )}
            {meetings.hasMore && (
              <ShowMoreMenuItem
                count={meetings.moreCount}
                onClick={meetings.showMore}
              />
            )}
          </MenuGroup>
        </Menu>
      </PaneContainer>
    </Sheet>
  );
}

export default MeetingPage;
