import { CSSProperties, DOMAttributes, forwardRef, ReactNode } from "react";

import { cx } from "@utils/class-names";

import { HStack, StackVerticalProps, VStack } from "@ui/flex";

import styles from "./container.module.css";
import { equalsAny } from "@utils/logic";

export type Sides =
  | "vertical"
  | "horizontal"
  | "both"
  | "top"
  | "right"
  | "bottom"
  | "left"
  | "none";

export type Props = {
  padding?: Sides;
  size?: "full" | "half" | "double";
  inset?: Sides;
  stack?: "vertical" | "horizontal";
  gap?: number;
  fit?: "content" | "container";
  wrap?: boolean;
  height?: "content" | "container";
  width?: "content" | "container";

  className?: string;
  children: ReactNode;
  style?: CSSProperties;
} & ({} | ({ stack: "vertical" | "horizontal" } & StackVerticalProps)) &
  DOMAttributes<HTMLDivElement>;

export const Container = forwardRef<HTMLDivElement, Props>(
  (
    {
      padding = "both",
      inset = "none",
      stack,
      children,
      size = "full",
      className,
      fit = "container",
      ...props
    },
    ref
  ) => {
    const classes = cx(
      styles.container,
      styles[`${padding}Padded`],
      styles[`${size}Size`],
      styles[`${inset}Inset`],
      className
    );

    if (stack === "vertical") {
      return (
        <VStack className={classes} fit={fit} {...props}>
          {children}
        </VStack>
      );
    }

    if (stack === "horizontal") {
      return (
        <HStack className={classes} fit={fit} {...props}>
          {children}
        </HStack>
      );
    }

    const { width, height, style, ...rest } = props;

    return (
      <div
        ref={ref}
        className={classes}
        style={{
          display: "flex",
          flexDirection: "column",
          // Don't set width when horizontal insets are in play as they need to set width
          width:
            !equalsAny(inset, ["horizontal", "both", "left", "right"]) &&
            (width || fit) === "container"
              ? "100%"
              : "",
          height: height === "container" ? "100%" : "",
          ...style,
        }}
        {...rest}
      >
        {children}
      </div>
    );
  }
);

export const Centered = ({ className, ...rest }: Props) => (
  <Container className={cx(styles.centered, className)} {...rest} />
);
