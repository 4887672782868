import { first } from "lodash";
import { useMemo } from "react";
import { Action, Status } from "@api";

import { useCheckAction } from "@state/actions";
import { useLazyPropertyValue } from "@state/databases";

import { withHandle, withHardHandle } from "@utils/event";
import { switchEnum } from "@utils/logic";
import { Maybe } from "@utils/maybe";
import { cx } from "@utils/class-names";
import { asMutation, toMutation } from "@utils/property-mutations";
import { justOne } from "@utils/array";

import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Label } from "@ui/label";
import { MenuItem } from "@ui/menu-item";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { SelectableListCard, SelectableListItem } from "@ui/selectable-items";
import { CheckIcon, Icon } from "@ui/icon";
import { EditableText } from "@ui/editable-text";
import { PropertyValue } from "@ui/property-value";
import { SmartLocationLabel } from "@ui/location-button";
import { ConvertButton } from "./convert-dialog";
import { RelationLabel } from "@ui/relation-label";
import { GoToButton } from "@ui/go-to-button";

import { UIEngine } from "../types";

import styles from "./styles.module.css";

export const ActionEngine: UIEngine<Action> = {
  asMenuItem: function ({
    item,
    className,
    onChange,
    onOpen,
    showLocation = true,
    ...props
  }) {
    const onCheck = useCheckAction(item, onChange);
    const status = useLazyPropertyValue(item, {
      field: "status",
      type: "status",
    });
    const assignedProp = useLazyPropertyValue(item, {
      field: "assigned",
      type: "relation",
    });
    const done = useMemo(
      () => !!item.status?.id && item.status?.id !== "OPN",
      [item.status?.id]
    );
    const converted = useMemo(
      () => justOne(item.refs?.convertedTo),
      [item.refs?.convertedTo]
    );

    return (
      <EntityContextMenu entity={item}>
        <MenuItem
          {...props}
          className={cx(className, styles.menuItem)}
          onClick={() => onOpen?.(item)}
        >
          <SpaceBetween>
            <HStack gap={4}>
              <Icon
                onClick={withHardHandle(() => onCheck?.())}
                icon={<ActionCheckMark status={status.value.status} />}
              />
              {converted ? (
                <RelationLabel
                  relation={converted}
                  iconRight={<GoToButton item={converted} />}
                />
              ) : (
                <Label
                  text={item.title}
                  subtle={done}
                  fit="content"
                  className={cx(styles.wrap, done && styles.strikethrough)}
                />
              )}
              {showLocation && (
                <SmartLocationLabel
                  showTeam={false}
                  showIcons={false}
                  size="small"
                  subtle
                  location={justOne(item.refs?.meeting)?.id || item.location}
                />
              )}
            </HStack>

            <HStack gap={0}>
              {!done && !converted && <ConvertButton item={item} />}

              {assignedProp && (
                <PropertyValue
                  source={item.source}
                  valueRef={assignedProp}
                  variant="icon-only"
                  size="small"
                  onChange={(e) =>
                    onChange?.(asMutation(assignedProp, e[assignedProp.type]))
                  }
                />
              )}
            </HStack>
          </SpaceBetween>
        </MenuItem>
      </EntityContextMenu>
    );
  },
  // TODO:
  asListCard: (props) => (
    <EntityContextMenu entity={props.item}>
      <SelectableListCard {...props}>
        <VStack className={styles.bar}>
          <Label className={styles.title}>{props.item.title || "Action"}</Label>
        </VStack>
      </SelectableListCard>
    </EntityContextMenu>
  ),
  asListItem: function (props) {
    const { item, onChange } = props;
    const onCheck = useCheckAction(item, onChange);
    const assignedProp = useLazyPropertyValue(item, {
      field: "assigned",
      type: "relation",
    });
    const done = useMemo(
      () => !!item.status?.id && item.status?.id !== "OPN",
      [item.status?.id]
    );
    const converted = useMemo(
      () => first(item.refs?.convertedTo),
      [item.refs?.convertedTo]
    );

    return (
      <EntityContextMenu
        entity={item}
        selection={props.selection}
        setSelection={props.setSelection}
      >
        <SelectableListItem
          {...props}
          className={cx(styles.listItem, props.className)}
        >
          <SpaceBetween>
            <HStack gap={8}>
              <Icon
                onClick={withHandle(() => onCheck?.())}
                icon={<ActionCheckMark status={item.status} />}
              />

              {converted ? (
                <RelationLabel
                  relation={converted}
                  iconRight={<GoToButton item={converted} />}
                />
              ) : (
                <EditableText
                  key={item.id}
                  text={item.title || ""}
                  placeholder="New action..."
                  blurOnEnter={true}
                  className={cx(styles.wrap, done && styles.strikethrough)}
                  onChange={(v) =>
                    onChange?.(
                      toMutation(item, { field: "title", type: "text" }, v)
                    )
                  }
                />
              )}

              <SmartLocationLabel
                showIcons={false}
                showTeam={false}
                subtle
                size="small"
                location={justOne(item.refs?.meeting)?.id}
              />
            </HStack>

            <HStack gap={0}>
              {!done && !converted && (
                <ConvertButton item={item} selection={props.selection} />
              )}
              {assignedProp && (
                <PropertyValue
                  source={item.source}
                  valueRef={assignedProp}
                  variant="icon-only"
                  size="small"
                  onChange={(e) =>
                    onChange?.(asMutation(assignedProp, e[assignedProp.type]))
                  }
                />
              )}
            </HStack>
          </SpaceBetween>
        </SelectableListItem>
      </EntityContextMenu>
    );
  },
};

export const ActionCheckMark = ({ status }: { status: Maybe<Status> }) => {
  return switchEnum(status?.id || "", {
    OPN: () => <CheckIcon className={styles.actionCheck} checked={false} />,
    DNE: () => (
      <CheckIcon className={styles.actionCheck} checked={true} color="red" />
    ),
    CLS: () => (
      <CheckIcon className={styles.actionCheck} checked={true} color="gray" />
    ),
    CNV: () => (
      <CheckIcon className={styles.actionCheck} checked="moved" color="red" />
    ),
    else: () => <CheckIcon className={styles.actionCheck} checked={false} />,
  });
};
