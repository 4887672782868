import { cx } from "@utils/class-names";

import styles from "./styles.module.css";

export type RadioedState = boolean | "indeterminate" | "moved";

interface Props {
  checked: RadioedState;
  className?: string;
}

export const RadioIcon = ({ checked, className }: Props) => (
  <div
    className={cx(
      styles.radioIcon,
      (checked === true || checked === "moved") && styles.checked,
      checked === "indeterminate" && styles.indeterminate,
      className
    )}
  >
    {checked && <span className={styles.radioDot} />}
  </div>
);
