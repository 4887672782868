import { last, map } from "lodash";
import { useCallback, useMemo } from "react";

import {
  PropertyMutation,
  Entity,
  Update,
  DatabaseID,
  PropertyRef,
  EntityType,
  ViewLayout,
} from "@api";

import { useQueueUpdates, useReorderItems } from "@state/generic";
import { useInflatedPropertyValue } from "@state/databases";

import { cx } from "@utils/class-names";
import { useGoTo } from "@utils/navigation";
import { OneOrMany, reverse } from "@utils/array";
import { usePageSelection } from "@utils/selectable";
import { switchEnum } from "@utils/logic";
import { asUpdate } from "@utils/property-mutations";
import { GroupedItems } from "@utils/grouping";
import { whenNotEmpty } from "@utils/maybe";
import { useShowMore } from "@utils/hooks";

import { useAppPageContext } from "@ui/app-page";
import { Props as AddInputProps } from "@ui/add-entity-input";
import { AddEntityInput } from "@ui/add-entity-input";
import { ListItemOpts, render, useEngine } from "@ui/engine";
import { ShowMoreMenuItem } from "@ui/menu-item";
import { Section } from "@ui/section";
import { DropTarget } from "@ui/entity-drag-drop";
import { useSuggestedProps } from "@ui/suggested-props";
import { PropertyLabel } from "./property-label";

import styles from "./entity-list.module.css";

export type Props = Omit<
  ListItemOpts<Entity>,
  "key" | "item" | "onChange" | "onReorder" | "selection" | "setSelection"
> & {
  items: Entity[];
  limit?: number;
  group?: GroupedItems<Entity>;
  layout?: Extract<ViewLayout, "list" | "card">;
  editable?: boolean;
  onChange?: (t: OneOrMany<Update<Entity>>) => void;
  orderKey?: string;
  addButton?: Pick<
    AddInputProps,
    | "source"
    | "onAdded"
    | "autoFocus"
    | "defaults"
    | "placeholder"
    | "showLocation"
    | "size"
  >;
  className?: string;
};

const useDefaultProps = (
  source: DatabaseID,
  showProps: ListItemOpts<Entity>["showProps"]
) => {
  const suggested = useSuggestedProps();
  return useMemo(
    () =>
      whenNotEmpty(showProps, () => showProps) ||
      whenNotEmpty(suggested, () => suggested) ||
      switchEnum<EntityType, PropertyRef[]>(source?.type, {
        task: [{ field: "assigned", type: "relation" }],
        else: () => [],
      }),
    [source?.type, showProps, suggested]
  );
};

export const EntityList = ({
  items: _items,
  onOpen,
  addButton,
  limit = 5,
  editable = true,
  showLocation = true,
  group,
  layout,
  className,
  onChange,
  orderKey,
  showProps: _showProps,
  ...props
}: Props) => {
  const pageId = useAppPageContext();
  const mutate = useQueueUpdates(pageId);
  const onReorder = useReorderItems(
    addButton?.source?.type || _items[0]?.source?.type || "task",
    pageId,
    orderKey
  );
  const showProps = useDefaultProps(
    addButton?.source || _items[0]?.source,
    _showProps
  );
  const orderedShowProps = useMemo(
    () => (layout === "list" ? reverse(showProps) : showProps),
    [layout, showProps]
  );
  const goTo = useGoTo();
  const engine = useEngine(_items[0]?.source.type || "task");
  const {
    visible: items,
    showMore,
    hasMore,
    moreCount,
  } = useShowMore(_items, limit);
  const [selection, setSelection] = usePageSelection();

  const handleOnChange = useCallback(
    (item: Entity, change: OneOrMany<PropertyMutation<Entity>>) =>
      onChange
        ? onChange(asUpdate(item, change))
        : mutate(asUpdate(item, change)),
    [onChange, mutate]
  );

  return (
    <>
      <ul
        className={cx(
          styles.list,
          layout === "card" && styles.stackWrap,
          className
        )}
      >
        {map(items, (item) =>
          render(layout === "card" ? engine?.asListCard : engine?.asListItem, {
            key: item.id,
            ...props,
            item: item,
            group: group,
            showProps: orderedShowProps,
            showLocation: showLocation,
            variant:
              showProps?.length > 2
                ? "icon-only"
                : props.variant || "unlabelled",
            onReorder: onReorder,
            onChange: (c) => handleOnChange(item, c),
            onOpen: onOpen || goTo,
            selection: selection,
            setSelection: setSelection,
          })
        )}

        {hasMore && <ShowMoreMenuItem count={moreCount} onClick={showMore} />}

        {editable && addButton && (
          <DropTarget
            position="after"
            item={last(items)}
            type={addButton.source.type}
          >
            <AddEntityInput {...addButton} />
          </DropTarget>
        )}
      </ul>
    </>
  );
};

type EntityListGroup = Omit<Props, "items" | "group"> & {
  group: GroupedItems<Entity>;
  source: DatabaseID;
};

export const EntityListGroup = ({
  group,
  source,
  ...rest
}: EntityListGroup) => {
  return (
    <Section
      className={styles.group}
      title={
        <PropertyLabel
          subtle
          size="small"
          icon={false}
          valueRef={group.value}
          source={source}
        />
      }
    >
      <EntityList
        {...rest}
        items={(group as GroupedItems)?.items}
        group={group as GroupedItems}
      />
    </Section>
  );
};
