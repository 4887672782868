import { ReactNode } from "react";
import { cx } from "@utils/class-names";

import Breadcrumb, { Props as BreadcrumbProps } from "./breadcrumb";
import { HStack, SpaceBetween } from "./flex";

import styles from "./app-header.module.css";

type Props = {
  className?: string;
  border?: boolean;
  left?: ReactNode;
  right?: ReactNode;
} & Partial<BreadcrumbProps>;

export function AppHeader({ className, links, border, right, left }: Props) {
  return (
    <SpaceBetween
      className={cx(styles.header, border && styles.bordered, className)}
    >
      <HStack>{links ? <Breadcrumb links={links} /> : left}</HStack>
      <HStack align="center" gap={8}>
        {right}
      </HStack>
    </SpaceBetween>
  );
}
