import { RecoilState, useRecoilCallback } from "recoil";
import { useCallback } from "react";

import { EntityType } from "@api";

import { getStore, useAllStores } from "@state/generic";
import { PropertyDefStoreAtom } from "@state/databases";
import { JobStoreAtom } from "@state/jobs";
import { FetchResultsStoreAtom } from "@state/fetch-results";
import { AppSpaceAtom } from "@state/spaces";

import { pause, debug } from "@utils/debug";
import { switchEnum } from "@utils/logic";

export function useClearStore() {
  const reset = useRecoilCallback(({ reset }) => (store: RecoilState<any>) => {
    reset(store);
  });
  return useCallback(
    (type: EntityType | "fetchResults" | "props" | "jobs" | "space") => {
      try {
        switchEnum(type, {
          jobs: () => reset(JobStoreAtom),
          props: () => reset(PropertyDefStoreAtom),
          fetchResults: () => reset(FetchResultsStoreAtom),
          space: () => reset(AppSpaceAtom),
          else: () => {
            const Store = getStore(type as EntityType);
            reset(Store);
            reset(FetchResultsStoreAtom);
          },
        });
      } catch (err) {
        debug("Cant find store.", type);
      }
    },
    []
  );
}

export function useInspectStoresEffect() {
  const stores = useAllStores();
  return async () => {
    pause();
    return stores;
  };
}
