import { useMemo } from "react";
import { last, values } from "lodash";
import { toHTML } from "slack-markdown";

import { Message } from "@api/integrations/slack";

import { toDisplayName, useLookupPersonByAlias } from "@state/persons";

import { Maybe, when } from "@utils/maybe";
import { format } from "@utils/date";
import { cx } from "@utils/class-names";
import { replaceMentions } from "@utils/slack";
import { fallback } from "@utils/fn";

import { ImageIcon, PersonIcon } from "@ui/icon";
import { HStack, VStack } from "@ui/flex";

import styles from "./slack-message.module.css";

interface MessageProps {
  message: Message;
  className?: string;
}

interface TextProps {
  text: Maybe<string>;
  className?: string;
}

export const SlackMarkdown = ({ text, className }: TextProps) => (
  <span
    className={cx(styles.markdown, className)}
    dangerouslySetInnerHTML={{ __html: toHTML(text || "") }}
  />
);

export const SlackText = ({ text, className }: TextProps) => {
  const { toPerson } = useLookupPersonByAlias();
  const markdown = useMemo(
    () =>
      replaceMentions(text || "", (id) => when(toPerson(id), toDisplayName)),
    [text, toPerson]
  );

  return <SlackMarkdown className={className} text={markdown} />;
};

export const SlackMessage = ({ message }: MessageProps) => {
  const { toPerson } = useLookupPersonByAlias();
  const author = useMemo(
    () =>
      when(
        message.user ||
          (message.metadata?.event_payload as Record<string, string>)?.user,
        toPerson
      ),
    [message]
  );

  const username = useMemo(
    () =>
      author?.name ||
      message.bot_profile?.name ||
      message.user ||
      (message as Maybe<{ username?: string }>)?.username,
    [author, message]
  );

  const avatarIcon = useMemo(
    () =>
      fallback(
        () => author && <PersonIcon person={author} shape="square" />,
        () =>
          when(
            last(
              values(
                (message as Maybe<{ icons: Record<string, string> }>)?.icons
              )
            ),
            (url) => <ImageIcon url={url} />
          ),
        () =>
          message?.bot_profile?.icons &&
          when(last(values(message?.bot_profile?.icons)), (url) => (
            <ImageIcon url={url} />
          )),
        () => <></>
      ),
    [author, message]
  );

  return (
    <HStack className={styles.message}>
      <div className={styles.avatar}>{avatarIcon}</div>
      <VStack gap={0}>
        <HStack>
          <span className={styles.name}>{username}</span>
          <span className={styles.date}>
            {format(new Date(Number(message.ts) * 1000), "dd MMM")}
          </span>
        </HStack>
        <SlackText className={styles.body} text={message?.text} />
      </VStack>
    </HStack>
  );
};
