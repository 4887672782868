import { find, toLower } from "lodash";
import { useCallback, useEffect, useMemo } from "react";

import { ID, Team } from "@api";

import { useMe } from "@state/persons";
import { useActiveSpace } from "@state/spaces";
import { useActiveWorkspaceId } from "@state/workspace";

import { omitEmpty } from "@utils/array";
import { when } from "@utils/maybe";
import { titleCase } from "@utils/string";

import { Button } from "@ui/button";
import { AngleDownIcon, PanesFilled, TeamIcon } from "@ui/icon";
import { Select, SelectProps } from "@ui/select";
import { Text } from "@ui/text";

type Props = {
  value?: ID;
  onChanged: (scope: ID) => void;
  casing?: "lower" | "title";
  showMe?: boolean;
} & Omit<SelectProps, "value" | "onChange" | "options">;

export const SpaceFilterSelect = ({
  value,
  onChanged,
  showMe = false,
  casing = "title",
  children,
  ...props
}: Props) => {
  const wId = useActiveWorkspaceId();
  const me = useMe();
  const space = useActiveSpace();
  const toLabel = useCallback(
    (label: string) => (casing === "title" ? titleCase(label) : toLower(label)),
    [casing]
  );
  const options = useMemo(
    () =>
      omitEmpty([
        {
          id: space.id,
          name: "This Team",
          icon: <TeamIcon team={space.entity as Team} />,
        },
        {
          id: wId,
          name: "All Teams",
          icon: PanesFilled,
        },
        showMe
          ? {
              id: me.id,
              name: "Private",
            }
          : undefined,
      ]),
    [space.id]
  );
  const selected = useMemo(
    () => find(options, (o) => o.id === value),
    [value, options]
  );

  useEffect(() => {
    if (!value) {
      onChanged(space.id);
    }
  }, [value]);

  return (
    <Select
      {...props}
      value={selected}
      options={options}
      onChange={(v) => onChanged(v?.id || wId)}
    >
      {children || (
        <Button subtle inset size="small" iconRight={AngleDownIcon}>
          <Text subtle>{when(selected?.name || "Unknown", toLabel)}</Text>
        </Button>
      )}
    </Select>
  );
};
