import { addMinutes, getDay, startOfDay } from "date-fns";
import { last } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Entity,
  EntityType,
  ID,
  Meeting,
  Period,
  PropertyMutation,
  Schedule,
} from "@api";

import {
  CreateTemplateOpts,
  useCreateEntity,
  useCreateFromObject,
  useCreateFromTemplate,
  useEntitySource,
  useLazyEntity,
} from "@state/generic";
import { useActiveWorkspaceId } from "@state/workspace";
import { useSetting } from "@state/settings";

import {
  asMutation,
  asUpdate,
  flattenChanges,
} from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";
import { Maybe, required, safeAs, when } from "@utils/maybe";
import { formatShort } from "@utils/date";
import { typeFromId } from "@utils/id";
import { Fn } from "@utils/fn";
import { fromCalDate, now, toPointDate, useCalDate } from "@utils/date-fp";
import { toLocation } from "@utils/scope";
import { debug } from "@utils/debug";
import { toCalDate, useISODate } from "@utils/date-fp";
import { asTimezone } from "@utils/timezone";
import { toISOTime, toTime } from "@utils/time";

import { useMutate } from "@ui/mutate";

import { toNextDate } from "./utils";

export const useScheduleEntityType = (schedule: Maybe<Schedule>) => {
  return useMemo(
    () =>
      schedule?.entity ||
      when(
        schedule?.useTemplate?.id || last(schedule?.instances)?.id,
        typeFromId<EntityType>
      ),
    [schedule?.id, schedule?.entity, schedule?.useTemplate?.id]
  );
};

export const useCreateNextForSchedule = (
  scheduleId: ID,
  onCompleted?: Fn<void, void>
) => {
  const mutate = useMutate();

  const schedule = useLazyEntity<"schedule">(scheduleId);
  const childType = useScheduleEntityType(schedule);
  const childSource = useEntitySource(childType, schedule?.source);
  const template = useLazyEntity(schedule?.useTemplate?.id);
  const createNew = useCreateEntity(
    childType || "task",
    childSource?.scope || schedule?.source.scope || ""
  );

  // Next date to create for
  const next = useMemo(() => {
    if (!schedule || (schedule.useTemplate?.id && !template)) {
      return undefined;
    }

    return schedule.next || toNextDate(schedule);
  }, [schedule, template]);

  const overrides = useMemo(() => {
    if (!next) {
      return undefined;
    }

    const timezone = schedule?.timeZone;
    const instanceDate = timezone
      ? toPointDate(asTimezone(fromCalDate(next, "local"), timezone))
      : next;

    return [
      ...(childType === "content"
        ? [asMutation({ field: "publish", type: "date" }, instanceDate)]
        : childType === "meeting"
        ? [
            asMutation({ field: "start", type: "date" }, instanceDate),
            asMutation(
              { field: "end", type: "date" },
              useISODate(instanceDate, (d) =>
                addMinutes(d, safeAs<Meeting>(template)?.duration || 60)
              )
            ),
          ]
        : [
            asMutation({ field: "start", type: "date" }, instanceDate),
            asMutation({ field: "end", type: "date" }, instanceDate),
          ]),

      // Blank out schedules field which is used for the template only
      asMutation({ field: "refs.schedules", type: "relations" }, []),

      // Replace with the repeat field which is used for instances
      asMutation({ field: "refs.repeat", type: "relation" }, toRef(schedule)),

      ...((schedule?.overrides as Maybe<PropertyMutation[]>) || []),
    ] as PropertyMutation<Entity>[];
  }, [next, schedule?.id, template?.id, schedule?.timeZone]);

  const bumpScheduleNext = useCallback(
    // Update the next date on the schedule
    () => {
      if (!schedule) {
        throw new Error("Schedule should be here.");
      }

      mutate(
        asUpdate(schedule, [
          // Update from to be the previous next (held in reference)
          asMutation({ field: "last", type: "date" }, next),

          // Pre-calculate next date for the schedule
          asMutation(
            { field: "next", type: "date" },
            toNextDate({ ...schedule, last: next })
          ),
        ])
      );

      // All done
      onCompleted?.();
    },
    [schedule?.id, next]
  );

  const createTemplate = useCreateFromTemplate(
    childSource,
    bumpScheduleNext,
    useMemo(
      () =>
        when(overrides, (d) => ({
          // Append the date to the schedule name
          appendName:
            !!next && childSource?.type !== "meeting"
              ? ` (${useISODate(next, (d) => formatShort(d))})`
              : false,
          overrides: {
            [schedule?.useTemplate?.id || "*"]: when(d, flattenChanges) || {},
          },
        })),
      [overrides, next]
    )
  );

  const ready = useMemo(() => {
    if (!schedule) {
      return false;
    }

    // Creating from template
    if (schedule?.useTemplate?.id) {
      return !!childSource && !!template && createTemplate.ready;
    }

    // Creating from new
    return !!createNew && !!childSource && !!overrides;
  }, [
    schedule,
    createTemplate.ready,
    createNew,
    childSource,
    template,
    overrides,
  ]);

  const handleCreate = useCallback(
    (opts?: CreateTemplateOpts) => {
      if (!ready) {
        debug("Not ready to create next schedule job.");
        return;
      }

      if (schedule?.useTemplate?.id) {
        required(
          createTemplate.create,
          () => "Expecting create to be defined."
        )(
          required(template, () => "Expecting template to be defined."),
          opts
        );
      } else {
        createNew(
          required(overrides, () => "Expecting defaults to be defined.")
        );
        bumpScheduleNext();
      }
    },
    [ready, createTemplate.create, createNew, overrides, template, schedule]
  );

  return useMemo(
    () => ({
      create: handleCreate,
      ready,
      next,
      loading: createTemplate.loading,
    }),
    [handleCreate, ready, next, createTemplate.loading]
  );
};

export const useTempSchedule = (
  defaults: Maybe<Partial<Schedule>>,
  pageId?: string
) => {
  const wID = useActiveWorkspaceId();
  const defaultTZ = useSetting<string>(wID, "timezone");
  const scope = useMemo(
    () => defaults?.source?.scope || defaults?.location || wID,
    [defaults?.source?.scope]
  );
  const [scheduleId, setScheduleId] = useState<ID>();
  const schedule = useLazyEntity<"schedule">(scheduleId);

  const createTempSchedule = useCreateFromObject(
    "schedule",
    scope,
    pageId,
    true
  );

  // Create immediately
  useEffect(() => {
    if (!createTempSchedule) {
      return;
    }

    const entity = defaults?.entity || "task";
    const [s] =
      useCalDate(defaults?.from || now(), (from) =>
        createTempSchedule?.([
          {
            period: Period.Week,
            frequency: 1,
            precreate: 1,
            timeOfDay: undefined,
            timeZone: undefined,
            name: undefined,
            useTemplate: undefined,

            // Meetings carry time
            ...(entity === "meeting"
              ? { timeOfDay: toISOTime(toTime(from)), timeZone: defaultTZ }
              : {}),

            ...defaults,

            entity,
            from: toCalDate(startOfDay(from), "local"),
            daysOfPeriod: [getDay(from)],
            instances: [],
            location: toLocation(scope),
            source: { type: "schedule", scope },
          },
        ])
      ) || [];

    setScheduleId(s?.id);
  }, [!!createTempSchedule]);

  return schedule;
};
