import { selector } from "recoil";

import { ActiveUserAtom } from "./atoms";

export const activeUserId = selector({
  key: "activeUserId",
  get: ({ get }) => {
    const current = get(ActiveUserAtom);
    return current?.id;
  },
});
