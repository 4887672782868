import { reduce } from "lodash";

import { set } from "@utils/object";

import { PresenceStoreState } from "./atoms";
import { TractionPresence } from "./types";

export const setPresence =
  (presence: TractionPresence) => (state: PresenceStoreState) => ({
    ...state,
    lookup: {
      ...state.lookup,
      [presence.person]: presence,
    },
  });

export const setPresences =
  (presences: TractionPresence[]) => (state: PresenceStoreState) => ({
    ...state,
    lookup: reduce(presences, (acc, p) => set(acc, p.person, p), {
      ...state.lookup,
    }),
  });

export const mergePresenceState =
  (presences: Record<string, TractionPresence>) =>
  (state: PresenceStoreState) => ({
    ...state,
    lookup: {
      ...state.lookup,
      ...presences,
    },
  });
