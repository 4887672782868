import { useCommandState } from "cmdk";
import { useMemo } from "react";

export const useCommandSearch = () => {
  return useCommandState((state) => state.search)?.trim();
};

export const useIsSearching = () => {
  const search = useCommandSearch();
  return useMemo(() => search?.length > 0, [search?.length > 0]);
};
