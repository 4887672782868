import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { isDefined } from "@utils/maybe";

import { Button, Props as ButtonProps } from "./button";
import { CounterIcon, CounterProps } from "./icon";

import styles from "./counter-button.module.css";

type Props = {
  title?: string;
  state?: "selected" | "default" | "faint";
  onClick?: Fn<React.MouseEvent, void>;
} & Partial<CounterProps> &
  ButtonProps;

export const CounterButton = ({
  count,
  onClick,
  color,
  title,
  state,
  size = "small",
  children,
  ...rest
}: Props) => {
  const isSelected = state === "selected";
  return (
    <Button
      variant={isSelected ? "primary" : "secondary"}
      subtle={isSelected}
      size={size}
      icon={
        isDefined(count) ? (
          <CounterIcon count={count} color={isSelected ? "blue" : color} />
        ) : undefined
      }
      className={cx(
        styles.button,
        isSelected ? styles.primary : styles.secondary,
        state === "faint" && styles.subtle
      )}
      {...rest}
      onClick={onClick}
    >
      {title || children}
    </Button>
  );
};
