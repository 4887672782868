export function formatBytes(bytes: number, decimals: number = 2): string {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  const dm = decimals < 0 ? 0 : decimals;
  const size = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  return `${size} ${sizes[i]}`;
}

export function estimateMemoryCost(obj: any) {
  // Convert the object to a JSON string
  const jsonString = JSON.stringify(obj);

  // Calculate the size of the string in bytes
  const sizeInBytes = new TextEncoder().encode(jsonString).length;

  return sizeInBytes;
}
