import { useRecoilValue } from "recoil";
import { useCallback, useMemo } from "react";
import { find, groupBy, map } from "lodash";

import {
  Form,
  PropertyDef,
  FormPropDef,
  DatabaseID,
  Entity,
  isBaseScopeEntity,
  Ref,
  PropertyRef,
} from "@api";

import { PropertyDefStoreAtom, useLazyProperties } from "@state/databases";
import { useNestedSource } from "@state/generic";
import { useLazyFetchResults } from "@state/fetch-results";

import { Maybe, safeAs, when } from "@utils/maybe";
import { ensureMany, omitEmpty } from "@utils/array";
import {
  toChildLocation,
  toBaseScope,
  toParentScope,
  isMatch,
} from "@utils/scope";
import { equalsAny, ifDo } from "@utils/logic";

import { withFixedFields } from "./utils";

export const useFormFields = (
  form: Maybe<Form>
): [FormPropDef, Maybe<PropertyDef>][] => {
  const itemSource = useNestedSource(form, form?.entity);

  // TODO: Modify getPropertyDefs to allow fetching all properties of a given field (regardless of entitytype)
  // Then fetch all properties for the form's fields
  // Make sure fetched
  useLazyProperties(itemSource, false);

  const store = useRecoilValue(PropertyDefStoreAtom);
  const byField = useMemo(
    () => groupBy(store.lookup, (v) => v?.field),
    [store.lookup]
  );
  const getDef = useCallback(
    (ref: PropertyRef) => {
      if (!itemSource) {
        return;
      }

      return find(
        byField[ref.field],
        (p) =>
          !!p &&
          equalsAny(form?.entity, ensureMany(p.entity)) &&
          p.type === ref.type &&
          isMatch(itemSource.scope, p.scope)
      );
    },
    [store]
  );

  return useMemo(
    () =>
      map(
        withFixedFields(form?.fields),
        (f) =>
          [f, getDef(f as PropertyRef)] as [FormPropDef, Maybe<PropertyDef>]
      ),
    [form?.fields, getDef, itemSource?.type, itemSource?.scope]
  );
};

const isEntity = (thing: Maybe<Entity | DatabaseID>): thing is Entity =>
  !!safeAs<Entity>(thing)?.id;

export const useFormsForLocation = (
  entityOrSource: Maybe<Entity | DatabaseID>
) => {
  const source = useMemo(
    () => (isEntity(entityOrSource) ? entityOrSource.source : entityOrSource),
    [entityOrSource]
  );

  const scope = useMemo(() => {
    if (!source) return undefined;

    if (!isEntity(entityOrSource)) {
      return source.scope;
    }

    return toChildLocation(source.scope, entityOrSource.id);
  }, [entityOrSource]);

  const allForms = useLazyFetchResults(
    `forms-for-${scope}`,
    "form",
    useMemo(
      () => ({
        and: omitEmpty([
          {
            field: "template",
            type: "text",
            op: "equals",
            value: { text: "root" },
          },

          // Filter for forms that are in this root location
          when(scope, (scope) => ({
            field: "location",
            type: "text",
            op: "starts_with",
            value: { text: toBaseScope(scope) },
          })),

          // For non-base entities, we filter for forms that create in this location
          ifDo(!when(source?.type, isBaseScopeEntity), () => ({
            field: "inLocation",
            type: "text",
            op: "contains",
            value: { text: toParentScope(scope) },
          })),
        ]),
      }),
      [scope]
    ),
    { templates: true, archived: false }
  );

  return allForms;
};

export const useFormsForTemplate = (template: Ref) => {
  const allForms = useLazyFetchResults(
    `forms-for-template-${template?.id}`,
    "form",
    useMemo(
      () => ({
        and: omitEmpty([
          {
            field: "useTemplate",
            type: "relation",
            op: "equals",
            value: { relation: { id: template.id } },
          },
        ]),
      }),
      [template?.id]
    ),
    { templates: true, archived: false }
  );

  return allForms;
};
