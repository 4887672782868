import { ReactNode, useMemo } from "react";

import { Color, Shade } from "@api";

import { cx } from "@utils/class-names";
import { fromColor, toColor, toColorVar } from "@utils/color";

import styles from "./cover-image.module.css";

interface ImageProps {
  url?: string;
  className?: string;
  children?: ReactNode;
}

interface ColorProps {
  color?: Color;
  variant?: "gradient" | "solid";
  className?: string;
  children?: ReactNode;
}

export const CoverImage = ({
  url = "/IMG_cover-default.jpg",
  children,
  className,
}: ImageProps) => (
  <div
    className={cx(styles.cover, styles.imageGradient, className)}
    style={
      url ? { backgroundImage: `url(${url})`, backgroundSize: "cover" } : {}
    }
  >
    {children}
  </div>
);

export const CoverColor = ({
  color = "blue_3",
  variant = "solid",
  children,
  className,
}: ColorProps) => {
  const [c, c2] = useMemo(() => {
    const [base, shade] = fromColor(color);
    return [
      toColor(base, shade),
      toColor(base, (((shade + 3) % 5) + 1) as Shade),
    ] as const;
  }, [color]);

  return (
    <div className={cx(styles.cover, className)}>
      <div
        className={cx(styles.colorBg)}
        // Linear gradient from top to bottom white
        style={{
          background:
            variant === "gradient"
              ? `linear-gradient(180deg, ${toColorVar(c)} 0%, white 90%)`
              : toColorVar(c),
        }}
      ></div>
      {children}
    </div>
  );
};
