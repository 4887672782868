import { ReactNode, useCallback } from "react";

import { ComponentOrNode } from "@utils/react";

import { AngleDownIcon, AngleRightIcon, ArrowDown, Icon } from "@ui/icon";
import { HStack, SpaceBetween } from "@ui/flex";

import { MenuItem, MenuItemProps } from "./default";

import styles from "./styles.module.css";
import { Button } from "@ui/button";
import { Props as IconProps } from "@ui/icon";
import { useStickyState } from "@utils/hooks";
import { cx } from "@utils/class-names";
import { respectHandled, withHardHandle } from "@utils/event";
import { Fn } from "@utils/fn";

type Props = Omit<MenuItemProps, "onClick" | "text" | "icon"> &
  Pick<IconProps, "size"> & {
    saveKey: string;
    text: string | ReactNode;
    icon: ReactNode;
    exapandable?: boolean;
    onClick: Fn<void, void>;
    children: ReactNode;
  };

export function ExpandableMenuItem({
  saveKey,
  text,
  children,
  className,
  onClick,
  exapandable = true,
  icon,
  size,
  ...props
}: Props) {
  const [expanded, setExpanded] = useStickyState<boolean>(
    false,
    `expandable-menu-item-${saveKey}`
  );
  const toggleExpanded = useCallback(() => setExpanded(!expanded), [expanded]);

  return (
    <>
      <MenuItem
        {...props}
        onClick={respectHandled(() => onClick?.())}
        className={cx(styles.expandable, className)}
      >
        <HStack fit="container" gap={6}>
          {exapandable ? (
            <div className={styles.iconContainer}>
              {icon}
              <Button
                className={styles.iconButton}
                subtle
                size="small"
                icon={expanded ? AngleDownIcon : AngleRightIcon}
                onClick={withHardHandle(toggleExpanded)}
              />
            </div>
          ) : (
            icon
          )}
          {text}
        </HStack>
      </MenuItem>
      {expanded && children}
    </>
  );
}
