import { RefObject } from "react";
import {
  ConnectDragSource,
  ConnectDropTarget,
  useDrag,
  useDrop,
} from "react-dnd";

import { Maybe } from "@utils/maybe";

export type DropPosition = "between" | "before" | "after";

export type DragRef<T> = {
  item: T;
  order: number;
};

export type DragToRef<T> = {
  item?: T;
  order?: number;
  position: DropPosition;
};

interface DragProps<T> {
  item: T;
  order?: number;
  onReorder: (t: DragRef<T>, to: DragToRef<T>) => void;
  ref: RefObject<HTMLDivElement | HTMLLIElement>;
  connect?: ConnectDropTarget | ConnectDragSource;
}

type DropProps<T> = {
  item?: T;
  order?: number;
  ref: RefObject<HTMLDivElement | HTMLLIElement>;
  connect?: ConnectDropTarget | ConnectDragSource;
};

export const useGenericDrag = <T>({
  item,
  order,
  onReorder,
  ref,
  connect,
}: DragProps<T>) => {
  const [{ opacity }, dragRef] = useDrag<
    DragRef<T>,
    DragToRef<T>,
    { opacity?: number }
  >(
    () => ({
      type: "item",
      item: () => ({
        item: item,
        order: order ?? 0,
      }),

      collect: (monitor) => (monitor.isDragging() ? { opacity: 0.5 } : {}),
      end: (_dragged, monitor) => {
        const target = monitor.getDropResult();
        const item = monitor.getItem();
        if (target) {
          onReorder?.(item, target);
        }
      },
    }),
    [item, onReorder]
  );

  if (connect) {
    connect(dragRef(ref));
  } else {
    dragRef(ref);
  }

  return [{ opacity }, dragRef] as [{ opacity: number }, ConnectDragSource];
};

export const useGenericDrop = <T>({
  item,
  order,
  connect,
  ref,
}: DropProps<T>) => {
  const [{ dropping }, dropRef] = useDrop<
    DragRef<T>[],
    DragToRef<T>,
    { dropping: Maybe<DropPosition> }
  >(
    () => ({
      accept: "item",
      canDrop: (_t, m) => m.isOver({ shallow: true }),
      collect: (monitor) => ({
        dropping: monitor.canDrop() && monitor.isOver() ? "before" : undefined,
      }),
      drop: () => ({
        item: item,
        order: order,
        position: "before",
      }),
    }),
    [item]
  );

  if (connect) {
    connect(dropRef(ref));
  } else {
    dropRef(ref);
  }

  return [{ dropping }, dropRef] as [
    { dropping: Maybe<DropPosition> },
    ConnectDropTarget
  ];
};

export const useGenericDragDrop = <T>(props: DragProps<T>) => {
  const [dragProps, dragRef] = useGenericDrag<T>(props);
  const [dropProps] = useGenericDrop<T>({ ...props, connect: dragRef });

  return { ...dragProps, ...dropProps };
};
