import { filter, map, uniq } from "lodash";
import DataLoader from "dataloader";

import {
  isError,
  Entity,
  EntityType,
  ID,
  PropertyFilter,
  PropertyRef,
} from "@api";
import * as api from "@api";
import { EntityForType } from "@api/mappings";

import { cachedFuncByWorkspace } from "@state/workspace";

import { debug } from "@utils/debug";
import { toMilliSeconds } from "@utils/time";
import { asTimestamp } from "@utils/date";
import { Fn } from "@utils/fn";
import { toConnectingRefsProp } from "@utils/property-refs";
import { typeFromId } from "@utils/id";
import { ifDo } from "@utils/logic";
import { omitEmpty } from "@utils/array";
import { ISODate } from "@utils/date-fp";
import { safeAs } from "@utils/maybe";

const entityLoader = new DataLoader(
  async (ids: readonly string[]) => {
    const uniqIds = uniq(ids);
    const items = await api.getByIds(uniqIds || []);
    // const hashed = maybeLookup(items, (i) =>
    //   i && isError(i) ? undefined : i.id
    // );
    return map(ids, (id) => {
      const uindex = uniqIds?.indexOf(id);
      return items[uindex];
    });
  },
  // Just use to combine individual fetch requests into one
  { cache: false }
);

const getByIds = async (
  ids: ID[],
  onceLoaded?: Fn<Entity[], void>
): Promise<Entity[]> => {
  const all = await entityLoader.loadMany(ids);
  // TODO: Need to show errors on missing entities...
  const loaded = filter(all, (l) => !isError(l)) as Entity[];

  if (loaded?.length) {
    onceLoaded?.(loaded);
  }

  return loaded;
};

const getById = async (id: ID, after?: Date, onceLoaded?: Fn<Entity, void>) => {
  const loaded = await entityLoader.load(id);

  if (isError(loaded)) {
    if (loaded.code !== "deleted" && loaded.handle !== "discard") {
      debug(loaded);
      throw new Error(loaded.message);
    }
    return undefined;
  }

  // Refetch if stale
  if (!!after && asTimestamp(loaded.updatedAt) < asTimestamp(after)) {
    entityLoader?.clear(id);
    await getById(id, undefined, onceLoaded);
  } else {
    onceLoaded?.(loaded);
  }
};

export const getEntityLoader = cachedFuncByWorkspace(
  () => getById,
  toMilliSeconds("10 seconds"),
  ([t, after, _cb]) => t + after?.getTime()
);

export const getEntitiesLoader = cachedFuncByWorkspace(
  () => getByIds,
  toMilliSeconds("10 seconds"),
  ([ts, _fn]) => ts?.join?.(",") || ""
);

export const getOptimizedForFilter = cachedFuncByWorkspace(
  () => api.getOptimizedForFilter,
  toMilliSeconds("10 seconds"),
  ([t, f]) => t.scope + t.type + JSON.stringify(f)
);

export const getEntitiesForSearch = cachedFuncByWorkspace(
  () => api.getForSearch,
  toMilliSeconds("10 seconds"),
  ([t, q]) => t + q
);

const getItemsNestedWithin = async <T extends EntityType>(
  id: ID,
  source: { type: T; scope?: string },
  since?: ISODate,
  onItems?: Fn<EntityForType<T>[], void>
): Promise<ID[]> => {
  const results = await api.getOptimizedForFilter(
    source,
    {
      or: [
        {
          field: "location",
          type: "text",
          op: "contains",
          value: { text: id },
        },
      ],
    },
    { since }
  );

  if (results.changed?.length) {
    onItems?.(safeAs<EntityForType<T>[]>(results.changed) || []);
  }

  return results.all;
};

export const getItemsNestedWithinLoader = cachedFuncByWorkspace(
  () => getItemsNestedWithin,
  toMilliSeconds("10 seconds"),
  ([id, source]) => id + source.type + source.scope
);

export const getItemsWithin = async <T extends EntityType>(
  id: ID,
  source: { type: T; scope?: string },
  parentProp: PropertyRef,
  callback?: Fn<EntityForType<T>[], void>
): Promise<EntityForType<T>[]> => {
  const type = typeFromId<EntityType>(id);

  const results = await api.getForFilter(source, {
    or: omitEmpty([
      {
        field: "location",
        type: "text",
        op: "contains",
        value: { text: id },
      },

      // When parent prop passed in, use it to find things...
      ifDo(!!parentProp && source.type !== "view", () => ({
        field: parentProp.field,
        type: parentProp.type,
        op: "equals",
        value:
          parentProp.type === "relations"
            ? { relations: [{ id }] }
            : { relation: { id } },
      })),

      // Some default schemas are currently not following the connected syntax
      ...(ifDo(!parentProp && source.type !== "view", (): PropertyFilter[] => [
        {
          field: `refs.${type}`,
          type: "relation",
          op: "equals",
          value: { relation: { id } },
        },
        {
          // Pluralized field namess
          ...toConnectingRefsProp(type),
          op: "contains",
          value: { relations: [{ id }] },
        },
      ]) || []),
    ]),
  });

  callback?.(results);

  return results;
};

export const getItemsWithinLoader = cachedFuncByWorkspace(
  () => getItemsWithin,
  toMilliSeconds("10 seconds"),
  ([id, source, prop]) => id + source.type + source.scope + prop?.field
);
