import { find } from "lodash";
import { Schedule } from "@api";

import { findAvailableStatuses } from "@state/databases";
import { WorkflowDefinition, WorkflowDefinitionConfig } from "@state/workflows";

import { asMutation, asUpdate } from "@utils/property-mutations";

import { PauseCircle, PlayCircle } from "@ui/icon";
import { SchedulePublishDialog } from "@ui/engine/schedule";

export const setScheduleLive: WorkflowDefinition<Schedule> = {
  id: "setScheduleLive",
  trigger: "ACTION",
  type: "schedule",
  icon: PlayCircle,
  title: "Set Live",

  allowed: ({ entity }) => entity.status?.group === "planning",

  collect({ data, onCollected, onCancelled, context }) {
    return (
      <SchedulePublishDialog
        scheduleId={data.entity.id}
        onComplete={() => onCollected([])}
        onCancel={onCancelled}
      />
    );
  },

  execute: ({ entity }, context) => {
    return [];
  },
};

export const pauseSchedule: WorkflowDefinition<Schedule> = {
  id: "pauseSchedule",
  trigger: "ACTION",
  type: "schedule",
  icon: PauseCircle,
  title: "Pause",
  variant: "secondary",

  allowed: ({ entity }) => entity.status?.group === "in-progress",

  execute: ({ entity }, context) => {
    const statuses = findAvailableStatuses(context.props, entity.source);
    const status = find(statuses, (s) => s.group === "planning");

    if (!status) {
      return;
    }

    return asUpdate(
      entity,
      asMutation({ field: "status", type: "status" }, status)
    );
  },
};

export const definitions: WorkflowDefinitionConfig<Schedule> = {
  actions: [setScheduleLive, pauseSchedule],
  suggestions: [],
};

export default definitions;
