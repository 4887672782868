import { useCallback, useState } from "react";
import { map } from "lodash";

import { createPage, Entity, ID, PropertyMutation, Task, Update } from "@api";
import { ParentReference } from "@api/integrations/notion";

import { useLazyWorkspace } from "@state/workspace";
import { useEntitySettings } from "@state/settings";
import {
  useCreateFromObject,
  useLazyEntity,
  useQueueUpdates,
} from "@state/generic";

import { openInNewTab } from "@utils/url";
import { fallback } from "@utils/fn";
import { ensureArray } from "@utils/array";
import { Maybe, when } from "@utils/maybe";
import { newID } from "@utils/id";
import { fallbackPropertyValue } from "@utils/property-refs";
import { toNotionUrl } from "@utils/notion";
import { toUpdate } from "@utils/property-mutations";
import { toMarkdown } from "@utils/rich-text";
import { extractTeam } from "@utils/scope";

import { toTaskUpdate } from "./utils";

/*
 * Proxies to new generic code
 */

export function useCreateTaskInScope(scope: string, pageId?: ID) {
  return useCreateFromObject("task", scope, pageId);
}

export function useQueueTaskUpdate(pageId?: ID) {
  return useQueueUpdates(pageId);
}

/*
 * Task specific effects
 */

export function useUpdateTask(pageId?: ID) {
  const mutate = useQueueUpdates<Task>(pageId);

  return useCallback(
    (
      task: Task | Task[],
      changes: PropertyMutation<Task> | PropertyMutation<Task>[],
      transaction: ID = newID()
    ) =>
      mutate(
        map(ensureArray(task), (t) =>
          toTaskUpdate(t, ensureArray(changes), transaction)
        )
      ),
    [mutate]
  );
}

// TODO: Make generic so that can be used for projects, teams, etc
// Also need to figure out how to find where to create in notion...
export function useConvertToNotion(taskId: ID) {
  const [converting, setConverting] = useState(false);
  const workspace = useLazyWorkspace();
  const task = useLazyEntity<"task">(taskId);
  const project = useLazyEntity<"project">(task?.refs?.projects?.[0]?.id || "");
  const team = useLazyEntity<"team">(extractTeam(project?.source.scope) || "");
  const update = useQueueTaskUpdate();

  const convert = useCallback(async () => {
    const parent = fallback(
      (): Maybe<ParentReference> =>
        when(
          fallbackPropertyValue([project, team, workspace], {
            field: "settings.task_db",
            type: "text",
          })?.text,
          (id) => ({ database_id: id, type: "database_id" })
        ),

      (): Maybe<ParentReference> =>
        when(team?.notionId, (id) => ({ page_id: String(id), type: "page_id" }))
    );

    if (!parent) {
      throw new Error("Task db not configured for Notion.");
    }

    if (task && !task.notionId) {
      setConverting(true);
      const { id } = await createPage(
        parent,
        task.title || "New task",
        toMarkdown(task.body)
      );
      const notionUrl = toNotionUrl(id);

      // Save notionId to DB
      update([
        toUpdate(task, { field: "notionId", type: "text" }, id),
        toUpdate(task, { field: "links", type: "links" }, [
          ...(task.links || []),
          { text: "Task description", url: notionUrl },
        ]),
      ] as Update<Entity>[]);

      // Open automatically (can add to setttings?)
      openInNewTab(notionUrl);

      setConverting(false);
    }
  }, [task]);

  const revert = useCallback(async () => {
    if (task && !task.notionId) {
      setConverting(true);

      // Remove notionID from the task
      update([
        toUpdate(task, { field: "notionId", type: "text" }, undefined),
      ] as Update<Entity>[]);

      setConverting(false);
    }
  }, [task]);

  return { convert, revert, converting };
}

// TODO: Refactor to use useEntityParents ?
export function useLazySettings(id: ID) {
  const task = useLazyEntity<"task">(id);
  useLazyEntity(task?.refs?.projects?.[0]?.id || "");
  return useEntitySettings(id);
}
