import { Fn } from "@utils/fn";

import { Button } from "@ui/button";
import { LinkIcon } from "@ui/icon";

interface ButtonProps {
  text?: string;
  url: string;
  icon?: string;
  className?: string;
  onClick?: Fn<void, void>;
}

export { LinkIcon } from "@ui/icon";

export const LinkButton = ({
  url,
  icon,
  className,
  onClick,
  ...props
}: ButtonProps) => {
  return (
    <Button
      subtle
      size="small"
      icon={icon ? <LinkIcon icon={icon} url={url} /> : undefined}
      className={className}
      {...props}
    />
  );
};
