import { useMemo } from "react";
import { PropertyDef, PropertyType } from "@api";

import { justOne } from "@utils/array";
import { switchEnum } from "@utils/logic";

import {
  AtIcon,
  BlockingTasksIcon,
  BoxOpen,
  Calendar,
  CalendarDue,
  CalendarStart,
  ChecklistIcon,
  ClockHistory,
  Dollar,
  Percent,
  Emoji,
  Home,
  Icon,
  LinkAlt,
  NumberIcon,
  ProjectIcon,
  RelationIcon,
  StatusProp,
  SubtasksIcon,
  TagAlt,
  TaskAdd,
  TextAlt,
  UserIcon,
} from "@ui/icon";

type Props = {
  type: PropertyType;
  field?: string;
  format?: PropertyDef["format"];
  options?: PropertyDef["options"];
};

export const PropertyTypeIcon = ({ type, format, field, options }: Props) => (
  <Icon
    icon={useMemo(
      () =>
        switchEnum(format || options?.format || "", {
          dollar: Dollar,
          percent: Percent,
          else: () => undefined,
        }) ||
        switchEnum(justOne(options?.references) || "", {
          person: UserIcon,
          project: ProjectIcon,
          resource: BoxOpen,
          schedule: ClockHistory,
          task: TaskAdd,
          else: () => undefined,
        }) ||
        switchEnum(field || "", {
          location: Home,
          id: NumberIcon,
          assigned: UserIcon,
          owner: UserIcon,
          email: AtIcon,
          icon: Emoji,
          start: CalendarStart,
          end: CalendarDue,
          "refs.resources": BoxOpen,
          "refs.tasks": TaskAdd,
          "refs.subtasks": SubtasksIcon,
          "refs.blockedBy": BlockingTasksIcon,
          "refs.blocks": BlockingTasksIcon,
          else: () => undefined,
        }) ||
        switchEnum(type, {
          multi_select: () => TagAlt,
          select: () => TagAlt,
          status: () => StatusProp,
          number: () => NumberIcon,
          person: () => UserIcon,
          boolean: () => ChecklistIcon,
          checklist: () => ChecklistIcon,
          relation: () => RelationIcon,
          relations: () => RelationIcon,
          links: () => LinkAlt,
          date: () => Calendar,
          text: () => TextAlt,
          else: () => TextAlt,
        }),
      [field, type, options?.references]
    )}
  />
);
