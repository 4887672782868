import { useRecoilState } from "recoil";
import { ViewQuickFilterAtom } from "./atoms";
import { useMemo } from "react";

export const useQuickSearch = (viewId: string) => {
  const [quickFilter] = useRecoilState(ViewQuickFilterAtom(viewId));
  return useMemo(
    () => ({
      query: quickFilter.search,
      isSearching: !!quickFilter?.search?.trim(),
    }),
    [quickFilter]
  );
};
