import { Dictionary, flatten, keys, map, reduce, uniq, values } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Entity, EntityType, Ref } from "@api";

import {
  useLazyEntities,
  useManyNestedEntities,
  useQueueUpdates,
} from "@state/generic";

import { Fn } from "@utils/fn";
import { mask, maybeValues } from "@utils/object";
import { maybeMap } from "@utils/maybe";
import { plural } from "@utils/string";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { newID } from "@utils/id";

import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { FillSpace, HStack } from "@ui/flex";
import { Check, SpinnerIcon } from "@ui/icon";
import { Label } from "@ui/label";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { CheckMenuItem, MenuItem } from "@ui/menu-item";
import { Dialog } from "@ui/dialog";

interface Props {
  targets: Ref[];
  onComplete?: Fn<void, void>;
  onCancel?: Fn<void, void>;
}

export const RestoreDialog = ({ targets, onCancel, onComplete }: Props) => {
  const pageId = usePageId();
  const entities = useLazyEntities(targets);
  const parentTypes = useMemo(
    () => uniq(maybeMap(entities, (e) => e.source?.type)),
    [entities]
  );
  const { children, loading } = useManyNestedEntities(entities);
  const queue = useQueueUpdates(pageId);
  const [opts, setRestore] = useState<Partial<Record<EntityType, boolean>>>({});

  const hasChildren = useMemo(
    () => maybeValues(children as Record<EntityType, Entity[]>)?.length > 0,
    [children]
  );

  const handleSubmit = useCallback(() => {
    if (!entities?.length) {
      return;
    }

    const allChildren = flatten(
      values(mask(children as Dictionary<Entity[]>, opts))
    );

    const transaction = newID();
    const changes = [
      asMutation({ field: "archivedAt", type: "date" }, undefined),
      asMutation({ field: "archivedBy", type: "relation" }, undefined),
    ];

    // Restore entity + all nested work
    queue(
      map([...entities, ...allChildren], (e) =>
        asUpdate(e, changes, transaction)
      )
    );

    // Callback
    onComplete?.();
  }, [queue, entities, opts]);

  useEffect(() => {
    setRestore(
      reduce(
        keys(children),
        (acc, k) => ({ ...acc, [k]: true }),
        {} as Partial<Record<EntityType, boolean>>
      )
    );
  }, [children]);

  if (!entities?.length) {
    return <></>;
  }

  return (
    <Dialog
      onDismiss={() => onCancel?.()}
      title={"Confirm restore"}
      description="Restore this work and all nested work?."
      actions={
        <HStack gap={4} fit="container" justify="flex-end">
          <Button onClick={() => onCancel?.()}>Cancel</Button>
          <Button variant="primary" onClick={handleSubmit}>
            Restore all
          </Button>
        </HStack>
      }
    >
      <Container stack="vertical" padding="none" gap={20}>
        <FillSpace direction="vertical" fit="container">
          <Container gap={20} stack="vertical" fit="container" padding="none">
            <Menu>
              {loading && (
                <MenuGroup>
                  <Label icon={SpinnerIcon}>Collecting nested work...</Label>
                </MenuGroup>
              )}
              {!loading && (
                <MenuGroup>
                  <CheckMenuItem checked={true} disabled onChecked={() => {}}>
                    Restore {entities?.length} selected{" "}
                    {map(parentTypes, (t) => plural(t, entities?.length))?.join(
                      "/"
                    )}
                  </CheckMenuItem>

                  {!hasChildren && (
                    <MenuItem disabled icon={Check} text="No nested work" />
                  )}

                  {map(children, (values, type) => (
                    <CheckMenuItem
                      key={type}
                      checked={opts[type as EntityType] ?? false}
                      onChecked={() =>
                        setRestore((d) => ({
                          ...d,
                          [type]: !(opts[type as EntityType] ?? false),
                        }))
                      }
                    >
                      Restore {values?.length || 0} nested{" "}
                      {plural(type, values?.length || 0)}
                    </CheckMenuItem>
                  ))}
                </MenuGroup>
              )}
            </Menu>
          </Container>
        </FillSpace>
      </Container>
    </Dialog>
  );
};
