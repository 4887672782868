import { map } from "lodash";

import { toLabel, useLazyMyTeams } from "@state/teams";

import { useGoTo } from "@utils/navigation";

import { CommandGroup, CommandItem } from "@ui/command-menu";
import { RelationIcon } from "@ui/relation-label";

import { AppCommandsProps } from "./types";
import { useCommandSearch } from "./utils";

export const TeamsCommands = ({ mode }: AppCommandsProps) => {
  const goTo = useGoTo();
  const teams = useLazyMyTeams();
  const query = useCommandSearch();

  if (mode === "searching" && query.length > 0) {
    return <></>;
  }

  return (
    <CommandGroup label="Teams">
      {map(teams, (t) => (
        <CommandItem
          key={t.id}
          value={`go to ${toLabel(t)} team ${t.id}`}
          icon={<RelationIcon relation={t} />}
          onClick={() => goTo(t)}
        >
          {toLabel(t)}
        </CommandItem>
      ))}
    </CommandGroup>
  );
};
