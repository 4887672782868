import { omit } from "lodash";
import { ID } from "@api";
import { now } from "@utils/date-fp";
import { RecentsState } from "./atoms";

export const addToRecents =
  (id: ID) =>
  (state: RecentsState): RecentsState => ({
    ...state,
    lookup: { ...state.lookup, [id]: now() },
    updatedAt: now(),
  });

export const removeFromRecents =
  (id: ID) =>
  (state: RecentsState): RecentsState => ({
    ...state,
    lookup: omit(state.lookup, id),
    updatedAt: now(),
  });
