import { reduce, sample } from "lodash";
import { useMemo } from "react";

import { Maybe } from "@utils/maybe";

import { SectionLabel } from "@ui/label";
import { Text } from "@ui/text";
import { VStack } from "./flex";

interface Props {
  relevantTo?: string[];
  tip?: string;
}

const TIPS: Record<string, string[]> = {
  general: [
    "The most effective way to scope priority is by making sure that everyone is one the same page.",
  ],
  task: [
    "You can quickly assign tasks to people by mentioning them in the task title.",
  ],
};

export const EducationTip = ({ relevantTo, tip: _tip }: Props) => {
  const tip = useMemo(
    () =>
      _tip ||
      reduce(
        relevantTo,
        (r, tag) => r || sample(TIPS[tag]) || sample(TIPS["general"]),
        undefined as Maybe<string>
      ),
    [_tip, relevantTo]
  );
  return (
    <VStack gap={0}>
      <SectionLabel>Tip</SectionLabel>
      <Text subtle>{tip}</Text>
    </VStack>
  );
};
