import { useCallback, useMemo, useState } from "react";
import { isString, map, orderBy } from "lodash";

import {
  DatabaseID,
  FilterQuery,
  PropertyRef,
  RelationRef,
  toTitleOrName,
} from "@api";

import { useCreateEntity } from "@state/generic";

import { omitEmpty } from "@utils/object";
import { toBaseScope } from "@utils/scope";
import { isLocalID, newLocalHumanId } from "@utils/id";
import { Maybe, when } from "@utils/maybe";

import { useFilteredTemplates } from "@state/templates";
import { useOpenState } from "@ui/dropdown";
import { RelationSelect } from "./relation";
import { GlobalEntitySelectProps, SideMenu } from "./entity";
import { SplitMenu } from "./comps";

import styles from "./select.module.css";
import { withoutStar } from "@utils/wildcards";
import { ensureMany } from "@utils/array";

type TemplateSelectProps = GlobalEntitySelectProps & {
  allowNew?: boolean;
  hasFieldSet?: PropertyRef;
};

export function TemplateSelect({
  type,
  scope,
  onChange,
  allowNew,
  open: _open,
  setOpen: _setOpen,
  portal = true,
  closeOnTeam,
  allowed,
  placeholder,
  className,
  hasFieldSet,
  ...props
}: TemplateSelectProps) {
  const [open, setOpen] = useOpenState(_open, _setOpen);
  const [filter, setFilter] = useState<DatabaseID>(() => ({
    type: type || "task",
    scope: toBaseScope(scope || ""),
  }));
  const create = useCreateEntity(filter.type, filter.scope);

  const fieldFilter = useMemo(
    (): Maybe<FilterQuery> =>
      when(hasFieldSet, (f) => ({ ...f, op: "is_not_empty" })),
    [hasFieldSet?.field]
  );
  const templates = useFilteredTemplates(filter, fieldFilter);

  const options = useMemo(
    () =>
      orderBy(
        map(templates, (t) => ({
          id: t.id,
          template: t,
          name: toTitleOrName(t),
        })),
        (t) => t.name
      ),
    [templates]
  );

  const handleScopeChanged = useCallback(
    (changes: Partial<DatabaseID>) =>
      setFilter((e) => ({ ...e, ...omitEmpty(changes) })),
    [setFilter]
  );

  const Menu = useMemo(
    () =>
      open &&
      SplitMenu<RelationRef, false>(
        undefined,
        <SideMenu
          {...props}
          allowed={ensureMany(withoutStar(allowed))}
          scope={scope}
          open={open}
          setOpen={setOpen}
          showTeam={false}
          closeOnTeam={closeOnTeam}
          filter={filter}
          onFilterChanged={handleScopeChanged}
        />
      ),
    [open, filter]
  );

  const handleSelected = useCallback(
    (t: Maybe<RelationRef>) => {
      if (t && isLocalID(t?.id)) {
        const savedd = create([
          { field: "template", type: "text", value: { text: "root" } },
          { field: "name", type: "text", value: { text: t.name } },
        ]);
        onChange?.(savedd);
      } else {
        onChange?.(t);
      }
    },
    [onChange]
  );

  return (
    <RelationSelect
      {...props}
      onChange={handleSelected}
      className={{
        popover: styles.entityPopover,
        trigger: isString(className) ? className : className?.trigger,
      }}
      overrides={{
        ...props.overrides,
        ...(Menu ? { Menu } : {}),
      }}
      placeholder={placeholder || "Type to filter (or create new)..."}
      createable={allowNew}
      toNewOption={(v) => ({
        id: newLocalHumanId(filter.type),
        name: v,
        type: filter.type,
      })}
      portal={portal}
      options={options}
      open={open}
      setOpen={setOpen}
    />
  );
}
