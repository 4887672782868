import {
  Dictionary,
  find,
  flatten,
  keys,
  map,
  reduce,
  uniq,
  values,
} from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Entity, EntityType, HasLocation, Ref } from "@api";

import {
  useLazyEntities,
  useManyNestedEntities,
  useQueueUpdates,
} from "@state/generic";

import { Fn } from "@utils/fn";
import { mask, maybeValues } from "@utils/object";
import { Maybe, maybeMap } from "@utils/maybe";
import { plural } from "@utils/string";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { toNestedLocation } from "@utils/scope";

import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { FillSpace, HStack } from "@ui/flex";
import { Check, SpinnerIcon } from "@ui/icon";
import { CheckMenuItem, MenuItem } from "@ui/menu-item";
import { Dialog } from "@ui/dialog";
import { LocationSelect } from "@ui/select";
import { Fields } from "@ui/input";

interface Props {
  targets: Ref[];
  suggested?: string;
  onComplete?: Fn<void, void>;
  onCancel?: Fn<void, void>;
}

export const LocationDialog = ({
  suggested,
  targets,
  onCancel,
  onComplete,
}: Props) => {
  const pageId = usePageId();
  const entities = useLazyEntities(targets);
  const parentTypes = useMemo(
    () => uniq(maybeMap(entities, (e) => e.source?.type)),
    [entities]
  );
  const [picking, setPicking] = useState(false);
  const { children, loading } = useManyNestedEntities(entities);
  const mutate = useQueueUpdates(pageId);
  const [opts, setOpts] = useState<Partial<Record<EntityType, boolean>>>({});
  const [location, setLocation] = useState(
    suggested || (entities?.[0] as Maybe<HasLocation>)?.location
  );

  const hasChildren = useMemo(
    () => maybeValues(children as Record<EntityType, Entity[]>)?.length > 0,
    [children]
  );

  const handleSubmit = useCallback(() => {
    if (!entities?.length || !location) {
      return;
    }

    const allChildren = flatten(
      values(mask(children as Dictionary<Entity[]>, opts))
    );

    // Move parent entity to selected location
    mutate(
      map(entities, (e) =>
        asUpdate(e, asMutation({ field: "location", type: "text" }, location))
      )
    );

    // Move nested entities to chosen location while maintaining parent structure
    mutate(
      map(allChildren, (c) => {
        // Find this child's parent else use first entity
        const parent =
          find(entities, (p) => (c as HasLocation).location?.includes(p.id)) ||
          entities[0];

        // Set child new location to be newly chosen location while keeping parent nested structure.
        return asUpdate(
          c,
          asMutation(
            { field: "location", type: "text" },
            toNestedLocation((c as HasLocation).location, parent.id, location)
          )
        );
      })
    );

    // Callback
    onComplete?.();
  }, [mutate, location, entities, opts]);

  useEffect(() => {
    setOpts(
      reduce(
        keys(children),
        (acc, k) => ({ ...acc, [k]: true }),
        {} as Partial<Record<EntityType, boolean>>
      )
    );
  }, [children]);

  if (!entities?.length) {
    return <></>;
  }

  return (
    <Dialog
      title="Move to new location"
      description={`Do you want to move ${entities?.length} selected
              ${parentTypes?.join("/")} and all nested work?`}
      onDismiss={onCancel}
      actions={
        <HStack gap={4} fit="container" justify="flex-end">
          <Button onClick={() => onCancel?.()}>Cancel</Button>
          <Button variant="primary" onClick={handleSubmit}>
            {hasChildren && "Move all"}
            {!hasChildren &&
              `Move ${entities?.length} ${plural("item", entities?.length)}`}
          </Button>
        </HStack>
      }
    >
      <Container stack="vertical" padding="none" gap={20}>
        <FillSpace direction="vertical" fit="container">
          <Container gap={20} stack="vertical" fit="container" padding="none">
            <Fields label="Location">
              <LocationSelect
                fit="container"
                variant="full"
                open={picking}
                setOpen={setPicking}
                location={location}
                defaultOpen={true}
                source={entities[0]?.source}
                onChange={setLocation}
              />
            </Fields>

            <Fields label="Work to move">
              <CheckMenuItem
                checked={true}
                inset={false}
                disabled
                onChecked={() => {}}
              >
                Move {entities?.length} selected{" "}
                {map(parentTypes, (t) => plural(t, entities?.length))?.join(
                  "/"
                )}
              </CheckMenuItem>

              {loading && (
                <MenuItem disabled icon={SpinnerIcon}>
                  Collecting nested work...
                </MenuItem>
              )}

              {!loading && !hasChildren && (
                <MenuItem disabled icon={Check} text="No nested work" />
              )}

              {!loading &&
                map(children, (values, type) => (
                  <CheckMenuItem
                    key={type}
                    inset={false}
                    checked={opts[type as EntityType] ?? false}
                    onChecked={() =>
                      setOpts((d) => ({
                        ...d,
                        [type]: !(opts[type as EntityType] ?? false),
                      }))
                    }
                  >
                    Move {values?.length || 0} nested{" "}
                    {plural(type, values?.length || 0)}
                  </CheckMenuItem>
                ))}
            </Fields>
          </Container>
        </FillSpace>
      </Container>
    </Dialog>
  );
};
