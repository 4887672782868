import { subDays } from "date-fns";
import { map, orderBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useRecoilState } from "recoil";

import {
  FilterOptions,
  HasFollowers,
  HasNotes,
  Note,
  NoteType,
  PropertyMutation,
  Ref,
  getRecentNotes,
} from "@api";
import { getNoteLoader } from "./queries";

import { getItem, setItems } from "@state/store";
import { ID } from "@state/types";
import { useActiveWorkspaceId } from "@state/workspace";
import {
  useCreateEntity,
  useLazyEntities,
  useNestedSource,
  useQueueUpdates,
} from "@state/generic";

import { useAsyncEffect } from "@utils/effects";
import { toScope } from "@utils/scope";
import { isLocalID } from "@utils/id";
import { Maybe, maybeMap, required } from "@utils/maybe";
import { now } from "@utils/now";
import { asMutation, asUpdate } from "@utils/property-mutations";

import { NoteAtom, NoteStoreAtom } from "./atoms";
import { toPointDate } from "@utils/date-fp";
import { useMe } from "@state/persons";

export function useLazyGetNote(id: ID) {
  const [note, setNote] = useRecoilState(NoteAtom(id));

  useAsyncEffect(async () => {
    if (!id || isLocalID(id)) {
      return;
    }

    if (!note?.fetchedAt) {
      const latest = await getNoteLoader(id);
      setNote(latest);
    }
  }, [id]);

  return note;
}

export function useLazyGetNotes(refs?: Ref[], order?: "asc" | "desc") {
  const notes = useLazyEntities<"note">(refs || []);

  return useMemo(
    () => orderBy(notes, "createdAt", order || "desc"),
    [notes, refs]
  );
}

export function useLazyGetRecentNotes(filter?: FilterOptions<Note>) {
  const [store, setStore] = useRecoilState(NoteStoreAtom);
  const [recent, setRecent] = useState<string[]>([]);

  useAsyncEffect(async () => {
    const latest = await getRecentNotes({
      ...filter,
      since: filter?.since || toPointDate(subDays(now(), 14)),
      type: filter?.type || NoteType.Update,
    });
    setStore(setItems(latest || []));
    setRecent(map(latest, (n) => n.id)?.reverse());
  }, [filter?.team, filter?.since, filter?.type]);

  return useMemo(
    () => maybeMap(recent, (r) => getItem(store, r)),
    [store?.lookup, recent]
  );
}

export function useUpdateNote(id: ID, pageId?: ID) {
  const mutate = useQueueUpdates<Note>(pageId);
  const note = useLazyGetNote(id);

  return useCallback(
    async (changes: PropertyMutation<Note>[]) =>
      note && mutate(asUpdate(note, changes)),
    [note, mutate]
  );
}

export function useCreateNote(
  attachTo: Maybe<HasNotes>,
  pageId?: Maybe<ID>,
  attach?: boolean
) {
  const workspaceId = useActiveWorkspaceId();
  const mutate = useQueueUpdates(pageId);
  const me = useMe();
  const noteSource = useNestedSource(attachTo, "note");
  const create = useCreateEntity(
    "note",
    noteSource?.scope || toScope(workspaceId),
    pageId
  );

  return useCallback(
    (changes: PropertyMutation<Note>[], transaction?: ID) => {
      // Create the note
      const note = create([
        ...changes,
        asMutation({ field: "refs.seenBy", type: "relations" }, [
          { id: required(me, () => "Missing current user.").id },
        ]),
        asMutation(
          { field: "refs.followers", type: "relations" },
          (attachTo as HasFollowers)?.refs?.followers
        ),
      ]);

      // Link it to the provided entity
      if (attachTo && attach !== false) {
        mutate(
          asUpdate(attachTo, [
            {
              field: "refs.notes",
              type: "relations",
              op: "add",
              value: { relations: [{ id: note.id }] },
            },
          ])
        );
      }

      return note;
    },
    [attachTo, mutate]
  );
}
