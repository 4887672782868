import { useCallback, useEffect, useMemo, useState } from "react";

import { useTempView, View } from "@state/views";
import { useActiveWorkspaceId } from "@state/workspace";
import { useActiveSpace } from "@state/spaces";

import { Fn } from "@utils/fn";
import { Maybe, when } from "@utils/maybe";

import { Menu } from "@ui/menu";
import { PlusIcon } from "@ui/icon";
import { Container } from "@ui/container";
import { DialogSplit } from "@ui/dialog-split";
import { Button, Props as ButtonProps } from "@ui/button";
import ViewOptionsMenu, { ViewOption } from "@ui/view-options-menu";

export interface Props {
  defaults: Partial<Omit<View, "id">>;
  onEditing?: Fn<View, void>;
  onSaved?: Fn<View, void>;
  onCancel?: Fn<void, void>;
}

export const ViewCreateDialog = ({
  onSaved,
  onEditing,
  defaults: _defaults,
  ...props
}: Props) => {
  const wId = useActiveWorkspaceId();
  const space = useActiveSpace();
  const [section, setSection] = useState<Maybe<ViewOption>>("source");
  const defaults = useMemo(
    () => ({
      ..._defaults,
      for: { id: _defaults.for?.id || space?.id || wId },
    }),
    [_defaults]
  );
  const { view, create, rollback, setup } = useTempView(defaults);

  useEffect(() => {
    setup();
  }, []);

  const onCancel = useCallback(() => {
    rollback();
    props.onCancel?.();
  }, [rollback]);

  const onCreate = useCallback(() => {
    if (!view) {
      return;
    }

    const saved = create();

    if (saved) {
      onSaved?.(saved);
    } else {
      onCancel?.();
    }
  }, [view, onSaved]);

  useEffect(() => {
    when(view, (v) => onEditing?.(v));
  }, [view?.id]);

  if (!view) {
    return <></>;
  }

  const menu = (
    <Container padding="top" fit="container">
      <Menu>
        <ViewOptionsMenu
          viewId={view.id}
          highlight={section}
          onSectionChanged={setSection}
        />
      </Menu>
    </Container>
  );

  return (
    <DialogSplit
      title={"New board"}
      onDismiss={onCancel}
      side={menu}
      actions={
        <>
          <Button onClick={() => onCancel?.()}>Cancel</Button>
          <Button variant="primary" onClick={onCreate}>
            Open board
          </Button>
        </>
      }
    >
      {section && (
        <ViewOptionsMenu
          viewId={view.id}
          section={section}
          onSectionChanged={setSection}
          header={false}
        />
      )}
    </DialogSplit>
  );
};

export const ViewCreateButton = ({
  icon,
  children,
  onSaved,
  onCancel,
  defaults,
  onEditing,
}: Props & Pick<ButtonProps, "icon" | "children">) => {
  const [open, setOpen] = useState(false);

  function withClose<A>(fn: Maybe<Fn<A, void>>) {
    return (a: A) => {
      fn?.(a);
      setOpen(false);
    };
  }

  return (
    <>
      {open && (
        <ViewCreateDialog
          defaults={defaults}
          onEditing={onEditing}
          onSaved={withClose(onSaved)}
          onCancel={withClose(onCancel)}
        />
      )}
      <Button subtle icon={icon || PlusIcon} onClick={() => setOpen(true)}>
        {children || "New view"}
      </Button>
    </>
  );
};
