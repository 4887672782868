import { useCallback, useMemo, useRef, useState } from "react";

import { DatabaseID, PropertyDef, RichText } from "@api";

import { autoBrief, useStreamAIUseCase } from "@state/ai";

import { toFieldName } from "@utils/property-refs";
import { isEmpty } from "@utils/rich-text";
import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";
import { useMagicDust } from "@utils/confetti";

import { Button } from "@ui/button";
import { DialogSplit } from "@ui/dialog-split";
import { EmptyState } from "@ui/empty-state";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { DocumentEditor, ReadonlyDocument } from "@ui/rich-text";
import { TabBar } from "@ui/tab-bar";
import { Heading, Text, TextMedium } from "@ui/text";
import { LocationLabel } from "@ui/location-button";
import { Divider } from "@ui/divider";

import styles from "./ai-auto-brief.module.css";

interface Props {
  original: Maybe<RichText>;
  prop: PropertyDef;
  source: DatabaseID;
  onSaved: Fn<RichText, void>;
  onDismiss: () => void;
}

export const AIAutoBrief = ({
  onSaved,
  original,
  prop,
  source,
  onDismiss,
}: Props) => {
  const [instructions, setInstructions] = useState<RichText>({ html: "" });
  const [isInstructing, setInstructing] = useState(false);
  const [content, setContent] = useState<RichText>({ html: "" });
  const [activeTab, setActiveTab] = useState("original");
  const [current, setCurrent] = useState(original);
  const sprinkleTarget = useRef<HTMLDivElement>(null);
  const sprinke = useMagicDust(sprinkleTarget, "large");
  const readyToSave = useMemo(
    () => !isInstructing && !isEmpty(content),
    [isInstructing, content]
  );
  const onResponse = useCallback((markdown: string, finished: boolean) => {
    setContent({ markdown });
    if (finished) {
      sprinke();
    }
  }, []);

  const { run, loading } = useStreamAIUseCase(
    autoBrief,
    onResponse,
    source.scope
  );

  const tabs = useMemo(
    () => [
      { id: "original", title: "Current" },
      { id: "updated", title: loading ? "Writing" : "Updated" },
    ],
    [loading]
  );

  const regenerate = useCallback(() => {
    if (loading) {
      return;
    }

    setInstructing(false);
    setActiveTab("updated");

    run({
      original: current,
      prop: prop,
      source: source,
      instructions,
    });
  }, [loading, content, current, prop, source, instructions]);

  const left = (
    <SpaceBetween
      direction="vertical"
      width="container"
      align="flex-start"
      gap={20}
    >
      <SpaceBetween align="flex-end">
        <FillSpace overflow="scroll" direction="horizontal">
          <VStack gap={6} fit="container">
            <Heading bold>Doc Assist</Heading>
            <HStack gap={6}>
              <LocationLabel
                showTeam={true}
                variant="full"
                location={source?.scope}
              />
              <Text subtle>/</Text>
              <Text>{toFieldName(prop)}</Text>
            </HStack>
          </VStack>
        </FillSpace>

        <Button
          variant={!readyToSave ? "primary" : "secondary"}
          loading={loading}
          onClick={() => regenerate()}
        >
          {!isEmpty(content)
            ? "Try again"
            : isEmpty(current)
            ? "Generate"
            : "Improve"}
        </Button>
      </SpaceBetween>

      <FillSpace direction="vertical" width="container">
        <DocumentEditor
          scope={source.scope}
          autoFocus
          placeholder={
            isEmpty(current)
              ? "Jot down quickly what you want to communicate..."
              : "Instructions on how to change the existing brief..."
          }
          content={instructions}
          onChanged={(rt) => {
            setInstructions(rt);
            setInstructing(true);
          }}
          className={styles.fullHeight}
          updateDelay={100}
        />
      </FillSpace>
    </SpaceBetween>
  );

  const right = (
    <VStack gap={10}>
      <SpaceBetween fit="container">
        <TextMedium>{toFieldName(prop)}</TextMedium>
        <TabBar
          active={activeTab}
          options={tabs}
          onActiveChanged={setActiveTab}
        />
      </SpaceBetween>

      <Divider />

      {activeTab === "updated" && (
        <>
          {!isEmpty(content) ? (
            <DocumentEditor
              content={content}
              onChanged={setContent}
              scope={source.scope}
              updateDelay={0}
            />
          ) : (
            <EmptyState
              text={loading ? "Generating..." : "Waiting for input..."}
            />
          )}
        </>
      )}

      {activeTab === "original" && (
        <>
          {!isEmpty(current) ? (
            <div onClick={() => setActiveTab("updated")}>
              <ReadonlyDocument content={current} />
            </div>
          ) : (
            <EmptyState text={"No input template"} />
          )}
        </>
      )}
    </VStack>
  );

  return (
    <DialogSplit
      onDismiss={onDismiss}
      className={{
        left: styles.left,
        right: styles.right,
        modal: styles.modal,
      }}
      side={left}
      actions={
        content && !isEmpty(content) ? (
          <div ref={sprinkleTarget}>
            <HStack fit="container" justify="flex-end">
              <Button subtle onClick={onDismiss}>
                Cancel
              </Button>
              <Button
                disabled={loading}
                variant={readyToSave ? "primary" : "secondary"}
                onClick={() => onSaved(content)}
              >
                Accept and Edit
              </Button>
            </HStack>
          </div>
        ) : undefined
      }
    >
      {right}
    </DialogSplit>
  );
};
