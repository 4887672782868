import { useMemo } from "react";

import { Deal, Status, PropertyMutation } from "@api";

import { useUpdateEntity } from "@state/generic";
import { useLazyPropertyValue } from "@state/databases";

import { when } from "@utils/maybe";
import { asMutation } from "@utils/property-mutations";
import { OneOrMany } from "@utils/array";
import { Fn } from "@utils/fn";

import { usePageId } from "@ui/app-page";
import { Centered, Container } from "@ui/container";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Sheet } from "@ui/sheet-layout";
import { WorkflowActions } from "@ui/workflow-action-button";
import { EditableHeading } from "@ui/editable-heading";
import { TemplateBanner } from "@ui/template-banner";
import { EntityHeaderBar } from "@ui/entity-header-bar";
import { EntityProperties } from "@ui/entity-properties";
import { PropertyLabel } from "@ui/property-label";
import { Divider } from "@ui/divider";
import { PropertyValueButton } from "@ui/property-value-button";

import { PaneOpts } from "../types";

export const DealPane = ({ id, item }: PaneOpts<Deal>) => {
  const mutate = useUpdateEntity(item.id, item.source.scope);
  const status = useLazyPropertyValue(item, {
    field: "status",
    type: "status",
  });
  const sectionProps = useMemo(
    () => ({ deal: item, status: status?.value.status, mutate }),
    [item, mutate, status?.value.status]
  );

  return (
    <Sheet size="primary">
      {!!item.template && <TemplateBanner />}

      <VStack fit="container" gap={20}>
        <DealHeader {...sectionProps} />

        <Container padding="horizontal">
          <Divider />
        </Container>

        <Centered stack="vertical" gap={30} padding="horizontal">
          <EntityProperties entityId={id} />
        </Centered>
      </VStack>
    </Sheet>
  );
};

interface HeaderProps {
  deal: Deal;
  status?: Status;
  mutate: Fn<OneOrMany<PropertyMutation<Deal>>, void>;
}

const DealHeader = ({ deal, status }: HeaderProps) => {
  const pageId = usePageId();
  const mutate = useUpdateEntity(deal.id, pageId);

  const value = useLazyPropertyValue(deal, {
    field: "value",
    type: "number",
  });

  if (!deal) {
    return <h1>Not found.</h1>;
  }

  return (
    <VStack gap={40}>
      <EntityHeaderBar entity={deal} padding="both" />

      <Centered padding="horizontal">
        <VStack gap={20} fit="container">
          <SpaceBetween>
            <VStack gap={6} fit="container">
              <Container padding="none" inset="both">
                <PropertyValueButton
                  entity={deal}
                  field="status"
                  type="status"
                />
              </Container>

              <EditableHeading
                key={deal.id}
                text={deal.title || ""}
                size="h2"
                placeholder="Deal name"
                onChange={(text) => {
                  when(text, (i) =>
                    mutate(asMutation({ field: "title", type: "text" }, i))
                  );
                }}
              />
            </VStack>
            <VStack align="flex-end">
              <PropertyValueButton
                entity={deal}
                field="owner"
                type="relation"
              />
              <PropertyLabel
                subtle={false}
                size="large"
                bold
                valueRef={value}
                source={deal.source}
              />
            </VStack>
          </SpaceBetween>

          {!deal.template && (
            <HStack fit="container" gap={4}>
              <WorkflowActions size="small" entity={deal} />
            </HStack>
          )}
        </VStack>
      </Centered>
    </VStack>
  );
};
