import { forEach, last } from "lodash";
import { getRecoil } from "recoil-nexus";
import { Editor } from "@tiptap/core";
import FileHandler, {
  FileHandlePluginOptions,
} from "@tiptap-pro/extension-file-handler";

import { Entity, Update } from "@api";

import { ActiveWorkspaceSessionAtom } from "@state/workspace";

import { OneOrMany } from "@utils/array";
import { Fn } from "@utils/fn";
import {
  asAppendMutation,
  asCreateUpdate,
  asMutation,
} from "@utils/property-mutations";
import { mapAll } from "@utils/promise";
import { fromScope } from "@utils/scope";
import { typeFromId } from "@utils/id";

import { uploadFile } from "@ui/upload";
import { showSuccess, withLoading } from "@ui/notifications";

type UploadExtensionOptions = FileHandlePluginOptions & {
  scope?: string;
  mutate?: Fn<OneOrMany<Update<Entity>>, void>;
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    upload: {
      uploadFiles: (options: { files?: File[] }) => ReturnType;
    };
  }
}

const getOptions = (name: string, editor: Editor) =>
  editor.extensionManager.extensions.find((ext) => ext.name === name)?.options;

export const UploadExtension = FileHandler.extend<UploadExtensionOptions>({
  name: "resourceUploader",
  addCommands() {
    return {
      uploadFiles:
        (options) =>
        ({}) => {
          const session = getRecoil(ActiveWorkspaceSessionAtom);
          const files = options.files || [];
          const scope = this.options.scope || "";
          const mutate = this.options.mutate;

          const uploadPromise = mapAll(files, (file) =>
            uploadFile(file, scope, session)
          );

          withLoading(uploadPromise, {
            message: "Uploading files...",
            position: "bottom-center",
          }).then((uploads) => {
            forEach(uploads, (uploaded, i) => {
              const file = files[i];

              const resource = asCreateUpdate(
                { type: "resource", scope: scope },
                [
                  asMutation({ field: "name", type: "text" }, file.name),
                  asMutation({ field: "path", type: "text" }, uploaded.path),
                  asMutation({ field: "url", type: "text" }, uploaded.url),
                  asMutation({ field: "location", type: "text" }, scope),
                  asMutation(
                    { field: "mimeType", type: "text" },
                    uploaded.mimeType
                  ),
                  asMutation({ field: "type", type: "text" }, "file"),
                ]
              );
              mutate?.([resource]);

              // Link it to the provided entity
              const parent = last(fromScope(scope));
              if (parent) {
                mutate?.([
                  {
                    id: parent,
                    method: "update",
                    source: {
                      type: typeFromId(parent),
                      scope: scope?.replace(parent + "/", ""),
                    },
                    changes: [
                      asAppendMutation(
                        { field: "refs.resources", type: "relations" },
                        [{ id: resource.id }]
                      ),
                    ],
                  },
                ]);
              }

              if (uploaded?.mimeType?.includes("image")) {
                this.editor.commands.setEmbed({
                  url: uploaded.url,
                  title: uploaded.name,
                  type: "image",
                });
              } else {
                this.editor.commands.setMention({
                  id: resource?.id,
                });
              }

              showSuccess("Added to resources.");
            });
          });

          return true;
        },
    };
  },

  addOptions() {
    return {
      ...this.parent?.(),
      onDrop: (editor, files) => {
        editor.commands.uploadFiles({ files });
      },
      onPaste: (editor, files, htmlContent) => {
        editor.commands.uploadFiles({ files });
      },
    };
  },
});
