import { mapValues } from "lodash";
import {
  EntityType,
  hasLocation,
  HasTemplate,
  ID,
  Integration,
  isAssignable,
  isOwnable,
  View,
} from "@api";
import { atomFamily, selectorFamily } from "recoil";

import {
  aliasedID,
  applyMutations,
  cleanLocalAliases,
  getItem,
  persistedID,
  setItemPure,
  StoreState,
  tempUpdatesForId,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";
import { getStore } from "@state/generic";
import { getViewTemplate } from "@state/templates";
import { setFetchResults, FetchResultsAtom } from "@state/fetch-results";

import { isDefault } from "@utils/recoil";
import { Maybe, safeAs, when } from "@utils/maybe";
import { toRef } from "@utils/property-refs";
import { toChildLocation, toLast } from "@utils/scope";
import { onClient } from "@utils/ssr";
import { typeFromId } from "@utils/id";
import { composel } from "@utils/fn";
import { asTimestamp } from "@utils/date";

import { appendKey, localStorageEffect } from "../local-storage-effect";

import { getEngine } from "@ui/engine";

import {
  addNewFilter,
  fromTemplateViewId,
  isTemplateViewId,
  newView,
} from "./utils";

export type { View };

export type ViewState = View;

export type ViewStoreState = StoreState<ViewState>;

const removeTemp = (config: ViewStoreState) =>
  onClient(() => ({
    ...config,
    lookup: mapValues(config.lookup, (view) =>
      !view?.id || isTemplateViewId(view?.id) ? undefined : view
    ),
  }));

export const ViewFetchResultsAtom = selectorFamily({
  key: "ViewFetchResultsAtom",
  get:
    (id: ID) =>
    ({ get }) => {
      const store = get(ViewStoreAtom);
      const alias = aliasedID(id, store?.aliases);
      const idResults = get(FetchResultsAtom(id));
      const aliasResults = get(FetchResultsAtom(alias || ""));

      return aliasResults?.fetchedAt && idResults?.fetchedAt
        ? asTimestamp(aliasResults.fetchedAt) > asTimestamp(idResults.fetchedAt)
          ? aliasResults
          : idResults
        : idResults || aliasResults;
    },

  set:
    (id: ID) =>
    ({ set, get }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        const store = get(ViewStoreAtom);
        const alias = aliasedID(id, store?.aliases);
        const idResults = get(FetchResultsAtom(id));
        const aliasResults = get(FetchResultsAtom(alias || ""));

        const idToSet =
          aliasResults?.fetchedAt && idResults?.fetchedAt
            ? asTimestamp(aliasResults.fetchedAt) >
              asTimestamp(idResults.fetchedAt)
              ? alias
              : persistedID(id, store.aliases) || id
            : persistedID(id, store.aliases) || id || alias;

        if (!idToSet) {
          return;
        }

        set(
          FetchResultsAtom(idToSet),
          setFetchResults(newValue.ids, newValue.fetchedAt)
        );
      }
    },
});

export const lastFetchedViewResults = selectorFamily({
  key: "lastFetchedViewResults",
  get:
    (id: ID) =>
    ({ get }) => {
      const results = get(ViewFetchResultsAtom(id));
      return results?.fetchedAt;
    },
});

export const WorkspaceViewStoreAtom = atomFamily<ViewStoreState, ID>({
  key: "WorkspaceViewStoreAtom",
  default: { updatedAt: undefined, lookup: {}, unsaved: [] },
  effects: (wid) => [
    localStorageEffect<ViewStoreState>({
      key: appendKey("traction.store.view", wid),
      props: ["lookup", "unsaved", "aliases"],
      clean: composel(cleanLocalAliases, removeTemp),
    }),
  ],
});

export const ViewStoreAtom = WorkspaceWrappedAtom(
  "ViewStoreAtom",
  WorkspaceViewStoreAtom
);

export const ViewAtom = selectorFamily({
  key: "ViewAtom",
  get:
    (id: ID) =>
    ({ get }) => {
      const item = getItem(get(ViewStoreAtom), id);

      if (!item && isTemplateViewId(id)) {
        const { key, params } = fromTemplateViewId(id);
        const { parent: tempLocation, entity, for: dataSource } = params;
        const assignedId = params.assigned || params.owner;

        // Possibly not loaded yet
        const parent = when(toLast(tempLocation) || undefined, (id) =>
          getItem(get(getStore(typeFromId<EntityType>(id))), id)
        );
        // Possibly not loaded yet
        const target = when(
          toLast(dataSource) || toLast(tempLocation) || undefined,
          (id) => getItem(get(getStore(typeFromId<EntityType>(id))), id)
        );
        const location = hasLocation(parent)
          ? toChildLocation(parent.location, parent.id)
          : tempLocation;

        // Parent not loaded yet, we don't want to return the template yet because info wont be correct
        if ((tempLocation && !parent) || !location) {
          return undefined;
        }

        const { template } =
          getViewTemplate(key) ||
          // Fallback to default list view if not found
          getViewTemplate("default-list") ||
          // Else undefined
          {};

        if (!template) {
          throw new Error(`System template (${key}) not configured.`);
        }

        const type = (entity as Maybe<EntityType>) || template?.entity;

        const view = newView({
          id: id,

          ...template,

          // What data source we are looking at
          entity: type,

          // Restricts items to only show up for this scope
          for: when(target?.id || dataSource, toRef),

          // Where this lives in the hierarchy, what it's linked to
          location: location,

          // Filter setup based on input params
          filter:
            assignedId && type && (isAssignable(type) || isOwnable(type))
              ? addNewFilter(template.filter, {
                  field: isAssignable(type) ? "assigned" : "owner",
                  type: "relation",
                  op: "equals",
                  value: { relation: { id: assignedId } },
                })
              : template.filter,

          // Default to first item in list if not set on template
          order: template.order ?? 0,

          // Set the tmp view to be a template if the parent is a template
          template: !!(
            safeAs<HasTemplate>(parent)?.template ||
            safeAs<HasTemplate>(target)?.template
          )
            ? "nested"
            : undefined,

          ...(parent
            ? getEngine(parent.source.type)?.toViewDefaults?.(id, parent)
            : {}),

          source: {
            source: Integration.Traction,
            type: "view",
            scope: location,
          },
        });

        // If we started mutating this view and then refreshed, the changes are in the unsaved state
        // but we are creating it from scratch here...
        const tempChanges = tempUpdatesForId(id, get(ViewStoreAtom));
        if (tempChanges.length) {
          return applyMutations(view, tempChanges);
        }

        return view;
      }

      return item;
    },
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(ViewStoreAtom, setItemPure(newValue));
      }
    },
});
