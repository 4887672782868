import { atomFamily, selectorFamily } from "recoil";
import { ID, Note } from "@api";

import { isDefault } from "@utils/recoil";

import {
  getItem,
  indexedDBStorageForStore,
  setItemPure,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type { Note };
export type NoteStoreState = StoreState<Note>;

export const WorkspaceNoteStoreAtom = atomFamily<NoteStoreState, ID>({
  key: "WorkspaceNoteStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "note")],
});

export const NoteStoreAtom = WorkspaceWrappedAtom(
  "NoteStoreAtom",
  WorkspaceNoteStoreAtom
);

export const NoteAtom = selectorFamily({
  key: "NoteAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(NoteStoreAtom), id) || undefined,
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(NoteStoreAtom, setItemPure(newValue));
      }
    },
});
