import { useMemo, useState } from "react";
import { EntityType, PropertyDef, PropertyRef } from "@api/types";

import { toManageFields } from "@state/teams";

import { extractTeam } from "@utils/scope";
import { useGoTo } from "@utils/navigation";
import { when } from "@utils/maybe";

import { ArrowUpRight } from "@ui/icon";
import { Button } from "@ui/button";
import { Divider } from "@ui/divider";
import { PropertyEditDialog } from "@ui/property-edit-dialog";

import styles from "./property.module.css";

// Cant use at the moment since it blurs the dropdowns and so closes the dialog
export const EditFieldFooter = ({
  prop,
  scope,
  type,
}: {
  prop: PropertyDef;
  scope: string;
  type: EntityType;
}) => {
  const [editing, setEditing] = useState(false);
  return (
    <div className={styles.manageProps}>
      {editing && <PropertyEditDialog prop={prop} source={{ type, scope }} />}

      <Divider direction="horizontal" />
      <Button
        fit="container"
        variant="link"
        iconRight={ArrowUpRight}
        onClick={() => setEditing(true)}
      >
        Manage field
      </Button>
    </div>
  );
};

interface ManageFieldsProps {
  text?: string;
  scope: string;
  type: EntityType;
}

interface ManageFieldProps {
  prop: PropertyRef;
  scope: string;
  type: EntityType;
}

export const ManageFieldsFooter = ({
  scope,
  type,
  text,
}: ManageFieldsProps) => {
  const goTo = useGoTo();
  return (
    <div className={styles.manageProps}>
      <Divider direction="horizontal" />
      <Button
        fit="container"
        variant="link"
        iconRight={ArrowUpRight}
        onClick={() =>
          when(extractTeam(scope), (id) => goTo(toManageFields(id, type)))
        }
      >
        {text || "Manage fields"}
      </Button>
    </div>
  );
};

export const ManageValuesFooter = ({ prop, scope, type }: ManageFieldProps) => {
  const goTo = useGoTo();
  const text = useMemo(
    () => (prop.type === "status" ? "Manage statuses" : "Manage values"),
    [prop.type]
  );

  // Schedules don't support custom fields
  if (type === "schedule") {
    return <></>;
  }

  return (
    <div className={styles.manageProps}>
      <Divider direction="horizontal" />
      <Button
        fit="container"
        variant="link"
        iconRight={ArrowUpRight}
        children={text}
        onClick={() =>
          when(extractTeam(scope), (id) =>
            goTo(toManageFields(id, type, prop.field))
          )
        }
      />
    </div>
  );
};
