import { first, last, map } from "lodash";
import { useEffect, useMemo } from "react";

import { Backlog, ID, PropertyRef, EntityType } from "@api";

import {
  useOpenAppCommands,
  usePageUndoRedo,
  useRegisterPage,
} from "@state/app";
import {
  useLazyEntity,
  useLazyQuery,
  useNestedSource,
  useUpdateEntity,
} from "@state/generic";
import { SystemPackages, useHasPackages } from "@state/packages";
import { toTemplateViewId } from "@state/views";
import { useAddToRecents } from "@state/recents";

import SmartViewPane from "@ui/view-pane";
import { Maybe, when } from "@utils/maybe";
import { fromScope, toChildLocation } from "@utils/scope";
import { asMutation } from "@utils/property-mutations";
import { omitEmpty } from "@utils/array";
import { useSyncPathnameID } from "@utils/url";
import { getSetting } from "@utils/property-refs";
import { useShowMore, useStickyState } from "@utils/hooks";
import { useGoTo } from "@utils/navigation";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { EditableHeading } from "@ui/editable-heading";
import { SpaceBetween, VStack } from "@ui/flex";
import { PlusIcon, Slash } from "@ui/icon";
import { Main, PageLayout, SideNav } from "@ui/page-layout";
import { LabelledPropertyValueList } from "@ui/property-value-tile";
import { MagicEmojiSelect } from "@ui/select/emoji";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import { PackageTag } from "@ui/package-label";
import AppPage from "./app-page";
import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { Divider } from "@ui/divider";
import { AddWorkButton } from "@ui/add-work-dialog";
import { WithSuggestedProps } from "@ui/suggested-props";
import { LabelledValue } from "@ui/property-value";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { ShowMoreMenuItem } from "@ui/menu-item";
import { render, useEngine } from "@ui/engine";
import { ViewStats } from "@ui/view-stats";
import { FormsCreatingHere } from "@ui/engine/form";

import styles from "./backlog-page.module.css";

export const BacklogPage = ({ id }: { id: ID }) => {
  const backlog = useLazyEntity<"backlog">(id);
  const [viewId, setViewId] = useStickyState<Maybe<string>>(
    backlog?.inbox?.id || first(backlog?.views)?.id || undefined,
    `backlog-${id}-view`
  );
  const view = useLazyEntity(viewId);

  const installed = useHasPackages(id, [SystemPackages.Sprints]);
  const suggested = useMemo(
    () =>
      omitEmpty([
        ...(backlog?.fields || []),
        installed[SystemPackages.Sprints]
          ? { field: "sprint", type: "relation" }
          : undefined,
      ]) as PropertyRef[],
    [backlog]
  );
  const [page] = useRegisterPage(id, backlog);
  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(id, backlog?.id);

  // Add to recents
  useAddToRecents(id);

  // When roadmap has loaded, set default view
  useEffect(() => {
    if (backlog && (!viewId || !view)) {
      setViewId(
        toTemplateViewId("backlog-list", {
          parent: id,
          entity: getSetting<EntityType>(backlog?.settings, "child_type"),
        })
      );
    }
  }, [backlog?.id, view?.id, id]);

  if (!backlog) {
    return <></>;
  }

  return (
    <AppPage page={page}>
      <StackContainer>
        <SmartBreadcrumbSheet />
        <Sheet size="full" transparency="mid" interactable={false}>
          <PageLayout>
            <SideHeader id={id} item={backlog} pageId={page.id} />

            <Main className={styles.main}>
              <WithSuggestedProps props={suggested}>
                {viewId && (
                  <SmartViewPane
                    viewId={viewId}
                    className={styles.viewPane}
                    onChangeView={(v) => setViewId(v.id)}
                    showTitle={false}
                  />
                )}
              </WithSuggestedProps>
            </Main>
          </PageLayout>
        </Sheet>
      </StackContainer>
    </AppPage>
  );
};

const SideHeader = ({
  id,
  pageId,
  item,
}: {
  id: ID;
  pageId: ID;
  item: Backlog;
}) => {
  const mutate = useUpdateEntity(item.id, pageId);
  const goTo = useGoTo();
  const engine = useEngine("backlog");
  const installed = useHasPackages(id, [SystemPackages.Forms]);

  const allBacklogs = useLazyQuery(
    "backlog",
    useMemo(
      () => ({
        and: [
          {
            field: "location",
            type: "text",
            op: "ends_with",
            value: { text: last(fromScope(item.location)) },
          },
          {
            field: "id",
            type: "text",
            op: "does_not_equal",
            value: { text: item.id },
          },
        ],
      }),
      [item.location, item.id]
    ),

    { limit: 10, fetch: false }
  );
  const {
    visible: otherBacklogs,
    hasMore,
    showMore,
  } = useShowMore(allBacklogs, 3);

  const nestedSource = useNestedSource(
    item,
    getSetting(item?.settings, "child_type")
  );

  const openCmdK = useOpenAppCommands(item);

  return (
    <SideNav className={styles.nav}>
      <SpaceBetween direction="vertical">
        <VStack gap={10} width="container">
          <VStack gap={20} width="container">
            <Container gap={10} padding="none" inset="bottom" stack="vertical">
              <SpaceBetween>
                <MagicEmojiSelect
                  key={item.id}
                  entity={item}
                  size="xlarge"
                  emoji={item.icon || "🗓️"}
                  onChange={(emoji) =>
                    mutate(asMutation({ field: "icon", type: "text" }, emoji))
                  }
                />
                <PackageTag type="backlog" scope={item.source.scope} />
              </SpaceBetween>

              <EditableHeading
                key={item.id}
                text={item.name || ""}
                size="h2"
                onChange={(text) => {
                  when(text, (i) =>
                    mutate(asMutation({ field: "name", type: "text" }, i))
                  );
                }}
              />
            </Container>
          </VStack>

          <Container inset="horizontal" padding="none" fit="container">
            <SpaceBetween>
              <Button fit="container" icon={Slash} subtle onClick={openCmdK}>
                Modify
              </Button>

              <AddWorkButton
                fit="container"
                icon={PlusIcon}
                subtle
                defaults={{
                  refs: { roadmaps: [{ id: item.id }] },
                  location: toChildLocation(item.location, item.id),
                }}
                allowed={[nestedSource.type]}
              >
                Add new
              </AddWorkButton>
            </SpaceBetween>
          </Container>

          <Container padding="vertical">
            <Divider />
          </Container>

          {when(
            toTemplateViewId("backlog-list", {
              parent: id,
              entity: nestedSource.type,
            }),
            (id) => (
              <ViewStats
                itemClassName={styles.half}
                wrap={true}
                viewId={id}
                organizeBy={item.fields?.[0]}
              />
            )
          )}

          <Divider margin="on" />

          {installed[SystemPackages.Forms] && (
            <>
              <FormsCreatingHere item={item} />
              <Divider margin="on" />
            </>
          )}

          <LabelledPropertyValueList
            entity={item}
            onChange={mutate}
            stack="vertical"
          >
            <LabelledValue label="Backlogging" fit="container">
              <Button subtle disabled inset size="small" wrapLabel={false}>
                <PackageTag {...nestedSource} disabled={true} plural={true} />
              </Button>
            </LabelledValue>
          </LabelledPropertyValueList>
        </VStack>

        {!!otherBacklogs?.length && (
          <Menu>
            <MenuGroup label="Other Backlogs">
              {map(otherBacklogs, (cal) =>
                render(engine.asMenuItem, {
                  key: cal.id,
                  item: cal,
                  onOpen: goTo,
                })
              )}
              {hasMore && <ShowMoreMenuItem onClick={showMore} />}
            </MenuGroup>
          </Menu>
        )}
      </SpaceBetween>
    </SideNav>
  );
};

export default BacklogPage;
