import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { isArray, map } from "lodash";
import { useCallback, useMemo } from "react";

import {
  DatabaseID,
  Entity,
  EntityRef,
  EntityType,
  PropertyDef,
  PropertyValue,
  Update,
} from "@api";

import { getItem } from "@state/store";
import { getStore, useQueueUpdates } from "@state/generic";
import {
  AppCommandsAtom,
  addToStack,
  closePropertyEdit,
  currentPage,
  editPropertyInCmdK,
  setCommandsOpen,
} from "@state/app";
import { useEntityLabels } from "@state/settings";

import { maybeMap } from "@utils/array";
import { switchEnum } from "@utils/logic";
import { toUpdate } from "@utils/property-mutations";
import { newID } from "@utils/id";
import { plural } from "@utils/string";
import { composel } from "@utils/fn";

import { showSuccess } from "@ui/notifications";
import { MultiSelectCommands } from "./multi-select-commands";
import { SelectCommands } from "./select-commands";
import { DateCommands } from "./date-commands";
import { PersonSelectCommands } from "./person-commands";
import { RelationSelectCommands } from "./relation-commands";
import { MultiRelationSelectCommands } from "./multi-relation-commands";
import { LocationSelectCommands } from "./location-commands";
import { AppCommandsProps } from "../types";
import { IconCommands } from "./icon-commands";
import { NumberCommands } from "./number-commands";

type Props = AppCommandsProps & {
  entities: EntityRef[];
  type: EntityType;
  property: PropertyDef;
};

export const SetCommands = (compProps: Props) => {
  const { entities, property } = compProps;
  const [page, setPage] = useRecoilState(currentPage);
  const setCommands = useSetRecoilState(AppCommandsAtom);
  const db = entities[0]?.source as DatabaseID;
  const Store = getStore(db.type);
  const store = useRecoilValue(Store);
  const realEntities = maybeMap(entities, (e) => getItem(store, e.id));
  const queueUpdate = useQueueUpdates(page?.id);
  const toLabels = useEntityLabels(db.scope);

  const mutate = useCallback(
    (value: PropertyValue | Update<Entity>[]) => {
      // Only put into a transaction when there are multiple entities
      const transaction = entities?.length > 1 ? newID() : undefined;

      const updates = isArray(value)
        ? // When an array of updates are passed in, then just add a transaction to them
          map(value, (u) => ({ ...u, transaction }))
        : // Else map the new value to mutations with the transaction
          map(realEntities, (entity) =>
            toUpdate(
              entity,
              property,
              value[property.type],
              undefined,
              transaction
            )
          );

      queueUpdate(updates);

      showSuccess(
        `Updated ${realEntities.length} ${plural(
          toLabels(db.type),
          realEntities?.length
        )}.`,
        undefined,
        {
          position: "bottom-left",
        }
      );

      setPage(addToStack(updates));

      if (property.type !== "relations" && property.type !== "multi_select") {
        setCommands(editPropertyInCmdK(undefined));
      }
    },
    [queueUpdate]
  );

  const props = useMemo(
    () => ({
      ...compProps,
      mutate,
      onDismiss: () =>
        setCommands(composel(setCommandsOpen(false), closePropertyEdit())),
    }),
    [compProps, mutate]
  );

  // Special fields
  if (property.field === "location") {
    return <LocationSelectCommands {...props} />;
  }

  return (
    switchEnum(property.field, {
      icon: () => <IconCommands {...props} />,
      else: () => undefined,
    }) ||
    switchEnum(property.type, {
      number: () => <NumberCommands {...props} />,
      date: () => <DateCommands {...props} />,
      status: () => <SelectCommands {...props} />,
      select: () => <SelectCommands {...props} />,
      multi_select: () => <MultiSelectCommands {...props} />,
      person: () => <PersonSelectCommands {...props} />,
      relation: () => <RelationSelectCommands {...props} />,
      relations: () => <MultiRelationSelectCommands {...props} />,
      else: () => <></>,
    })
  );
};
