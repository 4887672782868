import { fuzzy } from "fast-fuzzy";
import { isString, without } from "lodash";
import { Command } from "cmdk";
import {
  Children,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Dialog, DialogContent, Portal } from "@radix-ui/react-dialog";

import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { validShortcut, respectHandled, useShortcut } from "@utils/event";
import { useStateCallback } from "@utils/hooks";
import { newID } from "@utils/id";

import { MenuGroup, MenuGroupProps } from "@ui/menu-group";
import { MenuItem, MenuItemProps } from "@ui/menu-item";
import { HStack, SpaceBetween } from "@ui/flex";
import { Text } from "@ui/text";
import { Icon, SpinnerIcon } from "@ui/icon";

import styles from "./command-menu.module.css";
import { useCommandSearch } from "@ui/app-commands/utils";

const CommandMenuContext = createContext<{
  onClose?: Fn<void, void>;
  onReset?: Fn<void, void>;
  onLoading?: (key: string, loading: boolean) => void;
}>({});

type CommandItemProps = Omit<MenuItemProps, "onClick"> & {
  value: string;
  onSelectAction?: "close" | "clear" | "none";
  resetOnSelect?: boolean;
  onClick?: Fn<void, void>;
};

type LoaderProps = {};

export const CommandLoading = ({}: LoaderProps) => {
  const key = useMemo(() => newID(), []);
  const { onLoading } = useContext(CommandMenuContext);

  useEffect(() => {
    onLoading?.(key, true);
    return () => onLoading?.(key, false);
  });

  return <Command.Loading></Command.Loading>;
};

export const CommandItem = ({
  children,
  className,
  onClick,
  onSelectAction = "close",
  ...props
}: CommandItemProps) => {
  const { onClose, onReset } = useContext(CommandMenuContext);

  return (
    <Command.Item
      className={styles.itemContainer}
      value={props.value || (isString(children) ? children : "unknown")}
      onSelect={() => {
        onClick?.(undefined);
        if (onSelectAction === "close") {
          onClose?.();
        } else if (onSelectAction === "clear") {
          onReset?.();
        }
      }}
    >
      <MenuItem className={cx(styles.item, className)} {...props}>
        {children}
      </MenuItem>
    </Command.Item>
  );
};

// Only show these items when filter is at least 2 characters
export const CommandSubItem = ({
  children,
  threshold,
  always,
  ...props
}: CommandItemProps & { always?: boolean; threshold?: number }) => {
  const search = useCommandSearch();

  if (!always && search.length < (threshold ?? 2)) {
    return <></>;
  }

  return <CommandItem {...props}>{children}</CommandItem>;
};

export const CommandGroup = ({
  children,
  label: title,
  labelClassName,
  ...props
}: MenuGroupProps) => {
  const hasChildren = Children.toArray(children).length > 0;
  return hasChildren ? (
    <Command.Group
      value={(isString(title) ? title : undefined) || "unknown"}
      className={styles.group}
    >
      <MenuGroup
        labelClassName={cx(styles.groupLabel, labelClassName)}
        listClassName={styles.groupItems}
        label={title}
        bold
        {...props}
      >
        {children}
      </MenuGroup>
    </Command.Group>
  ) : (
    <></>
  );
};

export const CommandDivider = () => (
  <Command.Separator className={styles.divider} />
);

interface Props {
  children: ReactNode;
  header?: ReactNode;
  placeholder?: string;
  open?: boolean;
  mode?: "blocking" | "peaking";
  prefix?: string;
  onOpen?: Fn<boolean, void>;
  onSearched?: Fn<string, void>;
  onClose?: Fn<void, void>;
  onBack?: Fn<void, void>;
}

export const CommandMenu = ({
  children,
  header,
  placeholder,
  onSearched,
  onClose,
  onBack,
  open,
  prefix,
  mode = "blocking",
  onOpen,
  ...props
}: Props) => {
  const input = useRef<HTMLInputElement>(null);
  const [entering, setEntering] = useState(true);
  const [loading, setLoading] = useState<string[]>([]);
  const [search, setSearch] = useStateCallback<string>("", onSearched);
  const setOpen = useCallback(
    (open: boolean) => {
      setSearch("");
      if (open) {
        onOpen?.(open);
      } else {
        onClose?.();
      }
    },
    [onOpen, open, onClose, setSearch]
  );

  const context = useMemo(
    () => ({
      onClose: () => setOpen(false),
      onReset: () => setSearch(""),
      onLoading: (key: string, loading: boolean) =>
        loading
          ? setLoading((l) => [...l, key])
          : setLoading((l) => without(l, key)),
    }),
    []
  );

  // Go back when backspace inside of empty search
  useShortcut(
    [{ key: "Backspace" }],
    [
      (e) =>
        e.target === input.current && !search?.length && mode === "blocking",
      () => onBack?.(),
    ],
    [mode, search, onBack]
  );

  // Captures search when peaking
  useShortcut(
    [{ key: /[a-zA-Z]/gi }],
    [
      (e) =>
        validShortcut(e) && input?.current !== document.activeElement && !!open,
      (e) => {
        setSearch((s) => s + e.key);
        input.current?.focus();
      },
    ],
    [mode, open]
  );

  useEffect(() => {
    if (open) {
      setTimeout(() => setEntering(false), 500);
    } else {
      setEntering(true);
    }
  }, [open ?? false]);

  useLayoutEffect(() => {
    if (mode === "blocking" && open) {
      input?.current?.focus();
    }
  }, [open, mode]);

  return (
    <Dialog
      open={open}
      modal={false}
      onOpenChange={(c) => (mode === "blocking" ? setOpen(c) : undefined)}
    >
      {open && mode === "blocking" && (
        <div className={styles.overlay} onClick={() => setOpen(false)} />
      )}
      <Portal data-ignore-auto-close>
        <DialogContent className={cx(styles.dialog)} {...props}>
          <CommandMenuContext.Provider value={context}>
            <Command
              loop={false}
              autoFocus={false}
              className={cx(
                styles.container,
                mode === "blocking" && styles.center,
                mode === "peaking" && styles.peaking,
                mode === "peaking" && !!search?.length && styles.peakingVisible,
                entering && styles.animating
              )}
              onValueChange={onSearched}
              filter={(val, search) =>
                val?.startsWith("skip") ? 1 : fuzzy(search, val) > 0.8 ? 1 : 0
              }
            >
              <div
                onClick={respectHandled(() => {
                  setOpen(true);
                  setTimeout(() => input?.current?.focus(), 100);
                })}
              >
                {header && <div className={styles.header}>{header}</div>}
                <HStack gap={2} className={styles.inputContainer}>
                  {prefix && <Text className={styles.prefix}>{prefix}</Text>}

                  <SpaceBetween>
                    <Command.Input
                      ref={input}
                      value={search}
                      onValueChange={setSearch}
                      className={cx(
                        styles.input,
                        mode === "peaking" && styles.disabled
                      )}
                      disabled={mode === "peaking"}
                      placeholder={placeholder}
                    />

                    {!!loading.length && <Icon icon={SpinnerIcon} />}
                  </SpaceBetween>
                </HStack>
              </div>

              <Command.List>
                <Command.Empty>No results</Command.Empty>
                {children}
              </Command.List>
            </Command>
          </CommandMenuContext.Provider>
        </DialogContent>
      </Portal>
    </Dialog>
  );
};
