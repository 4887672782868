import { differenceInMinutes } from "date-fns";
import { ReactNode, useMemo } from "react";

import { HasDates, HasLocation, Meeting } from "@api";

import { useLazyEntity } from "@state/generic";

import { cx } from "@utils/class-names";
import { toColorVar } from "@utils/color";
import { when } from "@utils/maybe";
import { extractTeam } from "@utils/scope";
import { now } from "@utils/now";
import { useTick } from "@utils/time";
import { fromPointDate, ISODate, usePointDate } from "@utils/date-fp";

import { Divider } from "@ui/divider";
import { Text, TextSmall } from "@ui/text";
import { TimeLabel } from "@ui/time-label";

import styles from "./styles.module.css";

export const MeetingDivider = ({
  entity: entity,
  className,
}: {
  entity: HasLocation;
  className?: string;
}) => {
  const teamId = useMemo(
    () => extractTeam(entity.location || entity.source.scope),
    [entity]
  );
  const team = useLazyEntity<"team">(teamId);
  const css = useMemo(
    () => when(team?.color, (c) => ({ background: toColorVar(c) })),
    [team]
  );
  return (
    <Divider
      style={css}
      className={cx(styles.meetingDivider, className)}
      direction="vertical"
    />
  );
};

export const TimeStack = ({
  entity,
  subtle = true,
  separator,
}: {
  entity: Partial<HasDates>;
  subtle?: boolean;
  separator?: string | ReactNode;
}) => {
  if (!entity.end && !entity.start) {
    return <TextSmall subtle>Time not set</TextSmall>;
  }

  return (
    <>
      {entity.start && (
        <TimeLabel
          subtle={subtle}
          fit="content"
          bold={true}
          date={fromPointDate(entity.start)}
        />
      )}
      {when(separator, (sep) => <Text subtle>{sep}</Text>) || false}
      {entity.end && (
        <TimeLabel
          subtle={subtle}
          fit="content"
          date={fromPointDate(entity.end)}
        />
      )}
    </>
  );
};

export const NowIndicator = ({
  start,
  end,
}: {
  start?: ISODate;
  end?: ISODate;
}) => {
  const nowish = useMemo(() => now(), [useTick("30 seconds")]);
  const diff = useMemo(
    () =>
      when(start || end, (d) =>
        usePointDate(d, (d) => differenceInMinutes(nowish, d))
      ),
    [start, end, nowish]
  );
  const percent = useMemo(
    () => when(diff, (d) => Math.max(0, Math.min(100, (d / 60) * 100))),
    [diff]
  );

  if (!diff || diff < 0 || percent === 100) {
    return <></>;
  }

  return <div className={styles.nowIndicator} style={{ top: `${percent}%` }} />;
};
