import { Fn } from "./fn";
import { Maybe } from "./maybe";

export function hashable<T extends {}>(
  obj: T
): T & { toJSON: Fn<void, string> };
export function hashable<T extends {}>(
  obj: Maybe<T>
): Maybe<T & { toJSON: Fn<void, string> }> {
  const hash = JSON.stringify(obj);
  return !!obj
    ? {
        ...obj,
        toJSON: () => hash,
      }
    : undefined;
}

export const to = <T extends Record<string, string>>(serialized: string) =>
  JSON.parse(serialized) as T;
