import { Entity, PropertyDef, SingleFilterQuery, Task } from "@api";
import { Fn } from "@utils/fn";
import { Maybe, when } from "@utils/maybe";
import { isEmptyFilter } from "@utils/filtering";

import { Dropdown, useOpenState } from "@ui/dropdown";
import { TextInput } from "@ui/input";

import { OpHeader } from "./op-header";
import { TriggerButton } from "./trigger-button";
import styles from "./input.module.css";

export interface Props {
  filter: SingleFilterQuery;
  definition: PropertyDef<Entity>;
  onChanged?: Fn<Maybe<SingleFilterQuery>, void>;
  editing?: boolean;
}

export default function PropertyFilterInput({
  filter,
  definition,
  onChanged,
  editing,
}: Props) {
  const [open, setOpen] = useOpenState(editing);
  if (!["text", "rich_text", "number"]?.includes(filter.type)) {
    throw new Error("Cannot use FilterInput for non-text filters.");
  }

  const selected = filter.value?.[filter.type];

  return (
    <Dropdown
      open={open}
      setOpen={setOpen}
      portal={true}
      trigger={
        <TriggerButton
          filter={filter}
          prop={definition}
          onChanged={onChanged}
        />
      }
    >
      <OpHeader
        filter={filter}
        prop={definition}
        onChanged={onChanged}
        setOpen={setOpen}
      />

      {!isEmptyFilter(filter) && (
        <TextInput
          className={styles.input}
          value={when(selected, String) || ""}
          placeholder={"Enter value..."}
          inputType={filter.type === "number" ? "number" : "text"}
          onChange={(v) =>
            onChanged?.({
              field: filter.field,
              type: filter.type,
              op: filter.op,
              value: {
                [filter.type]: filter.type === "number" ? Number(v) : v,
              },
            })
          }
        />
      )}
    </Dropdown>
  );
}
