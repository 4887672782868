import { useCallback } from "react";

import { Workspace, WorkspaceConfig } from "@api";

import { useCreateWorkspace } from "@state/workspace";

import { Fn } from "@utils/fn";

import { Button } from "@ui/button";
import { Dialog } from "@ui/dialog";
import { Field, TextInput } from "@ui/input";
import { VStack } from "@ui/flex";

interface Props {
  defaults: Partial<Omit<Workspace, "id">>;
  onSaved?: Fn<WorkspaceConfig, void>;
  onCancel?: Fn<void, void>;
}

export const WorkspaceCreateDialog = ({
  onCancel,
  onSaved,
  defaults,
}: Props) => {
  const { workspace, setWorkspace, saving, create } = useCreateWorkspace();

  const onCreate = useCallback(async () => {
    const saved = await create?.(workspace);

    if (saved) {
      onSaved?.(saved);
    } else {
      onCancel?.();
    }
  }, [workspace, onSaved]);

  return (
    <Dialog
      title={"New workspace"}
      onDismiss={onCancel}
      actions={
        <>
          <Button onClick={() => onCancel?.()}>Cancel</Button>
          <Button variant="primary" onClick={onCreate}>
            {saving ? "Saving" : "Save"}
          </Button>
        </>
      }
    >
      <VStack gap={10}>
        <Field label="Name">
          <TextInput
            value={workspace.name || ""}
            onChange={(t) => setWorkspace({ ...workspace, name: t })}
            updateOn="change"
            placeholder="Enter workspace name..."
          />
        </Field>
      </VStack>
    </Dialog>
  );
};
