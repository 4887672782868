import { find, groupBy } from "lodash";
import { HasStatus, PropertyDef, Status } from "@api";
import { percent } from "./math";
import { switchEnum } from "./logic";
import { Maybe, when } from "./maybe";

export const STATUS_GROUPS = ["planning", "not-started", "in-progress", "done"];

export const isCompleteStatus = (s: Status) => s.group === "done";

export const isStartedStatus = (s: Maybe<Status>) =>
  s?.group === "in-progress" || s?.group === "done";

export const toLabel = (status: Maybe<Partial<Status>>) =>
  status?.name ||
  when(status?.group, (g) =>
    switchEnum(g, {
      planning: "Draft",
      "not-started": "Ready",
      "in-progress": "In Progress",
      done: "Completed",
      else: "Uknown",
    })
  );

export const byStatusGroups = <T extends HasStatus>(
  items: T[],
  statusProp?: PropertyDef<T>
) => {
  const toStatus = statusProp
    ? (i: T) =>
        find(statusProp.values.status, (s) => s.id === i.status?.id) || i.status
    : (i: T) => i.status;

  return groupBy(items, (i) => {
    const status = toStatus(i);
    return status ? status.group : "not-started";
  });
};

export const toStatusPercents = <T extends HasStatus>(
  items: T[],
  statusProp?: PropertyDef<T>
) => {
  const byGroups = byStatusGroups(items, statusProp);
  return [
    percent(byGroups["done"]?.length, items?.length),
    percent(byGroups["in-progress"]?.length, items?.length),
    percent(
      (byGroups["not-started"]?.length || 0) +
        (byGroups["planning"]?.length || 0),
      items?.length
    ),
  ];
};
