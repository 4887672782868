import { useCallback } from "react";
import { map } from "lodash";

import { PropertyMutation, Ref, Team } from "@api";

import { asAppendMutation } from "@utils/property-mutations";
import { Fn } from "@utils/fn";
import { useGoTo } from "@utils/navigation";

import { HStack, SpaceBetween, ThirdSpace } from "@ui/flex";
import { Container } from "@ui/container";
import { TextXLarge } from "@ui/text";
import { PersonSelect } from "@ui/select";
import { Text } from "@ui/text";
import { MinusIcon, PlusIcon } from "@ui/icon";
import { Button } from "@ui/button";
import { Card } from "@ui/card";
import { ContextItem, ContextMenu } from "@ui/context-menu";

import { RelationLabel } from "../connected/relation-label";

interface Props {
  team: Team;
  mutate: Fn<PropertyMutation<Team>[], void>;
}

export const TeamPeople = ({ team, mutate }: Props) => {
  const goTo = useGoTo();

  const handleRemoveFromTeam = useCallback(
    (ref: Ref) => {
      mutate([
        asAppendMutation(
          {
            field: "people",
            type: "relations",
          },
          [ref],
          "remove"
        ),
      ]);
    },
    [team]
  );

  return (
    <Container stack="vertical" gap={20} padding="none">
      <SpaceBetween>
        <HStack onClick={() => goTo("./people")}>
          <TextXLarge bold>People</TextXLarge>
          <TextXLarge bold subtle>
            {team?.people?.length}
          </TextXLarge>
        </HStack>

        <PersonSelect
          portal={true}
          value={undefined}
          onChange={(p) =>
            p &&
            mutate([
              asAppendMutation({ field: "people", type: "relations" }, [
                { id: p.id },
              ]),
            ])
          }
        >
          <Button icon={PlusIcon} subtle>
            <Text subtle>Add people</Text>
          </Button>
        </PersonSelect>
      </SpaceBetween>
      <HStack fit="container" wrap={true} gap={2}>
        {map(team?.people, (p) => (
          <ThirdSpace key={p.id}>
            <ContextMenu
              actions={
                <ContextItem
                  text="Remove from team"
                  icon={MinusIcon}
                  onClick={() => handleRemoveFromTeam(p)}
                />
              }
            >
              <Card onClick={() => goTo(p)} fit="container">
                <RelationLabel size="large" relation={p} />
              </Card>
            </ContextMenu>
          </ThirdSpace>
        ))}
      </HStack>
    </Container>
  );
};
