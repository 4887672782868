import { atomFamily, selectorFamily } from "recoil";
import { ID, Outcome } from "@api";

import { isDefault } from "@utils/recoil";

import {
  getItem,
  indexedDBStorageForStore,
  setItemPure,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type { Outcome };
export type OutcomeStoreState = StoreState<Outcome>;

export const WorkspaceOutcomeStoreAtom = atomFamily<OutcomeStoreState, ID>({
  key: "WorkspaceOutcomeStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "outcome")],
});

export const OutcomeStoreAtom = WorkspaceWrappedAtom(
  "OutcomeStoreAtom",
  WorkspaceOutcomeStoreAtom
);

export const OutcomeAtom = selectorFamily({
  key: "OutcomeAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(OutcomeStoreAtom), id) || undefined,
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(OutcomeStoreAtom, setItemPure(newValue));
      }
    },
});
