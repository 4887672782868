import { ReactNode, RefObject, useRef } from "react";
import { map } from "lodash";
import { concat as concat_ } from "lodash/fp";
import {
  ConnectDragSource,
  ConnectDropTarget,
  useDrag,
  useDrop,
} from "react-dnd";

import { Entity, EntityType, PropertyDef } from "@api";
import { TypeForEntity } from "@api/mappings";

import { isDefined, Maybe, when } from "@utils/maybe";
import { GroupedItems, GroupedValue, NestedGroup } from "@utils/grouping";
import { DragRef, DragToRef, DropPosition } from "@utils/drag-drop";
import {
  getNextSelectable,
  getPrevSelectable,
  getSelectable,
  SelectionState,
} from "@utils/selectable";
import { fallback } from "@utils/fn";
import { toArray } from "@utils/set";
import { DropHighlight } from "@ui/drop-highlight";
import { maybeTypeFromId } from "@utils/id";

export type OnReorder<T extends Entity> = (
  t: DragRef<T>[],
  to: DragToRef<T>
) => void;

interface DragProps<T extends Entity = Entity> {
  item: T;
  selection?: SelectionState;
  field?: string;
  group?: NestedGroup<T>;
  parents?: GroupedValue<T>[];
  onReorder: OnReorder<T>;
  ref: RefObject<HTMLLIElement | HTMLDivElement>;
  connect?: ConnectDropTarget | ConnectDragSource;
  forcePosition?: "before" | "after";
}

type DropProps<T extends Entity = Entity> = {
  type: TypeForEntity<T>;
  item?: T;
  group?: NestedGroup<T>;
  parents?: GroupedValue<T>[];
  ref: RefObject<HTMLDivElement | HTMLLIElement>;
  connect?: ConnectDropTarget | ConnectDragSource;
  acceptsFields?: boolean;
  forcePosition?: "before" | "after";
};

export const useItemDrag = <T extends Entity = Entity>({
  item,
  selection,
  group,
  parents = [],
  onReorder,
  ref,
  field,
  connect,
}: DragProps<T>) => {
  const [{ opacity }, dragRef] = useDrag<
    DragRef<T>[],
    DragToRef<T>,
    { opacity?: number }
  >(
    () => ({
      type: item?.source?.type || maybeTypeFromId(item.id) || "item",
      item: () => {
        // Copy the group def down to the property def
        const groupValue: Maybe<GroupedValue<T>> = when(group?.value, (v) => ({
          ...v,
          def: v.def || (group?.def as Maybe<PropertyDef<T>>),
        }));

        return selection?.selected?.has(item.id)
          ? map(toArray(selection.selected), (id) => ({
              entity: id,
              field,
              groups: when(groupValue, concat_(parents)),
            }))
          : [
              {
                entity: item.id,
                field,
                groups: when(groupValue, concat_(parents)),
              },
            ];
      },
      collect: (monitor) => (monitor.isDragging() ? { opacity: 0.5 } : {}),
      end: (_dragged, monitor) => {
        const target = monitor.getDropResult();
        const items = monitor.getItem();
        if (target) {
          onReorder?.(items, target);
        }
      },
    }),
    [item, field, selection, onReorder, group?.value, parents]
  );

  if (connect) {
    connect(dragRef(ref));
  } else {
    dragRef(ref);
  }

  return [{ opacity }, dragRef] as [{ opacity: number }, ConnectDragSource];
};

export const useItemDrop = <T extends Entity = Entity>({
  item,
  type,
  group,
  parents = [],
  connect,
  ref,
  acceptsFields = false,
  forcePosition,
}: DropProps<T>) => {
  const [{ dropping }, dropRef] = useDrop<
    DragRef<T>[],
    DragToRef<T>,
    { dropping: Maybe<DropPosition> }
  >(
    () => ({
      accept: type,
      canDrop: (t, m) =>
        (!t[0]?.field || acceptsFields) &&
        m.isOver({ shallow: !acceptsFields }),

      collect: (monitor) => ({
        dropping:
          monitor.canDrop() && monitor.isOver()
            ? forcePosition || "before"
            : undefined,
      }),

      drop: (_, m) => {
        const dropId = fallback(
          item?.id,
          () => (group as GroupedItems<T>)?.items?.[0]?.id
        );
        const dropElement = getSelectable(dropId);

        // Hardcode everything to be dropping before for now since you can see where you are dropping however both modes work...
        const searchPosition = forcePosition ?? "before";
        const isOtherBefore = searchPosition === "before";

        // When forcing before/after from drop opts, don't calculate between as is
        // used for getting start/end of a group and so before/after is getting item from next group...
        const otherId = when(
          !forcePosition ? dropElement?.element : undefined,
          isOtherBefore ? getPrevSelectable : getNextSelectable
        )?.key;

        // Copy the group def down to the property def
        const groupValue: Maybe<GroupedValue<T>> = when(group?.value, (v) => ({
          ...v,
          def: v.def || (group?.def as Maybe<PropertyDef<T>>),
        }));

        // Using both before and after to calculate new order
        if (isDefined(otherId) && isDefined(dropId)) {
          return {
            entity: isOtherBefore ? [otherId, dropId] : [dropId, otherId],
            groups: when(groupValue, concat_(parents)),
            position: "between",
          };
        }

        // Just using the dropped item location
        return {
          entity: dropId,
          groups: when(groupValue, concat_(parents)),
          position: searchPosition,
        };
      },
    }),
    [item, forcePosition, group?.value, parents]
  );

  if (connect) {
    connect(dropRef(ref));
  } else {
    dropRef(ref);
  }

  return [{ dropping }, dropRef] as [
    { dropping: Maybe<DropPosition> },
    ConnectDropTarget
  ];
};

export const useItemDragDrop = <T extends Entity = Entity>(
  props: DragProps<T>
) => {
  const [dragProps, dragRef] = useItemDrag<T>(props);

  const groupedItems = (props?.group as Maybe<GroupedItems>)?.items;
  const groupedPosition = groupedItems?.indexOf(props.item);
  // @ts-ignore - cant get types to line up with Drop
  const [dropProps] = useItemDrop<T>({
    ...props,
    type: props.item.source.type as TypeForEntity<T>,
    connect: dragRef,
    // Force before after (not between) when dragging to start/end of a group so that it doesn't calculate between groups
    forcePosition: props?.forcePosition
      ? props.forcePosition
      : props?.group && groupedPosition === 0
      ? "before"
      : props?.group && groupedPosition === groupedItems?.length
      ? "after"
      : undefined,
  });

  return { ...dragProps, ...dropProps };
};

interface DropTargetProps {
  type: EntityType;
  group?: NestedGroup;
  parents?: GroupedValue[];
  position: "before" | "after";
  item?: Entity;
  children: ReactNode;
}

export const DropTarget = ({
  type,
  group,
  position,
  parents,
  item,
  children,
}: DropTargetProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ dropping }] = useItemDrop({
    type,
    ref,
    group,
    parents,
    item,
    forcePosition: position,
  });
  return (
    <div ref={ref}>
      {dropping && <DropHighlight />}
      {children}
    </div>
  );
};
