import { mapValues } from "lodash";
import { RecoilState, selector } from "recoil";

import { Entity, EntityType } from "@api";
import { EntityForType } from "@api/mappings";

import { NoteStoreAtom } from "@state/notes";
import { ProjectStoreAtom } from "@state/projects";
import { StoreState } from "@state/store";
import { TaskStoreAtom } from "@state/tasks";
import { PersonStoreAtom } from "@state/persons";
import { TeamStoreAtom } from "@state/teams";
import { ViewStoreAtom } from "@state/views";
import { WorkspaceStoreAtom } from "@state/workspace";
import { ResourceStoreAtom } from "@state/resources";
import { OutcomeStoreAtom } from "@state/outcomes";
import { BacklogStoreAtom } from "@state/backlog";
import { SprintStoreAtom } from "@state/sprint";
import { ScheduleStoreAtom } from "@state/schedule";
import { CampaignStoreAtom } from "@state/campaigns";
import { CalendarStoreAtom } from "@state/calendar";
import { ContentStoreAtom } from "@state/content";
import { RoadmapStoreAtom } from "@state/roadmap";
import { ActionStoreAtom } from "@state/actions";
import { MeetingStoreAtom } from "@state/meetings";
import { AgendaStoreAtom } from "@state/agendas";
import { PageStoreAtom } from "@state/page";
import { ProcessStoreAtom } from "@state/process";
import { KnowledgeBaseStoreAtom } from "@state/knowledgebase";
import { FormStoreAtom } from "@state/form";
import { WorkflowStoreAtom } from "@state/workflow";
import { PipelineStoreAtom } from "@state/pipeline";
import { RequestStoreAtom } from "@state/request";
import { EventStoreAtom } from "@state/event";
import { CompanyStoreAtom } from "@state/company";
import { ContactStoreAtom } from "@state/contact";
import { DealStoreAtom } from "@state/deal";

import { switchEnum } from "@utils/logic";
import { cachedFunc } from "@utils/fn";
import { toMilliSeconds } from "@utils/time";

// Update on new entity
export const getStore = <
  T extends EntityType,
  E extends Entity = EntityForType<T>
>(
  t: T
): RecoilState<StoreState<E>> =>
  switchEnum<T, RecoilState<StoreState<E>>>(t, {
    // @ts-ignore cant't follow the mappings
    project: () => ProjectStoreAtom as RecoilState<StoreState<E>>,
    campaign: () => CampaignStoreAtom as RecoilState<StoreState<E>>,
    calendar: () => CalendarStoreAtom as RecoilState<StoreState<E>>,
    content: () => ContentStoreAtom as RecoilState<StoreState<E>>,
    task: () => TaskStoreAtom as RecoilState<StoreState<E>>,
    outcome: () => OutcomeStoreAtom as RecoilState<StoreState<E>>,
    backlog: () => BacklogStoreAtom as RecoilState<StoreState<E>>,
    roadmap: () => RoadmapStoreAtom as RecoilState<StoreState<E>>,
    sprint: () => SprintStoreAtom as RecoilState<StoreState<E>>,
    schedule: () => ScheduleStoreAtom as RecoilState<StoreState<E>>,
    view: () => ViewStoreAtom as RecoilState<StoreState<E>>,
    team: () => TeamStoreAtom as RecoilState<StoreState<E>>,
    note: () => NoteStoreAtom as RecoilState<StoreState<E>>,
    resource: () => ResourceStoreAtom as RecoilState<StoreState<E>>,
    meeting: () => MeetingStoreAtom as RecoilState<StoreState<E>>,
    agenda: () => AgendaStoreAtom as RecoilState<StoreState<E>>,
    action: () => ActionStoreAtom as RecoilState<StoreState<E>>,
    page: () => PageStoreAtom as RecoilState<StoreState<E>>,
    person: () => PersonStoreAtom as RecoilState<StoreState<E>>,
    process: () => ProcessStoreAtom as RecoilState<StoreState<E>>,
    knowledgebase: () => KnowledgeBaseStoreAtom as RecoilState<StoreState<E>>,
    form: () => FormStoreAtom as RecoilState<StoreState<E>>,
    workspace: () => WorkspaceStoreAtom as RecoilState<StoreState<E>>,
    event: () => EventStoreAtom as RecoilState<StoreState<E>>,
    request: () => RequestStoreAtom as RecoilState<StoreState<E>>,
    pipeline: () => PipelineStoreAtom as RecoilState<StoreState<E>>,
    workflow: () => WorkflowStoreAtom as RecoilState<StoreState<E>>,
    company: () => CompanyStoreAtom as RecoilState<StoreState<E>>,
    contact: () => ContactStoreAtom as RecoilState<StoreState<E>>,
    deal: () => DealStoreAtom as RecoilState<StoreState<E>>,
  });

export const getStoreCached = cachedFunc(
  () => getStore,
  toMilliSeconds("10 hours")
);

const TYPE_LOOKUP: Record<EntityType, true> = {
  project: true,
  campaign: true,
  calendar: true,
  content: true,
  task: true,
  outcome: true,
  backlog: true,
  roadmap: true,
  sprint: true,
  schedule: true,
  view: true,
  team: true,
  note: true,
  resource: true,
  meeting: true,
  agenda: true,
  action: true,
  page: true,
  person: true,
  process: true,
  knowledgebase: true,
  form: true,
  workspace: true,
  workflow: true,
  pipeline: true,
  event: true,
  request: true,
  company: true,
  contact: true,
  deal: true,
};

export const EntityStores = selector({
  key: "EntityStores",
  get: ({ get }) =>
    mapValues(TYPE_LOOKUP, (_, type) => get(getStore(type as EntityType))) as {
      [K in EntityType]: StoreState<EntityForType<K>>;
    },
});
