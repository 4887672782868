import { Note, DatabaseID, NoteType } from "@api";
import { now } from "@utils/date-fp";
import { newLocalHumanId } from "@utils/id";
import { toLocation } from "@utils/scope";

// Get default value from local storage effect
export const newNote = (n: { source: DatabaseID } & Partial<Note>): Note => ({
  id: newLocalHumanId("note"),
  title: undefined,
  summary: undefined,
  body: undefined,
  pinned: false,
  refs: {
    refs: [],
    seenBy: undefined,
    followers: undefined,
  },
  type: NoteType.Update,
  author: undefined,
  links: undefined,
  custom: {},
  stamps: {},
  location: toLocation(n.source.scope),

  ...n,

  createdAt: now(),
  createdBy: undefined,
  updatedAt: now(),
  updatedBy: undefined,
  fetchedAt: undefined,
  deletedAt: undefined,
  deletedBy: undefined,
});
