import styles from "./drop-highlight.module.css";

export const DropHighlight = ({ offset }: { offset?: number }) => (
  <div className={styles.container}>
    <div
      className={styles.insertBefore}
      style={{ top: offset ? `${offset}px` : "0px" }}
    />
  </div>
);
