import { map } from "lodash";
import { ReactNode, useMemo } from "react";

import { Content, Entity, ID } from "@api";

import { useOpenAppCommands } from "@state/app";
import { useMarkAsSeen, useQueueUpdates } from "@state/generic";
import { useActionWorkflows } from "@state/workflows";

import { timeAgo } from "@utils/date";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { uniqRefs } from "@utils/relation-ref";
import { usePushTo } from "@utils/navigation";
import { useISODate } from "@utils/date-fp";

import { useAppPageContext } from "@ui/app-page";
import { Container } from "@ui/container";
import { EditableHeading } from "@ui/editable-heading";
import { EntityProperties } from "@ui/entity-properties";
import { HStack, VStack } from "@ui/flex";
import { Sheet } from "@ui/sheet-layout";
import { TemplateBanner } from "@ui/template-banner";
import { Text } from "@ui/text";
import { WorkflowActionButton } from "@ui/workflow-action-button";
import { EmojiSelect } from "@ui/select/emoji";
import { EntityHeaderBar } from "@ui/entity-header-bar";

import { PaneOpts } from "../types";

import styles from "./styles.module.css";

export default function ContentPane({ id, item }: PaneOpts<Content>) {
  const pageId = useAppPageContext();
  const pushTo = usePushTo();

  // Mark the note as seen by current user
  useMarkAsSeen(id, pageId);

  if (!item) {
    return <></>;
  }

  return (
    <Sheet height="container" size="primary">
      {item.template && <TemplateBanner />}

      <ContentHeader id={id} content={item} />

      <Container
        stack="vertical"
        gap={20}
        fit="container"
        style={{ paddingTop: "10px" }}
      >
        <EntityProperties entityId={id} />
      </Container>
    </Sheet>
  );
}

interface HeaderProps {
  id: ID;
  content: Content;
  quickActions?: boolean;
  actions?: ReactNode;
}

export const ContentHeader = ({
  id,
  quickActions = true,
  content: entity,
  actions,
}: HeaderProps) => {
  const pageId = useAppPageContext();
  const showCommands = useOpenAppCommands(entity);
  const [workflowActions, actionData] = useActionWorkflows(entity);
  const people = useMemo(
    () =>
      uniqRefs([
        ...(entity?.refs?.seenBy || []),
        ...(entity?.refs?.followers || []),
      ]),
    [entity?.refs?.seenBy, entity?.refs?.followers]
  );

  const mutate = useQueueUpdates(pageId);

  if (!entity) {
    return <h1>Not found.</h1>;
  }

  return (
    <div>
      <EntityHeaderBar padding="both" entity={entity} />

      <VStack className={styles.panel} gap={12}>
        <VStack gap={0} fit="container">
          <Container
            fit="container"
            inset="left"
            padding="none"
            stack="horizontal"
            gap={6}
          >
            <EmojiSelect
              size="large"
              emoji={entity.icon || "✉️"}
              onChange={(emoji) =>
                mutate(
                  asUpdate(entity, [
                    asMutation({ field: "icon", type: "text" }, emoji),
                  ])
                )
              }
            />
            <EditableHeading
              key={entity.id}
              size="h2"
              text={entity.name || ""}
              placeholder="Untitled content..."
              onChange={(t) =>
                t &&
                mutate(
                  asUpdate<Entity>(entity, [
                    asMutation({ field: "name", type: "text" }, t),
                  ])
                )
              }
            />
          </Container>
        </VStack>

        <Container gap={2} stack="horizontal" inset="vertical" padding="none">
          {entity.publish && (
            <Text subtle>
              Content to be published {useISODate(entity.publish, timeAgo)}
            </Text>
          )}
        </Container>

        {quickActions && !entity?.template && !!workflowActions?.length && (
          <HStack gap={4}>
            {map(
              workflowActions,
              (a, i) =>
                actionData && (
                  <WorkflowActionButton
                    key={a.id}
                    variant={i === 0 ? "primary" : "secondary"}
                    action={a}
                    data={actionData}
                    source={entity.source}
                  />
                )
            )}
          </HStack>
        )}
      </VStack>
    </div>
  );
};
