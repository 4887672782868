import { map } from "lodash";
import { useCallback, useEffect, useState } from "react";

import { Entity, EntityType, PropertyValueRef, Update } from "@api";

import { Maybe } from "@graph";

import { useCombinedStore, useLazyEntity } from "@state/generic";
import {
  useRunAction,
  useSuggestionWorkflows,
  WorkflowSuggestion,
} from "@state/workflows";

import { Fn } from "@utils/fn";

import { showSuggestion } from "@ui/notifications";
import { WorkflowCollectDialog } from "@ui/workflow-collect-dialog";

const SuggestionRunner = ({
  entity,
  update,
  suggestion,
  onClose,
}: {
  entity: Entity;
  update: Update<Entity>;
  suggestion: WorkflowSuggestion<Entity>;
  onClose: Fn<void, void>;
}) => {
  const { run, context } = useRunAction<EntityType, Entity>(
    entity.source.type || "task",
    { entity }
  );

  const handleRun = useCallback(
    (
      action: WorkflowSuggestion<Entity>,
      collected: PropertyValueRef<Entity>[]
    ) => {
      run?.(action, collected);
      onClose();
    },
    [run, onClose]
  );

  if (!context) {
    return <></>;
  }

  return (
    <WorkflowCollectDialog
      action={suggestion}
      data={{ entity, update }}
      source={entity.source}
      context={context}
      onCollected={handleRun}
      onCancel={onClose}
    />
  );
};

export const WorkflowSuggestions = () => {
  const { unsaved, updating, history } = useCombinedStore();
  const toSuggestion = useSuggestionWorkflows();
  const [collecting, setCollecting] =
    useState<Maybe<[Update<Entity>, WorkflowSuggestion<Entity>]>>();
  const collectingEntity = useLazyEntity(collecting?.[0]?.id);

  useEffect(() => {
    map([...unsaved, ...updating], (update) => {
      const suggestions = toSuggestion(update);

      map(suggestions, (w) => {
        const { suggestion } = w;

        showSuggestion(
          suggestion.id,
          suggestion.text,
          suggestion.description,
          map(suggestion.options, (o, i) =>
            i === suggestion.options?.length - 1
              ? {
                  ...o,
                  onClick: () => setCollecting([update, w]),
                }
              : o
          )
        );
      });
    });
  }, [unsaved, history]);

  return (
    <>
      {collecting && collectingEntity && (
        <SuggestionRunner
          entity={collectingEntity}
          update={collecting[0]}
          suggestion={collecting[1]}
          onClose={() => setCollecting(undefined)}
        />
      )}
    </>
  );
};
