import { groupBy, map } from "lodash";
import { useMemo } from "react";

import { HasResources, Ref } from "@api";

import {
  Resource,
  useAttachFile,
  useAttachLink,
  useLazyGetResources,
  useUpdateResource,
} from "@state/resources";
import { useDeleteEntitys } from "@state/generic";
import { useActiveWorkspaceId } from "@state/workspace";

import { Maybe } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { usePageSelection } from "@utils/selectable";
import { useShowMore } from "@utils/hooks";

import { usePageId } from "@ui/app-page";
import { CollapsibleSection } from "@ui/collapsible-section";
import { Container } from "@ui/container";
import { HStack, HalfSpace, VStack } from "@ui/flex";
import { ShowMoreMenuItem } from "@ui/menu-item";
import { AddResourcesButton } from "@ui/resources-add-button";
import { Text } from "@ui/text";
import { render, useEngine } from "@ui/engine";
import { UploadFileButton } from "./upload-modal";

interface Props {
  refs: Maybe<Ref[]>;
  entity?: HasResources;
  mode?: "important" | "unseen" | "pinned" | "all";
  sectionLabel?: string;
  hideOnEmpty?: boolean;
  onClick?: (update: Resource) => void;
}

export const ResourcesSection = ({
  entity,
  refs,
  sectionLabel,
  mode = "all",
  hideOnEmpty = false,
  onClick: _onClick,
}: Props) => {
  const pageId = usePageId();
  const goTo = useGoTo();
  const wID = useActiveWorkspaceId();
  const resources = useLazyGetResources(refs);
  const mutate = useUpdateResource(pageId);
  const onLinkAdded = useAttachLink(entity, pageId);
  const onFileUploaded = useAttachFile(entity, pageId);
  const onDelete = useDeleteEntitys(pageId);
  const onClick = useMemo(() => _onClick || goTo, [_onClick]);
  const [selection, setSelection] = usePageSelection();

  const { pinned, rest } = useMemo(
    () => groupBy(resources, (n) => (!!n.pinned ? "pinned" : "rest")),
    [resources]
  );
  const nonPinned = useShowMore(rest, 5);

  if (hideOnEmpty && !pinned.length) {
    return <></>;
  }

  const engine = useEngine("resource");

  if (!entity) {
    return <></>;
  }

  return (
    <CollapsibleSection
      labelSize="medium"
      count={(pinned?.length || 0) + (rest?.length || 0)}
      title={`${sectionLabel || "Attachments"}`}
    >
      <VStack gap={8} fit="container">
        <Container
          padding="none"
          inset="left"
          gap={8}
          fit="container"
          wrap
          stack="horizontal"
        >
          {map(pinned, (u) => (
            <HalfSpace key={u.id} gap={8}>
              {render(engine.asListCard, {
                key: u.id,
                item: u,
                selection: selection,
                setSelection: setSelection,
                onOpen: () => onClick?.(u),
                onChange: (c) => mutate(u, c),
                onDelete: (r) => onDelete([r.id]),
              })}
            </HalfSpace>
          ))}
        </Container>
        {nonPinned && (
          <VStack gap={0} fit="container" wrap>
            {map(nonPinned.visible, (u) =>
              render(engine.asListItem, {
                key: u.id,
                item: u,
                selection: selection,
                setSelection: setSelection,
                onOpen: () => onClick?.(u),
                onChange: (c) => mutate(u, c),
                onDelete: (r) => onDelete([r.id]),
              })
            )}
            {nonPinned.hasMore && (
              <ShowMoreMenuItem
                count={nonPinned.moreCount}
                onClick={nonPinned.showMore}
              />
            )}
          </VStack>
        )}

        <HStack gap={0}>
          <AddResourcesButton
            entity={entity}
            defaultPinned={true}
            mode="new-link"
            onAddLink={onLinkAdded}
          >
            <Text subtle>Link to</Text>
          </AddResourcesButton>

          <UploadFileButton
            scope={entity.source.scope}
            onUploaded={onFileUploaded}
          >
            <Text subtle>Upload</Text>
          </UploadFileButton>
        </HStack>
      </VStack>
    </CollapsibleSection>
  );
};
