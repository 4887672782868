import { ReactNode } from "react";
import { map } from "lodash";

import { ID, Outcome, Task, isOutcome, isTask } from "@api";

import { useActionWorkflows } from "@state/workflows";
import { useLazyEntity, useUpdateEntity } from "@state/generic";

import { timeAgo } from "@utils/date";
import { now } from "@utils/now";
import { asMutation } from "@utils/property-mutations";
import { fromPointDate } from "@utils/date-fp";

import { HStack, VStack } from "@ui/flex";
import { WorkflowActionButton } from "@ui/workflow-action-button";
import { SectionLabel } from "@ui/label";
import { SubtasksIcon } from "@ui/icon";
import { EditableHeading } from "@ui/editable-heading";
import { useAppPageContext } from "@ui/app-page";
import { CodeLabel } from "@ui/code-label";
import { RelationLabel } from "@ui/relation-label";
import { Container } from "@ui/container";
import { EntityHeaderBar } from "@ui/entity-header-bar";

import styles from "./task-header.module.css";

interface Props {
  id: ID;
  quickActions?: boolean;
  actions?: ReactNode;
}

export const TaskHeader = ({ id, quickActions = true, actions }: Props) => {
  const pageId = useAppPageContext();
  const entity = useLazyEntity(id);

  if (!!entity && !isTask(entity) && !isOutcome(entity)) {
    throw new Error("TaskHeader only supports tasks and outcomes.");
  }

  const parent = useLazyEntity(entity?.refs?.parent?.[0]?.id || "");
  const [workflowActions, actionData] = useActionWorkflows(entity);

  const mutate = useUpdateEntity(entity?.id || "", pageId);

  if (!entity) {
    return <h1>Not found.</h1>;
  }

  return (
    <div>
      <EntityHeaderBar padding="both" entity={entity} />

      <VStack className={styles.panel} gap={12}>
        <VStack gap={0}>
          {entity.refs?.parent ? (
            <HStack>
              <CodeLabel code={(parent as Task | Outcome)?.code} />
              <SectionLabel icon={SubtasksIcon} />
              <CodeLabel code={entity?.code} />
            </HStack>
          ) : (
            <HStack>
              <CodeLabel code={entity?.code} />
            </HStack>
          )}

          <EditableHeading
            key={entity.id}
            size="h2"
            text={entity.title || ""}
            placeholder="Untitled"
            onChange={(t) =>
              t && mutate(asMutation({ field: "title", type: "text" }, t))
            }
          />
        </VStack>

        <Container gap={2} stack="horizontal" inset="vertical" padding="none">
          {entity.createdBy && (
            <p className={styles.secondary}>
              Created by{" "}
              <RelationLabel
                className={styles.creator}
                relation={entity.createdBy}
              />{" "}
              {timeAgo(fromPointDate(entity.createdAt) || now())}
            </p>
          )}
        </Container>

        {quickActions &&
          entity?.source.type === "task" &&
          !entity?.template &&
          !!workflowActions?.length && (
            <HStack gap={4}>
              {map(
                workflowActions,
                (a, i) =>
                  actionData && (
                    <WorkflowActionButton
                      key={a.id}
                      variant={i === 0 ? "primary" : "secondary"}
                      action={a}
                      data={actionData}
                      source={entity.source}
                    />
                  )
              )}
            </HStack>
          )}
      </VStack>
    </div>
  );
};
