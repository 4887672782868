import { ReactNode, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Fn, fallback } from "@utils/fn";
import { cx } from "@utils/class-names";
import { respectHandled } from "@utils/event";
import { ifDo_ } from "@utils/logic";
import { when } from "@utils/maybe";
import { ComponentOrNode } from "@utils/react";

import { Button } from "@ui/button";
import { AngleRightIcon, Dot, Icon } from "@ui/icon";
import { HStack, SpaceBetween } from "@ui/flex";
import { MenuItem } from "./default";

import styles from "./styles.module.css";

interface Props {
  id: string;
  title: string;
  link?: string;
  indent?: number;
  expandable?: boolean;
  icon?: ComponentOrNode;
  actions?: ReactNode;
  children?: Fn<string, ReactNode>;
  className?: string;
  expanded?: boolean;
  onExpand?: (id: string, expanded: boolean) => void;
  onSelect?: Fn<string, void>;
}

export function TreeMenuItem({
  id,
  title,
  link,
  icon: _icon,
  indent,
  actions,
  className,
  children,
  onExpand,
  expanded,
  onSelect,
  expandable,
}: Props) {
  const hasChildren = !!children;
  const navigate = useNavigate();
  const icon = useMemo(
    () =>
      fallback(
        ifDo_(expandable, () => (
          <Button
            className={styles.expandIcon}
            icon={
              <Icon
                icon={AngleRightIcon}
                style={expanded ? { transform: "rotate(90deg)" } : {}}
              />
            }
            variant="link"
            onClick={(e: React.MouseEvent) => {
              if (onExpand) {
                onExpand?.(id, !(expanded || false));
                e.stopPropagation();
                return false;
              }
            }}
          />
        )),
        ifDo_(
          () => !expandable && hasChildren,
          () => <Icon icon={Dot} />
        ),
        () => when(_icon, (i) => <Icon icon={i} size="small" />)
      ),
    [onExpand, hasChildren, _icon]
  );
  return (
    <>
      <MenuItem
        className={cx(
          styles.menuItem,
          !icon && !hasChildren && styles.noIcon,
          className
        )}
        indent={indent}
        icon={icon}
        onClick={respectHandled(() => {
          if (onSelect) {
            onSelect?.(id);
          } else if (link) {
            navigate(link);
          }
        })}
      >
        <SpaceBetween>
          <HStack gap={6}>
            {hasChildren && _icon ? (
              <Icon icon={_icon} size="small" />
            ) : undefined}
            {title}
          </HStack>
          {actions}
        </SpaceBetween>
      </MenuItem>

      {expanded && children?.(id)}
    </>
  );
}
