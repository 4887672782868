import { useMemo } from "react";
import { addYears } from "date-fns";

import { Entity, PropertyDef, SingleFilterQuery } from "@api";
import { Fn } from "@utils/fn";
import { Maybe, when } from "@utils/maybe";
import { isEmptyFilter } from "@utils/filtering";
import { cx } from "@utils/class-names";
import { construct, deconstruct, evaluate, FormulaParts } from "@utils/formula";
import { now } from "@utils/now";
import { fromCalDate, toCalDate } from "@utils/date-fp";

import { Dropdown, useOpenState } from "@ui/dropdown";
import { DatePicker, DateRangePicker } from "@ui/date-picker";
import { Divider } from "@ui/divider";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Text } from "@ui/text";
import { ButtonGroup, SplitButton } from "@ui/button-group";
import { TextInput } from "@ui/input";
import { Container } from "@ui/container";
import { CheckMenuItem } from "@ui/menu-item";

import { TriggerButton } from "./trigger-button";
import { OpHeader } from "./op-header";

import styles from "./date.module.css";

export interface Props {
  filter: SingleFilterQuery;
  definition: PropertyDef<Entity>;
  onChanged?: Fn<Maybe<SingleFilterQuery>, void>;
  editing?: boolean;
}

export default function PropertyFilterDate({
  filter,
  definition,
  onChanged,
  editing,
}: Props) {
  const [open, setOpen] = useOpenState(editing);
  const formula = useMemo(
    () => when(filter.value?.formula, deconstruct),
    [filter.value?.formula]
  );
  const selected = useMemo(
    () =>
      when(filter.value?.formula, evaluate) || fromCalDate(filter.value?.date),
    [filter.value?.date, filter.value?.formula]
  );
  const mode = !!formula ? "formula" : "date";

  const handleDateChanged = (v: Maybe<Date>) => {
    onChanged?.({
      ...filter,
      value: { date: when(v, (d) => toCalDate(d, "local")) },
    });
  };
  const handleFormulaChanged = (v: Partial<FormulaParts>) => {
    onChanged?.({
      ...filter,
      value: v
        ? {
            formula: construct({
              ...formula,
              ...v,
            } as FormulaParts),
          }
        : { date: undefined },
    });
  };

  return (
    <Dropdown
      open={open}
      setOpen={setOpen}
      portal={true}
      className={{ popover: styles.popover }}
      trigger={
        <TriggerButton
          filter={filter}
          prop={definition}
          onChanged={onChanged}
        />
      }
    >
      <OpHeader
        filter={filter}
        prop={definition}
        onChanged={onChanged}
        setOpen={setOpen}
      />

      <Container className={styles.container}>
        {!isEmptyFilter(filter) && (
          <VStack>
            <ButtonGroup fit="container">
              <SplitButton
                size="small"
                selected={mode === "date"}
                onClick={() => handleDateChanged(undefined)}
              >
                Fixed dates
              </SplitButton>
              <SplitButton
                size="small"
                selected={mode === "formula"}
                onClick={() =>
                  handleFormulaChanged({ op: "-", freq: 7, period: "d" })
                }
              >
                Relative dates
              </SplitButton>
            </ButtonGroup>

            {mode === "formula" && (
              <>
                <HStack>
                  <Text subtle>Dates</Text>

                  <ButtonGroup>
                    <SplitButton
                      size="small"
                      selected={formula?.op === "-"}
                      onClick={() => handleFormulaChanged({ op: "-" })}
                    >
                      Last
                    </SplitButton>
                    <SplitButton
                      size="small"
                      selected={formula?.op === "+"}
                      onClick={() => handleFormulaChanged({ op: "+" })}
                    >
                      Next
                    </SplitButton>
                  </ButtonGroup>
                  <TextInput
                    className={styles.input}
                    value={String(formula?.freq)}
                    inputType="number"
                    updateOn="change"
                    onChange={(v) =>
                      handleFormulaChanged({
                        freq: Math.max(Math.abs(parseInt(v)), 0),
                      })
                    }
                    placeholder="#"
                  />
                </HStack>

                <SpaceBetween gap={6}>
                  <Text subtle>Period</Text>
                  <ButtonGroup fit="container">
                    <SplitButton
                      size="small"
                      selected={formula?.period === "d"}
                      onClick={() => handleFormulaChanged({ period: "d" })}
                    >
                      Days
                    </SplitButton>
                    <SplitButton
                      size="small"
                      selected={formula?.period === "w"}
                      onClick={() => handleFormulaChanged({ period: "w" })}
                    >
                      Weeks
                    </SplitButton>
                    <SplitButton
                      size="small"
                      selected={formula?.period === "m"}
                      onClick={() => handleFormulaChanged({ period: "m" })}
                    >
                      Months
                    </SplitButton>
                  </ButtonGroup>
                </SpaceBetween>

                <CheckMenuItem
                  inset
                  checked={filter.empty ?? false}
                  onChecked={(t) => onChanged?.({ ...filter, empty: t })}
                >
                  Include empty values
                </CheckMenuItem>

                <Divider />

                <DateRangePicker
                  className={cx(styles.picker, styles.faded)}
                  dates={
                    filter?.op === "after" || filter?.op === "on_or_after"
                      ? { from: selected, to: addYears(now(), 10) }
                      : { from: addYears(now(), -10), to: selected }
                  }
                  onChanged={() => {}}
                />
              </>
            )}

            {mode === "date" && (
              <>
                <CheckMenuItem
                  inset
                  checked={filter.empty ?? false}
                  onChecked={(t) => onChanged?.({ ...filter, empty: t })}
                >
                  Include empty values
                </CheckMenuItem>
                <DatePicker
                  className={cx(styles.picker)}
                  date={selected}
                  onChanged={handleDateChanged}
                />
              </>
            )}
          </VStack>
        )}
      </Container>
    </Dropdown>
  );
}
