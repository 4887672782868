import { filter, find } from "lodash";

import { CreateOrUpdate, Backlog } from "@api";
import { WorkflowDefinition, WorkflowDefinitionConfig } from "@state/workflows";

import { asMutation, asUpdate } from "@utils/property-mutations";
import { maybeMap } from "@utils/maybe";

// When changing backlog.fields, update the inbox filter
export const backlogFieldsChanged: WorkflowDefinition<Backlog> = {
  id: "backlogFieldsChanged",
  trigger: "WILL_UPDATE",
  type: "backlog",
  allowed: ({ update }) =>
    // Only when updating backlogs, not on creation
    update.method === "update" &&
    // When changing fields field
    filter(update.changes, (c) => c.field === "fields")?.length === 1,

  execute: ({ entity, update }, _context) => {
    const change = find(
      (update as CreateOrUpdate<Backlog>)?.changes,
      (c) => c.field === "fields"
    );
    const inboxId = entity.inbox?.id;

    if (!inboxId) {
      return [];
    }

    return asUpdate(
      {
        id: inboxId,
        source: { type: "view", scope: entity.source.scope },
      },

      asMutation(
        { field: "filter", type: "json" },
        {
          or: maybeMap(change?.value.properties, (p) => ({
            field: p.field,
            type: p.type,
            op: "is_empty",
          })),
        }
      )
    );
  },
};

export const definitions: WorkflowDefinitionConfig<Backlog> = {
  triggers: [backlogFieldsChanged],
  actions: [],
};

export default definitions;
