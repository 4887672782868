import { filter, find } from "lodash";

import { CreateOrUpdate, Entity, Sprint, Update } from "@api";
import {
  WorkflowAction,
  WorkflowContext,
  WorkflowDefinition,
  WorkflowDefinitionConfig,
} from "@state/workflows";

import { asMutation, asUpdate } from "@utils/property-mutations";
import { inflateProperty } from "@utils/property-refs";
import { durationInDays, toEndDate } from "@utils/date";
import { now } from "@utils/now";

import { ParentCloseDialog } from "@ui/parent-close-dialog";
import { ParentStartDialog } from "@ui/parent-start-dialog";
import { fromPointDate, useCalDate } from "@utils/date-fp";

// // Archive a sprint when completed
export const closeSprint: WorkflowAction<Sprint, Update<Entity>[]> = {
  id: "closeSprint",
  trigger: "ACTION",
  type: "sprint",
  icon: undefined,
  title: "Close sprint",

  allowed: ({ entity }, { props }) =>
    // Is in planning or not started
    ["in-progress"]?.includes(
      inflateProperty(entity, find(props, { field: "status" }))?.status
        ?.group || "not-started"
    ),

  collect: ({ data: { entity }, onCollected, onCancelled, context }) => (
    <ParentCloseDialog
      entity={entity}
      toParent={{ field: "refs.sprint", type: "relation" }}
      onCancel={onCancelled}
      onProcess={onCollected}
      context={context as WorkflowContext<Entity>}
    />
  ),
  execute: ({ entity, collected }, { session }) => {
    return collected as Update<Sprint>[];
  },
};

// Sets the task.followers from person relations
export const sprintDatesDurationsSync: WorkflowDefinition<Sprint> = {
  id: "sprint-dates-duration-sync",
  trigger: "WILL_UPDATE",
  type: "sprint",
  allowed: ({ update }) =>
    // Only when updating sprints, not on creation
    update.method === "update" &&
    // When ONLY changing either start, end or duration
    filter(
      update.changes,
      (c) => c.field === "start" || c.field === "end" || c.field === "duration"
    )?.length === 1,

  execute: ({ entity, update }, _context) => {
    const change = find(
      (update as CreateOrUpdate<Sprint>)?.changes,
      (c) => c.field === "start" || c.field === "end" || c.field === "duration"
    );

    if (change?.field === "duration") {
      return asUpdate(entity, [
        asMutation(
          { field: "end", type: "date" },
          useCalDate(entity.start, (d) =>
            toEndDate(d || now(), change.value.number || 0)
          )
        ),
      ]);
    }

    if (change?.field === "start") {
      return asUpdate(entity, [
        asMutation(
          { field: "end", type: "date" },
          useCalDate(change.value.date, (d) =>
            toEndDate(d || now(), entity.duration || 0)
          )
        ),
      ]);
    }

    if (change?.field === "end") {
      return asUpdate(entity, [
        asMutation(
          { field: "duration", type: "number" },
          useCalDate(change.value.date, (d) =>
            durationInDays(d || now(), fromPointDate(entity.start) || now())
          )
        ),
      ]);
    }

    return [];
  },
};

// Archive a sprint when completed
export const startSprint: WorkflowAction<Sprint, Update<Entity>[]> = {
  id: "startSprint",
  trigger: "ACTION",
  type: "sprint",
  icon: undefined,
  title: "Start sprint",

  allowed: ({ entity }, { props }) =>
    // Is in planning or not started
    ["planning", "not-started"]?.includes(
      inflateProperty(entity, find(props, { field: "status" }))?.status
        ?.group || "not-started"
    ),
  collect: ({ data: { entity }, onCollected, onCancelled, context }) => (
    <ParentStartDialog
      entity={entity}
      toParentProp={{ field: "refs.sprint", type: "relation" }}
      context={context as WorkflowContext<Entity>}
      onCollected={onCollected}
      onCancelled={onCancelled}
    />
  ),
  execute: ({ entity, collected }, { session }) => {
    return collected as Update<Sprint>[];
  },
};

export const definitions: WorkflowDefinitionConfig<Sprint> = {
  triggers: [sprintDatesDurationsSync],
  actions: [startSprint, closeSprint],
};

export default definitions;
