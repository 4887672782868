import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useLazyGetResource } from "@state/resources";

import { useSyncPathnameID } from "@utils/url";

import { Sheet, StackContainer } from "@ui/sheet-layout";

import AppPage from "./app-page";
import { useEffect } from "react";
import { useBrowserBack, useGoTo } from "@utils/navigation";

interface Props {
  resourceId: string;
}

export default function ResourcePage({ resourceId }: Props) {
  const goTo = useGoTo();
  const goBack = useBrowserBack();
  const resource = useLazyGetResource(resourceId);
  const [page] = useRegisterPage(resourceId, resource);

  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(resourceId, resource?.id);

  useEffect(() => {
    if (resource?.url) {
      goTo(resource.url);

      goBack();
    }
  }, [resource?.url]);

  return (
    <AppPage page={page} title={resource?.name} loading={!resource}>
      <StackContainer>
        <Sheet>Opening in a new tab...</Sheet>
      </StackContainer>
    </AppPage>
  );
}
