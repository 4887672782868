import { atomFamily, selectorFamily } from "recoil";

import { ID, Request } from "@api";

import {
  getItem,
  indexedDBStorageForStore,
  mergeItem,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { isDefault } from "@utils/recoil";

export type RequestStoreState = StoreState<Request>;

export const WorkspaceRequestStoreAtom = atomFamily<RequestStoreState, ID>({
  key: "WorkspaceRequestStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "request")],
});

export const RequestStoreAtom = WorkspaceWrappedAtom(
  "RequestStoreAtom",
  WorkspaceRequestStoreAtom
);

export const RequestAtom = selectorFamily({
  key: "RequestAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(RequestStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(RequestStoreAtom, mergeItem<Request>(newValue));
      }
    },
});
