import { ReactNode, Ref, forwardRef, useCallback, useMemo } from "react";
import { camelCase } from "change-case";

import { Fn } from "@utils/fn";
import { cx } from "@utils/class-names";
import { ComponentOrNode } from "@utils/react";
import { Label } from "./label";
import { Icon, SpinnerIcon, Props as IconProps } from "./icon";

import styles from "./button.module.css";

export interface Props {
  icon?: ComponentOrNode;
  iconRight?: ComponentOrNode;
  children?: ReactNode;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  as?: "button" | "div";
  fit?: "container" | "content";
  variant?:
    | "primary"
    | "primary-alt"
    | "secondary"
    | "danger"
    | "ai"
    | "icon-only"
    | "link";
  subtle?: boolean;
  size?: "tiny" | "small" | "regular";
  iconSize?: IconProps["size"];
  inset?: boolean;
  wrapLabel?: boolean;
  onClick?: Fn<React.MouseEvent, void>;
  style?: React.CSSProperties;
  onMouseEnter?: Fn<React.MouseEvent, void>;
  onMouseLeave?: Fn<React.MouseEvent, void>;
  onMouseDown?: Fn<React.MouseEvent, void>;
}

export const Button = forwardRef(
  (
    {
      children,
      className,
      icon,
      iconRight,
      iconSize,
      disabled,
      as = "div",
      subtle = false,
      loading = false,
      size = "regular",
      variant = "secondary",
      fit = "content",
      inset,
      wrapLabel,
      style,
      onClick: _onClick,
      ...handlers
    }: Props,
    ref: Ref<HTMLDivElement>
  ) => {
    const onClick = useCallback(
      (e: React.MouseEvent) => {
        if (loading || disabled) {
          return;
        }
        _onClick?.(e);
      },
      [_onClick, loading, disabled]
    );
    const label = useMemo(
      () =>
        wrapLabel === false ? (
          children
        ) : (
          <Label
            className={cx(styles.label)}
            iconClassName={styles.icon}
            size={iconSize}
            icon={icon}
            iconRight={iconRight}
          >
            {children}
          </Label>
        ),
      [children, icon, iconRight]
    );

    const props = useMemo(
      () => ({
        className: cx(
          styles.button,
          styles[subtle ? camelCase("subtle-" + variant) : camelCase(variant)],
          disabled && styles.disabled,
          !children && styles.noText,
          variant !== "link" && styles[size],
          variant !== "link" && subtle && styles.subtle,
          !!icon && styles.iconLeft,
          !!iconRight && styles.iconRight,
          loading && styles.loading,
          styles[fit],
          className
        ),
        style: inset
          ? {
              ...style,
              marginLeft: "-6px",
              width: fit === "container" ? "calc(100% + 6px)" : undefined,
              display: "table", // Fixes weird flex content clipping
            }
          : style,
      }),
      [
        className,
        icon,
        iconRight,
        children,
        subtle,
        disabled,
        loading,
        size,
        variant,
        fit,
        inset,
      ]
    );

    if (ref && as === "button") {
      throw new Error("Can't use ref with as=button.");
    }

    return as === "button" ? (
      <button {...props} {...handlers} onClick={onClick}>
        {label}
        {loading && <Icon className={styles.spinner} icon={SpinnerIcon} />}
      </button>
    ) : (
      <div ref={ref} {...props} {...handlers} onClick={onClick}>
        {label}
        {loading && <Icon className={styles.spinner} icon={SpinnerIcon} />}
      </div>
    );
  }
);
