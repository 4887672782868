import { find, first, isString, map, without } from "lodash";
import { ReactNode, useCallback, useMemo, useState } from "react";

import {
  AggregatePropRef,
  Entity,
  GroupByPropDef,
  PropertyDef,
  PropertyRef,
  PropertyType,
  PropertyValueRef,
  SelectOption,
} from "@api";

import {
  countForGroup,
  countUniqValues,
  EMPTY_KEY,
  orderedItemsForGroup,
  PropertyValueRefCount,
  ViewState,
} from "@state/views";
import { useEntitySource } from "@state/generic";

import { cx } from "@utils/class-names";
import { Fn, use } from "@utils/fn";
import { Maybe, when } from "@utils/maybe";
import { GroupedValue, isNested, NestedGroup } from "@utils/grouping";
import {
  asPropertyValueRef,
  colorForTag,
  toKey,
  toLabel,
} from "@utils/property-refs";
import { respectHandled, withHandle, withHardHandle } from "@utils/event";
import { maybeMap } from "@utils/array";
import { fromColor, toColorVar } from "@utils/color";
import { aggregateBy } from "@utils/aggregating";

import { ActionItem, ActionMenu } from "@ui/action-menu";
import { Button } from "@ui/button";
import { AngleDownIcon, AngleRightIcon, EyeSlash, TagAlt } from "@ui/icon";
import { RelationIcon, RelationLabel } from "@ui/relation-label";
import { Text, TextMedium } from "@ui/text";
import { PropertyLabel } from "@ui/property-label";
import { Tag } from "@ui/tag";
import { CollapsibleSection } from "./collapsible-section";
import { WorkProgression } from "@ui/child-work-progression";
import { HStack, SpaceBetween } from "@ui/flex";
import { PropertyRefEditDialog } from "@ui/property-edit-dialog";

import styles from "./nested-groups.module.css";

type GroupHeadingProps = {
  view: ViewState;
  onChanged?: Fn<GroupByPropDef<Entity, PropertyType>, void>;
  inset?: boolean;
  open?: boolean;
  onOpen?: Fn<boolean, void>;
  group: NestedGroup<Entity>;
  aggregate?: [AggregatePropRef, PropertyDef<Entity>];
  className?: string;
  children?: ReactNode;
};

export const GroupHeading = ({
  group,
  open,
  onOpen,
  inset = true,
  className,
  children,
  aggregate,
  onChanged,
  view,
}: GroupHeadingProps) => {
  const { items, subGroups } = useMemo(
    () =>
      isNested(group)
        ? { subGroups: group.groups, items: undefined }
        : { subGroups: undefined, items: group.items },
    [group]
  );
  const [editProp, setEditProp] = useState<PropertyRef<Entity>>();
  const itemSource = useEntitySource(view.entity, view.source);
  const count = useMemo(() => countForGroup(group), [group]);
  const counts = useMemo(
    (): (PropertyValueRefCount | PropertyValueRef)[] =>
      aggregate
        ? use(orderedItemsForGroup(group), (items) =>
            aggregate?.[0]?.method !== "count"
              ? [
                  asPropertyValueRef(aggregate?.[0], {
                    number: aggregateBy(items, aggregate?.[0], aggregate?.[1]),
                  }),
                ]
              : countUniqValues(items, aggregate?.[1])
          )
        : [],
    [group, aggregate]
  );

  const onHide = useCallback(() => {
    if (!group.def) {
      return;
    }

    const key = toKey(group.value, group.def?.format, EMPTY_KEY) || EMPTY_KEY;
    if (group.def.alwaysShow?.includes(key)) {
      // Remove always show from an empty group inside a
      // hide empty groups view
      onChanged?.({
        ...group.def,
        alwaysShow: without(group.def.alwaysShow, key),
      });
    } else {
      // Force hide a group with tasks
      onChanged?.({
        ...group.def,
        alwaysShow: without(group.def.alwaysShow, key),
        alwaysHide: [...(group.def.alwaysHide || []), key],
      });
    }
  }, [group]);

  return (
    <Button
      subtle
      data-sync-height="group-heading"
      inset={inset}
      icon={when(onOpen, () => (!open ? AngleRightIcon : AngleDownIcon))}
      fit="container"
      as="div"
      className={cx(
        styles.groupHeader,
        styles.open,
        className,
        !subGroups?.length && !items?.length && styles.hidden
      )}
      onClick={respectHandled(withHardHandle((e) => onOpen?.(!open)))}
    >
      {children}
      {editProp && (
        <PropertyRefEditDialog
          prop={editProp}
          source={itemSource}
          onClose={() => setEditProp(undefined)}
        />
      )}
      <SpaceBetween>
        <HStack>
          <Button size="tiny" subtle inset>
            <PropertyLabel
              onClick={withHandle(() => setEditProp(group.def))}
              className={styles.headerLabel}
              valueRef={group?.value}
              format={group?.def?.format}
              source={itemSource}
            />
          </Button>
          <TextMedium bold subtle>
            {count}
          </TextMedium>
        </HStack>

        <HStack gap={4}>
          {group.def?.field !== "status" && (
            <WorkProgression items={items || []} source={itemSource} />
          )}

          {maybeMap(counts, (count) => (
            <Tag
              key={toKey(count)}
              color={count?.value?.status?.color || count?.value?.select?.color}
            >
              {when(count, (c) => toLabel(c, aggregate?.[1]?.format))}{" "}
              <Text subtle>{(count as PropertyValueRefCount)?.count}</Text>
            </Tag>
          ))}

          {!!onChanged && (
            <ActionMenu
              actions={
                <>
                  <ActionItem
                    icon={TagAlt}
                    onClick={() =>
                      when(group.def || group.value.def, (d) =>
                        setEditProp(d as PropertyDef<Entity>)
                      )
                    }
                  >
                    Edit field
                  </ActionItem>
                  <ActionItem icon={EyeSlash} onClick={onHide}>
                    Hide
                  </ActionItem>
                </>
              }
            />
          )}
        </HStack>
      </SpaceBetween>
    </Button>
  );
};

export const GroupColor = ({
  group,
  dropping,
}: {
  group: Maybe<GroupedValue>;
  dropping: boolean;
}) => {
  const style = useMemo(() => {
    const color =
      when(
        group?.value.status ||
          group?.value.select ||
          first(group?.value.multi_select),
        (option) =>
          colorForTag(
            find(
              group?.def?.values as SelectOption[],
              (v) => v.id === option.id
            ) || option
          )
      ) || "gray_5";
    const [baseColor] = fromColor(color);

    return {
      background: toColorVar(baseColor, 5),
    };
  }, [group?.value]);

  return (
    <div
      className={cx(styles.groupColor, dropping && styles.dropping)}
      style={style}
    />
  );
};

type HiddenGroupsProps = {
  hidden?: NestedGroup<Entity>[];
  open?: boolean;
  onChanged?: Fn<GroupByPropDef<Entity, PropertyType>, void>;
  className?: string | { list?: string; container?: string };
};

export function HiddenGroups({
  open,
  hidden,
  onChanged,
  className,
}: HiddenGroupsProps) {
  const onShow = useCallback(
    (group: NestedGroup) =>
      group.def &&
      onChanged?.({
        ...group.def,
        alwaysHide: without(
          group.def.alwaysHide,
          toKey(group.value, group.def?.format, EMPTY_KEY) || EMPTY_KEY
        ),
        alwaysShow: [
          ...(group.def.alwaysShow || []),
          toKey(group.value, group.def?.format, EMPTY_KEY) || EMPTY_KEY,
        ],
      }),
    [onChanged]
  );
  return (
    <CollapsibleSection
      divider={false}
      defaultOpen={open ?? false}
      className={isString(className) ? className : className?.container}
      title={
        <Button size="tiny" subtle>
          <Text subtle>Hidden groups</Text>
        </Button>
      }
    >
      <div
        className={cx(
          styles.hiddenGroups,
          !isString(className) && className?.list
        )}
      >
        {map(hidden, (group) => (
          <Button
            key={toKey(group.value)}
            subtle
            variant="secondary"
            className={cx(styles.hiddenGroup)}
            icon={when(first(group.value.value.relations), (ref) => (
              <RelationIcon relation={ref} />
            ))}
            iconRight={EyeSlash}
            onClick={() => onShow(group)}
          >
            <HStack gap={6}>
              {(group.value?.type === "relations" &&
                when(first(group.value.value.relations), (ref) => (
                  <RelationLabel
                    icon={false}
                    className={styles.label}
                    relation={ref}
                  />
                ))) || (
                <>{toLabel(group.value, group.def?.format) || "Not set"} </>
              )}

              {when((group as { items: Entity[] }).items?.length, (length) => (
                <span className={styles.groupCounter}> {length}</span>
              ))}
            </HStack>
          </Button>
        ))}
      </div>
    </CollapsibleSection>
  );
}
