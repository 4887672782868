import { subDays } from "date-fns";
import { filter, now, reduce } from "lodash";
import { useMemo } from "react";

import { ID, PropertyRef } from "@api";
import { useLazyItemsForView } from "@state/views";

import { isEmptyRef, toPropertyValueRef } from "@utils/property-refs";

import { Container, Props as ContainerProps } from "@ui/container";
import { StatButton } from "@ui/square-button";
import { fromPointDate } from "@utils/date-fp";

type Props = Omit<ContainerProps, "children"> & {
  viewId: ID;
  organizeBy?: PropertyRef;
  itemClassName?: string;
};

export function ViewStats({
  viewId,
  className,
  itemClassName,
  organizeBy,
  ...rest
}: Props) {
  const { items } = useLazyItemsForView(viewId);

  const old = subDays(now(), 7);
  const recent = subDays(now(), 7);

  const stats = useMemo(
    () =>
      reduce(
        items.all,
        (res, i) => {
          res.total++;

          if (fromPointDate(i.createdAt) > recent) {
            res.new++;
          }
          if (fromPointDate(i.createdAt) < old) {
            res.stale++;
          }

          return res;
        },
        {
          total: 0,
          new: 0,
          stale: 0,
          unprioritized: organizeBy
            ? filter(items?.all, (i) =>
                isEmptyRef(toPropertyValueRef(i, organizeBy))
              )?.length
            : 0,
        }
      ),
    [organizeBy?.field, items]
  );

  return (
    <Container
      padding="none"
      stack="horizontal"
      gap={6}
      className={className}
      {...rest}
    >
      <StatButton
        className={itemClassName}
        disabled={true}
        stat={stats.total}
        text="Total items"
      />
      <StatButton
        className={itemClassName}
        disabled={true}
        stat={stats.new}
        text="Recently added"
      />
      <StatButton
        className={itemClassName}
        disabled={true}
        stat={stats.unprioritized}
        text="Not organized"
      />
      <StatButton
        className={itemClassName}
        disabled={true}
        stat={stats.stale}
        text="Stale items"
      />
    </Container>
  );
}
