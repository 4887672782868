import { first, orderBy, values } from "lodash";
import { selector, selectorFamily } from "recoil";

import { ID } from "@api";

import { ActiveUserAtom } from "@state/workspace";

import { Maybe } from "@utils/maybe";
import { containsRef } from "@utils/relation-ref";
import { maybeValues } from "@utils/object";

import { TeamAtom, TeamStoreAtom } from "./atoms";

export const teamOrDefault = selectorFamily({
  key: "teamOrDefault",
  get:
    (id: Maybe<ID>) =>
    ({ get }) =>
      id ? get(TeamAtom(id)) : first(values(get(TeamStoreAtom).lookup)),
});

export const allTeams = selector({
  key: "allTeams",
  get: ({ get }) => {
    const me = get(ActiveUserAtom);

    if (!me) {
      return [];
    }

    return orderBy(
      maybeValues(get(TeamStoreAtom).lookup),
      (t) => `${containsRef(t.people, me) ? 0 : 1}.${t.name}`
    );
  },
});
