import { useCallback, useState } from "react";

import { createPage, ID } from "@api";
import { ParentReference } from "@api/integrations/notion";

import { useLazyWorkspace } from "@state/workspace";
import { useEntitySettings } from "@state/settings";
import { useLazyTeam } from "@state/teams";
import { useLazyEntity } from "@state/generic";
import { useQueueUpdateEffect } from "@state/store";

import { toUpdate } from "@utils/property-mutations";
import { Maybe, when } from "@utils/maybe";
import { fallbackPropertyValue } from "@utils/property-refs";
import { toNotionUrl } from "@utils/notion";
import { openInNewTab } from "@utils/url";
import { fallback } from "@utils/fn";
import { toMarkdown } from "@utils/rich-text";
import { extractTeam } from "@utils/scope";

import { ProjectStoreAtom } from "./atoms";

export function useLinkToNotion(projectId: ID) {
  const project = useLazyEntity<"project">(projectId);
  const update = useQueueUpdateEffect(ProjectStoreAtom);

  return useCallback(
    async (id: string) => {
      if (project && !project.notionId) {
        const notionUrl = toNotionUrl(id);

        // Save notionId to DB
        update([
          toUpdate(project, { field: "notionId", type: "text" }, id),
          toUpdate(project, { field: "links", type: "links" }, [
            ...(project.links || []),
            { text: "Project Documentation", url: notionUrl },
          ]),
        ]);
      }
    },
    [project?.notionId]
  );
}

// TODO: Make generic so that can be used for projects, teams, etc
// Also need to figure out how to find where to create in notion...
export function useConvertToNotion(projectId: ID) {
  const workspace = useLazyWorkspace();
  const [converting, setConverting] = useState(false);
  const project = useLazyEntity<"project">(projectId);
  const team = useLazyTeam(extractTeam(project?.source.scope) || "");
  const link = useLinkToNotion(projectId);

  const convert = useCallback(async () => {
    const parent = fallback(
      (): Maybe<ParentReference> =>
        when(
          fallbackPropertyValue([project, team, workspace], {
            field: "settings.project_db",
            type: "text",
          })?.text,
          (id) => ({ database_id: id, type: "database_id" })
        ),

      (): Maybe<ParentReference> =>
        when(team?.notionId, (id) => ({ page_id: String(id), type: "page_id" }))
    );

    if (!parent) {
      throw new Error("Project db not configured for Notion.");
    }

    if (project && !project.notionId) {
      setConverting(true);
      const { id } = await createPage(
        parent,
        project.name || "A Project",
        toMarkdown(project.body)
      );

      // Link to project
      link(id);

      // Open automatically (can add to setttings?)
      openInNewTab(toNotionUrl(id));

      setConverting(false);
    }
  }, [project?.name, project?.notionId, project?.body]);

  return { convert, revert: undefined, converting };
}

export const useLazySettings = (id: ID) => {
  // Make sure entity is loaded... Might not be needed tbh..
  useLazyEntity(id || "");
  return useEntitySettings(id || "");
};
