import { getRecoil } from "recoil-nexus";
import { subDays } from "date-fns";

import { EntityType, View } from "@api";
import { activeUserId } from "@state/workspace";

import { now } from "@utils/now";
import { SafeRecord } from "@utils/types";
import { Fn } from "@utils/fn";

import { QuickFilter } from "./atoms";

export const filtersForView = (view: View): QuickFilter[] => {
  return FILTERS[view.entity]?.(view) || [];
};

const FILTERS: Partial<SafeRecord<EntityType, Fn<View, QuickFilter[]>>> = {
  task: ({ id: viewId }: View) =>
    [
      {
        id: "your-tasks",
        name: "My work",
        color: "gray",
        filter: {
          and: [
            {
              field: "assigned",
              type: "relation",
              op: "equals",
              value: { relation: { id: getRecoil(activeUserId) } },
            },
          ],
        },
      },

      {
        id: "blocked",
        name: "Blocked",
        color: "red",
        filter: {
          or: [
            {
              field: "blocked",
              type: "boolean",
              op: "equals",
              value: { boolean: true },
            },
            {
              field: "status",
              type: "status",
              op: "equals",
              value: { status: { name: "Blocked" } },
            },
          ],
        },
      },

      {
        id: "overdue",
        name: "Overdue",
        color: "red",
        filter: {
          field: "end",
          type: "date",
          op: "on_or_before",
          value: { date: now() },
        },
      },

      {
        id: "stale",
        name: "Stale",
        color: "gray",
        filter: {
          field: "updatedAt",
          type: "date",
          op: "before",
          value: { date: subDays(now(), 30) },
        },
      },
    ] as QuickFilter[],

  outcome: ({ id: viewId }: View) =>
    [
      {
        id: "your-outcomes",
        name: "Assigned to me",
        color: "gray",
        filter: {
          and: [
            {
              field: "assigned",
              type: "relation",
              op: "equals",
              value: { relation: { id: getRecoil(activeUserId) } },
            },
          ],
        },
      },

      {
        id: "overdue",
        name: "Overdue",
        color: "red",
        filter: {
          field: "end",
          type: "date",
          op: "on_or_before",
          value: { date: now() },
        },
      },

      {
        id: "stale",
        name: "Stale",
        color: "gray",
        filter: {
          field: "updatedAt",
          type: "date",
          op: "before",
          value: { date: subDays(now(), 30) },
        },
      },
    ] as QuickFilter[],

  content: ({ id: viewId }: View) =>
    [
      {
        id: "your-content",
        name: "Assigned to me",
        color: "gray",
        filter: {
          and: [
            {
              field: "owner",
              type: "relation",
              op: "equals",
              value: { relation: { id: getRecoil(activeUserId) } },
            },
          ],
        },
      },

      {
        id: "going-out",
        name: "Going out soon",
        color: "red",
        filter: {
          and: [
            {
              field: "publish",
              type: "date",
              op: "on_or_after",
              value: { formula: "=today-1d" },
            },
            {
              field: "publish",
              type: "date",
              op: "before",
              value: { formula: "=today+5d" },
            },
            {
              field: "status",
              type: "status",
              op: "does_not_equal",
              value: { status: { group: "done" } },
            },
          ],
        },
      },
    ] as QuickFilter[],
};
