import { atomFamily, selectorFamily } from "recoil";

import { ID, Campaign } from "@api";

import {
  getItem,
  indexedDBStorageForStore,
  mergeItem,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { isDefault } from "@utils/recoil";

export type CampaignStoreState = StoreState<Campaign>;

export const WorkspaceCampaignStoreAtom = atomFamily<CampaignStoreState, ID>({
  key: "WorkspaceCampaignStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "campaign")],
});

export const CampaignStoreAtom = WorkspaceWrappedAtom(
  "CampaignStoreAtom",
  WorkspaceCampaignStoreAtom
);

export const CampaignAtom = selectorFamily({
  key: "CampaignAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(CampaignStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(CampaignStoreAtom, mergeItem<Campaign>(newValue));
      }
    },
});
