import { GroupBase } from "react-select";
import { filter, groupBy, map } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { View, Ref, EntityType, toTitleOrName } from "@api";

import { toShortTitle, useAllViewsForParent } from "@state/views";
import { useEntityLabels } from "@state/settings";
import { useLazyEntity } from "@state/generic";

import { Fn } from "@utils/fn";
import { Maybe, when } from "@utils/maybe";

import { ArrowLeft, ViewIcon } from "@ui/icon";

import { GlobalEntitySelect } from "./entity";
import { MultiRelationSelectProps } from "./relation";
import { Select, Option, TextSubtextOption } from "./single-select";

type Props = Omit<
  MultiRelationSelectProps,
  "options" | "onChange" | "value"
> & {
  scope: string;
  onChange?: Fn<View[], void>;
};

export const ViewSelect = ({
  children,
  scope,
  onChange,
  toIcon,
  ...rest
}: Props) => {
  const [open, _setOpen] = useState(false);
  const [parentRef, _setParent] = useState<Ref>();
  const parent = useLazyEntity(parentRef?.id || "");
  const toEntityLabel = useEntityLabels(scope);

  const setParent = useCallback(
    (ref: Maybe<Ref>) => {
      _setParent(ref);
      _setOpen(true);
    },
    [_setParent]
  );
  const setOpen = useCallback(
    (o: boolean) => {
      _setOpen(o);
      _setParent(undefined);
    },
    [_setOpen]
  );

  const views = useAllViewsForParent(parentRef?.id || "");

  const viewOptions = useMemo(() => {
    const grouped = groupBy(views, (v) => v.entity);

    return [
      {
        options: [
          {
            id: "back",
            name: when(parent, toTitleOrName) || "Back",
            icon: ArrowLeft,
          },
        ],
      },
      ...map(grouped, (views, entity) => ({
        label: toEntityLabel(entity as EntityType, { plural: true }),
        options: map(views, (a) => ({
          id: a.id,
          name: toShortTitle(a),
          icon: <ViewIcon layout={a.layout} />,
        })),
      })),
    ] as (Option | GroupBase<Option>)[];
  }, [views]);

  const handleSelect = useCallback(
    (o: Maybe<Option>) => {
      if (o?.id === "back") {
        setParent(undefined);
        return;
      }

      onChange?.(filter(views, (a) => a.id === o?.id));
      setOpen(false);
    },
    [views, onChange]
  );

  if (!parent) {
    return (
      <GlobalEntitySelect
        scope={scope}
        open={open}
        setOpen={setOpen}
        value={undefined}
        onChange={setParent}
        closeOnSelect={false}
        closeOnTeam={false}
        closeOnBlur={false}
      >
        {children}
      </GlobalEntitySelect>
    );
  }

  return (
    <Select
      {...rest}
      open={open}
      setOpen={setOpen}
      value={undefined}
      options={viewOptions}
      overrides={{ Option: TextSubtextOption }}
      onChange={handleSelect}
      closeOnSelect={false}
      closeOnBlur={false}
      searchable={false}
    >
      {children}
    </Select>
  );
};
