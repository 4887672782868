import { MouseHighlight } from "@utils/selectable";

import styles from "./mouse-selection.module.css";

export const MouseSelection = (props: MouseHighlight) => (
  <div
    className={styles.dragHighlight}
    style={
      props.dragging
        ? {
            opacity: 1,
            top: `${props.top || 0}px`,
            left: `${props.left || 0}px`,
            height: `${props.height || 0}px`,
            width: `${props.width || 0}px`,
          }
        : { opacity: 0 }
    }
  />
);
