import { isEqual } from "lodash";
import { FetchOptions, ID, Ref } from "@api";

import { Maybe } from "@utils/maybe";
import { pickIds, uniqConcat } from "@utils/array";
import { now, PointDate } from "@utils/date-fp";

import { FetchResultsState } from "./atoms";

export const setFetchResults =
  (ids: ID[], fetchedAt?: PointDate) =>
  (state: Maybe<FetchResultsState>): Maybe<FetchResultsState> =>
    ids && {
      ids: ids,
      fetchedAt: fetchedAt || state?.fetchedAt,
    };

export const setFetchedAt =
  (fetchedAt: Maybe<PointDate>) =>
  (state: Maybe<FetchResultsState>): Maybe<FetchResultsState> =>
    state && {
      ...state,
      fetchedAt: fetchedAt,
    };

export const resetFetchedAt =
  () =>
  (state: Maybe<FetchResultsState>): Maybe<FetchResultsState> =>
    state && {
      ...state,
      fetchedAt: undefined,
    };

export const addViewResultsIds =
  (ids: ID[], fetchedAt?: PointDate) =>
  (state: Maybe<FetchResultsState>): Maybe<FetchResultsState> =>
    ids && {
      ids: uniqConcat(state?.ids || [], ids),
      fetchedAt: fetchedAt || state?.fetchedAt,
    };

export const updateFetchedResults =
  (ids: ID[], fetchedAt: PointDate = now()) =>
  (state: Maybe<FetchResultsState>): Maybe<FetchResultsState> =>
    (isEqual(state?.ids, ids)
      ? setFetchedAt(fetchedAt)
      : setFetchResults(ids, fetchedAt))(state);

// Combine tasks with existing tasks in the view
export const addRefsToFetchResults =
  (refs: Ref[]) =>
  (state: Maybe<FetchResultsState>): Maybe<FetchResultsState> =>
    addViewResultsIds(pickIds(refs))(state);

/*
 * Global Fetch Options
 */

export const showArchived =
  (show: boolean) =>
  (state: Maybe<Partial<FetchOptions>>): Partial<FetchOptions> => ({
    ...(state || {}),
    archived: show,
  });
