import { useSetRecoilState } from "recoil";
import { first } from "lodash";

import { EntityRef } from "@api";

import {
  AppCommandsAction,
  AppCommandsAtom,
  currentPage,
  setCommandsOpen,
  setPageSelected,
} from "@state/app";

import { useGoTo } from "@utils/navigation";
import { maybeTypeFromId } from "@utils/id";
import { Maybe, when } from "@utils/maybe";

import { AiUpdatePane } from "@ui/ai-update-pane";
import { SpaceBetween, VStack } from "@ui/flex";
import { TimesIcon } from "@ui/icon";
import { Modal } from "@ui/modal";
import { Text, TextXLarge } from "@ui/text";
import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { DuplicateDialog } from "@ui/duplicate-dialog";
import { TemplateCreateDialog } from "@ui/template-create-dialog";
import { DeleteDialog } from "@ui/delete-dialog";
import { ArchiveDialog, UnArchiveDialog } from "@ui/archive-dialog";
import { RestoreDialog } from "@ui/restore-dialog";
import { LocationDialog } from "@ui/location-dialog";
import { SmartDefaultsAIAddWorkDialog } from "@ui/ai-add-work-dialog";

import { AppCommandsProps } from "../types";
import { ConvertDialog } from "@ui/engine/action/convert-dialog";

export type AppActionsCommands = AppCommandsProps & {
  action: AppCommandsAction;
  entities: Maybe<EntityRef[]>;
};

export const AppActions = ({ action, entities }: AppActionsCommands) => {
  const goTo = useGoTo();
  const setPage = useSetRecoilState(currentPage);
  const setCommands = useSetRecoilState(AppCommandsAtom);

  if (action === "delete") {
    return (
      <DeleteDialog
        targets={entities || []}
        onComplete={() => {
          setPage(setPageSelected([]));
          setCommands(setCommandsOpen(false));
        }}
        onCancel={() => setCommands(setCommandsOpen(false))}
      />
    );
  }

  if (action === "archive") {
    return (
      <ArchiveDialog
        targets={entities || []}
        onComplete={() => {
          setPage(setPageSelected([]));
          setCommands(setCommandsOpen(false));
        }}
        onCancel={() => setCommands(setCommandsOpen(false))}
      />
    );
  }

  if (action === "unarchive") {
    return (
      <UnArchiveDialog
        targets={entities || []}
        onComplete={() => {
          setPage(setPageSelected([]));
          setCommands(setCommandsOpen(false));
        }}
        onCancel={() => setCommands(setCommandsOpen(false))}
      />
    );
  }

  if (action === "restore") {
    return (
      <RestoreDialog
        targets={entities || []}
        onComplete={() => {
          setPage(setPageSelected([]));
          setCommands(setCommandsOpen(false));
        }}
        onCancel={() => setCommands(setCommandsOpen(false))}
      />
    );
  }

  if (action === "duplicate" && entities?.length) {
    return (
      <DuplicateDialog
        target={entities?.[0]}
        onComplete={(created) => {
          // TODO: Schedules don't yet have their own pages
          if (maybeTypeFromId(created.id) !== "schedule") {
            when(created, goTo);
          }
          setCommands(setCommandsOpen(false));
        }}
        onCancel={() => setCommands(setCommandsOpen(false))}
      />
    );
  }

  if (action === "save_template" && entities?.length) {
    return (
      <TemplateCreateDialog
        target={entities?.[0]}
        onComplete={(created) => {
          when(created, goTo);
          setCommands(setCommandsOpen(false));
        }}
        onCancel={() => setCommands(setCommandsOpen(false))}
      />
    );
  }

  if (action === "move_location" && entities?.length) {
    return (
      <LocationDialog
        targets={entities}
        onComplete={() => setCommands(setCommandsOpen(false))}
        onCancel={() => setCommands(setCommandsOpen(false))}
      />
    );
  }

  if (action === "convert_work" && entities?.length) {
    return (
      <ConvertDialog
        targets={entities}
        onComplete={() => setCommands(setCommandsOpen(false))}
        onCancel={() => setCommands(setCommandsOpen(false))}
      />
    );
  }

  if (action === "ai_add_work") {
    return (
      <SmartDefaultsAIAddWorkDialog
        entityId={entities?.[0]?.id}
        onSaved={() => setCommands(setCommandsOpen(false))}
        onCancel={() => setCommands(setCommandsOpen(false))}
      />
    );
  }

  if (action === "ai_status_update") {
    return (
      <>
        <Modal
          open={true}
          onOpenChanged={(o) => !o && setCommands(setCommandsOpen(false))}
          mode="passive"
          padding="none"
          size="secondary"
        >
          <Container fit="container">
            <SpaceBetween fit="container">
              <VStack gap={0}>
                <TextXLarge bold>Status Update</TextXLarge>
                <Text subtle>
                  Get up to speed on this work thanks to your Ai Assistant.
                </Text>
              </VStack>

              <Button
                subtle
                onClick={() => setCommands(setCommandsOpen(false))}
                icon={TimesIcon}
              />
            </SpaceBetween>
          </Container>

          <AiUpdatePane entity={first(entities)} />
        </Modal>
      </>
    );
  }

  return <></>;
};
