import { every, keys, map } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import {
  Integration,
  PropertyMutation,
  Ref,
  Update,
  Workspace,
  createWorkspace,
  getSession,
  getSwitchableSessions,
} from "@api";

import { storage } from "@state/storage";
import { setItem, useQueueUpdateEffect } from "@state/store";

import { useAsyncEffect } from "@utils/effects";
import { required, when } from "@utils/maybe";
import { toMilliSeconds } from "@utils/time";
import { useGoTo } from "@utils/navigation";

import { showError } from "@ui/notifications";

import {
  ActiveUserAtom,
  ActiveWorkspaceAtom,
  ActiveWorkspaceId,
  ActiveWorkspaceSessionAtom,
  MaybeActiveWorkspaceSessionAtom,
  WorkspaceSessionStateAtom,
  WorkspaceStoreAtom,
  cachedFuncByWorkspace,
} from "./atoms";
import { addWorkspaceConfig } from "./actions";
import { onUnauthed } from "./utils";
import { activeUserId } from "./selectors";

export const getSessionCached = cachedFuncByWorkspace(
  () => getSession,
  toMilliSeconds("5 minutes")
);

export const getSwitchableSessionsCached = cachedFuncByWorkspace(
  () => getSwitchableSessions,
  toMilliSeconds("5 minutes")
);

// Deprecated for useMe instead.
export const useAuthedUser = (): Ref => {
  return required(
    useRecoilValue(ActiveUserAtom),
    () => "Missing current user."
  );
};

export const useAuthedUserId = () => {
  return useAuthedUser()?.id;
};

export const useLazyWorkspaces = (fetch: boolean = true) => {
  const [workspaceConfig, setWorkspaceConfig] = useRecoilState(
    WorkspaceSessionStateAtom
  );
  const setWorkspaceStore = useSetRecoilState(WorkspaceStoreAtom);

  useAsyncEffect(async () => {
    if (workspaceConfig.workspaces?.length && !fetch) {
      return;
    }

    const workspaces = await getSwitchableSessionsCached();
    if (workspaces) {
      setWorkspaceConfig(addWorkspaceConfig(workspaces));
      map(workspaces, ({ workspace }) =>
        when(workspace, (workspace) => setWorkspaceStore(setItem(workspace)))
      );
    }
  }, []);

  return workspaceConfig;
};

export const useLazyWorkspace = () => {
  useLazyWorkspaces();
  return useRecoilValue(ActiveWorkspaceAtom);
};

export const useCurrentWorkspace = () => {
  return useRecoilValue(ActiveWorkspaceAtom);
};

export const useActiveWorkspaceId = () => {
  return useRecoilValue(ActiveWorkspaceId);
};

export const useCreateWorkspace = () => {
  const [saving, setSaving] = useState(false);
  const [workspace, setWorkspace] = useState<Partial<Workspace>>({ name: "" });

  const create = useCallback(
    async (defaults?: Partial<Workspace>) => {
      if (saving) {
        return undefined;
      }

      setSaving(true);
      try {
        return await createWorkspace(
          defaults?.name || workspace?.name || "New Workspace"
        );
      } finally {
        setSaving(false);
      }
    },
    [saving, workspace]
  );

  return { saving, workspace, setWorkspace, create };
};

export function useUpdateWorkspace() {
  const workspace = useLazyWorkspace();
  const queueUpdate = useQueueUpdateEffect(WorkspaceStoreAtom);

  return useCallback(
    (changes: PropertyMutation<Workspace>[]) => {
      if (!workspace) {
        throw new Error("Missing workspace for update.");
      }

      const update: Update<Workspace> = {
        id: workspace?.id,
        source: {
          source: Integration.Traction,
          type: "workspace",
          scope: workspace?.id,
        },
        method: "update",
        changes,
      };
      queueUpdate(update);
    },
    [workspace]
  );
}

export const useSession = () => {
  return useRecoilValue(ActiveWorkspaceSessionAtom);
};

const getIsLoggedIn = () => !!storage().getItem("traction.auth.workspaces");

export const useIsLoggedIn = () => {
  const session = useRecoilValue(MaybeActiveWorkspaceSessionAtom);
  return useMemo(() => getIsLoggedIn(), [session]);
};

export const useEnsureAuthed = () => {
  const isLoggedIn = useIsLoggedIn();

  if (!isLoggedIn) {
    onUnauthed();
  }

  return isLoggedIn;
};

// TODO: Move to a suggested action or on homepage
export const useEnsureAccountSetup = () => {
  const session = useRecoilValue(MaybeActiveWorkspaceSessionAtom);
  const goTo = useGoTo();

  const isLoggedIn = useEnsureAuthed();

  useEffect(() => {
    if (
      isLoggedIn &&
      // No user auth setup yet
      every(
        keys(session?.auths) as Integration[],
        (k) => !session?.auths?.[k]?.user
      )
    ) {
      showError("Please link your accounts.");
      return goTo("/settings/connections");
    }
  }, [session]);
};
