import { groupBy } from "lodash";
import { useMemo } from "react";

import { useLazyEntities, useLazyEntity } from "@state/generic";
import { useLazyPropertyDef, useLazyPropertyValue } from "@state/databases";

import { DatabaseID, Entity, ID, PropertyRef } from "@api";

import { toPropertyValueRef, inflateValue } from "@utils/property-refs";

import { VStack } from "@ui/flex";
import { SectionLabel } from "@ui/label";
import ProgressBar from "@ui/progress-bar";

interface Props {
  parentId: ID;
  property: PropertyRef<Entity, "relations">;
}

export const useCompletionStats = (items: Entity[], source: DatabaseID) => {
  const prop = useLazyPropertyDef(source, { field: "status", type: "status" });

  return useMemo(() => {
    if (!prop) {
      return;
    }

    const byCompleted = groupBy(
      items,
      (t) =>
        inflateValue(toPropertyValueRef(t, prop), prop.values, prop)?.value
          ?.status?.group
    );

    return {
      total: items?.length,
      blocked: byCompleted["blocked"]?.length || 0,
      notStarted: byCompleted["not-started"]?.length || 0,
      inProgress: byCompleted["in-progress"]?.length || 0,
      completed: byCompleted["done"]?.length || 0,
      percent: items.length
        ? Math.round(((byCompleted["done"]?.length || 0) / items.length) * 100)
        : 0,
    };
  }, [items, prop]);
};

export const ChildWorkProgression = ({ parentId, property }: Props) => {
  const parent = useLazyEntity(parentId);
  const value = useLazyPropertyValue(parent, property);
  const items = useLazyEntities(value?.value.relations || []);

  return <WorkProgression items={items || []} />;
};

export const WorkProgression = ({
  items,
  source,
}: {
  items: Entity[];
  source?: DatabaseID;
}) => {
  const stats = useCompletionStats(items, source || items?.[0]?.source);
  const percents = useMemo(
    () =>
      !stats
        ? undefined
        : [stats.percent, (stats.inProgress / stats.total) * 100],
    [stats]
  );

  if (!items?.length || !percents || !stats) {
    return <></>;
  }

  return (
    <VStack gap={0} style={{ width: 50 }}>
      <SectionLabel>{stats.percent}%</SectionLabel>
      <ProgressBar percent={percents} />
    </VStack>
  );
};
