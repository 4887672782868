import { flatMap } from "lodash";

import {
  DisplayAs,
  EntityType,
  ID,
  PropertyDef,
  PropertyVisibility,
} from "@api";

import { ComponentOrNode } from "@utils/react";

import {
  BacklogFilled,
  BoltFilled,
  CalendarFilled,
  CompanyFilled,
  ContractFilled,
  DocumentFilled,
  EnvelopeFilled,
  EventFilled,
  FormFilled,
  Google,
  ImageIcon,
  MilestoneFilled,
  Notion,
  PeopleFilled,
  PipelineFilled,
  ProcessFilled,
  RoadmapFilled,
  SlackColor,
  TasksFilled,
} from "@ui/icon";

type PropertyDefConfig = Pick<PropertyDef, "entity" | "type" | "field"> &
  Partial<PropertyDef>;

export interface Package {
  id: ID;
  name: string;
  description: string;
  available?: boolean;
  type?: "entity" | "integration" | "custom";
  tags?: string[];
  icon?: ComponentOrNode;
  entity?: EntityType;
  alias?: string;
  props?: PropertyDefConfig[];
  deps?: ID[];
}

export enum SystemPackages {
  Outcomes = "pkg_outcome",
  Backlogs = "pkg_backlog",
  Roadmaps = "pkg_roadmap",
  Sprints = "pkg_sprint",
  Projects = "pkg_project",
  Calendars = "pkg_calendar",
  Campaigns = "pkg_campaign",
  Content = "pkg_content",
  Tasks = "pkg_task",
  Pages = "pkg_page",
  Forms = "pkg_form",
  Meetings = "pkg_meeting",
  Agendas = "pkg_agenda",
  Actions = "pkg_action",
  Processes = "pkg_process",
  KnowledgeBase = "pkg_knowledgebase",
  Workflows = "pkg_workflow",
  Pipelines = "pkg_pipeline",
  Events = "pkg_event",
  Requests = "pkg_request",
  Companys = "pkg_company",
  Contacts = "pkg_contact",
  Deals = "pkg_deal",

  Notion = "pkg_notion",
  Google = "pkg_google",
  Slack = "pkg_slack",
}

export const PACKAGES: Package[] = [
  {
    id: "pkg_outcome",
    name: "Outcomes",
    icon: MilestoneFilled,
    entity: "outcome",
    alias: "Objective",
    type: "entity",
    description:
      "Set goals, track progress, and celebrate wins with your team.",
    props: [
      {
        entity: ["outcome"],
        field: "refs.projects",
        label: "Project",
        type: "relations",
        options: { references: "project", hierarchy: "parent" },
        visibility: PropertyVisibility.ShowAlways,
        displayAs: DisplayAs.Property,
      },
      {
        entity: ["outcome"],
        field: "refs.campaigns",
        label: "Campaign",
        type: "relations",
        options: { references: "campaign", hierarchy: "parent" },
        visibility: PropertyVisibility.HideEmpty,
        displayAs: DisplayAs.Property,
      },
      {
        entity: ["outcome"],
        field: "refs.tasks",
        type: "relations",
        options: {
          references: "task",
          hierarchy: "child",
          sync: "refs.outcomes",
        },
        visibility: PropertyVisibility.HideEmpty,
        displayAs: DisplayAs.Section,
      },
      {
        entity: ["outcome"],
        field: "refs.sprint",
        label: "Sprint",
        type: "relation",
        options: { references: "sprint", hierarchy: "parent" },
        visibility: PropertyVisibility.HideEmpty,
        displayAs: DisplayAs.Property,
      },
      {
        entity: ["outcome"],
        field: "refs.roadmaps",
        type: "relations",
        options: { references: "roadmap", hierarchy: "parent" },
        visibility: PropertyVisibility.HideEmpty,
        displayAs: DisplayAs.Property,
      },
    ],
  },
  {
    id: "pkg_task",
    name: "Tasks",
    entity: "task",
    type: "entity",
    icon: TasksFilled,
    description:
      "Create and manage tasks that can be assigned to team members.",
    props: [
      {
        entity: ["task"],
        field: "refs.outcomes",
        type: "relations",
        options: {
          references: "outcome",
          hierarchy: "parent",
          sync: "refs.tasks",
        },
        visibility: PropertyVisibility.HideEmpty,
        displayAs: DisplayAs.Section,
        order: 7,
      },

      {
        entity: ["task"],
        field: "refs.contents",
        label: "Contents",
        type: "relations",
        options: {
          references: "content",
          hierarchy: "parent",
          sync: "refs.tasks",
        },
        visibility: PropertyVisibility.HideEmpty,
        displayAs: DisplayAs.Section,
        order: 7,
      },

      {
        entity: ["task"],
        field: "refs.events",
        label: "Events",
        type: "relations",
        options: {
          references: "event",
          hierarchy: "parent",
          sync: "refs.tasks",
        },
        visibility: PropertyVisibility.HideEmpty,
        displayAs: DisplayAs.Section,
        order: 7,
      },
      {
        entity: ["task"],
        field: "refs.deals",
        type: "relations",
        options: {
          references: "deal",
          hierarchy: "parent",
          sync: "refs.tasks",
        },
        visibility: PropertyVisibility.HideEmpty,
        order: 3,
      },
      {
        entity: ["task"],
        field: "refs.projects",
        label: "Project",
        type: "relations",
        options: { references: "project", hierarchy: "parent" },
        visibility: PropertyVisibility.HideEmpty,
        order: 3,
      },
      {
        entity: ["task"],
        field: "refs.campaigns",
        label: "Campaign",
        type: "relations",
        options: { references: "campaign", hierarchy: "parent" },
        visibility: PropertyVisibility.HideEmpty,
        order: 3,
      },
      {
        entity: ["task"],
        field: "refs.backlog",
        label: "Backlog",
        type: "relation",
        options: { references: "backlog", hierarchy: "parent" },
        visibility: PropertyVisibility.HideEmpty,
      },
      {
        entity: ["task"],
        field: "refs.contacts",
        label: "Contacts",
        type: "relations",
        options: { references: "contact", hierarchy: "parent" },
        visibility: PropertyVisibility.HideEmpty,
        displayAs: DisplayAs.Section,
      },
      {
        entity: ["task"],
        field: "refs.sprint",
        label: "Sprint",
        type: "relation",
        options: { references: "sprint", hierarchy: "parent" },
        visibility: PropertyVisibility.HideEmpty,
      },

      {
        entity: ["task"],
        field: "refs.parent",
        label: "Parent task",
        type: "relation",
        options: {
          references: "task",
          hierarchy: "parent",
          sync: "refs.subtasks",
        },
        order: 1, // Above task brief
        visibility: PropertyVisibility.HideEmpty,
        displayAs: DisplayAs.Section,
      },
      {
        entity: ["task"],
        field: "refs.subtasks",
        label: "Sub-Tasks",
        type: "relations",
        order: 1, // Above task brief
        options: {
          references: "task",
          hierarchy: "child",
          sync: "refs.parent",
        },
        displayAs: DisplayAs.Section,
      },
    ],
  },
  {
    id: "pkg_project",
    name: "Projects",
    entity: "project",
    type: "entity",
    icon: MilestoneFilled,
    description:
      "Create flexible projects that can be used to track bigger pieces of work.",
    props: [
      {
        entity: ["project"],
        field: "refs.outcomes",
        type: "relations",
        options: {
          references: "outcome",
          sync: "refs.projects",
          hierarchy: "child",
        },
      },
      {
        entity: ["project"],
        field: "refs.tasks",
        type: "relations",
        options: {
          references: "task",
          hierarchy: "child",
          // TODO: Add to options and respect server side to not persist
          // persist: false,
          sync: "refs.projects",
        },
        displayAs: DisplayAs.Section,
      },
    ],
  },
  {
    id: "pkg_campaign",
    name: "Campaigns",
    entity: "campaign",
    type: "entity",
    icon: MilestoneFilled,
    description: "Create clear briefs, track progress, manage tasks, and more.",
    props: [
      {
        entity: ["campaign"],
        field: "refs.outcomes",
        type: "relations",
        options: {
          references: "outcome",
          sync: "refs.projects",
          hierarchy: "child",
        },
      },
      {
        entity: ["campaign"],
        field: "refs.contents",
        type: "relations",
        order: 2,
        options: {
          references: "content",
          hierarchy: "child",
          // TODO: Add to options and respect server side to not persist
          // persist: false,
          sync: "refs.campaigns",
        },
        visibility: PropertyVisibility.ShowAlways,
        displayAs: DisplayAs.Section,
      },
      {
        entity: ["campaign"],
        field: "refs.tasks",
        type: "relations",
        order: 3,
        options: {
          references: "task",
          hierarchy: "child",
          // TODO: Add to options and respect server side to not persist
          // persist: false,
          sync: "refs.campaigns",
        },
        visibility: PropertyVisibility.ShowAlways,
        displayAs: DisplayAs.Section,
      },
    ],
  },
  {
    id: "pkg_calendar",
    name: "Calendars",
    icon: CalendarFilled,
    entity: "calendar",
    type: "entity",
    description: "Plan your social & email content calendars in one place.",
    props: [
      {
        entity: ["calendar"],
        field: "refs.outcomes",
        type: "relations",
        options: {
          references: "outcome",
          sync: "refs.projects",
          hierarchy: "child",
        },
      },
      {
        entity: ["calendar"],
        field: "refs.contents",
        type: "relations",
        options: {
          references: "content",
          hierarchy: "child",
          // TODO: Add to options and respect server side to not persist
          // persist: false,
          sync: "refs.calendars",
        },
        displayAs: DisplayAs.Section,
      },
      {
        entity: ["calendar"],
        field: "refs.events",
        type: "relations",
        options: {
          references: "event",
          hierarchy: "child",
          // TODO: Add to options and respect server side to not persist
          // persist: false,
          sync: "refs.calendars",
        },
        displayAs: DisplayAs.Section,
      },
      {
        entity: ["calendar"],
        field: "refs.tasks",
        type: "relations",
        options: {
          references: "task",
          hierarchy: "child",
          // TODO: Add to options and respect server side to not persist
          // persist: false,
          sync: "refs.calendars",
        },
        displayAs: DisplayAs.Section,
      },
    ],
  },
  {
    id: "pkg_content",
    name: "Content",
    entity: "content",
    type: "entity",
    icon: EnvelopeFilled,
    description:
      "Plan and track content creation like social posts, blog posts, videos, etc.",
    props: [
      {
        entity: ["content"],
        field: "refs.campaigns",
        label: "Campaigns",
        type: "relations",
        options: { references: "campaign", hierarchy: "parent" },
        visibility: PropertyVisibility.HideEmpty,
      },
      {
        entity: ["content"],
        field: "refs.calendars",
        label: "Calendars",
        type: "relations",
        options: { references: "calendar", hierarchy: "parent" },
        visibility: PropertyVisibility.HideEmpty,
      },
      {
        entity: ["content"],
        field: "refs.tasks",
        type: "relations",
        options: {
          references: "task",
          hierarchy: "child",
          sync: "refs.contents",
        },
        visibility: PropertyVisibility.ShowAlways,
        displayAs: DisplayAs.Section,
      },
    ],
  },
  {
    id: "pkg_event",
    name: "Event",
    icon: EventFilled,
    entity: "event",
    type: "entity",
    description: "Plan and track events like team leave, and more.",
    props: [
      {
        entity: ["event"],
        field: "refs.calendars",
        label: "Calendars",
        type: "relations",
        options: { references: "calendar", hierarchy: "parent" },
        visibility: PropertyVisibility.HideEmpty,
        displayAs: DisplayAs.Section,
      },
      {
        entity: ["event"],
        field: "refs.tasks",
        type: "relations",
        options: {
          references: "task",
          hierarchy: "child",
          sync: "refs.events",
        },
        visibility: PropertyVisibility.ShowAlways,
        displayAs: DisplayAs.Section,
      },
    ],
  },
  {
    id: "pkg_backlog",
    name: "Backlogs",
    entity: "backlog",
    type: "entity",
    icon: BacklogFilled,
    description: "Log and prioritize work that needs to be remembered.",
    props: [
      {
        entity: ["backlog"],
        field: "refs.tasks",
        type: "relations",
        options: {
          references: "task",
          hierarchy: "child",
          // TODO: Add to options and respect server side to not persist
          // persist: false,
          sync: "refs.backlog",
        },
        visibility: PropertyVisibility.Hidden,
        displayAs: DisplayAs.Section,
      },
    ],
  },
  {
    id: "pkg_roadmap",
    name: "Roadmaps",
    icon: RoadmapFilled,
    entity: "roadmap",
    type: "entity",
    description: "Create clarity for where your team is heading.",
    props: [
      {
        entity: ["roadmap"],
        field: "refs.outcomes",
        type: "relations",
        options: {
          references: "outcome",
          hierarchy: "child",
          // TODO: Add to options and respect server side to not persist
          // persist: false,
          sync: "refs.roadmaps",
        },
        visibility: PropertyVisibility.Hidden,
        displayAs: DisplayAs.Section,
      },
    ],
  },
  {
    id: "pkg_sprint",
    name: "Sprints",
    entity: "sprint",
    type: "entity",
    icon: BoltFilled,
    description:
      "Run regular time-boxed sprints/cycles to create a cadence of performance.",
    props: [
      {
        entity: ["sprint"],
        field: "refs.outcomes",
        type: "relations",
        options: {
          references: "outcome",
          // TODO: Add to options and respect server side to not persist
          // persist: false,
          sync: "refs.sprint",
          hierarchy: "child",
        },
        visibility: PropertyVisibility.HideEmpty,
        displayAs: DisplayAs.Section,
      },
      {
        entity: ["sprint"],
        field: "refs.tasks",
        type: "relations",
        options: {
          references: "task",
          // TODO: Add to options and respect server side to not persist
          // persist: false,
          sync: "refs.sprint",
          hierarchy: "child",
        },
        visibility: PropertyVisibility.HideEmpty,
        displayAs: DisplayAs.Section,
      },
    ],
  },
  {
    id: "pkg_blocks",
    name: "Dependency Management",
    description: "Blocking fields used for setting dependencies between work.",
    props: flatMap(
      [
        "sprint",
        "project",
        "campaign",
        "outcome",
        "task",
        "content",
      ] as EntityType[],
      (type) => [
        {
          entity: [type],
          field: "refs.blockedBy",
          label: "Blocked by",
          type: "relations",
          visibility: PropertyVisibility.HideEmpty,
          displayAs: DisplayAs.Property,
          options: { references: type, sync: "refs.blocks" },
        },
        {
          entity: [type],
          field: "refs.blocks",
          label: "Blocks",
          type: "relations",
          visibility: PropertyVisibility.HideEmpty,
          displayAs: DisplayAs.Property,
          options: { references: type, sync: "refs.blockedBy" },
        },
      ]
    ),
  },
  {
    id: "pkg_notion",
    name: "Notion",
    icon: Notion,
    type: "integration",
    description:
      "Connect your Notion workspace to easily find and create docs.",
  },
  {
    id: "pkg_google",
    name: "Google",
    icon: Google,
    type: "integration",
    description:
      "Connect your Google workspace to easily find and create docs.",
  },
  {
    id: "pkg_slack",
    name: "Slack",
    icon: SlackColor,
    type: "integration",
    description:
      "Connect your Slack workspace to link all related conversations.",
  },

  {
    id: "pkg_meeting",
    name: "Meetings",
    icon: CalendarFilled,
    entity: "meeting",
    type: "entity",
    description: "Put and end to over-time meetings that go nowhere.",
    props: [],
  },

  {
    id: "pkg_page",
    name: "Pages",
    entity: "page",
    type: "entity",
    icon: DocumentFilled,
    alias: "Document",
    description: "Document and collaborate in a notion-like editor experience.",
    props: [],
  },

  {
    id: "pkg_process",
    name: "Process & Procedures",
    entity: "process",
    type: "entity",
    icon: ProcessFilled,
    description: "Document, align, and automate SOPs.",
    props: [
      {
        entity: ["process"],
        field: "refs.knowledgebases",
        type: "relations",
        options: {
          hierarchy: "parent",
          references: "knowledgebase",
          sync: "refs.processes",
        },
        visibility: PropertyVisibility.HideEmpty,
        displayAs: DisplayAs.Property,
      },
    ],
  },

  {
    id: "pkg_form",
    name: "Forms",
    entity: "form",
    type: "entity",
    icon: FormFilled,
    description: "Collect info and kick off workflows.",
    props: [],
  },

  {
    id: "pkg_company",
    name: "Company",
    entity: "company",
    type: "entity",
    icon: CompanyFilled,
    description: "Log and organise your clients.",
    props: [
      {
        entity: ["company"],
        field: "refs.pipelines",
        type: "relations",
        options: { references: "pipeline", hierarchy: "parent" },
        visibility: PropertyVisibility.HideEmpty,
        displayAs: DisplayAs.Property,
      },
      {
        entity: ["company"],
        field: "refs.deals",
        type: "relations",
        order: 2,
        options: {
          references: "deal",
          hierarchy: "child",
          sync: "refs.companys",
        },
        visibility: PropertyVisibility.ShowAlways,
        displayAs: DisplayAs.Section,
      },
      {
        entity: ["company"],
        field: "refs.contacts",
        type: "relations",
        order: 1,
        options: {
          references: "contact",
          hierarchy: "child",
          sync: "refs.companys",
        },
        visibility: PropertyVisibility.ShowAlways,
        displayAs: DisplayAs.Section,
      },
    ],
  },
  {
    id: "pkg_contact",
    name: "Contact",
    icon: PeopleFilled,
    entity: "contact",
    type: "entity",
    description: "Quick and easy CRM.",
    props: [
      {
        entity: ["contact"],
        field: "refs.pipelines",
        type: "relations",
        options: { references: "pipeline", hierarchy: "parent" },
        visibility: PropertyVisibility.HideEmpty,
        displayAs: DisplayAs.Property,
      },
      {
        entity: ["contact"],
        field: "refs.tasks",
        type: "relations",
        options: {
          references: "task",
          sync: "refs.contacts",
          hierarchy: "child",
        },
        visibility: PropertyVisibility.ShowAlways,
        displayAs: DisplayAs.Section,
      },
      {
        entity: ["contact"],
        field: "refs.deals",
        type: "relations",
        options: {
          references: "deal",
          sync: "refs.contacts",
        },
        visibility: PropertyVisibility.ShowAlways,
        displayAs: DisplayAs.Section,
      },
      {
        entity: ["contact"],
        field: "refs.companys",
        label: "Company",
        type: "relations",
        options: {
          references: "company",
          hierarchy: "parent",
          sync: "refs.contacts",
        },
        visibility: PropertyVisibility.HideEmpty,
        displayAs: DisplayAs.Section,
      },
    ],
  },
  {
    id: "pkg_deal",
    name: "Deal",
    entity: "deal",
    type: "entity",
    icon: ContractFilled,
    description: "Track your deals from lead to close.",
    props: [
      {
        entity: ["deal"],
        field: "refs.tasks",
        type: "relations",
        options: { references: "task", hierarchy: "child", sync: "refs.deals" },
        visibility: PropertyVisibility.ShowAlways,
        displayAs: DisplayAs.Section,
      },
      {
        entity: ["deal"],
        field: "refs.pipelines",
        type: "relations",
        options: { references: "pipeline", hierarchy: "parent" },
        visibility: PropertyVisibility.HideEmpty,
        displayAs: DisplayAs.Property,
      },
      {
        entity: ["deal"],
        field: "refs.companys",
        label: "Company",
        type: "relations",
        options: {
          references: "company",
          hierarchy: "parent",
          sync: "refs.deals",
        },
        visibility: PropertyVisibility.HideEmpty,
        displayAs: DisplayAs.Section,
      },
      {
        entity: ["deal"],
        field: "refs.contacts",
        type: "relations",
        options: {
          references: "contact",
          sync: "refs.deals",
        },
        visibility: PropertyVisibility.HideEmpty,
        displayAs: DisplayAs.Property,
      },
    ],
  },

  {
    id: "pkg_pipeline",
    name: "Pipelines",
    entity: "pipeline",
    type: "entity",
    icon: PipelineFilled,
    description: "Move work through pre-defined stages.",
    props: [
      {
        entity: ["pipeline"],
        field: "refs.contents",
        type: "relations",
        options: {
          references: "content",
          hierarchy: "child",
          // TODO: Add to options and respect server side to not persist
          // persist: false,
          sync: "refs.pipelines",
        },
        displayAs: DisplayAs.Section,
      },
      {
        entity: ["pipeline"],
        field: "refs.deals",
        type: "relations",
        options: {
          references: "deal",
          hierarchy: "child",
          // TODO: Add to options and respect server side to not persist
          // persist: false,
          sync: "refs.pipelines",
        },
        displayAs: DisplayAs.Section,
      },
      {
        entity: ["pipeline"],
        field: "refs.companys",
        type: "relations",
        options: {
          references: "company",
          hierarchy: "child",
          // TODO: Add to options and respect server side to not persist
          // persist: false,
          sync: "refs.pipelines",
        },
        displayAs: DisplayAs.Section,
      },
      {
        entity: ["pipeline"],
        field: "refs.contacts",
        type: "relations",
        options: {
          references: "contact",
          hierarchy: "child",
          // TODO: Add to options and respect server side to not persist
          // persist: false,
          sync: "refs.pipelines",
        },
        displayAs: DisplayAs.Section,
      },
    ],
  },

  /*
   * Placeholders
   */
  {
    id: "pkg_marketing",
    name: "Marketing OS",
    description: "Traction operating system for Marketing Teams.",
    type: "custom",
    icon: <ImageIcon url="/favicon/favicon-32x32.png" />,
    tags: ["os"],
    available: false,
    props: [],
  },

  {
    id: "pkg_dev",
    name: "Dev OS",
    type: "custom",
    icon: <ImageIcon url="/favicon/favicon-32x32.png" />,
    tags: ["os"],
    description: "Traction operating system for Dev & Engineering Teams.",
    available: false,
    props: [],
  },
  {
    id: "pkg_dev",
    name: "Business Operations OS",
    type: "custom",
    icon: <ImageIcon url="/favicon/favicon-32x32.png" />,
    tags: ["os"],
    description: "Traction operating system for BusOps Teams.",
    available: false,
    props: [],
  },

  {
    id: "pkg_linear",
    name: "Linear Clone",
    description: "Run product teams with Cycles, Roadmaps, etc.",
    icon: <ImageIcon url="https://linear.app/static/favicon.svg" />,
    type: "custom",
    tags: ["clone"],
    available: false,
    props: [],
  },

  {
    id: "pkg_asana",
    name: "Asana Clone",
    description: "Run marketing teams with simple nesting Projects and Tasks.",
    icon: (
      <ImageIcon url="https://asana.com/assets/img/brand/asana-logo-favicon.ico" />
    ),
    type: "custom",
    tags: ["clone"],
    available: false,
    props: [],
  },

  {
    id: "pkg_jira",
    name: "Jira + Confluence Clone",
    description: "Setup and document teams used to Atlassian.",
    icon: (
      <ImageIcon url="https://static-00.iconduck.com/assets.00/atlassian-icon-2048x2048-bn41pv87.png" />
    ),
    type: "custom",
    tags: ["clone"],
    available: false,
    props: [],
  },

  {
    id: "pkg_salesforce",
    name: "SalesForce Clone",
    description: "Manage your deals, clients, contacts, etc.",
    icon: (
      <ImageIcon url="https://static-00.iconduck.com/assets.00/salesforce-icon-1024x717-zsvxc6sf.png" />
    ),
    type: "custom",
    tags: ["clone"],
    available: false,
    props: [],
  },

  {
    id: "pkg_knowledgebase",
    name: "Knowledge Base",
    entity: "knowledgebase",
    type: "entity",
    description: "Organise a searchable repository of knowledge.",
    available: false,
    props: [
      {
        entity: ["knowledgebase"],
        field: "refs.processes",
        type: "relations",
        options: {
          hierarchy: "child",
          references: "process",
          sync: "refs.knowledgebases",
          // TODO: Add to options and respect server side to not persist
          // persist: false,
        },
        visibility: PropertyVisibility.Hidden,
      },
    ],
  },

  {
    id: "pkg_workflow",
    name: "Workflows",
    // entity: "workflow",
    type: "entity",
    description: "Automate processes and procedures.",
    available: false,
    props: [],
  },

  {
    id: "pkg_queue",
    name: "Queues",
    // entity: "queue",
    type: "entity",
    description: "Support queues with automatic assignment.",
    available: false,
    props: [],
  },
  {
    id: "pkg_release",
    name: "Release Notes",
    // entity: "release",
    type: "entity",
    description: "Share and celebrate progress.",
    available: false,
    props: [],
  },
];
