import { isString } from "lodash";
import {
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactSelect from "react-select/creatable";
import {
  SelectComponentsConfig,
  GroupBase,
  OptionProps,
  OnChangeValue,
  MenuProps,
  SelectInstance,
  InputActionMeta,
  GroupProps,
  ClearIndicatorProps,
} from "react-select";

import { Maybe, safeAs, when } from "@utils/maybe";
import { Fn } from "@utils/fn";
import { cx } from "@utils/class-names";
import { switchEnum } from "@utils/logic";
import { ComponentOrNode } from "@utils/react";
import { asLocal, cid } from "@utils/id";

import { PlaceholderText, Text } from "@ui/text";
import { Label } from "@ui/label";
import { MenuItem, MenuItemProps } from "@ui/menu-item";
import { Button } from "@ui/button";
import { useShortcut } from "@utils/event";
import { Dropdown, Props as DropdownProps, useOpenState } from "@ui/dropdown";
import { AngleDownIcon, SpinnerIcon } from "@ui/icon";
import { MenuGroup } from "@ui/menu-group";
import { Container } from "@ui/container";
import { HStack } from "@ui/flex";
import { Ellipsis } from "@ui/ellipsis";

import styles from "./select.module.css";

export type SelectGroup<T> = GroupBase<T>;

export interface Option {
  id?: string;
  name?: string;
  icon?: ComponentOrNode;
}

export type SelectProps<T extends Option = Option> = {
  value: Maybe<T>;
  options: (T | GroupBase<T>)[];
  placeholder?: string;
  header?: ReactNode;
  footer?: ReactNode;
  toIcon?: Fn<T, MenuItemProps["icon"]>;
  searchable?: boolean;
  loading?: boolean;
  closeOnBlur?: boolean;
  closeOnSelect?: boolean;
  children?: ReactNode;
  hideSelected?: boolean;
  caret?: boolean;
  portal?: boolean;
  position?: DropdownProps["position"];
  clearable?: boolean;
  createable?: boolean;
  toNewOption?: Fn<string, T>;
  onChange?: Fn<Maybe<T>, void>;
  onSearch?: Fn<string, void>;
  onBlur?: Fn<void, void>;
  overrides?: SelectComponentsConfig<T, false, GroupBase<T>>;
  className?:
    | string
    | {
        select?: string;
        popover?: string;
        dropdown?: string;
        trigger?: string;
      };
  // Either can pass in a default or control it externally
  defaultOpen?: boolean;
  open?: boolean;
  setOpen?: Fn<boolean, void>;
};

const None = () => <></>;

export const SingleOption =
  <T extends Option>(toIcon: SelectProps<T>["toIcon"]) =>
  ({
    innerRef,
    innerProps,
    data,
    children,
    isFocused,
  }: OptionProps<T, false>) =>
    (
      <div ref={innerRef} {...innerProps}>
        <MenuItem
          dark
          className={cx(styles.menuItem, isFocused && styles.focused)}
          icon={(data && toIcon?.(data as T)) || data?.icon}
        >
          {children}
        </MenuItem>
      </div>
    );

export const TextSubtextOption = <
  T extends Option & {
    text?: string;
    subtext?: string;
    subtle?: boolean;
    icon?: ComponentOrNode;
  }
>({
  innerRef,
  innerProps,
  data,
  children,
  isFocused,
}: OptionProps<T, false>) => (
  <div ref={innerRef} {...innerProps}>
    <MenuItem
      dark
      className={cx(styles.menuItem, isFocused && styles.focused)}
      icon={data && (data as T).icon}
    >
      <HStack gap={4}>
        {when(data.text || data.name, (t) => (
          <Text subtle={!!safeAs<{ subtle?: boolean }>(data)?.subtle}>{t}</Text>
        ))}
        {data.subtext && (
          <Text subtle>
            <Ellipsis>{data.subtext}</Ellipsis>
          </Text>
        )}
      </HStack>
    </MenuItem>
  </div>
);

export const GroupOverride = <T extends Option>({
  data,
  children,
}: GroupProps<T, false>) => (
  <MenuGroup
    label={data.label}
    className={cx(styles.menuGroup)}
    labelClassName={styles.menuGroupHeading}
    divider={safeAs<{ divider: boolean }>(data)?.divider}
  >
    {children}
  </MenuGroup>
);

export const MenuOverride = <T extends Option, M extends boolean>({
  innerRef,
  innerProps,
  children,
}: MenuProps<T, M>) => (
  <div ref={innerRef} {...innerProps}>
    {children}
  </div>
);

export const LoadingIndicator = () => (
  <Button size="small" icon={SpinnerIcon} disabled subtle>
    <Text subtle>Loading...</Text>
  </Button>
);

export const ClearIndicator = <T extends Option, M extends boolean>({
  clearValue,
}: ClearIndicatorProps<T, M>) => (
  <Button size="small" subtle onClick={clearValue}>
    <Text subtle>Clear</Text>
  </Button>
);

export const NoOptionsMessage = () => (
  <MenuItem disabled={true}>
    <Text subtle>No matches</Text>
  </MenuItem>
);

export const Select = <T extends Option>({
  value,
  toIcon = (o) => (o as Maybe<{ icon?: ComponentOrNode }>)?.icon,
  children,
  header,
  footer,
  onChange,
  onBlur,
  onSearch,
  hideSelected,
  searchable = true,
  closeOnBlur = true,
  closeOnSelect = true,
  clearable = true,
  caret = false,
  createable = false,
  toNewOption,
  portal,
  options,
  className,
  position,
  placeholder,
  overrides,
  loading,
  ...props
}: SelectProps<T>) => {
  const select = useRef<SelectInstance<T>>(null);
  const [filtering, setFiltering] = useState(false);
  const [_open, _setOpen] = useOpenState(props.defaultOpen);
  const open = props.open ?? _open;
  const setOpen = props.setOpen ?? _setOpen;

  const popClassName = useMemo(
    () =>
      !isString(className)
        ? {
            dropdown: cx(styles.defaultDropdown, className?.dropdown),
            popover: cx(styles.defaultPopover, className?.popover),
          }
        : { dropdown: styles.defaultDropdown, popover: styles.defaultPopover },
    [className]
  );

  const onChanged = useCallback(
    (n: OnChangeValue<T, false>) => {
      onChange?.((n as Maybe<T>) || undefined);
      if (closeOnSelect) {
        setOpen(false);
      }
    },
    [onChange, closeOnSelect, setOpen]
  );

  const handleInputChange = useCallback(
    (raw: string, o?: InputActionMeta) => {
      const search = raw || "";

      if (
        !onSearch ||
        o?.prevInputValue === raw ||
        o?.prevInputValue === search
      ) {
        return;
      }

      switchEnum(o?.action || "", {
        "input-change": () => onSearch(search),
        "set-value": () => onSearch(search),
        else: () => () => onSearch(""),
      });
    },
    [onSearch]
  );

  const handleBlur = useCallback(() => {
    if (closeOnBlur) {
      // This allows clicking links inside of the dropdown before it closes...
      setTimeout(() => {
        onBlur?.();
        setOpen(false);
      }, 400);
    } else {
      onBlur?.();
    }
  }, [onBlur, setOpen, closeOnBlur]);

  // Close when escaping from empty filter input
  useShortcut(
    "Escape",
    [
      (e) => !e.defaultPrevented || !filtering,
      () => {
        setOpen(false);
        onBlur?.();
      },
    ],
    [filtering]
  );

  // Track filtering state to know above escaping rule
  useEffect(() => {
    const listener = (e: Event) => {
      setFiltering(!!(e.target as HTMLInputElement).value);
    };
    select.current?.inputRef?.addEventListener(
      "keydown",
      listener as EventListener
    );
    return () =>
      select.current?.inputRef?.removeEventListener("keydown", listener);
  }, [select.current?.inputRef]);

  useLayoutEffect(() => {
    if (open) {
      select.current?.inputRef?.focus();
    }
  }, [select.current, open]);

  return (
    <Dropdown
      open={open}
      setOpen={setOpen}
      closeOnEscape={false}
      portal={portal}
      position={position}
      className={popClassName}
      trigger={
        children ?? (
          <Button
            className={cx(
              styles.button,
              open && styles.open,
              className && (isString(className) ? className : className.trigger)
            )}
            subtle
            size="small"
            iconRight={caret && AngleDownIcon}
          >
            <div className={cx(styles.value)}>
              {!!value && (
                <Label
                  subtle={!value}
                  icon={(value && toIcon?.(value as T)) || value?.icon}
                >
                  {value?.name || "None"}
                </Label>
              )}
              {!value && (
                <PlaceholderText>{placeholder || "None"}</PlaceholderText>
              )}
            </div>
          </Button>
        )
      }
    >
      <Container
        padding="none"
        gap={0}
        fit="content"
        onMouseUp={() => {
          setTimeout(() => select.current?.inputRef?.focus(), 100);
        }}
      >
        {header}
        <ReactSelect
          ref={select}
          autoFocus={true}
          defaultValue={value as T}
          value={value as T}
          placeholder={placeholder || "Type to filter..."}
          options={options as T[]}
          classNamePrefix="select"
          getOptionValue={(o: T) => o.name || ""}
          getOptionLabel={(o: T) => o.name || ""}
          defaultMenuIsOpen={true}
          menuIsOpen={true}
          onMenuOpen={() => {}}
          onMenuClose={() => {}}
          openMenuOnClick={false}
          openMenuOnFocus={false}
          closeMenuOnSelect={false}
          blurInputOnSelect={false}
          controlShouldRenderValue={false}
          escapeClearsValue={true}
          isClearable={clearable}
          isValidNewOption={(v) => !!createable && !!v?.trim()}
          getNewOptionData={(v) =>
            toNewOption?.(v) || ({ id: asLocal(cid(4)), name: v } as T)
          }
          backspaceRemovesValue={false}
          hideSelectedOptions={hideSelected}
          isLoading={loading}
          className={cx(
            styles.select,
            !searchable && styles.noSearch,
            className && (isString(className) ? className : className.select)
          )}
          // Filtering is done externally
          {...(onSearch ? { filterOption: () => true } : {})}
          isSearchable={searchable}
          onInputChange={handleInputChange}
          onFocus={() => handleInputChange("")}
          onChange={onChanged}
          onBlur={handleBlur}
          components={useMemo(
            () => ({
              Menu: MenuOverride,
              DropdownIndicator: None,
              IndicatorSeparator: None,
              NoOptionsMessage: NoOptionsMessage,
              LoadingIndicator: LoadingIndicator,
              ClearIndicator: ClearIndicator,
              Option: SingleOption<T>(toIcon),
              Group: GroupOverride,
              ...overrides,
            }),
            [toIcon, overrides]
          )}
        />
        {footer}
      </Container>
    </Dropdown>
  );
};
