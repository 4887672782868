import { last } from "lodash";
import { selector } from "recoil";

import { isDefault } from "@utils/recoil";

import { updatePage } from "./actions";

import { AppLocationAtom } from "./atoms";

export const currentPage = selector({
  key: "currentPage",
  get: ({ get }) => last(get(AppLocationAtom).history),
  set: ({ set }, newValue) => {
    !!newValue &&
      !isDefault(newValue) &&
      set(AppLocationAtom, updatePage(newValue.id, newValue));
  },
});
