import { Color } from "@api";
import {
  mergeAttributes,
  Node,
  NodeViewProps,
  textblockTypeInputRule,
} from "@tiptap/core";
import { Plugin, PluginKey, Selection, TextSelection } from "@tiptap/pm/state";
import {
  NodeViewContent,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from "@tiptap/react";
import { EmojiSelect } from "@ui/select/emoji";
import { Maybe } from "@utils/maybe";

import styles from "./document-editor.module.css";

export interface CalloutOptions {
  defaultIcon: Maybe<string>;
  defaultColor: Maybe<string>;
  HTMLAttributes: Record<string, any>;
}

export interface CalloutAttributes {
  icon?: string;
  color?: Color;
  text?: string;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    callout: {
      setCallout: (attributes?: CalloutAttributes) => ReturnType;
      toggleCallout: (attributes?: CalloutAttributes) => ReturnType;
    };
  }
}

/**
 * Matches a callout with bang.
 */
export const bangRegex = /^\!([a-z]+)?[\s\n]$/;

export const CalloutExtension = Node.create<CalloutOptions>({
  name: "callout",

  addOptions() {
    return {
      defaultIcon: undefined,
      defaultColor: undefined,
      HTMLAttributes: {},
    };
  },

  content: "text*",

  marks: "",

  group: "block",

  code: true,

  defining: true,

  addAttributes() {
    return {
      icon: {
        default: this.options.defaultIcon,
        parseHTML: (element: HTMLElement) => element.getAttribute("data-icon"),
        renderHTML: (attributes) => {
          return {
            "data-icon": attributes.icon,
          };
        },
      },
      color: {
        default: this.options.defaultColor,
        parseHTML: (element: HTMLElement) => element.getAttribute("data-color"),
        renderHTML: (attributes) => {
          return {
            "data-color": attributes.color,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "aside",
        preserveWhitespace: "full",
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      "aside",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, node.attrs),
      0,
    ];
  },

  addCommands() {
    return {
      setCallout:
        (attributes) =>
        ({ commands }) => {
          return commands.setNode(this.name, attributes);
        },
      toggleCallout:
        (attributes) =>
        ({ commands }) => {
          return commands.toggleNode(this.name, "paragraph", attributes);
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      "Mod-a": ({ editor }) => {
        const { state, view } = editor;
        const { selection } = state;
        const { $from, $anchor } = selection;

        // Find the parent node where the selection currently is
        const parentNode = $from.node($from.depth);

        if (editor.isActive(this.name)) {
          const nodeStart = $from.start($from.depth); // Get the starting position of the <aside> node
          const nodeEnd = nodeStart + parentNode.nodeSize; // Calculate the end position of the <aside> node

          // Set selection inside the <aside> content, ignoring the node boundaries
          const tr = state.tr.setSelection(
            TextSelection.create(state.doc, nodeStart, nodeEnd)
          );
          view.dispatch(tr);

          return true; // Prevent the default Mod-a behavior
        }

        // If not inside an <aside>, fall back to default
        return false;
      },
      // remove callout when at start of document or callout is empty
      Backspace: () => {
        const { empty, $anchor } = this.editor.state.selection;
        const isAtStart = $anchor.pos === 1;

        if (!empty || $anchor.parent.type.name !== this.name) {
          return false;
        }

        if (isAtStart || !$anchor.parent.textContent.length) {
          return this.editor.commands.clearNodes();
        }

        return false;
      },

      ArrowDown: ({ editor }) => {
        const { state } = editor;
        const { selection, doc } = state;
        const { $from, empty } = selection;

        if (!empty || $from.parent.type !== this.type) {
          return false;
        }

        const isAtEnd = $from.parentOffset === $from.parent.nodeSize - 2;

        if (!isAtEnd) {
          return false;
        }

        const after = $from.after();

        if (after === undefined) {
          return false;
        }

        const nodeAfter = doc.nodeAt(after);

        if (nodeAfter) {
          return editor.commands.command(({ tr }) => {
            tr.setSelection(Selection.near(doc.resolve(after)));
            return true;
          });
        }

        return editor.commands.exitCode();
      },
      ArrowUp: ({ editor }) => {
        const { state } = editor;
        const { selection, doc } = state;
        const { $from, empty } = selection;

        // Ensure the selection is empty and inside the CalloutExtension node
        if (!empty || $from.parent.type !== this.type) {
          return false;
        }

        // Check if the selection is at the start of the CalloutExtension
        const isAtStart = $from.parentOffset === 0;

        if (!isAtStart) {
          return false;
        }

        // Calculate the position just before the CalloutExtension node
        const depth = $from.depth;
        const beforePos = $from.before(depth); // Correct position before the current node

        // If `beforePos` is valid, attempt to move the selection to the previous node
        if (beforePos !== undefined && beforePos >= 0) {
          const nodeBefore = doc.nodeAt(beforePos);

          if (nodeBefore) {
            return editor.commands.command(({ tr }) => {
              // Move selection to the nearest valid position before the current node
              tr.setSelection(Selection.near(doc.resolve(beforePos), -1));
              return true;
            });
          }
        }

        // If no previous node is found, exit the current node view
        return editor.commands.exitCode();
      },
    };
  },

  addInputRules() {
    return [
      textblockTypeInputRule({
        find: bangRegex,
        type: this.type,
        getAttributes: (match) => ({
          icon: match[1],
        }),
      }),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(CalloutComp);
  },

  addProseMirrorPlugins() {
    return [
      // this plugin creates a callout for pasted content from VS Code
      // we can also detect the copied code language
      new Plugin({
        key: new PluginKey("calloutPasteHandler"),
        props: {
          handlePaste: (view, event) => {
            if (!event.clipboardData) {
              return false;
            }

            // don’t create a new callout within callouts
            if (this.editor.isActive(this.type.name)) {
              return false;
            }

            const text = event.clipboardData.getData("text/plain");
            const html = event.clipboardData.getData("text/html");

            // Check if html is an <aside> element
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, "text/html");
            const aside = doc.querySelector("aside");

            if (!text || !html || !aside) {
              return false;
            }

            const { tr, schema } = view.state;

            // prepare a text node
            // strip carriage return chars from text pasted as code
            // see: https://github.com/ProseMirror/prosemirror-view/commit/a50a6bcceb4ce52ac8fcc6162488d8875613aacd
            const textNode = schema.text(text.replace(/\r\n?/g, "\n"));

            // create a callout with the text node
            // replace selection with the callout
            tr.replaceSelectionWith(this.type.create({}, textNode));

            if (tr.selection.$from.parent.type !== this.type) {
              // put cursor inside the newly created callout
              tr.setSelection(
                TextSelection.near(
                  tr.doc.resolve(Math.max(0, tr.selection.from - 2))
                )
              );
            }

            // store meta information
            // this is useful for other plugins that depends on the paste event
            // like the paste rule plugin
            tr.setMeta("paste", true);

            view.dispatch(tr);

            return true;
          },
        },
      }),
    ];
  },
});

export const CalloutComp = ({ node, updateAttributes }: NodeViewProps) => (
  <NodeViewWrapper>
    <aside>
      <EmojiSelect
        emoji={node.attrs.icon}
        onChange={(emoji) => updateAttributes({ icon: emoji })}
      />
      <NodeViewContent className={styles.innerAside} as={"div"} />
    </aside>
  </NodeViewWrapper>
);
