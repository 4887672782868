import { useState } from "react";
import { useSetRecoilState } from "recoil";

import { AuthScope } from "@graph/types";
import { authorize, Integration } from "@api";

import { useAsyncEffect } from "@utils/effects";
import { redirect } from "@utils/url";

import {
  addWorkspaceConfig,
  WorkspaceSessionStateAtom,
} from "@state/workspace";

import { VStack } from "@ui/flex";
import { AuthLayout } from "@ui/page-layout";
import { Sheet } from "@ui/sheet-layout";
import { Heading, Text } from "@ui/text";
import { Divider } from "@ui/divider";
import { Icon, SpinnerIcon } from "@ui/icon";

import styles from "./public.module.css";

interface Props {
  code?: string;
  error?: string;
}

export default function SlackConnect({ code, error: slackError }: Props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(slackError);
  const setWorkspace = useSetRecoilState(WorkspaceSessionStateAtom);

  useAsyncEffect(async () => {
    if (loading || !code) {
      return;
    }

    setLoading(true);
    try {
      const config = await authorize(code, Integration.Slack, AuthScope.Person);

      if (!config) {
        throw new Error("No config after create.");
      }

      setWorkspace(addWorkspaceConfig(config));

      // Allow recoil to sync
      setTimeout(() => redirect("/home"), 100);

      setLoading(false);
    } catch (err) {
      setError((err as Error).message);
    }
  }, []);

  return (
    <AuthLayout>
      <VStack>
        <Sheet className={styles.authCard} height="content">
          <VStack gap={30}>
            <VStack>
              <Heading bold>Finalizing...</Heading>
              <Text subtle>
                Please wait a second while we get your workspace ready.
              </Text>
            </VStack>

            <Divider />

            {loading && <Icon size="large" icon={SpinnerIcon} />}
            {error && <Text>{error}</Text>}
          </VStack>
        </Sheet>
      </VStack>
    </AuthLayout>
  );
}
