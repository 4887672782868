import { map } from "lodash";

import { DatabaseID } from "@api";
import { useLazyPropertyValues } from "@state/databases";

import { CommandItem } from "@ui/command-menu";
import { NoneIcon } from "@ui/icon";

import { SetPropertyCommands } from "./types";

export const PersonSelectCommands = ({
  property,
  entities,
  mutate,
}: SetPropertyCommands) => {
  const propType = property.type;
  if (propType !== "relation" && propType !== "relations") {
    throw new Error(
      "PersonSelectCommands can only be used on relation properties."
    );
  }
  const db = entities[0]?.source as DatabaseID;
  const values = useLazyPropertyValues(db, property);

  return (
    <>
      <CommandItem value={""} onClick={() => mutate([])} icon={<NoneIcon />}>
        None
      </CommandItem>
      {map(values[propType], (option) => {
        return (
          <CommandItem
            key={option.id || option.name}
            value={`${option.name} ${option.id}`}
            onClick={() => mutate({ [propType]: [option] })}
            // TODO: Need to inflate person ref
            // icon={<PersonIcon person={option} />}
          >
            {option.name}
          </CommandItem>
        );
      })}
    </>
  );
};
