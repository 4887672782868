import { ReactNode, useState } from "react";

import { Fn } from "@utils/fn";
import { cx } from "@utils/class-names";

import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { TextXLarge, Text } from "@ui/text";
import { Button } from "./button";
import { Modal, Props as ModalProps } from "@ui/modal";
import { ArrowUpLeft, ArrowDownRight, TimesIcon } from "./icon";

import styles from "./dialog.module.css";

interface Props {
  title: string;
  description?: string;
  actions: ReactNode;
  mode?: ModalProps["mode"];
  onDismiss?: Fn<void, void>;
  onAutoFocus?: ModalProps["onAutoFocus"];
  children?: ReactNode;
}

export const Dialog = ({
  children,
  title,
  description,
  onAutoFocus,
  actions,
  mode: _mode = "blocking",
  onDismiss,
}: Props) => {
  const [mode, setMode] = useState(_mode);

  return (
    <Modal
      open={true}
      mode={mode}
      onOpenChanged={(open) => !open && onDismiss?.()}
      className={cx(styles.dialog, styles.fullWidth)}
      onAutoFocus={onAutoFocus}
    >
      <HStack className={styles.container}>
        <VStack fit="container">
          <VStack gap={4} fit="container">
            <SpaceBetween>
              <TextXLarge bold={true}>{title}</TextXLarge>
              <HStack gap={0}>
                <Button
                  subtle
                  icon={mode === "blocking" ? ArrowDownRight : ArrowUpLeft}
                  onClick={() =>
                    setMode(mode === "blocking" ? "passive" : "blocking")
                  }
                />
                <Button subtle icon={TimesIcon} onClick={() => onDismiss?.()} />
              </HStack>
            </SpaceBetween>
            {description && <Text subtle>{description}</Text>}
          </VStack>
          <div className={cx(styles.content, styles.fullWidth)}>{children}</div>
          <HStack gap={12} justify="flex-end" className={cx(styles.fullWidth)}>
            {actions}
          </HStack>
        </VStack>
      </HStack>
    </Modal>
  );
};
