import { ReactNode } from "react";

import { cx } from "@utils/class-names";

import { Button, Props as ButtonProps } from "./button";

import styles from "./button-group.module.css";

export const SplitButton = ({
  selected,
  className,
  ...props
}: ButtonProps & { selected: boolean }) => (
  <Button
    {...props}
    variant={selected ? "primary" : "secondary"}
    subtle
    className={cx(styles.button, className)}
  />
);

export const ButtonGroup = ({
  className,
  fit,
  children,
}: {
  fit?: "container" | "content";
  className?: string;
  children: ReactNode;
}) => (
  <div
    className={cx(styles.group, fit === "container" && styles.full, className)}
  >
    {children}
  </div>
);
