import Head from "next/head";
import { ReactNode, useMemo } from "react";

import {
  AppPage as PageState,
  useActivePage,
  useSyncPageSelection,
} from "@state/app";

import {
  PageSelectionContext,
  SelectionState,
  SetSelectionState,
  useKeyboardSelectable,
  useMouseSelectable,
  useSelectableState,
} from "@utils/selectable";
import { usePushTo } from "@utils/navigation";
import { when } from "@utils/maybe";

import { AppPageContext } from "@ui/app-page";
import { useSelectionShortcuts } from "@ui/selection-shortcuts";
import { MouseSelection } from "@ui/mouse-selection";
import { SelectionPreview } from "@ui/selection-preview";
import { WithViewingWithin } from "@ui/viewing-within";

interface Props {
  page: PageState;
  children: ReactNode;
  title?: string;
  loading?: boolean;
  selection?: SelectionState;
  setSelection?: SetSelectionState;
}

export default function AppPage({
  loading,
  title,
  page,
  children,
  ...rest
}: Props) {
  const pushTo = usePushTo();
  const isActive = useActivePage(page?.id);

  const pageSelection = useSelectableState();
  const selectionState = useMemo(
    (): [SelectionState, SetSelectionState] =>
      rest?.selection && rest?.setSelection
        ? [rest.selection, rest.setSelection]
        : pageSelection,
    [rest.selection, rest.setSelection, pageSelection]
  );
  const [selection, setSelection] = selectionState;

  useKeyboardSelectable(selection, setSelection, isActive);
  const drag = useMouseSelectable(selection, setSelection, isActive);
  useSelectionShortcuts(page.id, { selection, setSelection, onOpen: pushTo });
  useSyncPageSelection(page.id, selection);

  return (
    <AppPageContext.Provider value={page?.id}>
      <WithViewingWithin
        scope={when(page.entity, (e) => [e.source.scope, e.id])}
      >
        <PageSelectionContext.Provider value={selectionState}>
          <Head>
            <title>{loading ? "Loading..." : title}</title>
          </Head>
          <MouseSelection {...drag} />
          {children}
          <SelectionPreview selection={selection} setSelection={setSelection} />
        </PageSelectionContext.Provider>
      </WithViewingWithin>
    </AppPageContext.Provider>
  );
}
