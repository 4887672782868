import { useMemo } from "react";
import { filter, map } from "lodash";

import { Entity, hasBody, hasCode, toTitleOrName } from "@api";

import { useEntityLabels } from "@state/settings";
import { useLazyProperties } from "@state/databases";

import { typeFromId } from "@utils/id";
import { isEmpty } from "@utils/rich-text";
import { withoutStar } from "@utils/wildcards";
import { isEmptyRef, toPropertyValueRef } from "@utils/property-refs";
import { justOne } from "@utils/array";

import { EntityProperties } from "@ui/entity-properties";
import { Divider } from "@ui/divider";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { ReadonlyDocument } from "@ui/rich-text";
import { SectionLabel, TextLarge } from "@ui/text";
import { ShowMoreOverflow } from "@ui/show-more-overflow";
import { CodeLabel } from "@ui/code-label";
import { GoToButton } from "@ui/go-to-button";

import styles from "./entity-preview.module.css";

import { ViewRelationsMenu } from "@ui/view-results-section";

interface EntityPreviewProps<T extends Entity> {
  entity: T;
  editable?: boolean;
  onOpen?: (e: T) => void;
}

export const EntityPreview = <T extends Entity>({
  entity,
  editable = true,
  onOpen,
}: EntityPreviewProps<T>) => {
  const toEntityLabel = useEntityLabels(entity.source.scope);
  const props = useLazyProperties(entity.source);
  const childRelations = useMemo(
    () =>
      filter(
        props,
        (p) => p.type === "relations" && p.options?.hierarchy === "child"
      ),
    [props]
  );

  return (
    <VStack fit="container" className={styles.preview} gap={16}>
      <SpaceBetween align="flex-start">
        <VStack fit="container" gap={0}>
          <HStack>
            <SectionLabel primary>
              {toEntityLabel(entity.source.type || typeFromId(entity.id))}
            </SectionLabel>
            {hasCode(entity) && <CodeLabel code={entity.code} />}
          </HStack>
          <TextLarge bold>{toTitleOrName(entity)}</TextLarge>
        </VStack>
        <GoToButton item={entity} mode="push" />
      </SpaceBetween>

      <EntityProperties
        entityId={entity.id}
        hideSections={true}
        hideEmptyProps={true}
        className={{ propsList: styles.props }}
        editable={editable}
      />

      {map(childRelations, (p) => {
        const value = toPropertyValueRef(entity, p);
        const reffedTyped = justOne(withoutStar(p.options?.references));

        if (isEmptyRef(value) || !reffedTyped) {
          return <></>;
        }

        return (
          <ViewRelationsMenu
            key={p.entity?.[0] + p.field}
            childType={reffedTyped}
            parentId={entity.id}
          />
        );
      })}

      {hasBody(entity) && !isEmpty(entity.body) && (
        <>
          <Divider />
          <ShowMoreOverflow showAll={false} onShowAll={() => onOpen?.(entity)}>
            <ReadonlyDocument
              key={entity?.id}
              className={styles.scaled}
              content={entity.body}
            />
          </ShowMoreOverflow>
        </>
      )}
    </VStack>
  );
};
