import { dotCase } from "change-case";
import { flatMap, reduce, sortBy } from "lodash";

import { Person, Team } from "@api";

import { pushDirty } from "@utils/array";
import { when } from "@utils/maybe";

export const toFullName = (person: Person): string =>
  person?.fullName || person?.name || "Unknown";

export const toDisplayName = (person: Person): string =>
  when(person?.fullName || person?.name, dotCase) || person.id;

export const hasAlphaFeatures = (person: Person) =>
  !!person?.email?.includes("sebastian");

export const sortByCloseness = (
  people: Person[],
  myTeams: Team[],
  me: Person
) => {
  const teammates = reduce(
    flatMap(myTeams, (team) => team.people),
    (acc, ref) => (acc?.includes(ref.id) ? acc : pushDirty(acc, ref.id)),
    [] as string[]
  );
  return sortBy(people, (p, i) => {
    if (p.id === me?.id) {
      return people.length * 2;
    }

    const teamIndex = teammates?.indexOf(p.id);
    return teamIndex === -1 ? people.length + i : teamIndex;
  });
};
