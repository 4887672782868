import { Team } from "@api";

import { useUpdateEntity } from "@state/generic";

import { useGoTo, usePushTo } from "@utils/navigation";
import { respectHandled } from "@utils/event";
import { when } from "@utils/maybe";
import { toNotionUrl } from "@utils/notion";
import { toPropertyValueRef } from "@utils/property-refs";
import { toSlackUrl } from "@utils/slack";

import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Container } from "@ui/container";
import { Heading } from "@ui/text";
import { Cog, Icon, Notion, SlackColor, TeamIcon } from "@ui/icon";
import { Button } from "@ui/button";
import { ColorSelect } from "@ui/select/color";

import styles from "./team-header.module.css";

interface Props {
  team: Team;
}

export const TeamHeader = ({ team }: Props) => {
  const goTo = useGoTo();
  const pushTo = usePushTo();
  const mutate = useUpdateEntity(team.id);

  if (!team) {
    return <h1>Not found.</h1>;
  }

  return (
    <Container padding="none" inset="top">
      <VStack gap={40}>
        <VStack gap={6} className={styles.teamName}>
          <SpaceBetween>
            <HStack>
              <ColorSelect
                color="green"
                onChange={(color) =>
                  mutate({
                    field: "color",
                    type: "text",
                    value: { text: color },
                  })
                }
              >
                {team && <Icon icon={<TeamIcon team={team} />} size="large" />}
              </ColorSelect>
              <Heading bold>{team.name || "Team Home"}</Heading>
            </HStack>

            <HStack gap={4}>
              <Button
                size="small"
                icon={Cog}
                onClick={() => pushTo("/settings/data")}
              >
                Settings
              </Button>
              {team?.notionId && (
                <Button
                  icon={Notion}
                  subtle
                  onClick={respectHandled(() =>
                    when(team?.notionId, (n) => goTo(toNotionUrl(n)))
                  )}
                />
              )}
              {when(
                team &&
                  toPropertyValueRef(team, {
                    field: "settings.channel",
                    type: "text",
                  }),
                ({ value }) => (
                  <Button
                    icon={SlackColor}
                    subtle
                    onClick={respectHandled(() =>
                      when(value.text, (n) => goTo(toSlackUrl(n)))
                    )}
                  />
                )
              )}
            </HStack>
          </SpaceBetween>
        </VStack>
      </VStack>
    </Container>
  );
};
