import { map } from "lodash";
import { useCallback } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { useRegisterPage } from "@state/app";
import { useLogout } from "@state/generic";
import { useMe } from "@state/persons";
import { toLabel, useLazyAllTeams } from "@state/teams";
import { useGoTo } from "@utils/navigation";
import { useShowMore } from "@utils/hooks";

import { PageLayout, Main, SideNav } from "@ui/page-layout";
import { Modal } from "@ui/modal";
import { AppHeader } from "@ui/app-header";
import { MenuGroup } from "@ui/menu-group";
import { Menu } from "@ui/menu";
import { MenuItem, ShowMoreMenuItem } from "@ui/menu-item";
import {
  ArrowUpRight,
  Box,
  Brush,
  Cog,
  Exit,
  EyeSlash,
  LabelAlt,
  TeamIcon,
  TimesIcon,
  TractionHorizontal,
  UsersAlt,
} from "@ui/icon";
import { Button } from "@ui/button";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import { Redirect } from "@ui/redirect";
import { SpaceBetween } from "@ui/flex";
import { Container } from "@ui/container";
import {
  SettingsPersonal,
  SettingsPersonalConnections,
  SettingsPersonalThemes,
} from "@ui/settings-personal";
import {
  SettingsWorkspaceData,
  SettingsWorkspaceProperties,
} from "@ui/settings-workspace";
import { SettingsWorkspacePackages } from "@ui/settings-packages";

import AppPage from "./app-page";

import styles from "./settings-page.module.css";

interface Props {}

function SettingsCore(props: Props) {
  const location = useLocation();
  const me = useMe();
  const goTo = useGoTo();
  const logout = useLogout();
  const allTeams = useLazyAllTeams();
  const { hasMore, visible, showMore } = useShowMore(allTeams, 5);

  const goToSettingsPage = (group: string) => goTo(`/settings${group}`);

  return (
    <PageLayout>
      <SideNav className={styles.nav}>
        <SpaceBetween direction="vertical">
          <Container padding="none" fit="container">
            <TractionHorizontal />
          </Container>

          <Menu className={styles.navMenu}>
            <MenuGroup label="Personal">
              <MenuItem
                text="All Settings"
                icon={Cog}
                onClick={() => goToSettingsPage("/personal")}
              />
              <MenuItem
                text="Themes"
                icon={Brush}
                onClick={() => goToSettingsPage("/themes")}
              />
              <MenuItem
                text="Connections"
                icon={UsersAlt}
                onClick={() => goToSettingsPage("/connections")}
              />
            </MenuGroup>

            <MenuGroup label="Team">
              <MenuItem
                text="Private"
                icon={EyeSlash}
                iconRight={ArrowUpRight}
                onClick={() => goTo(`/${me.id}/settings`)}
              />
              {map(visible, (team) => (
                <MenuItem
                  key={team.id}
                  icon={<TeamIcon team={team} />}
                  text={toLabel(team)}
                  iconRight={ArrowUpRight}
                  onClick={() => goTo(`/${team.id}/settings`)}
                />
              ))}
              {hasMore && <ShowMoreMenuItem onClick={showMore} />}
            </MenuGroup>

            <MenuGroup label="Workspace">
              <MenuItem
                text="Data"
                icon={Cog}
                onClick={() => goToSettingsPage("/data")}
              />
              <MenuItem
                text="Properties"
                icon={LabelAlt}
                onClick={() => goToSettingsPage("/fields")}
              />
              <MenuItem
                text="Packages"
                icon={Box}
                onClick={() => goToSettingsPage("/packages")}
              />
            </MenuGroup>
          </Menu>

          <Menu>
            <MenuGroup label="Actions">
              <MenuItem text="Logout" icon={Exit} onClick={() => logout()} />
            </MenuGroup>
          </Menu>
        </SpaceBetween>
      </SideNav>
      <Main className={styles.main}>
        <Routes location={location}>
          <Route path="personal" element={<SettingsPersonal />} />
          <Route path="themes" element={<SettingsPersonalThemes />} />
          <Route path="connections" element={<SettingsPersonalConnections />} />
          <Route path="data" element={<SettingsWorkspaceData />} />
          <Route path="fields" element={<SettingsWorkspaceProperties />} />
          <Route path="packages" element={<SettingsWorkspacePackages />} />
          <Route path="*" element={<Redirect to="/settings/data" />} />
        </Routes>
      </Main>
    </PageLayout>
  );
}

export function SettingsPage(props: Props) {
  const [page] = useRegisterPage("settings");
  return (
    <AppPage page={page}>
      <StackContainer>
        <Sheet size="full" transparency="mid" interactable={false}>
          <SettingsCore {...props} />
        </Sheet>
      </StackContainer>
    </AppPage>
  );
}

export function SettingsModal(props: Props) {
  const [page] = useRegisterPage("settings-modal");
  const location = useLocation();
  const navigate = useNavigate();

  const handleClose = useCallback(
    () => navigate(location.state.return),
    [location.state?.return?.pathname]
  );

  return (
    <AppPage page={page}>
      <Modal open={true} onOpenChanged={handleClose} className={styles.modal}>
        <AppHeader
          links={[]}
          right={
            <>
              <Button
                as="button"
                subtle
                size="small"
                icon={TimesIcon}
                onClick={handleClose}
              />
            </>
          }
        />
        <SettingsCore {...props} />
      </Modal>
    </AppPage>
  );
}
