import { addDays } from "date-fns";
import {
  filter as loFilter,
  find,
  findIndex,
  flatMap,
  get,
  isString,
  last,
  map,
  reduce,
  set,
  sortBy,
  uniq,
} from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { getRecoil } from "recoil-nexus";

import {
  Entity,
  EntityType,
  HasOrders,
  HasTemplate,
  ID,
  Integration,
  isBaseScopeEntity,
  isTeam,
  PropertyDef,
  PropertyMutation,
  Ref,
  Update,
  View,
} from "@api";

import { useLazyProperties } from "@state/databases";
import {
  useEntitySource,
  useGetItemFromAnyStore,
  useLazyEntities,
  useLazyEntity,
  useLocalChanges,
  useQueueUpdates,
  useReorderItems,
} from "@state/generic";
import {
  clearTempUpdates,
  getItem,
  mergeUpdates,
  queueUpdate,
  removeItem,
} from "@state/store";
import { useActiveWorkspaceId, useAuthedUserId } from "@state/workspace";
import {
  addRefsToFetchResults,
  GlobalFetchOptionsAtom,
  resetFetchedAt,
  useOnFetchResults,
} from "@state/fetch-results";
import { useInstalledEntities } from "@state/packages";

import { debug } from "@utils/debug";
import { OneOrMany, ensureMany, move, omitEmpty } from "@utils/array";
import { differenceInDays } from "@utils/date";
import { replaceStar } from "@utils/wildcards";
import { DragRef, DragToRef } from "@utils/drag-drop";
import { useAsyncEffect } from "@utils/effects";
import { Fn, composel } from "@utils/fn";
import { GroupedValue } from "@utils/grouping";
import { isWorkspaceId, newLocalHumanId } from "@utils/id";
import { equalsAny, ifDo } from "@utils/logic";
import { Maybe, isDefined, safeAs, when } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { toOrder } from "@utils/ordering";
import {
  asMutation,
  asUpdate,
  flattenChanges,
  toMutation,
} from "@utils/property-mutations";
import { getPropertyValue, isAnyRelation, toRef } from "@utils/property-refs";
import { fromISO, useISODate } from "@utils/date-fp";
import { fromScope, toChildLocation, toScope } from "@utils/scope";
import { hashable } from "@utils/serializable";

import { showWarning } from "@ui/notifications";

import {
  mergeItems,
  useApplyUpdateEffect,
  useQueueCreateEffect,
  useQueueDeleteEffect,
  useQueueUpdateEffect,
} from "../store";
import {
  lastFetchedViewResults,
  ViewAtom,
  ViewFetchResultsAtom,
  ViewState,
  ViewStoreAtom,
} from "./atoms";
import {
  getOptimizedItemsForViewLoader,
  getViewsForLocationLoader,
} from "./queries";
import {
  getViews,
  itemsForView,
  viewsForFilter,
  viewsForParent,
} from "./selectors";
import {
  newView,
  propsToSatisfyView,
  readyToFetchViewItems,
  toDateFields,
  toShortTitle,
  toSortKey,
  toViewBaseFilter,
} from "./utils";
import { useArrayKey } from "@utils/react";
import { useMe } from "@state/persons";
import { useActiveSpace } from "@state/spaces";

export const useLazyGetView = (id: ID) => {
  return useLazyEntity<"view">(id);
};

export function useUpdateView(id: ID, temp: boolean = true) {
  const queueUpdate = useQueueUpdateEffect(ViewStoreAtom);
  const applyUpdate = useApplyUpdateEffect(ViewStoreAtom);
  const setFetchResults = useSetRecoilState(ViewFetchResultsAtom(id));

  const view = useRecoilValue(ViewAtom(id));

  return useCallback(
    async (changes: PropertyMutation<View>[]) => {
      // Nothing changed
      if (!view) {
        return;
      }

      const update: Update<ViewState> = {
        id: id,
        source: view.source,
        method: "update",
        mode: temp ? "temp" : undefined,
        changes: map(changes, (c) => ({
          ...c,
          prev: c.prev || getPropertyValue(view, c),
        })),
      };
      queueUpdate(update);
      setFetchResults(resetFetchedAt());
    },
    [view, applyUpdate, queueUpdate]
  );
}

export function useTempView(defaults?: Partial<ViewState>) {
  const workspace = useActiveWorkspaceId();
  const me = useAuthedUserId();
  const [viewId] = useState(newLocalHumanId("view"));
  const view = useRecoilValue(ViewAtom(viewId || ""));
  const setStore = useSetRecoilState(ViewStoreAtom);
  const { changes } = useLocalChanges(viewId, ViewStoreAtom);

  const setup = useCallback(
    (defaults2?: Partial<ViewState>) => {
      const finalDefaults = {
        ...defaults,
        ...defaults2,
      };

      const view = newView({
        id: viewId,
        source: {
          source: Integration.Traction,
          type: "view",
          scope: finalDefaults?.location || finalDefaults?.source?.scope || me,
        },
        ...finalDefaults,
      });

      const tempUpdate: Update<ViewState> = {
        id: view.id,
        method: "create",
        source: view.source,
        mode: "temp",
        changes: loFilter(
          [
            toMutation(view, { field: "entity", type: "text" }),
            toMutation(view, { field: "for", type: "relation" }),
            toMutation(view, { field: "layout", type: "text" }),
            toMutation(view, { field: "grouping", type: "text" }),
            toMutation(view, { field: "name", type: "text" }),
            toMutation(view, { field: "location", type: "text" }),
            toMutation(view, { field: "template", type: "text" }),
            toMutation(view, { field: "filter", type: "json" }),
            toMutation(view, { field: "group", type: "json" }),
            toMutation(view, { field: "sort", type: "json" }),
            toMutation(view, { field: "showProps", type: "json" }),
            toMutation(view, { field: "pinned", type: "boolean" }),
          ] as PropertyMutation<View>[],
          (p) => isDefined(p.value[p.type])
        ),
      };

      // Apply (not save) the changes in the real store
      setStore(queueUpdate(tempUpdate));

      return view;
    },
    [workspace, defaults]
  );

  const create = useCallback(
    (additional?: Update<View>[]) => {
      const merged = mergeUpdates(
        additional ? [...changes, ...additional] : changes
      );
      merged && setStore(queueUpdate({ ...merged, mode: undefined }));
      setStore(clearTempUpdates(viewId));
      return view;
    },
    [view, changes]
  );

  const rollback = useCallback(() => {
    setStore(
      composel(removeItem(view?.id || viewId), clearTempUpdates(viewId))
    );
  }, [view?.id, viewId]);

  return { view, create, setup, rollback };
}

export function useCreateView(teamId?: ID) {
  const queueCreate = useQueueCreateEffect(ViewStoreAtom);
  const workspace = useActiveWorkspaceId();

  return useCallback(
    (defaults: Partial<ViewState>, transaction?: ID) => {
      const view = newView({
        source: {
          source: Integration.Traction,
          type: "view",
          scope: defaults.location || defaults.source?.scope || toScope(teamId),
        },
        ...defaults,
      });

      const changes = loFilter(
        [
          toMutation(view, { field: "for", type: "relation" }),
          toMutation(view, { field: "filter", type: "json" }),
          toMutation(view, { field: "group", type: "json" }),
          toMutation(view, { field: "sort", type: "json" }),
          toMutation(view, { field: "showProps", type: "json" }),
          toMutation(view, { field: "layout", type: "text" }),
          toMutation(view, { field: "name", type: "text" }),
          toMutation(view, { field: "pinned", type: "boolean" }),
          toMutation(view, { field: "person", type: "relation" }),
          toMutation(view, { field: "location", type: "text" }),
        ],
        (p) => isDefined(p.value[p.type])
      );

      queueCreate(view, {
        id: view.id,
        method: "create",
        source: view.source,
        changes: changes,
        transaction,
      });

      return view;
    },
    [workspace, queueCreate]
  );
}

export function useLazyItemsForView(
  id: ID,
  options?: { archived?: boolean; templates?: boolean; quickFilter?: boolean },
  fetch: boolean = true
) {
  const view = useRecoilValue(ViewAtom(id));
  const [loading, setLoading] = useState(false);
  const globalOpts = useRecoilValue(GlobalFetchOptionsAtom);
  const lastFetchedAt = useRecoilValue(lastFetchedViewResults(id));
  const props = useLazyProperties(
    view ? { type: view?.entity, scope: view?.source.scope } : undefined
  );

  const opts = useMemo(
    () => ({
      since: lastFetchedAt,
      // If the archvies are open then we want to fetch all items
      archived: !!globalOpts?.archived ? true : options?.archived,
      // Fallback to showing templates when the parent is a template
      templates: options?.templates,
      quickFilter: options?.quickFilter,
    }),
    [
      globalOpts?.archived,
      options?.archived,
      options?.templates,
      options?.quickFilter,
      safeAs<HasTemplate>(parent)?.template,
    ]
  );

  const items = useRecoilValue(
    itemsForView(
      useMemo(
        () => hashable({ id, ...opts }),
        [id, opts?.archived, opts?.templates, opts?.quickFilter]
      )
    )
  );
  const onLoaded = useOnFetchResults(
    id,
    view?.entity || "task",
    ViewFetchResultsAtom
  );

  useAsyncEffect(async () => {
    // View not ready to fietch
    if (!view || loading) {
      return;
    }
    // Wait for props to load before doing first fetch
    if (!readyToFetchViewItems(view, props)) {
      debug("Not ready to fetch view", view, toViewBaseFilter(view, props));
      return;
    }
    // Fetch=false means freshness is not important, any results are good results
    if (!fetch && !!items.all?.length) {
      return;
    }

    setLoading(true);

    try {
      await getOptimizedItemsForViewLoader(view, opts, props, onLoaded);
    } finally {
      setLoading(false);
    }
  }, [
    id,
    view?.filter,
    view?.entity,
    view?.for?.id,
    globalOpts.archived,
    props.length,
  ]);

  return { items, loading };
}

export function useLinkToView(id: ID, pageId?: ID) {
  const view = useLazyGetView(id);
  const source = useEntitySource(view?.entity || "task", view?.source);
  const available = useLazyProperties(source);
  const queue = useQueueUpdates(pageId);
  const getItem = useGetItemFromAnyStore();
  const changes = useMemo(
    () => (view && available ? propsToSatisfyView(view, available) : []),
    [available]
  );

  return useCallback(
    (t: Ref, groups?: GroupedValue<Entity>[], transaction?: ID) => {
      const entity = getItem(t.id);
      if (entity) {
        queue(
          asUpdate(
            entity,
            [
              ...changes,
              // Pull out props to match groups, overriding props to match view
              ...map(groups, (g) =>
                asMutation({ field: g.field, type: g.type }, g.value[g.type])
              ),
            ],
            transaction
          )
        );
      }
    },
    [queue, getItem]
  );
}

export function useDefaultsForView(id: ID, groups?: GroupedValue<Entity>[]) {
  const me = useMe();
  const space = useActiveSpace();
  const view = useLazyGetView(id);
  const { items } = useLazyItemsForView(id);
  const source = useEntitySource(view?.entity || "task", view?.source);
  const available = useLazyProperties(source);

  return useMemo(() => {
    const sortKey = toSortKey(view);
    const sortOrder =
      when(
        (last(items.sorted) as Maybe<HasOrders>)?.orders,
        (o) => Math.floor(Number(toOrder(o, sortKey))) + 1
      ) || items.all.length;

    return {
      // When view is not filtering to any team/private, then use the personal default location
      ...(isWorkspaceId(view?.for?.id)
        ? {
            location:
              ifDo(!isWorkspaceId(space.id), () => space.id) ||
              safeAs<string>(me?.settings?.defaultLocation),
          }
        : {}),

      // Pull out props to match groups, overriding props to match view
      ...when(view, (v) => flattenChanges(propsToSatisfyView(v, available))),

      orders: {
        default: String(sortOrder),
        [sortKey]: String(sortOrder),
      },
      order: sortOrder,
      // Pull out props to match groups, overriding props to match view
      ...reduce(
        groups,
        (acc, g) => set(acc, g.field as string, g.value[g.type]),
        {}
      ),
    };
  }, [available, view?.filter, items?.all, space.id]);
}

export function useAddToView(id: ID) {
  const setResults = useSetRecoilState(ViewFetchResultsAtom(id));

  return useCallback(
    async (ts: OneOrMany<Ref>) => {
      setResults(addRefsToFetchResults(ensureMany(ts)));
    },
    [setResults]
  );
}

export function useQueueDeleteView(id: ID) {
  const queueDelete = useQueueDeleteEffect(ViewStoreAtom);
  const viewStore = useRecoilValue(ViewStoreAtom);

  return useCallback(() => {
    const view = getItem(viewStore, id);
    if (!view) {
      return;
    }

    queueDelete(view, {
      id: view.id,
      method: "delete",
      source: view.source,
      previous: view,
    });

    return undefined;
  }, [id]);
}

export function useLazyViewsForParent(
  parentId: ID,
  childType: EntityType,
  fetch: boolean = true
) {
  const views = useRecoilValue(
    viewsForParent(hashable({ parent: parentId, type: childType }))
  );
  const setStore = useSetRecoilState(ViewStoreAtom);

  useAsyncEffect(async () => {
    if (!parentId || !fetch) {
      return;
    }

    await getViewsForLocationLoader(parentId, (vs) =>
      setStore(mergeItems(vs as ViewState[]))
    );
  }, [parentId]);

  return useMemo(
    () =>
      sortBy(
        loFilter(views, (v) => v.entity === childType),
        (v, i) => v.order || views.length + i
      ),
    [views, childType]
  );
}

export function useLazyViewsForFilter(
  filter: { type: EntityType; for: Maybe<ID>; location: string },
  fetch: boolean = true
) {
  const hashedFilter = useMemo(
    () => hashable(filter),
    [filter.for, filter.location, filter.type]
  );
  const views = useRecoilValue(viewsForFilter(hashedFilter));
  const setStore = useSetRecoilState(ViewStoreAtom);

  useAsyncEffect(async () => {
    if (!filter.location || !fetch) {
      return;
    }

    await getViewsForLocationLoader(filter.location, (views) =>
      setStore(mergeItems(views))
    );
  }, [filter?.location]);

  return useMemo(
    () =>
      sortBy(
        loFilter(views, (v) => v.entity === filter.type),
        (v, i) => v.order || views.length + i
      ),
    [views, filter.type]
  );
}

export function useAllViewsForParent(parentId: ID, fetch: boolean = true) {
  const [store, setStore] = useRecoilState(ViewStoreAtom);
  const installed = useInstalledEntities(parentId);
  const parent = useLazyEntity(parentId);
  const props = useLazyProperties(parent?.source);
  const childRelations = useMemo(
    () =>
      isTeam(parent)
        ? installed
        : uniq(
            flatMap(props, (p) =>
              isAnyRelation(p) &&
              p.options?.hierarchy === "child" &&
              p.visibility !== "hidden"
                ? ensureMany(replaceStar(p.options.references, installed))
                : []
            )
          ),
    [props]
  );

  const views = useMemo(
    () =>
      flatMap(childRelations, (childType) =>
        getRecoil(
          viewsForParent(hashable({ parent: parentId, type: childType }))
        )
      ),
    [store.lookup, useArrayKey(childRelations)]
  );

  useAsyncEffect(async () => {
    if (!parentId || !fetch) {
      return;
    }

    await getViewsForLocationLoader(parentId, (views) =>
      setStore(mergeItems(views))
    );
  }, [parentId]);

  return useMemo(
    () => sortBy(views, (v, i) => v.entity + v.order || views.length + i),
    [views]
  );
}

export function useLazyViewsForRelation(
  id: ID,
  type: EntityType,
  fetch: boolean = true
) {
  const hashedFilter = useMemo(
    () => hashable({ parent: id, type: type }),
    [id, type]
  );
  const views = useRecoilValue(viewsForParent(hashedFilter));
  const setStore = useSetRecoilState(ViewStoreAtom);

  useAsyncEffect(async () => {
    if (!id || !fetch) {
      return;
    }

    await getViewsForLocationLoader(id, (views) => setStore(mergeItems(views)));
  }, [id]);

  return useMemo(
    () =>
      sortBy(
        loFilter(views, (v) => v.entity === type),
        (v) => v.order ?? views.length
      ),
    [views, type]
  );
}

export function useLazyViews(ids: ID[], fetch: boolean = true) {
  // Make sure all are fetched
  useLazyEntities(
    useMemo(() => map(ids, (id) => toRef(id)), [ids]),
    fetch
  );

  return useRecoilValue(getViews(ids));
}

export function useGoToView(onGoTo?: Fn<View, void>) {
  const me = useAuthedUserId();
  const goTo = useGoTo();
  const location = useLocation();
  return useCallback(
    (view: View) => {
      onGoTo?.(view);

      if (location?.pathname?.startsWith("/home")) {
        goTo(["/home", view]);
      } else if (view?.location === me) {
        goTo(["/boards", view]);
      } else {
        const parents = fromScope(view?.location);
        goTo(!parents?.[0]?.startsWith("u_") ? [...parents, view] : view);
      }
    },
    [goTo, onGoTo, me, location]
  );
}

export function useSourceForView(view: Maybe<View>) {
  const wId = useActiveWorkspaceId();
  const forr = useLazyEntity(view?.for?.id || "");

  return useMemo(
    () => ({
      type: view?.entity || "task",
      scope:
        when(forr, (f) =>
          isBaseScopeEntity(f.source.type)
            ? f.id
            : toChildLocation(f.source.scope, f.id)
        ) ||
        view?.source.scope ||
        wId,
    }),
    [view, forr?.source?.scope]
  );
}

export function useGetPropertiesForView(viewId: ID) {
  const view = useRecoilValue(ViewAtom(viewId));
  const source = useSourceForView(view);
  return useLazyProperties(source);
}

export const useReorderItemsInView = (view: Maybe<View>, pageId?: ID) => {
  return useReorderItems(
    view?.entity || "task",
    pageId,
    toSortKey(view),
    useCallback(
      ([from, to]: [DragRef<Entity>[], DragToRef<Entity>]) => {
        if (
          // There is a sort on the view
          !!view?.sort &&
          // It's not within a group or is within the same group
          (!from[0]?.groups?.[0] || from[0]?.groups?.[0] === to?.groups?.[0])
        ) {
          showWarning("This board has a custom sort applied.");
        }
        // Always try reorder even when custom sort as could be reordeirng within sort value
        return true;
      },
      [view?.sort]
    )
  );
};

export function useDropInView(view: Maybe<View>, pageId?: ID) {
  // Fallback to assuming dates are calendar dates (local time) if not specified
  const type = view?.entity || "task";
  const onReorder = useReorderItemsInView(view, pageId);
  const getItem = useGetItemFromAnyStore();

  return useCallback(
    (source: DragRef<Entity>[], to: DragToRef<Entity>) => {
      // If calendar view and dragging the edge (source.field is set)
      if (view?.layout === "calendar" && !!source[0]?.field) {
        const [startField, endField] = view ? toDateFields(view) : [];
        const date = to.groups?.[0]?.value?.date;
        // Assumes only dragging one item
        const firstItem = getItem(source[0].entity);
        // Drag source determines the field to pin
        const field = source[0].field;
        const oppositeField =
          startField?.field === field ? endField?.field : startField?.field;

        const oppDate =
          when(oppositeField, (opp) => get(firstItem, opp)) ||
          source[0]?.groups?.[0]?.value?.date;

        return onReorder([source[0]], {
          ...to,
          groups: omitEmpty([
            {
              field: field,
              type: "date",
              value: { date },
            },

            // Keep the other value to be the existing value
            // Need to save as some workflows look for just one field being changed keep duration constant
            when(oppositeField, (f) => ({
              field: f,
              type: "date",
              value: { date: oppDate },
            })),
          ]),
        });
      }
      // Calendar, dragging the item (not an edge)
      else if (view?.layout === "calendar" && !source[0]?.field) {
        // Either keep duration if droppping just one item, otherwise
        // just change the start date and blank out the end date
        const [startField, endField] = view ? toDateFields(view) : [];
        const target = to.groups?.[0]?.value?.date;
        const only =
          source?.length === 1 ? getItem(source[0].entity) : undefined;

        // Dropping multiple
        if (!only || !startField || !endField) {
          return onReorder(source, {
            ...to,
            groups: omitEmpty(to.groups),
          });
        }

        const onlyStart = when(startField, (p) =>
          fromISO(getPropertyValue(only, p).date)
        );
        const onlyEnd = when(endField, (p) =>
          fromISO(getPropertyValue(only, p).date)
        );
        const fixedDuration =
          only && onlyStart && onlyEnd
            ? differenceInDays(onlyStart, onlyEnd)
            : undefined;
        const end =
          fixedDuration && target
            ? useISODate(target, (t) => addDays(t, fixedDuration))
            : undefined;

        return onReorder(source, {
          ...to,
          groups: omitEmpty([
            ...(to.groups || []),

            when(end, (date) => ({
              field: endField.field,
              type: "date",
              value: { date: date },
            })),
          ]),
        });
      } else {
        // Groups represent real groups
        return onReorder(source, to);
      }
    },
    [type, view?.layout, onReorder, getItem]
  );
}

export const useToViewTitle = (view: Maybe<View>) => {
  const viewStore = useRecoilValue(ViewStoreAtom);
  const itemSource = useEntitySource(view?.entity || "task", view?.source);
  const itemProps = useLazyProperties(itemSource, false);

  return useCallback(
    (id: ID | View) =>
      when(isString(id) ? getItem(viewStore, id) : id, (id) =>
        toShortTitle(id, itemProps)
      ) ?? "Loading...",
    [itemProps]
  );
};

export const useViewTitle = (ref: Maybe<Ref>) => {
  const view = useLazyGetView(ref?.id || "");
  const itemProps = useLazyProperties(view?.source);

  return useMemo(
    () =>
      view ? toShortTitle(view, (itemProps || []) as PropertyDef[]) : "Board",
    [view]
  );
};

export const useReorderView = (views?: View[], pageId?: ID) => {
  const queueUpdates = useQueueUpdates<View>(pageId);
  return useCallback(
    (source: DragRef<View>[], to: DragToRef<View>) => {
      if (!views) {
        return;
      }

      const dropIndex = findIndex(views, (v) => v.id === to.entity);
      const newIndex = dropIndex + (to.position === "after" ? 1 : 0);
      const dragged = find(views, (v) => v.id === source[0].entity);
      if (!dragged) {
        return;
      }
      const newViews = move(views, views.indexOf(dragged), newIndex);

      queueUpdates(
        map(newViews, (v) =>
          asUpdate(
            v,
            asMutation(
              { field: "order", type: "number" },
              newViews.indexOf(v) + 1
            )
          )
        )
      );
    },
    [views, queueUpdates]
  );
};

export const useSmartProps = (view: Maybe<View>) => {
  const source = useEntitySource(view?.entity || "task", view?.source);
  const props = useLazyProperties(source);
  return useMemo(() => {
    if (view?.layout === "calendar") {
      return [];
    }

    return loFilter(
      props,
      (p) =>
        p.visibility === "show_always" &&
        !p.readonly &&
        !p.options?.hierarchy &&
        !equalsAny(p.type, ["property", "relations", "properties", "json"]) &&
        !equalsAny(p.field, [
          "icon",
          "name",
          "code",
          "title",
          "status",
          "type",
          "body",
        ])
    );
  }, [props]);
};
