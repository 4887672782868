import { getPerson, getPersons } from "@api";
import { cachedFuncByWorkspace } from "@state/workspace";

import { toMilliSeconds } from "@utils/time";

export const getPersonLoader = cachedFuncByWorkspace(
  () => getPerson,
  toMilliSeconds("1 minute"),
  ([v]) => v
);

export const getPersonsLoader = cachedFuncByWorkspace(
  () => getPersons,
  toMilliSeconds("1 minute")
);
