import { useEffect } from "react";

import { useGoTo } from "@utils/navigation";

interface Props {
  to: string;
}

export const Redirect = ({ to }: Props) => {
  const redirect = useGoTo();

  useEffect(() => redirect(to, {}, { replace: true }), []);

  return <></>;
};
