import { map, orderBy } from "lodash";
import { selector } from "recoil";

import { maybeMap } from "@utils/maybe";
import { toRef } from "@utils/property-refs";

import { RecentsAtom } from "./atoms";

export const recentsList = selector({
  key: "recentsList",
  get: ({ get }) => {
    const tuples = map(get(RecentsAtom).lookup, (date, id) => [id, date]);
    const ordered = orderBy(tuples, ([, date]) => date, "desc");
    return maybeMap(ordered, ([id]) => id);
  },
});

export const recentsListAsRefs = selector({
  key: "recentsListAsRefs",
  get: ({ get }) => {
    const tuples = map(get(RecentsAtom).lookup, (date, id) => [id, date]);
    const ordered = orderBy(tuples, ([, date]) => date, "desc");
    return maybeMap(ordered, ([id]) => toRef(id));
  },
});
