import { useMemo, useState } from "react";
import { map } from "lodash";

import { Meeting, ID, HasRefs } from "@api";

import {
  useLazyEntities,
  useLazyEntity,
  useQueueUpdates,
} from "@state/generic";
import { useLazyPropertyDef } from "@state/databases";
import { useAutoLinkRelated } from "@state/meetings";

import { useShowMore } from "@utils/hooks";
import { safeAs } from "@utils/maybe";
import {
  asAppendMutation,
  asMutation,
  asUpdate,
} from "@utils/property-mutations";
import { useGoTo, usePushTo } from "@utils/navigation";
import { toRef } from "@utils/property-refs";
import { toBaseScope } from "@utils/scope";

import { usePageId } from "@ui/app-page";
import { ArrowUpRight, LinkAdd, PlusAlt, RelationIcon } from "@ui/icon";
import { Sheet } from "@ui/sheet-layout";
import { AddMenuItem, ShowMoreMenuItem } from "@ui/menu-item";
import { GlobalEntitySelect, GlobalMultiEntitySelect } from "@ui/select";
import { PaneContainer, PaneHeader } from "@ui/pane-header";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { ResourceExtractor } from "@ui/resource-extractor";
import { Button } from "@ui/button";
import { HStack } from "@ui/flex";
import { useSyncPaneCount } from "@ui/pane-manager";

import { render, toEngine } from "..";
import { MeetingCreateDialog } from "./create-dialog";

export const MeetingLinksPane = ({ meeting }: { meeting: Meeting }) => {
  const pageId = usePageId();
  const goTo = useGoTo();
  const related = useLazyEntities(meeting.refs.related || []);
  const mutate = useQueueUpdates(pageId);
  const relatedDef = useLazyPropertyDef(meeting.source, {
    field: "refs.related",
    type: "relations",
  });
  const { visible, showMore, hasMore, moreCount } = useShowMore(
    related || [],
    5
  );

  // Automatically link work that is mentioned in the agendas....
  useAutoLinkRelated(meeting, pageId);

  return (
    <Sheet size="secondary">
      <PaneHeader title="Related work" />

      <ResourceExtractor entityId={meeting.id} />

      <PaneContainer>
        <Menu>
          <MenuGroup>
            {map(visible, (item) =>
              render(toEngine(item)?.asMenuItem, {
                key: item.id,
                item: item,
                onChange: (cs) => mutate(asUpdate(item, cs)),
                onOpen: goTo,
                iconRight: ArrowUpRight,
              })
            )}

            {hasMore && (
              <ShowMoreMenuItem count={moreCount} onClick={showMore} />
            )}

            <GlobalMultiEntitySelect
              value={meeting.refs.related}
              scope={meeting.source.scope}
              allowed={relatedDef?.options?.references}
              onChange={(r) =>
                mutate(
                  asUpdate(
                    meeting,
                    asMutation({ field: "refs.related", type: "relations" }, r)
                  )
                )
              }
              closeOnSelect={true}
              placeholder="Link work"
            >
              <AddMenuItem
                icon={RelationIcon}
                title="Link related work"
                subtle
              />
            </GlobalMultiEntitySelect>
          </MenuGroup>
        </Menu>
      </PaneContainer>
    </Sheet>
  );
};

export const RelatedMeetingsPane = ({ entityId }: { entityId: ID }) => {
  const pageId = usePageId();
  const pushTo = usePushTo();
  const [creating, setCreating] = useState(false);
  const entity = useLazyEntity(entityId);
  const allMeetings = useLazyEntities(
    safeAs<HasRefs>(entity)?.refs?.meetings || []
  );
  const mutate = useQueueUpdates(pageId);
  const defaults = useMemo(
    () =>
      creating && entity?.source.scope
        ? {
            location: toBaseScope(entity?.source.scope),
            refs: { related: [toRef(entityId)] },
          }
        : {},
    [creating, entityId]
  );
  const meetings = useShowMore(allMeetings || [], 8);

  // Sync the count with the pane manager
  useSyncPaneCount(allMeetings?.length);

  if (!entity) {
    return <></>;
  }

  return (
    <Sheet size="secondary">
      <PaneHeader title="Meetings" />

      {creating && (
        <MeetingCreateDialog
          defaults={defaults}
          onCancel={() => setCreating(false)}
          onSaved={(meeting) => {
            setCreating(false);
            mutate(
              asUpdate(
                entity,
                asAppendMutation(
                  { field: "refs.meetings", type: "relations" },
                  [meeting]
                )
              )
            );
          }}
        />
      )}

      <PaneContainer>
        <Menu>
          <MenuGroup>
            {map(meetings.visible, (item) =>
              render(toEngine(item)?.asMenuItem, {
                key: item.id,
                item: item,
                onChange: (cs) => mutate(asUpdate(item, cs)),
                onOpen: pushTo,
                iconRight: ArrowUpRight,
              })
            )}

            {meetings.hasMore && (
              <ShowMoreMenuItem
                count={meetings.moreCount}
                onClick={meetings.showMore}
              />
            )}

            <HStack gap={0}>
              <GlobalEntitySelect
                value={undefined}
                scope={entity?.source.scope}
                allowed={["meeting"]}
                onChange={(r) =>
                  entity &&
                  !!r &&
                  mutate(
                    asUpdate(
                      entity,
                      asAppendMutation(
                        { field: "refs.meetings", type: "relations" },
                        [r]
                      )
                    )
                  )
                }
                closeOnSelect={true}
                placeholder="Link meeting"
              >
                <Button icon={LinkAdd} subtle size="small">
                  Link existing
                </Button>
              </GlobalEntitySelect>

              <Button
                subtle
                icon={PlusAlt}
                size="small"
                onClick={() => setCreating(true)}
              >
                New Meeting
              </Button>
            </HStack>
          </MenuGroup>
        </Menu>
      </PaneContainer>
    </Sheet>
  );
};
